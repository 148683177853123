export enum PatientChartNodeType {
  RootNode = 1,
  DocumentNode,
  SectionNode,
  TemplateNode,
  TemplateListNode,
  TobaccoHistoryNode,
  ChiefComplaintNode,
  DrugHistoryNode,
  AlcoholHistoryNode,
  PreviousMedicalHistoryNode,
  PreviousSurgicalHistoryNode,
  FamilyHistoryNode,
  EducationNode,
  OccupationalHistoryNode,
  AllergiesNode,
  MedicationsNode,
  VitalSignsNode,
  AssessmentNode,
  ReviewedMedicalRecordsNode,
  ScanDocumentNode,
  PrescriptionNode,
  AddendumNode,
  ProblemListNode,
  CareTeamNode,
}
