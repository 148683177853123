import { Component, Input, OnInit } from '@angular/core';
import { Constants } from 'src/app/_classes/constants';

@Component({
  selector: 'admin-rich-text-editor',
  templateUrl: './admin-rich-text-editor.component.html',
})
export class AdminRichTextEditorComponent implements OnInit {
  @Input() initialContent?: string;
  @Input() height?: number;
  editorConfig: any;
  editor: any;
  configData: any = {};
  constructor(
  ) {

  }

  get content(): string {
    return this.editor.getContent();
  }

  ngOnInit() {
    this.editorConfiguration();
  }

  insertContent(content: string) {
    const wrappedContent = `<span>&nbsp;</span>${content}<span>&nbsp;</span>`;

    this.editor.execCommand('mceInsertContent', false, wrappedContent);
  }


  private editorConfiguration() {
    this.editorConfig = {
      extended_valid_elements: `${Constants.selectableItem.tagName.toLowerCase()}[${Constants.selectableItem.attributes.id}|${Constants.selectableItem.attributes.metadata}|style]`,
      content_style: Constants.tinymceStyles.detailedEditor,
      height: 400,
      body_class: 'admin-rich-text-editor',
      ui_container: '.popup',
      plugins: ["export", "lists", "table", "image", "powerpaste"],
      fontsize_formats: '8pt 10pt 12pt 14pt 18pt 20pt 24pt',
      menubar: true,
      toolbar: 'export insertfile undo redo | fontsize | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | image',
      powerpaste_allow_local_images: true,
      powerpaste_word_import: 'prompt',
      powerpaste_html_import: 'prompt',
      browser_spellcheck: true,
      setup: (editor: any) => {
        this.editor = editor;
      },
      init_instance_callback: (editor: any) => {
        editor && this.initialContent && editor.setContent(this.initialContent);
      },
    };
  }


}
