import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Constants } from 'src/app/_classes/constants';
import { EnvironmentUrlService } from 'src/app/_services/environment-url.service';


@Component({
  selector: 'notes-editor',
  templateUrl: './notes-editor.component.html',
  styleUrls: ['./notes-editor.component.sass'],
})
export class NotesEditorComponent implements OnInit {
  @Input() initialContent?: string = '';
  @Output() contentChanged = new EventEmitter<string>();
  editorConfig: any;
  editor: any;
  configData: any = {};
  constructor(
    private envService: EnvironmentUrlService
  ) {

  }

  insertContent(content: string) {
    if (content === null || content === undefined || content.trim() === "") {
      return;
    }
    const spaceRemovedContent = content.replace(/(\s?&nbsp;)+/g, '&nbsp;');
    const wrappedContent = `${spaceRemovedContent}`;
    this.editor.execCommand('mceInsertContent', false, wrappedContent);
  }

  get content(): string {
    return this.editor.getContent();
  }

  ngOnInit() {
    this.editorConfiguration();
  }

  showPhrasesHelper($event: any) {
    $event.preventDefault();
  }

  private editorConfiguration() {
    this.editorConfig = {
      extended_valid_elements: `${Constants.selectableItem.tagName.toLowerCase()}[${Constants.selectableItem.attributes.id}|${Constants.selectableItem.attributes.metadata}|style]`,
      content_style: Constants.tinymceStyles.detailedEditor,
      height: 300,
      body_class: 'admin-rich-text-editor',
      ui_container: '.popup',
      plugins: ["export", "lists", "table", "image", "powerpaste"],
      fontsize_formats: '8pt 10pt 12pt 14pt 18pt 20pt 24pt',
      menubar: true,
      toolbar: 'export insertfile undo redo | fontsize | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | image',
      powerpaste_allow_local_images: true,
      powerpaste_word_import: 'prompt',
      powerpaste_html_import: 'prompt',
      browser_spellcheck: true,
      images_upload_url: `${this.envService.urlAddress}Order/uploadFile`,
      setup: (editor: any) => {
        this.editor = editor;
      },
      init_instance_callback: (editor: any) => {
        editor && this.initialContent && editor.setContent(this.initialContent);
      },
    };
  }

  public clearContent() {
    this.editor.setContent('');
  }

  private emitContentChange() {
    const content = this.editor.getContent();

    this.contentChanged.emit(content);
  }
}
