import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TrowserViewBase } from 'src/app/shared/base-classes';
import { ButtonOptions } from 'src/app/shared/utils';
import { ReviewChartAdmissionComponent } from '../review-chart-admission/review-chart-admission.component';
import { ComponentHelper } from 'src/app/shared/utils/helpers/component.helper';
import { PatientPreviousAdmissionsComponent } from '../patient-previous-admissions/patient-previous-admissions.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-previous-appointments',
  templateUrl: './previous-appointments.component.html',
  styleUrls: ['./previous-appointments.component.sass']
})
export class PreviousAppointmentsComponent extends TrowserViewBase implements OnInit {
  @ViewChild('componentPreviousContainer', { static: false, read: ViewContainerRef }) componentContainer: ViewContainerRef;
  public showReviewChartComponentRef: ComponentRef<ReviewChartAdmissionComponent>;
  public showPatientAdmissionComponentRef: ComponentRef<PatientPreviousAdmissionsComponent>;
  public popupToolbarItems: any[] = [];
  public dtsPreviousAdmissions: any[] = [];
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private router: Router) {
    super();
    this.initPopupToolbarItems();
  }

  ngOnInit(): void {
  }

  public showPreviousAppointment(entity) {
    // this.appointmentService
    //   .getPatientPreviousVisits(entity.patientId, entity.startDate)
    //   .then(response => {
    //     this.dtsPreviousAdmissions = response;
    //     this.popupVisible = true;
    //   });
    this.dtsPreviousAdmissions = [
      {
        "companyId": "864b9abc-772e-ea11-a601-0003ff2a4338",
        "allegations": "PFT, walk test and EKG",
        "allegationsNotes": "",
        "startDate": "2023-08-03T18:00:00",
        "lastEditDate": "2023-08-03T18:00:00",
        "appointmentTime": "06:00 PM",
        "date": "2023-08-03T00:00:00",
        "endDate": "2023-08-03T18:30:00",
        "appointmentStatus": "Scheduled",
        "locationName": "Glendale",
        "locationId": "e1699912-04fd-ea11-af8c-00155dfc2b14",
        "roomId": "3871cc21-04fd-ea11-af8c-00155dfc2b14",
        "roomName": "One",
        "patientId": "595b70e1-be03-ee11-907c-000d3a73954d",
        "patientFirstName": "Cricket",
        "patientLastName": "Chatfield-De Sylvia",
        "patientNameSuffix": null,
        "patientDateOfBirth": "1945-12-09T07:00:00",
        "physicianId": "b5a28090-c32f-ea11-a601-0003ff2a4338",
        "physicianFirstName": "Glenn",
        "physicianLastName": "Kunsman",
        "nurseId": "528ec847-c22f-ea11-a601-0003ff2a4338",
        "admissionId": "f97e1828-c92c-ee11-907e-000d3a710c12",
        "nurseFirstName": "Melissa",
        "nurseLastName": "Kunsman",
        "mrn": null,
        "totalNumberOfPatientAppointments": 3,
        "signingDate": null,
        "previousAppointmentDate": "2023-07-27T21:00:00",
        "patientChartDocumentNodes": null,
        "appointmentPatientChartDocuments": [
          {
            "name": "progressNote",
            "title": "Progress Note"
          }
        ],
        "providerIds": [],
        "maIds": [],
        "appointmentType": null,
        "newDiagnosises": [],
        "chiefComplaints": null,
        "currentDiagnosises": [],
        "currentChiefComplaints": [],
        "careTeamIds": [],
        "notes": null,
        "id": "b58fba05-c82c-ee11-907e-000d3a710c12"
      },
      {
        "companyId": "864b9abc-772e-ea11-a601-0003ff2a4338",
        "allegations": "NP- get established, labs",
        "allegationsNotes": null,
        "startDate": "2023-07-27T21:00:00",
        "lastEditDate": "2023-08-03T18:00:00",
        "appointmentTime": "09:00 PM",
        "date": "2023-07-27T00:00:00",
        "endDate": "2023-07-27T22:00:00",
        "appointmentStatus": "Billed",
        "locationName": "Glendale",
        "locationId": "e1699912-04fd-ea11-af8c-00155dfc2b14",
        "roomId": "3871cc21-04fd-ea11-af8c-00155dfc2b14",
        "roomName": "One",
        "patientId": "595b70e1-be03-ee11-907c-000d3a73954d",
        "patientFirstName": "Cricket",
        "patientLastName": "Chatfield-De Sylvia",
        "patientNameSuffix": null,
        "patientDateOfBirth": "1945-12-09T07:00:00",
        "physicianId": "b5a28090-c32f-ea11-a601-0003ff2a4338",
        "physicianFirstName": "Glenn",
        "physicianLastName": "Kunsman",
        "nurseId": "528ec847-c22f-ea11-a601-0003ff2a4338",
        "admissionId": "bdbebb39-b02c-ee11-907e-000d3a710c12",
        "nurseFirstName": "Melissa",
        "nurseLastName": "Kunsman",
        "mrn": null,
        "totalNumberOfPatientAppointments": 3,
        "signingDate": null,
        "previousAppointmentDate": null,
        "patientChartDocumentNodes": null,
        "appointmentPatientChartDocuments": [
          {
            "name": "historyAndPhysical",
            "title": "History And Physical"
          }
        ],
        "providerIds": [],
        "maIds": [],
        "appointmentType": null,
        "newDiagnosises": [],
        "chiefComplaints": null,
        "currentDiagnosises": [],
        "currentChiefComplaints": [],
        "careTeamIds": [],
        "notes": null,
        "id": "a480f532-2906-ee11-907c-000d3a73954d"
      }
    ]
    this.popupVisible = true;
  }


  private initPopupToolbarItems(): void {
    this.popupToolbarItems = super.getTrowserToolbarItems(
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('Cancel', true, this.onBtnCancelClick, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', null, null, this.isDataProcessing),
    );
  }

  private onBtnCancelClick = () => {
    this.popupVisible = false;
  }

  public onBtnPatientChartClick(appointmentId: string) {
    this.router.navigate(['/patient-chart', appointmentId]);
  }

  public onBtnReviewChartClick(entity) {
    this.showReviewChartDetail(entity);
  }

  public showReviewChartDetail(entity) {
    this.showReviewChartComponentRef =
      ComponentHelper.addComponent(this.componentFactoryResolver, this.componentContainer, ReviewChartAdmissionComponent);
    const detailInstance = this.showReviewChartComponentRef.instance;
    detailInstance.popupTitle = "Patient Chart - ";
    detailInstance.showReviewChart(entity);
    detailInstance.popupHiddenEvent.subscribe(response => {
      ComponentHelper.removeComponent(this.componentContainer, this.showReviewChartComponentRef);
    });
  }

  public onBtnPreviousAdmissionClick(entity) {
    this.showPatientPreviousAdmissionDetail(entity);
  }

  public showPatientPreviousAdmissionDetail(entity) {
    this.showPatientAdmissionComponentRef =
      ComponentHelper.addComponent(this.componentFactoryResolver, this.componentContainer, PatientPreviousAdmissionsComponent);
    const detailInstance = this.showPatientAdmissionComponentRef.instance;
    detailInstance.popupTitle = "Patient Chart - ";
    detailInstance.showPreviousAdmission(entity);
    detailInstance.popupHiddenEvent.subscribe(response => {
      ComponentHelper.removeComponent(this.componentContainer, this.showPatientAdmissionComponentRef);
    });
  }


}
