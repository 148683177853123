import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DxDataGridComponent,
  DxFormComponent,
  DxPopupComponent,
} from 'devextreme-angular';
import { SearchConfiguration } from 'src/app/_classes/searchConfiguration';
import { AlertService } from 'src/app/_services/alert.service';
import { ErrorHandlerService } from 'src/app/_services/error-handler.service';
import { RepositoryService } from 'src/app/_services/repository.service';
import { PatientService } from 'src/app/_services/patient.service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxDataUrlService } from 'src/app/_services/dxDataUrl.service';
import { DevextremeAuthService } from 'src/app/_services/devextreme-auth.service';

@Component({
  selector: 'app-care-teams',
  templateUrl: './care-teams.component.html',
  styleUrls: ['./care-teams.component.sass'],
})
export class CareTeamsComponent implements OnInit {
  @ViewChild('careTeamFormDataGrid', { static: false })
  careTeamFormDataGrid!: DxDataGridComponent;
  @ViewChild('careTeamAddPopup', { static: false })
  careTeamAddPopup!: DxPopupComponent;
  @ViewChild('careTeamForm', { static: false })
  careTeamForm!: DxFormComponent;
  @Input() isSignedOff!: boolean;
  @Input() companyId!: string;
  @Input() patientId?: string;
  @Input() appointmentId?: string;

  searchConfiguration: SearchConfiguration = new SearchConfiguration();
  careTeamDataSource: any;
  allCareTeamDataSource: any = {};
  loading = false;
  isCareTeamFormOpened = false;
  isNewCareTeam = false;
  title = 'Add Care Team';
  careTeamIds: any;
  careTeamData: any;
  appointmentCareTeamData: any = {};
  id: any;
  oldNpi: any;
  npi: any;
  providerLastName: any;
  providerMiddleName: any;
  providerFirstName: any;
  phoneNumber: any;
  practiceLocationAddress: any;

  constructor(
    private alertService: AlertService,
    private repositoryService: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private dxDataUrlService: DxDataUrlService,
    private devextremeAuthService: DevextremeAuthService
  ) { }

  private bindCareTeam() {
    const apiUrl = `appointment/careteams/${this.appointmentId}`;
    this.repositoryService.getData(apiUrl).subscribe({
      next: res => {
        if (res.success) {
          this.careTeamDataSource = res.data;
          const npiList = this.careTeamDataSource.map(careTeam => careTeam.npi);
          this.careTeamIds = npiList.join(',');
        } else {
          this.alertService.error(res.message);
        }

        this.loading = false;
      },
      error: error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      },
    });
  }
  private initCareTeamDataSource(): void {
    const apiUrl = `CareTeam/Get-AllCareTeam`;
    this.repositoryService.getData(apiUrl).subscribe({
      next: res => {
        if (res.success) {
          this.allCareTeamDataSource = res.data;
        } else {
          this.alertService.error(res.message);
        }

        this.loading = false;
      },
      error: error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      },
    });
  }
  onCareTeamChanged(e: any) {
    const npi = e.value;
    this.getCareTeamByNPI(npi);
  }
  private getCareTeamByNPI(npi): void {
    const apiUrl = `CareTeam/GetByNpi-CareTeam/${npi}`;
    this.repositoryService.getData(apiUrl).subscribe({
      next: res => {
        if (res.success) {
          this.npi = res.data.npi;
          this.providerLastName = res.data.providerLastName;
          this.providerMiddleName = res.data.providerMiddleName;
          this.providerFirstName = res.data.providerFirstName;
          this.phoneNumber = res.data.phoneNumber;
          this.practiceLocationAddress = res.data.practiceLocationAddress;
        } else {
          this.alertService.error(res.message);
        }

        this.loading = false;
      },
      error: error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      },
    });
  }
  openVendorForm() {
    this.title = 'Add Care Team';
    this.isCareTeamFormOpened = true;
    this.appointmentCareTeamData = {};
    this.isNewCareTeam = true;
    this.npi = 0;
    this.getCareTeamByNPI(this.npi);
  }

  createCareTeam() {
    const validationResult = this.npi;

    if (validationResult == 0) {
      return;
    }
    this.appointmentCareTeamData.id = this.appointmentId;
    this.appointmentCareTeamData.careTeamIds = this.careTeamIds + ',' + this.npi;
    const apiUrl = `appointment/update-careteam`;
    this.repositoryService.update(apiUrl, this.appointmentCareTeamData).subscribe({
      next: res => {
        if (res.success) {
          this.alertService.info('Added');
          this.isCareTeamFormOpened = false;
          this.refreshCareTeamDataGrid();
          this.bindCareTeam();
        } else {
          this.alertService.error(res.message);
        }

        this.loading = false;
      },
      error: error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      },
    });
  }

  editCareTeam(args: any) {
    this.title = 'EDIT CARE TEAM';
    this.isCareTeamFormOpened = true;
    this.isNewCareTeam = false;
    this.appointmentCareTeamData = {};
    this.oldNpi = args.npi;
    this.npi = args.npi;
    this.getCareTeamByNPI(args.npi);
  }

  updateCareTeam() {
    const validationResult = this.npi;
    if (validationResult == 0) {
      return;
    }
    const ctList = this.careTeamIds.split(',').map(id => id.trim());
    const isNpiInList = ctList.includes(this.npi);
    if (!isNpiInList) {
      const regex = new RegExp(`\\b${this.oldNpi}\\b`, 'g');
      this.careTeamIds = this.careTeamIds.replace(regex, this.npi);
    }
    this.appointmentCareTeamData.id = this.appointmentId;
    this.appointmentCareTeamData.careTeamIds = this.careTeamIds;
    const apiUrl = `appointment/update-careteam`;
    this.repositoryService.update(apiUrl, this.appointmentCareTeamData).subscribe({
      next: res => {
        if (res.success) {
          this.alertService.info(res.message);
          this.isCareTeamFormOpened = false;
          this.refreshCareTeamDataGrid();
          this.bindCareTeam();
        } else {
          this.alertService.error(res.message);
        }

        this.loading = false;
      },
      error: error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      },
    });
  }

  deleteCareTeam(npi: string) {
    const npiString = npi.toString();
    const ctList = this.careTeamIds.split(',').map(id => id.trim());
    const filteredList = ctList.filter(id => id !== npiString);
    this.careTeamIds = filteredList.join(',');

    this.appointmentCareTeamData.id = this.appointmentId;
    this.appointmentCareTeamData.careTeamIds = this.careTeamIds;
    const apiUrl = `appointment/update-careteam`;
    this.repositoryService.update(apiUrl, this.appointmentCareTeamData).subscribe({
      next: res => {
        if (res.success) {
          this.alertService.info('Deleted');
          this.isCareTeamFormOpened = false;
          this.refreshCareTeamDataGrid();
          this.bindCareTeam();
        } else {
          this.alertService.error(res.message);
        }

        this.loading = false;
      },
      error: error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      },
    });
  }

  refreshCareTeamDataGrid() {
    this.careTeamFormDataGrid.instance.refresh();
  }

  ngOnInit() {
    this.initCareTeamDataSource();
    //this.initCareTeamDataSource1();
    this.bindCareTeam();
  }

  onVendorSelected(_$event: any) {
    throw 'onVendorSelected is not implemented';
  }
}
