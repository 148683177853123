import { Component, OnInit } from '@angular/core';
import { TrowserViewBase } from 'src/app/shared/base-classes';

@Component({
  selector: 'app-company-detail-info',
  templateUrl: './company-detail-info.component.html'
})
export class CompanyDetailInfoComponent extends TrowserViewBase implements OnInit {
  public currentCompanyId: any;
  constructor() {
    super();
  }

  ngOnInit(): void {
  }


  public showCompanyDetail(entity) {
    this.currentCompanyId = entity.id;
    this.popupVisible = true;
  }

  onCompanyHiding(e) {
    this.popupVisible = false;
  }
}
