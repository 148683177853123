import { Action, SystemUtility } from "../../utils";
import { CustomDialogHelper } from "../../utils/helpers/custom-dialog.helper";
import _ from 'lodash';
export class ViewBase {
  public emailPattern: any = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
  public phonePattern: any = /^[^A-Za-z]+$/;
  public isBatchAction = false;
  public isDataProcessing = false;
  public grdSelectedRows: any[] = [];
  public popupWidth;
  public popupHeight;
  public grdStoringEnabled = false;
  public popupPageSize = 5;
  public listPageSize = 25;
  public dtsBatchActions: Action[] = [];
  public dtsActions: Action[] = [];
  public compareIgnoreFields: any[] = [];
  public dateFormat = 'MM/dd/yyyy';
  public dateTimeFormat = 'MM/dd/yyyy hh:mm a';
  public formateTime = 'hh:mm a';
  constructor() {
    this.popupWidth = 800;
    this.popupHeight = 400;
  }

  protected setActionsStatus(actions: Action[], entity?: any) {
    actions.forEach(item => {
      if (item.text !== 'No action') {
        item.disabled = false;
        item.visible = item.getVisibleStatus(entity);
        if (item.getText) {
          item.text = item.getText(entity);
        }
      }
    });
    this.settingNoAction(actions);
  }

  protected disableAllActions(actions: Action[]) {
    actions.forEach(item => {
      item.visible = true;
      item.disabled = true;
    });
    this.settingNoAction(actions);
  }

  private settingNoAction(actions: Action[]) {
    if (this.isBatchAction) {
      const index = actions.findIndex(p => p.text === 'No action');
      if (actions.filter(p => p.visible).length === 0
        ||
        (actions.filter(p => p.visible).length === 1
          && actions.filter(p => p.visible && p.text === 'No action').length === 1)) {
        if (index < 0) {
          actions.push(new Action('', 'No action', true, '', true, () => true, true));
        }
      } else {
        if (index >= 0) {
          actions.splice(index, 1);
        }
      }
    }
  }

  public isEqual(objA: any, objB: any) {
    const objectA = this.pickDeep(objA, this.pickFunction);
    const objectB = this.pickDeep(objB, this.pickFunction);

    return _.isEqualWith(objectA, objectB, (leftVal, rightVal, key) => {
      return this.comparatorFunction(leftVal, rightVal, key);
    });
  }

  private comparatorFunction(leftVal: any, rightVal: any, key: any) {
    return undefined;
  }

  private pickFunction = (val: any, key: any) => {
    if (typeof (val) === 'undefined' || val === null || val === '' || this.compareIgnoreFields.includes(key)) {
      return false;
    }
    return true;
  }

  public pickDeep = (collection: any, predicate: any) => {
    predicate = _.iteratee(predicate);

    return _.transform(collection, (memo: any = [], val, key) => {
      let include = predicate(val, key);
      if (include && _.isObject(val) && !_.isDate(val)) {
        val = this.pickDeep(val, predicate);
        include = !_.isEmpty(val);
      }
      if (include) {
        _.isArray(collection) ? memo.push(val) : memo[key] = val;
      }
    });
  }

  protected confirmUnsavedChanges(saveCallbackFn: any = null, unsavedCallbackFn: any = null, cancelCallbackFn: any = null) {
    CustomDialogHelper.unsavedChangesConfirm()
      .show().done((popupResult: any) => {
        const unsavedConfirmResult = sessionStorage.getItem('unsavedConfirmResult');
        if (unsavedConfirmResult && popupResult && unsavedConfirmResult === 'Save') {
          sessionStorage.removeItem('unsavedConfirmResult');
          saveCallbackFn();
        } else if (!unsavedConfirmResult && popupResult) {
          unsavedCallbackFn();
        } else if (!popupResult) {
          if (cancelCallbackFn !== null) {
            cancelCallbackFn();
          }
        }
      });
  }


  protected toggleLoadingPanel(isShown: boolean, target: any = 'body'): void {
    SystemUtility.toggleLoadingPanel(isShown, target);
  }

  protected notifySuccessMsg(message: string) {
    CustomDialogHelper.notifySuccessMsg(message);
  }

  protected notifyErrorMsg(message: string) {
    CustomDialogHelper.alertErrorMsg(message);
  }

}
