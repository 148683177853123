import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IAdminSelectableComponent } from 'src/app/administration/interfaces/iAdminSelectableComponent';
import { BaseSelectableListComponent } from 'src/app/share/classes/baseSelectableListComponent';
import { BaseSelectableComponent } from 'src/app/share/classes/baseSelectableComponent';

@Component({
  selector: 'admin-selectable-name',
  templateUrl: './admin-selectable-name.component.html',
})
export class AdminSelectableNameComponent
  extends BaseSelectableListComponent
  implements IAdminSelectableComponent, OnInit
{
  @Output() selectableHtmlElementGenerated: EventEmitter<string> = new EventEmitter();
  @Input() companyId?: string;

  selectableListId = '';
  selectableListDataSource: any = {};

  constructor(
  ) {
    super();
  }

  get isLibrarySelectableList(): boolean {
    return !this.companyId;
  }

  get selectableComponent(): BaseSelectableComponent {
    return this;
  }

  ngOnInit() {
  }

}
