import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CareTeamService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  getAllByPatientId(patientId: string) {
    return firstValueFrom(
      this.http.get<any>(`${this.config.apiUrl}careTeam/Get-CareTeam/${patientId}`)
    );
  }
}
