import { Component, OnInit } from '@angular/core';
import { TrowserViewBase } from 'src/app/shared/base-classes';
import { ButtonOptions } from 'src/app/shared/utils';

@Component({
  selector: 'app-patient-previous-admissions',
  templateUrl: './patient-previous-admissions.component.html',
  styleUrls: ['./patient-previous-admissions.component.sass']
})
export class PatientPreviousAdmissionsComponent extends TrowserViewBase implements OnInit {
  public popupToolbarItems: any[] = [];
  public dtsPatientPreviousAdmissions: any[] = [];
  constructor() {
    super();
    this.initPopupToolbarItems();
  }

  ngOnInit(): void {
  }

  public showPreviousAdmission(entity) {
    this.dtsPatientPreviousAdmissions = entity;
    this.popupVisible = true;
  }


  private initPopupToolbarItems(): void {
    this.popupToolbarItems = super.getTrowserToolbarItems(
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('Cancel', true, this.onBtnCancelClick, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', null, null, this.isDataProcessing),
    );
  }

  private onBtnCancelClick = () => {
    this.popupVisible = false;
  }
}
