import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { TextHelper } from 'src/app/_helpers/text.helper';
import { GuidHelper } from 'src/app/_helpers/guid.helper';
import { PatientChartTemplateComponent } from '../patient-chart-template/patient-chart-template.component';
@Component({
  templateUrl: 'template-manual-editor.component.html',
  selector: 'template-manual-editor',
})
export class TemplateManualEditorComponent implements OnInit {
  @Input() initialContent!: string;
  @Input() templateId!: string;
  @Input() companyId!: string;
  @Output() contentChanged = new EventEmitter();
  isPhrasesHelperVisible = false;
  editor: any;
  editorId: string;
  configData: any = {};
  selection: any;
  isCheckDuplicateEnabled: boolean = true;
  cancelDuplicateCheck: boolean = false;
  templateChartName: string | null = '';
  editorContent: any;
  constructor(
    private patientChart: PatientChartTemplateComponent,
  ) {
    this.editorId = GuidHelper.generateNewGuid();
    this.startContentEmitter();

  }
  startContentEmitter() {
    setInterval(() => {
      this.getTemplateContent();
    }, 1000);
  }

  getTemplateContent() {
    if (this.initialContent !== undefined && this.initialContent !== "") {
      // if (this.isCheckDuplicateEnabled && this.templateChartName === 'General History of Present Illness' || this.templateChartName === 'General Review Of Systems') {
      //   var content = this.emitContentChangeAndDuplicateWords(this.editor.getContent());
      //   return this.contentChanged.emit(content);
      // }
      // else {

      // }

      return this.contentChanged.emit(this.editor.getContent());
    }
  }

  onPhraseSuggestionApplied($event: any) {
    if ($event) {
      const templateContent = this.editor.getContent();
      this.editor.setContent(`${templateContent}${$event}`);
    }
    this.isPhrasesHelperVisible = false;
  }

  showPhrasesHelper($event: any) {
    $event.preventDefault();

    this.isPhrasesHelperVisible = true;
  }

  ngOnInit() {
    this.templateChartName = localStorage.getItem('templateChartName')
      ? localStorage.getItem('templateChartName')
      : '';
  }

  setupEditor = (editor: any) => {
    this.editor = editor;
  };

  emitContentChangeAndDuplicateWords(searchSontent): string {
    const content = this.patientChart.callDuplicateWords(searchSontent);
    if (content && content.length) {
      this.contentChanged.emit(content);
      return content;
    }
  }

  getDefaultContent() {
    const content = TextHelper.findAndReplaceRedWords(this.editor.getContent());
    this.editor.setContent(content);
    this.patientChart.restartSelectableList();
  }

  toggleView(e: any) {
    if (!this.isCheckDuplicateEnabled) {
      this.emitContentChangeAndDuplicateWords(this.editor.getContent());
    } else {
      this.getDefaultContent();
    }
    if (e.event) {
      if (!this.cancelDuplicateCheck) {
        this.isCheckDuplicateEnabled = false;
      }
    }
  }
}
