import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/_services/config.service';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class RegistrationService {
  constructor(private http: HttpClient, private config: ConfigService) {}

  wakeUp() {
    return this.http.get(`${this.config.apiUrl}user/wake-up`).subscribe(
      () => {

      },
      error => {
      
      }
    );
  }
}
