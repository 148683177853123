import { NgModule } from '@angular/core';
import { CompaniesManagementComponent } from './components/companies-management/companies-management.component';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { CompanyDetailInfoComponent } from './detail/company-detail-info.component';
import { AdministrationModule } from '../administration/administration.module';

@NgModule({
  imports: [
    CommonModule,
    DxDataGridModule,
    DxPopupModule,
    DxScrollViewModule,
    DxFormModule,
    NgxMaskModule,
    AdministrationModule
  ],
  declarations: [CompaniesManagementComponent, CompanyDetailInfoComponent],
  providers: [],
})
export class CompaniesManagementModule { }
