import { Injectable } from '@angular/core';
import { PatientChartNode } from 'src/app/_models/patientChartNode';

@Injectable({
  providedIn: 'root',
})
export class TreeService {
  private updateNode(node: PatientChartNode, targetName: string): boolean {
    if (node.name === targetName) {
      node.attributes.nodeSpecificAttributes.editStatus = 'SE';
      node.attributes.nodeSpecificAttributes.auditStatus = 'CH';
      return true;
    }

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        if (this.updateNode(child, targetName)) {
          return true;
        }
      }
    }

    return false;
  }

  updateNodeByName(tree: PatientChartNode, targetName: string): PatientChartNode | null {
    if (this.updateNode(tree, targetName)) {
      return tree;
    }

    return null;
  }
}
