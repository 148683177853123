import { Component, OnInit, ViewChild } from '@angular/core';
import { DxSchedulerComponent } from 'devextreme-angular/ui/scheduler';
import { SchedulerViews } from 'src/app/scheduler/constants/schedulerViews';
import { SchedulerView } from 'src/app/scheduler/models/schedulerView';
import { AppConfiguration } from 'src/app/_classes/appConfiguration';
import { AlertService } from 'src/app/_services/alert.service';
import { RepositoryService } from 'src/app/_services/repository.service';

@Component({
  selector: 'app-reminder',
  templateUrl: './reminder.component.html',
  styleUrls: ['./reminder.component.sass'],
})
export class ReminderComponent implements OnInit {
  @ViewChild('reminderScheduler', { static: false })
  reminderScheduler!: DxSchedulerComponent;

  // currentDate: Date = new Date();
  schedulerAvailableViews: SchedulerView[] = SchedulerViews;
  appConfiguration: AppConfiguration = new AppConfiguration();
  loading = false;
  data: any[] = [];
  reminderData: any[] = [];

  constructor(private repository: RepositoryService, private alertService: AlertService) {

  }

  ngOnInit() {
    this.bindData();
  }

  bindData() {
    this.loading = true;
    const apiUrl = `notification/getNotifications`;
    this.repository.getData(apiUrl).subscribe({
      next: res => {
        if (res.success) {
          this.data = res.data.filter((x: any) => x.reminderDate != null) as any[];
          this.data.forEach(element => {
            this.reminderData.push({
              text: element.title,
              startDate: new Date(element.reminderDate),
              endDate: new Date(element.reminderDate),
            });
          });
        } else {
          this.alertService.error(res.message);
        }
        this.loading = false;
      },
      error: _error => {
        if (typeof _error.error === 'object') {
          this.alertService.error(
            "Can't connect to the API Server.<br>Please confirm your net connection or contact admin."
          );
        } else {
          this.alertService.error(_error.error);
        }
        this.loading = false;
      },
    });
  }
  public onAppointmentFormOpening(e) {
    e.component._appointmentPopup.popup.hide();
  }
  dataSource: any;
  currentDate: Date = new Date();
  resourcesDataSource: any[] = [];
}


