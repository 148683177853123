export class EmployeeTypeList {
  static values: any[] = [
    {
      value: 1,
      name: 'Physician',
    },
    {
      value: 2,
      name: 'Nurse',
    },
    {
      value: 3,
      name: 'Medical Assistant',
    },
    {
      value: 4,
      name: 'Support Staff',
    },
    {
      value: 5,
      name: 'Administrative',
    },
    {
      value: 6,
      name: 'Non-Medical',
    },
    /*  {
      value: 7,
      name: 'Patient',
    }, */
  ];
}
