import {
  Compiler,
  Component,
  ComponentFactory,
  NgModule,
  Directive,
  ViewContainerRef,
  ComponentRef,
  Input,
  ReflectiveInjector,
  OnChanges,
  OnDestroy,
} from '@angular/core';

import { PatientChartNode } from 'src/app/_models/patientChartNode';

import { PredefinedTemplateTypeNames } from 'src/app/_classes/predefinedTemplateTypeNames';

import { ShareModule } from 'src/app/share/share.module';
import { PatientChartTreeModule } from '../patient-chart-tree.module';
import { PatientChartInfo } from '../../models/patientChartInfo';
import { PatientChartNodeManagementService } from '../../services/patient-chart-node-management.service';
import {
  DevExtremeModule,
  DxAccordionModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxFileUploaderModule,
  DxFormModule,
  DxListModule,
  DxLoadIndicatorComponent,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxNumberBoxModule,
  DxPopoverModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxSliderModule,
  DxSwitchModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTemplateModule,
  DxTextAreaModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { PatientChartTemplateComponent } from '../components/patient-chart-template/patient-chart-template.component';
import { PatientChartComponent } from '../../components/patient-chart/patient-chart.component';
import { PatientTemplateEditorComponent } from '../components/patient-template-editor/patient-template-editor.component';
import { PatientRichTextEditorComponent } from '../components/patient-rich-text-editor/patient-rich-text-editor.component';
import { SelectableItemsEditorComponent } from 'src/app/share/components/selectable-items-editor/selectable-items-editor.component';
import { PatientSelectableRootComponent } from 'src/app/share/components/patient-selectable-root/patient-selectable-root.component';
import { PatientSelectableListComponent } from 'src/app/share/components/patient-selectable-list/patient-selectable-list.component';
import { PatientSelectableRangeComponent } from 'src/app/share/components/patient-selectable-range/patient-selectable-range.component';
import { PatientSelectableDateComponent } from 'src/app/share/components/patient-selectable-date/patient-selectable-date.component';
import { PatientSelectableVariableComponent } from 'src/app/share/components/patient-selectable-variable/patient-selectable-variable.component';
import { SafeHtmlPipe } from 'src/app/share/pipes/safe-html.pipe';
import { TemplateManualEditorComponent } from '../components/template-manual-editor/template-manual-editor.component';
import { TemplateListComponent } from '../components/template-list/template-list.component';
import { ChiefComplaintComponent } from '../components/chief-complaint-chart-section/chief-complaint/chief-complaint.component';
import { MedicationPrescriptionComponent } from '../components/medication-prescription/medication-prescription.component';
import { TobaccoHistoryComponent } from '../components/patient-history/tobacco-history/tobacco-history.component';
import { DrugHistoryComponent } from '../components/patient-history/drug-history/drug-history.component';
import { AlcoholHistoryComponent } from '../components/patient-history/alcohol-history/alcohol-history.component';
import { MedicalHistoryComponent } from '../components/patient-history/medical-history/medical-history.component';
import { SurgicalHistoryComponent } from '../components/patient-history/surgical-history/surgical-history.component';
import { FamilyHistoryComponent } from '../components/patient-history/family-history/family-history.component';
import { EducationHistoryComponent } from '../components/patient-history/education-history/education-history.component';
import { OccupationalHistoryComponent } from '../components/patient-history/occupational-history/occupational-history.component';
import { CareTeamManagementComponent } from '../components/patient-history/careTeamManagement/careTeamManagement.component';
import { AllergyComponent } from '../components/patient-history/allergy/allergy.component';
import { DetailGridComponent } from '../components/patient-history/careTeamManagement/detail-grid.component';
import { ReviewedMedicalRecordsComponent } from '../components/patient-history/reviewed-medical-records/reviewed-medical-records.component';
import { MedicationHistoryComponent } from '../components/patient-history/medication-history/medication-history.component';
import { PatientAllegationsComponent } from '../components/chief-complaint-chart-section/patient-allegations/patient-allegations.component';
import { ChiefComplaintManagementComponent } from '../components/chief-complaint-chart-section/chief-complaint-management/chief-complaint-management.component';
import { ChiefComplaintMapKeywordsComponent } from '../components/chief-complaint-chart-section/chief-complaint-management/chief-complaint-map-keywords/map-keywords.component';
import { AddTemplatesComponent } from '../components/chief-complaint-chart-section/chief-complaint-management/add-templates/add-templates.component';
import { MissedKeywordsComponent } from '../components/chief-complaint-chart-section/chief-complaint-management/chief-complaint-map-keywords/missed-keywords.component';
import { NewTemplateMappingComponent } from '../components/chief-complaint-chart-section/chief-complaint-management/new-template-mapping/new-template-mapping.component';
import { ChiefComplaintKeywordsComponent } from '../components/chief-complaint-chart-section/chief-complaint-management/chief-complaint-keywords/chief-complaint-keywords.component';
import { VitalSignsComponent } from '../components/vital-signs/vital-signs.component';
import { BaseVitalSignsComponent } from '../components/base-vital-signs/base-vital-signs.component';
import { VitalSignsPanelComponent } from '../components/vital-signs-panel/vital-signs-panel.component';
import { AssessmentComponent } from '../components/assessment/assessment.component';
import { ScanDocumentComponent } from '../components/scan-document/scan-document.component';
import { VisionVitalSignsComponent } from '../components/vision-vital-signs/vision-vital-signs.component';
import { AllegationsNotesStatusComponent } from '../components/chief-complaint-chart-section/allegations-notes-status/allegations-notes-status.component';
import { PhraseSuggestionHelperComponent } from '../components/phrase-suggestion-helper/phrase-suggestion-helper.component';
import { VitalSignsNotesComponent } from '../components/vital-signs-notes/vital-signs-notes.component';
import { VitalSignsConfigComponent } from '../components/vital-signs-config/vital-signs-config.component';
import { AddendumComponent } from '../components/addendum/addendum.component';
import { ExistedTemplateMappingComponent } from '../components/chief-complaint-chart-section/chief-complaint-management/existed-template-mapping/existed-template-mapping.component';
import { FormsModule } from '@angular/forms';
import { WebcamModule } from 'ngx-webcam';
import { EditorModule } from '@tinymce/tinymce-angular';
import { RouterModule } from '@angular/router';

export function createComponentFactory(
  compiler: Compiler,
  metadata: Component,
  patientChartRootNode: PatientChartNode | undefined,
  patientChartNode: PatientChartNode,
  patientId: string,
  admissionId: string,
  isSignedOff: boolean,
  appointmentId: string,
  companyId: string
): Promise<ComponentFactory<any>> {
  const cmpClass = class DynamicComponent {
    patientChartNode: PatientChartNode;
    patientChartDocumentNode?: PatientChartNode;
    patientId: string;
    admissionId: string;
    appointmentId: string;
    companyId: string;
    isSignedOff: boolean;

    //predefined template types
    ros: string = PredefinedTemplateTypeNames.ros;

    constructor() {
      this.patientChartNode = patientChartNode;
      this.patientChartDocumentNode = patientChartRootNode;

      this.patientId = patientId;
      this.admissionId = admissionId;
      this.appointmentId = appointmentId;
      this.companyId = companyId;
      this.isSignedOff = isSignedOff;
    }
  };

  const decoratedCmp = Component(metadata)(cmpClass);

  const module = NgModule({
    imports: [
      DxPopoverModule,
      CommonModule,
      DxTabsModule,
      DxPopupModule,
      DxListModule,
      DxTextAreaModule,
      DxButtonModule,
      DxNumberBoxModule,
      DxTextBoxModule,
      DxSelectBoxModule,
      DxDataGridModule,
      DxFormModule,
      DxRadioGroupModule,
      DxScrollViewModule,
      DxCheckBoxModule,
      FormsModule,
      DxDataGridModule,
      DxSelectBoxModule,
      DxFormModule,
      DxPopupModule,
      DxRadioGroupModule,
      DxPopoverModule,
      DxListModule,
      DxTextAreaModule,
      DxTabsModule,
      DxButtonModule,
      DxFileUploaderModule,
      WebcamModule,
      DxTabPanelModule,
      DxCheckBoxModule,
      DxScrollViewModule,
      EditorModule,
      RouterModule,
      DxAccordionModule,
      DxSliderModule,
      DxTemplateModule,
      DxLoadPanelModule,
      DxLoadIndicatorModule,
      DevExtremeModule,
      DxSwitchModule,
    ],
    declarations: [
      decoratedCmp,
      PatientChartTemplateComponent,
      PatientChartComponent,
      PatientTemplateEditorComponent,
      DxLoadIndicatorComponent,
      PatientRichTextEditorComponent,
      SelectableItemsEditorComponent,
      PatientSelectableRootComponent,
      PatientSelectableListComponent,
      PatientSelectableRangeComponent,
      PatientSelectableDateComponent,
      PatientSelectableVariableComponent,
      TemplateManualEditorComponent,
      SafeHtmlPipe,
      MedicationPrescriptionComponent,
      TobaccoHistoryComponent,
      DrugHistoryComponent,
      AlcoholHistoryComponent,
      MedicalHistoryComponent,
      SurgicalHistoryComponent,
      FamilyHistoryComponent,
      EducationHistoryComponent,
      OccupationalHistoryComponent,
      AllergyComponent,
      CareTeamManagementComponent,
      DetailGridComponent,
      MedicationHistoryComponent,
      ReviewedMedicalRecordsComponent,
      PatientRichTextEditorComponent,
      PatientChartTemplateComponent,
      TemplateListComponent,
      ChiefComplaintComponent,
      PatientAllegationsComponent,
      ChiefComplaintManagementComponent,
      ChiefComplaintMapKeywordsComponent,
      MissedKeywordsComponent,
      AddTemplatesComponent,
      ChiefComplaintKeywordsComponent,
      NewTemplateMappingComponent,
      VitalSignsComponent,
      BaseVitalSignsComponent,
      VitalSignsPanelComponent,
      AssessmentComponent,
      ScanDocumentComponent,
      VisionVitalSignsComponent,
      AllegationsNotesStatusComponent,
      PhraseSuggestionHelperComponent,
      VitalSignsNotesComponent,
      VitalSignsConfigComponent,
      AddendumComponent,
      ExistedTemplateMappingComponent,
    ],
    providers: [PatientChartComponent],
  })(class DynamicHtmlModule {});

  return compiler.compileModuleAndAllComponentsAsync(module).then(factories => {
    // Get the component factory.
    const componentFactory = factories.componentFactories[0];
    return componentFactory;
  });
}

@Directive({ selector: 'html-outlet-tree' })
export class HtmlOutletTreeDirective implements OnChanges, OnDestroy {
  @Input() patientChartInfo?: PatientChartInfo;

  cmpRef?: ComponentRef<any>;

  constructor(
    private vcRef: ViewContainerRef,
    private compiler: Compiler,
    private patientChartNodeManagementService: PatientChartNodeManagementService
  ) {}

  ngOnChanges() {

    if (!this.patientChartInfo) return;

    const patientChartDocumentNode = this.patientChartInfo.patientChartDocuemntNode;
    const patientChartNode = this.patientChartInfo.patientChartNode;
    let replacePatientChartNode = null;

    const patientId = this.patientChartInfo.patientId;
    const admissionId = this.patientChartInfo.admissionId;

    if (!patientChartNode || !patientId) return;

    const patientChartNodeTemplate = patientChartNode.template;

    const reportNodeViewTemplate = `<report-node-view [isSignedOff]='isSignedOff' [patientChartNode]='patientChartNode'
                [patientChartDocumentNode]='patientChartDocumentNode'
                [appointmentId]='appointmentId'
                [patientId]='patientId'
                [admissionId]='admissionId'
                [companyId]='companyId'>
             </report-node-view>`;

    let template = patientChartNodeTemplate
      ? patientChartNodeTemplate
      : reportNodeViewTemplate;

    let replaceChartNode = false;
    if (template?.includes('<medication-prescription ')) {
      template =
        "<medication-prescription [companyId]='companyId' [isSignedOff]='isSignedOff' [admissionId]='admissionId' [patientId]='patientId' [patientChartNode]='patientChartNode'></medication-prescription>";

      if (patientChartDocumentNode) {
        replacePatientChartNode = this.patientChartNodeManagementService.getByName(
          'assessment',
          patientChartDocumentNode
        );
        replaceChartNode = true;
      }
    } else if (template?.includes('<reviewed-medical-records ')) {
      template =
        "<reviewed-medical-records [companyId]='companyId' [isSignedOff]='isSignedOff' [patientId]='patientId' [patientChartNode]='patientChartNode'></reviewed-medical-records>";

      if (patientChartDocumentNode) {
        replacePatientChartNode = this.patientChartNodeManagementService.getByName(
          'assessment',
          patientChartDocumentNode
        );
        replaceChartNode = true;
      }
    }

    const companyId = this.patientChartInfo.companyId;

    this.createDynamicComponent(
      template,
      patientChartDocumentNode,
      replaceChartNode && replacePatientChartNode
        ? replacePatientChartNode
        : patientChartNode,
      patientId,
      admissionId,
      this.patientChartInfo.isSignedOff,
      this.patientChartInfo.appointmentId,
      companyId
    );
  }

  private createDynamicComponent(
    template: string,
    patientChartDocumentNode: PatientChartNode | undefined,
    patientChartNode: PatientChartNode,
    patientId: string,
    admissionId: string,
    isSignedOff: boolean,
    appointmentId: string,
    companyId: string
  ) {
    if (!template) return;

    if (this.cmpRef) {
      this.cmpRef.destroy();
    }

    const compMetadata = new Component({
      selector: 'dynamic-html',
      template: template,
    });

    createComponentFactory(
      this.compiler,
      compMetadata,
      patientChartDocumentNode,
      patientChartNode,
      patientId,
      admissionId,
      isSignedOff,
      appointmentId,
      companyId
    ).then(factory => {
      const injector = ReflectiveInjector.fromResolvedProviders(
        [],
        this.vcRef.parentInjector
      );
      this.cmpRef = this.vcRef.createComponent(factory, 0, injector, []);
    
    });
  }

  ngOnDestroy() {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }
}
