import { ColorHelper } from 'src/app/_helpers/color.helper';
import { AuditRequiredTypeList } from 'src/app/administration/classes/auditRequiredTypeList';
import { DefaultChartColors } from 'src/app/administration/classes/defaultChartColors';

export class ChartColor {
  updated: string;
  abnormal: string;
  defaultOrIncomplete: string;
  noContentChanged: string;
  borderUpdated: string;
  borderAbnormal: string;
  borderDefaultOrIncomplete: string;
  borderNoContentChanged: string;
  Updated: string;
  Abnormal: string;
  DefaultOrIncomplete: string;
  NoContentChanged: string;
  BorderUpdated: string;
  BorderAbnormal: string;
  BorderDefaultOrIncomplete: string;
  BorderNoContentChanged: string;
  colorHelper: ColorHelper;

  constructor() {
    this.updated = '';
    this.abnormal = '';
    this.defaultOrIncomplete = '';
    this.noContentChanged = '';
    this.borderUpdated = '';
    this.borderAbnormal = '';
    this.borderDefaultOrIncomplete = '';
    this.borderNoContentChanged = '';
    this.Updated = '';
    this.Abnormal = '';
    this.DefaultOrIncomplete = '';
    this.NoContentChanged = '';
    this.BorderUpdated = '';
    this.BorderAbnormal = '';
    this.BorderDefaultOrIncomplete = '';
    this.BorderNoContentChanged = '';
    this.colorHelper = new ColorHelper();
  }

  setAll(chartColor: ChartColor): void {
    this.updated = chartColor.updated;
    this.abnormal = chartColor.abnormal;
    this.defaultOrIncomplete = chartColor.defaultOrIncomplete;
    this.noContentChanged = chartColor.noContentChanged;
    this.borderUpdated = chartColor.borderUpdated;
    this.borderAbnormal = chartColor.borderAbnormal;
    this.borderDefaultOrIncomplete = chartColor.borderDefaultOrIncomplete;
    this.borderNoContentChanged = chartColor.borderNoContentChanged;
  }

  setDefault(defaultColor: DefaultChartColors): void {
    this.updated = defaultColor.updated;
    this.abnormal = defaultColor.abnormal;
    this.defaultOrIncomplete = defaultColor.defaultOrIncomplete;
    this.noContentChanged = defaultColor.noContentChanged;
    this.borderUpdated = defaultColor.borderUpdated;
    this.borderAbnormal = defaultColor.borderAbnormal;
    this.borderDefaultOrIncomplete = defaultColor.borderDefaultOrIncomplete;
    this.borderNoContentChanged = defaultColor.borderNoContentChanged;
  }

  change(colorTitle: string, newColor: string): void {
    switch (colorTitle) {
      case 'updated':
      case 'Updated':
        this.updated = newColor;
        break;
      case 'abnormal':
      case 'Abnormal':
        this.abnormal = newColor;
        break;
      case 'noContentChanged':
      case 'NoContentChanged':
        this.noContentChanged = newColor;
        break;
      case 'defaultOrIncomplete':
      case 'DefaultOrIncomplete':
        this.defaultOrIncomplete = newColor;
        break;
      case 'borderUpdated':
      case 'BorderUpdated':
        this.borderUpdated = newColor;
        break;
      case 'borderAbnormal':
      case 'BorderAbnormal':
        this.borderAbnormal = newColor;
        break;
      case 'borderDefaultOrIncomplete':
      case 'BorderDefaultOrIncomplete':
        this.borderDefaultOrIncomplete = newColor;
        break;
      case 'borderNoContentChanged':
      case 'BorderNoContentChanged':
        this.borderNoContentChanged = newColor;
        break;
      default:
        break;
    }
  }

  /**
   * @param {editStatus} editStatus - edit status of a patient chart node
   * @param {auditRequired} auditRequired - Audit required state of chart node
   * @returns {[string, string]} two hex strings, first is fill color, second is border color
   */

  getColorsForNode(editStatus: string, auditRequired: string): [string, string] {
    const auditReq: any = AuditRequiredTypeList.values.find(x => x.name == auditRequired);

    switch (editStatus) {
      case 'NC':
      case 'DO':
        return [this.noContentChanged, this.borderNoContentChanged];
      case 'EO':
      case 'SE':
      case 'ES':
      case 'SO':
      case 'CU':
        return [this.updated, this.borderUpdated];
      case 'IN':
        switch (auditReq?.value) {
          case 1: // is not required can be default
            return [this.defaultOrIncomplete, this.borderDefaultOrIncomplete];
          case 2: // Is Required (Cannot be default)
            return [this.abnormal, this.borderAbnormal];
          case 3: //Is Required (Can be default)
            return [this.defaultOrIncomplete, this.borderDefaultOrIncomplete];
          default:
            return [this.defaultOrIncomplete, this.borderDefaultOrIncomplete];
        }
      case 'UN':
        return [this.defaultOrIncomplete, this.borderDefaultOrIncomplete];
      default:
        return ['', ''];
    }
  }

  /**
   * @param {editStatus} editStatus - edit status of a patient chart node
   * @param {auditRequired} auditRequired - Audit required state of chart node
   * @param {nodeName} nodeName -The name of the current node
   * @param {allColorsData} allColorsData -The complete array of node colors from DB
   * @returns {[string, string]} two hex strings, first is fill color, second is border color
   */

  getColorsForSpecificNode(
    editStatus: string,
    auditRequired: string,
    nodeName: any,
    allColorsData: any
  ): [string, string] {
    const auditReq: any = AuditRequiredTypeList.values.find(x => x.name == auditRequired);

    //use the helper method in order to know the node color in DB
    const nameInDB = this.colorHelper.ChartColor(nodeName);

    //get the pack of colors for the specific node name
    const nodeColors: any = allColorsData.find((x: any) => x.Preset == nameInDB);

    //if no node match, return a default green value and default text color.
    if (!nodeColors) return ['#D5FFD5', '#00EC00'];


    //always return red(308) if is default content and is required can not be default
    if ((auditReq?.value === 2 || auditReq === undefined) && (editStatus === 'DO' || editStatus === null)) {
      return ['#fa2828', '#d62b2b'];
    }
    //get the right values corresponding with the status.
    switch (editStatus) {
      case 'NC':
      case 'DO':
      case 'UC':
      case null:
        return [nodeColors?.NoContentChanged, nodeColors?.BorderNoContentChanged];
      case 'EO':
      case 'SE':
      case 'ES':
      case 'SO':
      case 'CU':
      case 'CH':
        return [nodeColors?.Updated, nodeColors?.BorderUpdated];
      case 'IN':
        switch (auditReq?.value) {
          case 1: // is not required can be default
            return [
              nodeColors?.DefaultOrIncomplete,
              nodeColors?.BorderDefaultOrIncomplete,
            ];
          case 2: // Is Required (Cannot be default)
            return [nodeColors?.Abnormal, nodeColors?.BorderAbnormal];
          case 3: //Is Required (Can be default)
            return [
              nodeColors?.DefaultOrIncomplete,
              nodeColors?.BorderDefaultOrIncomplete,
            ];
          default:
            return [
              nodeColors?.DefaultOrIncomplete,
              nodeColors?.BorderDefaultOrIncomplete,
            ];
        }
      case 'UN':
        return [nodeColors?.DefaultOrIncomplete, nodeColors?.BorderDefaultOrIncomplete];
      default:
        return ['', ''];
    }
  }
}
