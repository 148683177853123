import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { AlertService } from 'src/app/_services/alert.service';
import { RepositoryService } from 'src/app/_services/repository.service';
import { Subscription } from 'rxjs';
import { GuidHelper } from 'src/app/_helpers/guid.helper';
export interface PreviewItem {
  id?: number;
  code_Desc?: string;
  code?: string;
  codeType?: string;
  testFee: number;
  notes?: string;
  category?: number;
  quantity?: number;
  vendorId?: number;
  vendorName?: string;
}

@Component({
  selector: 'app-patient-allegations',
  templateUrl: './patient-allegations.component.html',
  styleUrls: ['./patient-allegations.component.scss'],
})
export class PatientAllegationsComponent implements OnInit, OnChanges {      
  maxLength = 1000;  
  loading = false;
  allLabTests: any[] = [];
  source: any[] = [];  
  target: any[] = [];  
  format = {
    add: 'Add',
    remove: 'Remove',
    all: 'All',
    none: 'None',
    direction: 'left-to-right',
    draggable: true,
    locale: undefined,
  };  
  newAllegation = '';    
  constructor(
    private repository: RepositoryService,    
    private alertService: AlertService
  ) { }

  ngOnInit() {
    
  }

  ngOnChanges(_$event: any) {
    this.ngOnInit();
  }
  
  bindAllegation(): void {
    // Trim the new allegation and convert to lowercase
    const trimmedAllegation = this.newAllegation.trim().toLowerCase();

    // Check if the new allegation is not an empty string
    if (trimmedAllegation !== '') {
      // Check if the new allegation already exists in the source or target arrays (case insensitive)
      const existsInSource = this.source.some(item => item.toLowerCase() === trimmedAllegation);
      const existsInTarget = this.target.some(item => item.toLowerCase() === trimmedAllegation);

      if (existsInSource || existsInTarget) {
        // If the allegation exists, set the error message
        document.getElementById('allegationError').innerText = 'This allegation already exists';
      } else {
        // If the allegation doesn't exist, add it to the source array and reset the error message
        this.source.push(trimmedAllegation);
        this.allLabTests.push(trimmedAllegation);
        this.newAllegation = '';
        document.getElementById('allegationError').innerText = '';
      }
    }
  }
  
  bindPreviousAllegations(pId): void {
    if (pId !== undefined && pId !== '') { 
      this.loading = true;
      const apiUrl = `appointment/PatientAllegations/${pId}`;
      this.repository.getData(apiUrl).subscribe({
        next: res => {
          this.source = [];          
          if (res.success) {            
            res.data.forEach((element: any) => {              
              this.source.push(                
                element
              );              
              this.allLabTests.push(element);
            });            
          }
          this.loading = false;
        },
        error: _error => {
          if (typeof _error.error === 'object') {
            this.alertService.error(
              "Can't connect to the API Server.<br>Please confirm your net connection or contact admin."
            );
          } else {
            this.alertService.error(_error.error);
          }
          this.loading = false;
        },
      });
    }
  }    
}
