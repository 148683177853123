import * as $ from 'jquery';
import 'devextreme/integration/jquery';


export class SystemUtility {

    public static toggleLoadingPanel(isShow: boolean, target: any = 'body'): void {
        const instance = $('#loading').dxLoadPanel('instance');
        const hasShownPopup = $('body div').hasClass('d-con-popup');
        if (!isShow) {
            if (!hasShownPopup) {
                $('body').css({ 'overflow': 'auto' });
            }
            instance.hide();
            return;
        }
        $('body').css({ 'overflow': 'hidden' });

        const position = { of: target };
        instance.option('position', position);
        instance.show();
    }

    public static setFormFocus(formInstance: any, fieldName: string, fieldInstance: any = null) {
        if (typeof (formInstance.getEditor(fieldName)) !== 'undefined') {
            formInstance.getEditor(fieldName).element().find('input').focus().select();
        } else if (fieldInstance != null) {
            fieldInstance.focus();
        }
    }
}
