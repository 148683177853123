import {
  BaseHistoryReportSection,
  IReportContentProvider,
  PatientChartNodeReportInfo,
} from './baseHistoryReportSection';
import { DefaultValueService } from 'src/app/_services/default-value.service';
import { CareTeamService } from '../../../_services/careTeam.service';

export class CareTeamSection
  extends BaseHistoryReportSection
  implements IReportContentProvider
{
  constructor(
    private careTeamHistoryService: CareTeamService,
    defaultValueService: DefaultValueService
  ) {
    super(defaultValueService);
  }

  getPatientChartNodeReportContent(
    patientChartNodeReportInfo: PatientChartNodeReportInfo
  ): Promise<string> {
    return this.careTeamHistoryService
      .getAllByPatientId(patientChartNodeReportInfo.patientId)
      .then(careTeamHistory => {
        const careTeamHistorySectionTitle =
          patientChartNodeReportInfo.patientChartNode.title;
        if (careTeamHistory.length === 0)
          return this.getHistorySectionDefaultString(
            patientChartNodeReportInfo.patientChartNode.type,
            careTeamHistorySectionTitle,
            patientChartNodeReportInfo.patientChartNode.id
          );

        const careTeamHistoryProperties = [
          { name: 'providerFirstName', isFirstItem: true },
          { name: 'providerMiddleName' },
          { name: 'providerLastName' },
          { name: 'phoneNumber' },
          { name: 'faxNumber' },
          { name: 'practiceLocationAddressCity' },
          { name: 'practiceLocationAddressState' },
          { name: 'practiceLocationAddressZip' },
          { name: 'email' },
        ];

        return this.getHistoryReportSectionString(
          careTeamHistory.data,
          careTeamHistoryProperties,
          careTeamHistorySectionTitle,
          patientChartNodeReportInfo.patientChartNode.id
        );
      });
  }
}
