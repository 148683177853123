export class AuditRequiredTypeList {
  static values: any[] = [
    {
      value: 1,
      name: 'Is Not Required (Can be default)',
    },
    {
      value: 2,
      name: 'Is Required (Cannot be default)',
    },
    {
      value: 3,
      name: 'Is Required (Can be default)',
    },
  ];
}
