import { Injectable } from '@angular/core';
import { alert, custom, confirm, CustomDialogOptions } from 'devextreme/ui/dialog';
import { AlertMessageTypes } from '../_classes/alertMessageTypes';
import notify from 'devextreme/ui/notify';

@Injectable({ providedIn: 'root' })
export class AlertService {
  alert(message: string, title: string): void {
    alert(message, title);
  }

  confirm(message: string, title: string) {
    return confirm(message, title);
  }

  info(errorMessage: string): void {
    this.alert(errorMessage, AlertMessageTypes.info);
  }

  error(errorMessage: string): void {
    this.alert(errorMessage, AlertMessageTypes.error);
  }

  warning(warningMessage: string): void {
    this.alert(warningMessage, AlertMessageTypes.warning);
  }

  custom(options: CustomDialogOptions) {
    return custom(options);
  }

  customConfirmation(message: string, title: string = 'Warning') {
    return custom({
      title: title,
      messageHtml: message,
      buttons: [
        { text: 'Cancel', onClick: () => false },
        {
          text: 'Ok', onClick: () => true,
          type: "default",
          stylingMode: "contained"
        }
      ]
    });
  }


  notifyMsg(message: string, type: string) {
    notify({
      message: message,
      type: type,
      displayTime: 1000,
      position: { my: 'center', at: 'top', offset: '0 100' },
      animation: {
        show: { type: 'slideIn', duration: 400, direction: 'top' },
        hide: { type: 'slideOut', duration: 400, direction: 'top' }
      },
    });
  }
}

