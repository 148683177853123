import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { SearchConfiguration } from 'src/app/_classes/searchConfiguration';
import { MaskList } from 'src/app/_classes/maskList';
import { StateList } from 'src/app/_classes/stateList';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { Patient } from '../models/patient';
import { PatientInsurance } from '../models/patientInsurance';
import { Gender } from 'src/app/_classes/gender';
import { MaritalStatus } from '../classes/maritalStatus';
import { CompanyIdService } from 'src/app/_services/company-id.service';
import { PatientService } from '../../_services/patient.service';
import { AlertService } from 'src/app/_services/alert.service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxDataUrlService } from 'src/app/_services/dxDataUrl.service';
import { PatientInsuranceService } from '../../_services/patient-insurance.service';
import { GuidHelper } from 'src/app/_helpers/guid.helper';
import { forkJoin, from, Subscription } from 'rxjs';
import { ZipCodeType } from '../models/zipCodeType';
import { ZipCodeTypeList } from 'src/app/_classes/zipCodeTypeList';
import { DevextremeAuthService } from 'src/app/_services/devextreme-auth.service';
import { ApiBaseUrls } from 'src/app/_models/apiBaseUrls';
import { PatientSearchFilter } from 'src/app/_models/patientSearchFilter';
import { DateHelper } from 'src/app/_helpers/date.helper';
import { SelectableListConfig } from 'src/app/_models/selectableListConfig';
import { SelectableListsNames } from 'src/app/_classes/selectableListsNames';
import { LibrarySelectableListIds } from 'src/app/_classes/librarySelectableListIds';
import { SelectableListService } from 'src/app/_services/selectable-list.service';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { Appointment } from 'src/app/_models/appointment';
import { RegexRuleList } from '../../_classes/regexRuleList';
import { PatientIdentificationCodeService } from '../services/patient-identification-code.service';
import { PatientIdentificationCodeType } from '../models/enums/patientIdentificationCodeType';
import { RepositoryService } from 'src/app/_services/repository.service';
import { CompanyService } from 'src/app/_services/company.service';
import { NotesEditorComponent } from 'src/app/share/components/notes-editor/notes-editor.component';
import { Company } from 'src/app/_models/company';
import * as XLSX from 'xlsx';
import { EmployeeTypeList } from 'src/app/administration/classes/employeeTypeList';
import { PatientCommunicationMethodList } from 'src/app/_classes/patientCommunicationMethodList';
import { PatientIdentificationCodeSearchFilter } from '../../lookUpZipCode/models/PatientIdentificationCodeSearchFilter';
import moment from 'moment';

type PatientDataTab = {
  id: number;
  title: string;
  template: string;
};

@Component({
  selector: 'patients-management',
  templateUrl: './patients-management.component.html',
})
export class PatientsManagementComponent implements OnInit, OnDestroy {
  @ViewChild('patientForm', { static: false })
  patientForm!: DxFormComponent;
  @ViewChild('insuranceForm', { static: false })
  insuranceForm!: DxFormComponent;
  @ViewChild('patientDataGrid', { static: false })
  patientDataGrid!: DxDataGridComponent;
  @ViewChild('notesEditor', { static: false })
  notesEditor!: NotesEditorComponent;
  filterDataPatients: any;
  openPopupInfoOnlyPatient: boolean = false;
  patientFormToOnlyInfo: any;
  popupTitle: any;
  @ViewChild('myPopup', { static: false }) myPopup!: any;
  selectedPatientCommunicationMethods: any;
  canRenderComponent = false;
  isMrnManual? = false;
  isFinManual? = false;
  mrnManualCode?: string = '';
  finManualCode?: string = '';
  isFinCodeInUse = false;
  selectedAppointmentStatus: string = '';
  patientSearchKeyword: string = '';
  isCopyActionExecuting = false;
  patientCommunicationMethod: any[] = PatientCommunicationMethodList.values;
  currentIndex = 0;
  public isNewInsurance = true;
  isNewPatient = true;

  companyId: string = GuidHelper.emptyGuid;
  companyIdSubscription?: Subscription;
  searchConfiguration: SearchConfiguration = new SearchConfiguration();
  validationMasks: MaskList = new MaskList();
  states: any[] = StateList.values;
  gender: any[] = Gender.values;
  maritalStatus: any[] = MaritalStatus.values;
  zipCodeTypes: any[] = ZipCodeTypeList.values;
  public popupAnimation = {
    show: {
      type: 'slideIn',
      direction: 'top'
    },
    hide: {
      type: 'slideOut',
      direction: 'top'
    }
  };

  toolbarItems = [
    {
      toolbar: 'bottom',
      location: 'after',
      widget: 'dxButton',
      options: {
        text: 'Close',
        onClick: () => {
          this.openPopupInfoOnlyPatient = false;
        }
      }
    }
  ];
  allPatientDataTabs: PatientDataTab[] = [
    {
      id: 1,
      title: 'Demographics',
      template: 'patient',
    },
    {
      id: 2,
      title: 'Insurance',
      template: 'insurance',
    },
    {
      id: 3,
      title: 'Appointments',
      template: 'appointments',
    },
    {
      id: 4,
      title: 'Notes',
      template: 'patientNotes',
    },
    {
      id: 5,
      title: 'Credentials',
      template: 'patientCredentials',
    }
  ];
  patientDataTabs: PatientDataTab[] = []
  patient: any;
  insurance: any;
  selectedPatients: Array<any> = [];
  patientDataSource: any = {};
  insuranceCompanies: Array<any> = [];
  isPatientPopupOpened = false;
  regexRuleList: RegexRuleList = new RegexRuleList();
  currentIdentificationFormId?: string;
  identificationCodeTypes = PatientIdentificationCodeType;
  doesAppointmentExist = true;
  insuranceCompanyDataSource: any = {};
  serviceType?: number;
  maxId = 0;
  notes: any[] = [];
  company: Company = new Company();
  fileName = 'patients.xlsx';

  FiveDigitvalues: any[] = [{ name: 'Five Digit', value: 1 }];

  NineDigitvalues: any[] = [{ name: 'Nine Digit', value: 2 }];

  providersDataSource: any = {};
  masDataSource: any = {};
  maxDate: any = new Date();
  identificationID1: any;
  identificationID2: any;
  isSelectAtributtesToExportPopupOpened = false;
  columnsToExport: Array<any> = [
    {
      name: 'Name Suffix',
      value: false,
    },
    {
      name: 'FirstName',
      value: false,
    },
    {
      name: 'Middle Name',
      value: false,
    },
    {
      name: 'Last Name',
      value: false,
    },
    {
      name: 'Admission Date',
      value: false,
    },
    {
      name: 'Case Number',
      value: false,
    },
    {
      name: 'RQID',
      value: false,
    },
    {
      name: 'City',
      value: false,
    },
    {
      name: 'Date of Birth',
      value: false,
    },
    {
      name: 'Email',
      value: false,
    },
    {
      name: 'FIN',
      value: false,
    },
    {
      name: 'MRN',
      value: false,
    },
    {
      name: 'Primary Address',
      value: false,
    },
    {
      name: 'Primary Phone',
      value: false,
    },
    {
      name: 'Secondary Address',
      value: false,
    },
    {
      name: 'Secondary Phone',
      value: false,
    },
    {
      name: 'Zip',
      value: false,
    },
    {
      name: 'Notes',
      value: false,
    },
    {
      name: 'All',
      value: true,
    },
  ];
  insuranceReadOnly: any = {};
  get appointmentStatuses(): string[] {
    return this.selectableListService.getSelectableListValuesFromComponent(
      this,
      SelectableListsNames.application.appointmentStatus
    );
  }

  get patientSuffixListValues(): string[] {
    return this.selectableListService.getSelectableListValuesFromComponent(
      this,
      SelectableListsNames.application.patientSuffix
    );
  }

  get genderString(): string {
    if (!this.patient.gender) return '';

    return this.gender.find(g => g.value === this.patient.gender).name;
  }

  get zipMask(): string {
    //switch (this.patient.zipCodeType) {
    //  case ZipCodeType.FiveDigit:
    //    return this.validationMasks.fiveDigitZip;
    //  default:
    //    return this.validationMasks.nineDigitZip;
    //}

    // set limit to 9 and removed zip code type as Glenn's requested
    return this.validationMasks.nineDigitZip;
  }

  get zipMaskInsurance(): string {
    switch (this.insurance.zipCodeType) {
      case ZipCodeType.FiveDigit:
        return this.validationMasks.fiveDigitZip;
      default:
        return this.validationMasks.nineDigitZip;
    }
  }

  get familyListValues(): string[] {
    return this.selectableListService.getSelectableListValuesFromComponent(
      this,
      SelectableListsNames.familyHistory.familyMembers
    );
  }

  constructor(
    private companyIdService: CompanyIdService,
    private patientService: PatientService,
    private patientInsuranceService: PatientInsuranceService,
    private alertService: AlertService,
    private dxDataUrlService: DxDataUrlService,
    private devextremeAuthService: DevextremeAuthService,
    private selectableListService: SelectableListService,
    private appointmentService: AppointmentService,
    private repositoryService: RepositoryService,
    private companyService: CompanyService,
    private patientIdentificationCodeService: PatientIdentificationCodeService
  ) {
    this.insurance = new PatientInsurance();
    this.patient = new Patient();

  }

  onDetailedContentChanged(_args: any) { }

  appointmentStatusChanged($event: any) {
    this.selectedAppointmentStatus = $event.value;
    this.refreshPatientsGrid();
  }

  savePatientNotes() {
    if (!this.patient.id) return;

    if (!this.notesEditor.content) {
      this.alertService.error('Notes should not be empty');
      return;
    }

    this.patient.notes = this.notesEditor.content;
    this.patientService
      .updatePatientNotes(this.patient.id, this.patient.notes)
      .then(notes => {
        this.notes = notes;
        this.notesEditor.clearContent();
        this.alertService.notifyMsg('Patient notes were successfully updated!', "success");
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  getPatientNotes() {
    if (!this.patient.id) return;
    this.patientService
      .getPatientNotes(this.patient.id)
      .then(notes => {
        this.notes = notes;
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  sendPassword() {
    this.patientService
      .sendPassword(this.patient.id)
      .then(() => {
        this.alertService.notifyMsg('Password was successfully sent', "success");
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  generatePassword() {
    this.patientService
      .generatePassword(this.patient.id)
      .then((res: any) => {
        if (res.success) {
          this.alertService.notifyMsg('Password was successfully generated!', "success");
          this.patient.password = res.password;
        }
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  onPhraseSuggestionApplied($event: any) {
    if (this.patient) this.patient.notes = $event;
  }

  deletePatient(patientId: string, $event: any) {
    $event.stopPropagation();

    const currentDate = new Date();

    this.appointmentService
      .getPatientLastVisit(patientId, currentDate)
      .then(lastVisit => {
        const deleteConfirmationMessage =
          this.getPatientDeleteConfirmationMessage(lastVisit);

        if (lastVisit) {
          this.alertService.warning(deleteConfirmationMessage);
          return;
        }

        const confirmationPopup = this.alertService.confirm(
          deleteConfirmationMessage,
          'Confirm deletion'
        );

        confirmationPopup.then(dialogResult => {
          if (dialogResult) {
            this.patientService
              .delete(patientId)
              .then(() => this.refreshPatientsGrid())
              .catch(error =>
                this.alertService.error(error.message ? error.message : error)
              );
          }
        });
      });
  }

  ngOnInit() {
    this.subscribeToCompanyIdChanges();
    this.initProvidersDataSource();
    this.initMasDataSource();
    this.initInsuranceCompanyDataSource();
    this.initPatientDataStore();
    this.filterDataPatients = this.patientDataSource;
  }

  ngOnDestroy(): void {
    this.companyIdSubscription?.unsubscribe();
  }

  createUpdatePatientInsurance($event: any) {
    $event.preventDefault();
    const validationResult = this.insuranceForm.instance.validate();
    if (!validationResult.isValid) {
      return;
    }
    this.insurance.providerId = this.patient.providerId;
    this.insurance.maId = this.patient.maId;
    this.patientInsuranceService
      .save(this.insurance)
      .then(insurance => {
        this.isNewInsurance = false;
        this.insurance.id = insurance.id;
        this.alertService.notifyMsg('Patient insurance was saved successfully', "success");
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  createUpdatePatient() {
    const validationResult = this.patientForm.instance.validate();
    if (!validationResult.isValid) {
      return;
    }
    this.checkPatientExistance(this.patient)
      .then(existedPatients => {
        this.save();
        this.insurance.providerId = this.patient.providerId;
        this.insurance.maId = this.patient.maId;
        this.patientInsuranceService
          .save(this.insurance)
          .then(insurance => {
          })
          .catch(error => this.alertService.error(error.message ? error.message : error));

      }).catch(error => this.alertService.error(error.message ? error.message : error));


  }

  onPatientCommunicationMethodChanged = (e: any) => {
    this.selectedPatientCommunicationMethods = e.value;
  }

  private save() {
    this.patient.companyId = this.companyId;
    this.patient.caseNumber = this.patient.fin;
    this.patient.fin = this.patient.fin;
    this.patient.rqid = this.patient.rqid;

    this.patient.patientCommunicationMethod = this.patient.selectedPatientCommunicationMethods.join(', ');
    this.patientService.save(this.patient).then(patient => {
      this.patient.id = patient.id;
      this.patient.password = patient.password;
      this.patient.companyId = this.companyId;
      this.patient.rqid = patient.rqid;
      this.patient.caseNumber = patient.caseNumber;
      this.patient.fin = patient.fin;
      if (this.isFinManual) {
        this.insurance.fin = this.finManualCode;
      } else {
        this.insurance.fin = this.patient.fin;
      }

      if (this.isMrnManual) {
        this.insurance.mrn = this.mrnManualCode;
      } else {
        this.autoMRN();
        this.isMrnManual = false;
      }
      if (this.isNewPatient) {
        this.alertService.notifyMsg('Patient data was saved successfully', "success");
      } else {
        this.alertService.notifyMsg('Patient data was updated successfully', "success");
      }
      this.isNewPatient = false;
      this.isPatientPopupOpened = false;
      this.refreshPatientsGrid();

      if (!this.isFinManual)
        this.getMaxId();
    });
  }

  isCodeFinManual() {
    const codeSearchFilter = new PatientIdentificationCodeSearchFilter();
    codeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Fin;
    codeSearchFilter.companyId = this.companyId;
    this.patientIdentificationCodeService.isManualCode(codeSearchFilter).then(response => {
      this.isFinManual = response;
    });
  }

  isCodeMrnManual() {
    const codeSearchFilter = new PatientIdentificationCodeSearchFilter();
    codeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Mrn;
    codeSearchFilter.companyId = this.companyId;
    this.patientIdentificationCodeService.isManualCode(codeSearchFilter).then(response => {
      this.isMrnManual = response;
    });
  }

  async getFinManualCode() {
    const codeSearchFilter = new PatientIdentificationCodeSearchFilter();
    codeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Fin;
    codeSearchFilter.companyId = this.companyId;
    const code = await this.patientIdentificationCodeService.getByCompany(codeSearchFilter);
    this.finManualCode = code == null ? " " : code.manualCode;
  }

  async getMrnManualCode() {
    const codeSearchFilter = new PatientIdentificationCodeSearchFilter();
    codeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Mrn;
    codeSearchFilter.companyId = this.companyId;
    const code = await this.patientIdentificationCodeService.getByCompany(codeSearchFilter);
    this.mrnManualCode = code == null ? " " : code.manualCode;
  }


  async openPatientForm() {
    this.patientDataTabs = this.allPatientDataTabs.filter(tab => tab.id === 1);
    this.isPatientPopupOpened = true;
    this.popupTitle = "Patient";
    this.isCodeFinManual();
    this.isCodeMrnManual();
    this.getFinManualCode();

    if (this.isFinManual && this.finManualCode != '') {
      this.patientService.isFINAsigned(this.finManualCode!).then(result => {
        if (result) {
          this.isFinCodeInUse = true;
          this.alertService.error('The manual identification code is in used by another patient. You must generate a new code in admin FIN/MRN view.');
          return;
        }
      })
    }
  }

  closePatientForm() {
    this.isPatientPopupOpened = false;
  }

  getFullNameDisplayExpression(item: any) {
    if (!item) return '';
    return `${item.FirstName} ${item.LastName}`;
  }

  onPatientPopupHidden() {
    this.patient = new Patient();
    this.insurance = new PatientInsurance();

    this.selectedPatients = [];
    this.patientDataTabs = [];

    this.isNewPatient = true;
    this.isNewInsurance = true;
  }

  refreshPatientsGrid() {
    this.patientDataGrid.instance.refresh();
  }

  onPatientSelected($event: any) {
    const patientId = $event.data.id;

    const patientPromise = this.patientService.getById(patientId);
    const patientInsurancePromise =
      this.patientInsuranceService.getByPatientId(patientId);

    Promise.all([patientPromise, patientInsurancePromise])
      .then(result => {
        const patient = result[0];
        const insurance = result[1];

        this.patient = patient;
        this.patient.selectedPatientCommunicationMethods = patient.patientCommunicationMethod
          ? patient.patientCommunicationMethod.split(',').map(value => parseInt(value.trim(), 10))
          : [];

        this.isNewPatient = false;

        if (insurance) {
          this.insurance = insurance;
          this.insurance.fin = this.patient.fin;
          this.insurance.mrn = this.patient.mrn;
          this.isNewInsurance = false;
        }
        this.patient.providerId = this.insurance.providerId;
        this.patient.maId = this.insurance.maId;
        this.getPatientNotes();
        this.patientDataTabs = [];
        this.isPatientPopupOpened = true;
        if (this.company.zipCodeType == 1) {
          this.patient.zipCodeType = 1;
          this.insurance.zipCodeType = 1;
          this.zipCodeTypes = this.FiveDigitvalues;
        } else {
          this.patient.zipCodeType = 2;
          this.insurance.zipCodeType = 2;
          this.zipCodeTypes = this.NineDigitvalues;
        }
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  copyFromPatient() {
    this.isCopyActionExecuting = true;

    const patientFieldsToExclude = [
      'maritalStatus',
      'companyId',
      'id',
      'patientInsuranceId',
      'mrn'
    ];

    const patient = this.patient;
    const insurance = this.insurance;

    if (!patient || !insurance) {
      return;
    }

    for (const fieldName in patient) {
      if (
        patientFieldsToExclude.indexOf(fieldName) !== -1 ||
        !Object.prototype.hasOwnProperty.call(patient, fieldName)
      ) {
        continue;
      }

      (<any>insurance)[fieldName] = (<any>patient)[fieldName];
    }

    insurance.patientId = patient.id;
  }

  private getPatientDeleteConfirmationMessage(appointment: Appointment): string {
    if (!appointment) return 'Are you sure you want to delete the patient ?';

    const appointmentDate = DateHelper.getDate(appointment.startDate);

    return `Cannot delete patient.
                An appointment is scheduled for ${appointmentDate}.`;
  }


  private initPatientDataStore(): any {
    this.patientDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getGridUrl(ApiBaseUrls.patient),

      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.companyId;
          jQueryAjaxSettings.data.appointmentStatus = this.selectedAppointmentStatus ? this.selectedAppointmentStatus : null;
          jQueryAjaxSettings.data.searchKeyword = this.patientSearchKeyword ? this.patientSearchKeyword : null;
        },
        this
      ),
    });
  }

  private getMaxId(): void {
    this.repositoryService.getData(`patientinsurance/maxMrn`).subscribe({
      next: res => {
        if (this.insurance) {
          this.maxId = res as any;
          const mrn = this.company.name
            .split(/\s/)
            .reduce((response, word) => (response += word.slice(0, 1)), '');
          this.insurance.mrn = `MRN-${mrn}-${this.maxId + 10000}`;
          this.insurance.fin = `FIN${this.maxId + 10000}`;
        }
      },
      error: (error: any) => {

      },
    });
  }


  private checkIsManualFin(): boolean {
    const codeSearchFilter = new PatientIdentificationCodeSearchFilter();
    codeSearchFilter.companyId = this.companyId;

    codeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Fin;

    this.patientIdentificationCodeService.isManualCode(codeSearchFilter).then(result => {
      this.isFinManual = result;
    })
    return this.isFinManual!;
  }

  private autoMRN(): void {
    this.repositoryService.getData(`patientinsurance/maxMrn`).subscribe({
      next: res => {
        if (this.insurance) {
          this.maxId = res as any;
          const mrn = this.company.name
            .split(/\s/)
            .reduce((response, word) => (response += word.slice(0, 1)), '');
          this.insurance.mrn = `MRN-${mrn}-${this.maxId + 10000}`;
        }
      },
      error: error => {

      },
    });
  }

  private autoFIN(): void {
    this.repositoryService.getData(`patientinsurance/maxMrn`).subscribe({
      next: (res: any) => {
        if (this.insurance) {
          this.maxId = res;
          this.insurance.fin = `FIN${this.maxId + 10000}`;
        }
      },
      error: (error: any) => {

      },
    });
  }

  private subscribeToCompanyIdChanges() {
    this.companyIdSubscription = this.companyIdService.companyId.subscribe(companyId => {
      if (companyId !== "") {
        this.companyId = companyId;
        this.getMRNFRNData();
        this.companyService
          .getById(this.companyId)
          .then(company => {
            this.company = company;
          })
          .catch(error => this.alertService.error(error.message ? error.message : error));
        this.setCompanyType(companyId);
        this.initSelectableLists();

        if (this.patientDataGrid && this.patientDataGrid.instance)
          this.patientDataGrid.instance.refresh();
      }
    });
  }

  private setCompanyType(companyId: string) {
    this.companyService
      .getById(companyId)
      .then(company => {
        this.serviceType = company.serviceType;
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  private checkPatientExistance(patient: Patient): Promise<Patient[] | undefined> {
    const patientSearchFilter = new PatientSearchFilter();
    patientSearchFilter.companyId = this.companyId;
    patientSearchFilter.firstName = patient.firstName;
    patientSearchFilter.lastName = patient.lastName;
    patientSearchFilter.ssn = patient.ssn;
    patientSearchFilter.patientId = patient.id == undefined ? '00000000-0000-0000-0000-000000000000' : patient.id;
    patientSearchFilter.dateOfBirth = DateHelper.jsLocalDateToSqlServerUtc(
      patient.dateOfBirth
    );

    return this.patientService.validate(patientSearchFilter).then(response => {
      return response;
    });
  }

  private initSelectableLists() {
    const patientSuffixConfig = new SelectableListConfig(
      this.companyId,
      SelectableListsNames.application.patientSuffix,
      LibrarySelectableListIds.application.patientSuffix
    );

    const appointmentStatusConfig = new SelectableListConfig(
      this.companyId,
      SelectableListsNames.application.appointmentStatus,
      LibrarySelectableListIds.application.appointmentStatus
    );
    const familyListConfig = new SelectableListConfig(
      this.companyId,
      SelectableListsNames.familyHistory.familyMembers,
      LibrarySelectableListIds.familyHistory.familyMembers
    );
    const selectableLists = [patientSuffixConfig, appointmentStatusConfig, familyListConfig];

    this.selectableListService
      .setSelectableListsValuesToComponent(selectableLists, this)
      .then(() => {
        this.canRenderComponent = true;
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  private initInsuranceCompanyDataSource(): void {
    this.repositoryService.getData(`insurance/company/dx/lookup`).subscribe({
      next: res => {
        this.insuranceCompanies = res.data;
      },
      error: error => {

      },
    });
  }

  onInsuranceCompanyChanged(_$event: any) { }

  export(): void {
    const patientSearchFilter = new PatientSearchFilter();

    patientSearchFilter.companyId = this.companyId;
    patientSearchFilter.firstName = '';
    patientSearchFilter.lastName = '';
    patientSearchFilter.ssn = '';
    patientSearchFilter.take = 10000;

    this.patientService.getByFilter(patientSearchFilter).then(patients => {
      this.doExport(patients);
    });
  }

  private doExport = (data: any) => {
    const rowsData = this.patientDataGrid.instance.getSelectedRowsData();
    const ids = rowsData.map(item => item.id).toString();
    const array: any[] = [];
    const obj: any[] = data;

    obj.forEach(element => {
      if (ids.indexOf(element.id) !== -1) {
        if (this.columnsToExport[this.columnsToExport.length - 1].value) {
          array.push({
            'Name Suffix': element.nameSuffix,
            FirstName: element.firstName,
            'Middle Name': element.middleName,
            'Last Name': element.lastName,
            'Admission Date': element.admissionDate,
            'Case Number': element.caseNumber,
            RQID: element.rqid,
            City: element.city,
            'Date of Birth': element.dateOfBirth,
            Email: element.email,
            FIN: element.fin,
            MRN: element.mrn,
            'Primary Address': element.primaryAddress,
            'Primary Phone': element.primaryPhone,
            'Secondary Address': element.secondaryAddress,
            'Secondary Phone': element.secondaryPhone,
            Zip: element.zip,
            Notes: element.notes,
          });
        } else {
          const selectedAttributes: any = {};
          this.columnsToExport.forEach(column => {
            if (column.value) {
              const columnName = column.name;
              selectedAttributes[columnName] = this.getValueByColumnName(
                element,
                columnName
              );
            }
          });
          array.push(selectedAttributes);
        }
      }
    });

    if (array.length === 0) {
      this.alertService.error('Select employees to export.');
      return;
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(array);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  onSelectAtributtesToExportPopupHidden() {
    this.isSelectAtributtesToExportPopupOpened = false;
    let trueValueCount = 0;
    for (const column of this.columnsToExport) {
      if (column.value) {
        trueValueCount++;
      }
    }
    if (trueValueCount === 0) {
      this.alertService.error('You must select almost one column to export.');
      return;
    } else {
      this.export();
    }
  }

  onSelectAtributtesToExportPopupVisible() {
    this.isSelectAtributtesToExportPopupOpened = true;
  }

  onColumnToExportClicked(event: any) {
    if (event.name === 'All' && event.value === false) {
      for (const column of this.columnsToExport) {
        column.value = false;
      }
    } else if (
      event.name != 'All' &&
      this.columnsToExport[this.columnsToExport.length - 1].value === true
    ) {
      this.columnsToExport[this.columnsToExport.length - 1].value = false;
    }
  }

  getValueByColumnName(element: any, columnName: any) {
    switch (columnName) {
      case 'Name Suffix':
        return element.nameSuffix;
      case 'FirstName':
        return element.firstName;
      case 'Middle Name':
        return element.middleName;
      case 'Last Name':
        return element.lastName;
      case 'Admission Date':
        return element.admissionDate;
      case 'Case Number':
        return element.caseNumber;
      case 'RQID':
        return element.rqid;
      case 'City':
        return element.city;
      case 'Date of Birth':
        return element.dateOfBirth;
      case 'Email':
        return element.email;
      case 'FIN':
        return element.fin;
      case 'MRN':
        return element.mrn;
      case 'Primary Address':
        return element.primaryAddress;
      case 'Primary Phone':
        return element.primaryPhone;
      case 'Secondary Address':
        return element.secondaryAddress;
      case 'Secondary Phone':
        return element.secondaryPhone;
      case 'Zip':
        return element.zip;
      case 'Notes':
        return element.notes;
      default:
        return null;
    }
  }

  keywordChanged = (data: any) => {
    this.patientSearchKeyword = data.value;
    this.refreshPatientsGrid();
  }

  private initProvidersDataSource(): void {
    this.providersDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('user'),
      loadParams: { employeeType: EmployeeTypeList.values[0].value },
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.patient.companyId;
        },
        this
      ),
    });
  }

  private initMasDataSource(): void {
    this.masDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('user'),
      loadParams: { employeeType: EmployeeTypeList.values[2].value },
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.patient.companyId;
        },
        this
      ),
    });
  }

  public updateDataSourceWithFilter(event: any): void {
    this.patientDataSource = event;
  }

  public resetEmit(event: any) {
    this.patientDataSource = event;
  }
  private async getMRNFRNData() {
    const finCodeSearchFilter = new PatientIdentificationCodeSearchFilter();
    finCodeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Fin;
    finCodeSearchFilter.companyId = this.companyId;

    const mrnCodeSearchFilter = new PatientIdentificationCodeSearchFilter();
    mrnCodeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Mrn;
    mrnCodeSearchFilter.companyId = this.companyId;

    const finManualCode$ = from(this.patientIdentificationCodeService.getByCompany(finCodeSearchFilter));
    const mrnManualCode$ = from(this.patientIdentificationCodeService.getByCompany(mrnCodeSearchFilter));
    const [finCode, mrnCode] = await forkJoin([finManualCode$, mrnManualCode$]).toPromise();

    this.identificationID1 = finCode?.identificationLabel ?? (mrnCode?.identificationLabel ?? 'Case Number');
    this.identificationID2 = mrnCode?.identificationLabel ?? (finCode?.identificationLabel ?? 'RQID');

  }
  public async OpenPopupPatientOnlyInfo(patientId: string) {

    const patientPromise = this.patientService.getById(patientId);
    const patientInsurancePromise =
      this.patientInsuranceService.getByPatientId(patientId);
    Promise.all([patientPromise, patientInsurancePromise])
      .then(result => {
        const patient = result[0];
        this.patientFormToOnlyInfo = result[0];
        if (result[1] !== null) {
          this.insuranceReadOnly = result[1];
        }
        this.patientFormToOnlyInfo.selectedPatientCommunicationMethods = patient.patientCommunicationMethod
          ? patient.patientCommunicationMethod.split(',').map(value => parseInt(value.trim(), 10))
          : [];

        const statePatient = StateList.values.find(
          (state: any) => state.value == patient.state
        );

        const genderPatient = Gender.values.find(
          (gender: any) => gender.value == patient.gender
        );

        const maritalStatusPatient = MaritalStatus.values.find(
          (maritalStatus: any) => maritalStatus.value == patient.maritalStatus
        );

        const zipCodeTypePatient = ZipCodeTypeList.values.find(
          (zipCodeType: any) => zipCodeType.value == patient.zipCodeType
        );
        this.patientFormToOnlyInfo.state = statePatient.name;
        this.patientFormToOnlyInfo.gender = genderPatient.name;
        this.patientFormToOnlyInfo.maritalStatus = maritalStatusPatient.name;
        this.patientFormToOnlyInfo.zipCodeType = zipCodeTypePatient.name;

        this.popupTitle = "Patient - " + this.patientFormToOnlyInfo.lastName + ", " + this.patientFormToOnlyInfo.firstName
        this.openPopupInfoOnlyPatient = true;
      })
  }

  // This method is to open the popup that previously opened with a double click and shows everything about the patient and allows editing.


  async fetchAllCodes() {
    const finCodeSearchFilter = new PatientIdentificationCodeSearchFilter();
    finCodeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Fin;
    finCodeSearchFilter.companyId = this.companyId;

    const mrnCodeSearchFilter = new PatientIdentificationCodeSearchFilter();
    mrnCodeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Mrn;
    mrnCodeSearchFilter.companyId = this.companyId;

    const finManualCode$ = from(this.patientIdentificationCodeService.getByCompany(finCodeSearchFilter));
    const mrnManualCode$ = from(this.patientIdentificationCodeService.getByCompany(mrnCodeSearchFilter));
    const isFinManual$ = from(this.patientIdentificationCodeService.isManualCode(finCodeSearchFilter));
    const isMrnManual$ = from(this.patientIdentificationCodeService.isManualCode(mrnCodeSearchFilter));

    const [finCode, mrnCode, isFinManual, isMrnManual] = await forkJoin([finManualCode$, mrnManualCode$, isFinManual$, isMrnManual$]).toPromise();

    this.identificationID1 = finCode.identificationLabel;
    this.identificationID2 = mrnCode.identificationLabel;
    this.finManualCode = finCode == null ? " " : finCode.manualCode;
    this.mrnManualCode = mrnCode == null ? " " : mrnCode.manualCode;
    this.isFinManual = isFinManual;
    this.isMrnManual = isMrnManual;

    if (this.isFinManual && this.finManualCode != '') {
      this.patientService.isFINAsigned(this.finManualCode!).then(result => {
        if (result) {
          this.isFinCodeInUse = true;
          this.alertService.error('The manual identification code is in used by another patient. You must generate a new code in admin FIN/MRN view.');
          return;
        }
      })
    }
    else {
      this.isPatientPopupOpened = true;
    }

  }

  async OpenPopupForNotDoubleClick(patientId: string) {
    this.patientDataTabs = this.allPatientDataTabs;
    const patientPromise = this.patientService.getById(patientId);
    const patientInsurancePromise =
      this.patientInsuranceService.getByPatientId(patientId);
    await this.fetchAllCodes();
    Promise.all([patientPromise, patientInsurancePromise])
      .then(result => {
        const patient = result[0];
        const insurance = result[1];
        this.patient = patient;
        this.patient.selectedPatientCommunicationMethods = patient.patientCommunicationMethod
          ? patient.patientCommunicationMethod.split(',').map(value => parseInt(value.trim(), 10))
          : [];

        this.isNewPatient = false;
        this.patient.providerId = insurance.providerId;
        this.patient.maId = insurance.maId;
        if (insurance) {
          this.insurance = insurance;
          this.insurance.selectedPatientCommunicationMethods = patient.patientCommunicationMethod
            ? patient.patientCommunicationMethod.split(',').map(value => parseInt(value.trim(), 10))
            : [];
          this.insurance.fin = this.patient.fin;
          this.insurance.rqid = this.patient.rqid
          // if (this.isMrnManual) {
          //   this.insurance.mrn = this.mrnManualCode;
          // } else {
          //   this.insurance.mrn = this.patient.mrn;
          // }

          // if (this.isFinManual) {
          //   this.insurance.fin = this.finManualCode;
          // } else {
          //   this.insurance.fin = this.patient.fin;
          // }

          this.isNewInsurance = false;
        }
        // else {

        //   if (this.isFinManual) {
        //     this.insurance.fin = this.finManualCode;
        //   } else {
        //     this.insurance.fin = this.patient.fin;
        //   }

        //   if (this.isMrnManual) {
        //     this.insurance.mrn = this.mrnManualCode;
        //   } else {
        //     this.autoMRN();
        //     this.isMrnManual = false;
        //   }
        // }

        this.getPatientNotes();
        this.isPatientPopupOpened = true;
        this.currentIndex = 0;
        this.popupTitle = "Patient - " + patient.lastName + ", " + patient.firstName;
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));




  }

  // With this method we can create a template to be able to put a title to the popup.
  //Note:Angular's click event does not catch it, you have to work with javascript's onclick method and declare the function globally.
  //OnINit --- add this line (window as any).closePopup = this.closePopup.bind(this);
  updatePopupTitle(patient: any) {
    const formattedDate = moment(patient.dateOfBirth).format('MM/DD/YYYY');

    this.popupTitle = `<div><b>Name:</b> ${patient.firstName} ${patient.lastName}</div><div><b>DOB: </b>${formattedDate}</div> <div><b>Gender: </b>${patient.gender}</div>  <div><b>FIN:</b> ${patient.fin}</div> `;
    this.myPopup.instance.option('titleTemplate', () => {
      return `<div class="d-flex justify-content-between"><div class="d-flex" style="gap:10px">${this.popupTitle}</div> <button class="btn btn-outline-danger" onclick="closePopup()"><i class="fas fa-times"></i></button></div>`;
    });
  }

  closePopupInfoOnlyPatient() {
    this.openPopupInfoOnlyPatient = false;
  }

  public onGrdPatientsCellPrepared(e) {
    if (e.column.dataField === 'id') {
      e.cellElement.css('text-align', 'left');
    }
  }

  public getCaseVisible = () => {
    if (this.isNewPatient) {
      return !this.isFinManual;
    }
    else {
      return (!this.isFinManual && this.patient.fin !== null)
    }
  }

  public getRQVisible = () => {
    if (this.isNewPatient) {
      return !this.isMrnManual;
    }
    else {
      return (!this.isMrnManual && this.patient.rqid !== null)
    }
  }
}
