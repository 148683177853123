import { Observable } from 'rxjs';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';
import { CustomDialogHelper } from 'src/app/shared/utils/helpers/custom-dialog.helper';

@Injectable()
export class NotAuthorizedInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const token = localStorage.getItem('authToken');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request)
      .pipe(tap(
        {
          next: (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              return event.body;
            }
          },
          error: (err: any) => {
            if (err.status === 401) {
              CustomDialogHelper.alertInfoMsg('Your session has timed out, please login again.').done((popupResult) => {
                localStorage.clear();
                sessionStorage.clear();
                this.authenticationService.clearPreviouslySavedUser();
                location.reload();
              });
            }
          },
          complete: () => {
          }
        }))
      .pipe(shareReplay());
  }
}
