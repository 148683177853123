export class ColorHelper {
  ChartColor(chart: string) {
    let chartSelected = '';
    switch (chart) {
      case 'Physical Exam':
        chartSelected = 'physicalExam';
        break;
      case 'Patient Chart':
        chartSelected = 'patientChart';
        break;
      case 'Patient History':
        chartSelected = 'patientHistory';
        break;
      case 'Progress Note':
        chartSelected = 'progressNote';
        break;
      case 'History And Physical':
        chartSelected = 'historyAndPhysical';
        break;
      case 'Telehealth Progress Note':
        chartSelected = 'telehealth';
        break;
      case 'Patient Medical Records Reviewed':
        chartSelected = 'patientMedicalRecord';
        break;
      case 'reviewedMedicalRecords':
        chartSelected = 'patientMedicalRecord';
        break;
      case 'Pharmacy':
        chartSelected = 'pharmacy';
        break;
      case 'Tobacco History':
        chartSelected = 'tobaccoHistory';
        break;
      case 'tobaccoHistory':
        chartSelected = 'tobaccoHistory';
        break;
      case 'Drug History':
        chartSelected = 'drugHistory';
        break;
      case 'drugHistory':
        chartSelected = 'drugHistory';
        break;
      case 'Alcohol History':
        chartSelected = 'alcoholHistory';
        break;
      case 'alcoholHistory':
        chartSelected = 'alcoholHistory';
        break;
      case 'Previous Medical History':
        chartSelected = 'previousMedical';
        break;
      case 'previousMedicalHistory':
        chartSelected = 'previousMedical';
        break;
      case 'Previous Surgical History':
        chartSelected = 'previousSurgical';
        break;
      case 'previousSurgicalHistory':
        chartSelected = 'previousSurgical';
        break;
      case 'Family History':
        chartSelected = 'familyHistory';
        break;
      case 'familyHistory':
        chartSelected = 'familyHistory';
        break;
      case 'Education':
        chartSelected = 'education';
        break;
      case 'education':
        chartSelected = 'education';
        break;
      case 'Occupational History':
        chartSelected = 'occupational';
        break;
      case 'occupationalHistory':
        chartSelected = 'occupational';
        break;
      case 'Allergies':
        chartSelected = 'allergies';
        break;
      case 'allergies':
        chartSelected = 'allergies';
        break;
      case 'Medications':
        chartSelected = 'medications';
        break;
      case 'medications':
        chartSelected = 'medications';
        break;
      case 'Care Team':
        chartSelected = 'careTeam';
        break;
      case 'careTeam':
        chartSelected = 'careTeam';
        break;
      case 'Chief Complaint':
        chartSelected = 'chiefComplaint';
        break;
      case 'History of Present Illness':
        chartSelected = 'HPI';
        break;
      case 'General History of Present Illness':
        chartSelected = 'HPI';
        break;
      case 'Review Of Systems':
        chartSelected = 'ReviewSystem';
        break;
      case 'General Review Of Systems':
        chartSelected = 'ReviewSystem';
        break;
      case 'Activities of Daily Living':
        chartSelected = 'actDailyLiv';
        break;
      case 'pE_Default_ActivitiesOfDailyLiving':
        chartSelected = 'actDailyLiv';
        break;
      case 'Physical Exams':
        chartSelected = 'physicalExams';
        break;
      case 'Assessment':
        chartSelected = 'assessment';
        break;
      case 'assessment':
        chartSelected = 'assessment';
        break;
      case 'Plan':
        chartSelected = 'plan';
        break;
      case 'Vital Signs':
        chartSelected = 'vitalSigns';
        break;
      case 'vitalSigns':
        chartSelected = 'vitalSigns';
        break;
      case 'Procedure':
        chartSelected = 'procedure';
        break;
      case 'Prescription':
        chartSelected = 'prescription';
        break;
      case 'prescription':
        chartSelected = 'prescription';
        break;
      case 'Addendum':
        chartSelected = 'addendum';
        break;
      case 'Scan Document':
        chartSelected = 'scanDocument';
        break;
      case 'Discharge Instructions':
        chartSelected = 'dischargeInstruc';
        break;
      case 'Medico Discharge Instructions':
        chartSelected = 'dischargeInstruc';
        break;
      case 'Screenings ':
        chartSelected = 'screenings';
        break;
      case 'Statement of Examination':
        chartSelected = 'statementExam';
        break;
      case 'statementOfExamination':
        chartSelected = 'statementExam';
        break;

      case 'General':
        chartSelected = 'general';
        break;
      case 'Assistive Device':
        chartSelected = 'assistiveDevice';
        break;
      case 'Gait and Station':
        chartSelected = 'gaitStation';
        break;
      case 'Neurological':
        chartSelected = 'neurological';
        break;
      case 'Head, Ears, Eyes, Nose, and Throat Exam':
        chartSelected = 'headEyes';
        break;
      case 'Pulmonary':
        chartSelected = 'pulmonary';
        break;
      case 'Cardiovascular':
        chartSelected = 'cardiovascular';
        break;
      case 'Gastrointestinal':
        chartSelected = 'gastrointestinal';
        break;
      case 'Pulses':
        chartSelected = 'pulses';
        break;
      case 'Strength and Tone':
        chartSelected = 'strengthTone';
        break;
      case 'Sensory':
        chartSelected = 'sensory';
        break;
      case 'Fine Motor Skills':
        chartSelected = 'fineSkills';
        break;
      case 'Deep Tendon Reflexs':
        chartSelected = 'tendonReflexs';
        break;
      case 'Range of Motion':
        chartSelected = 'rangeMotion';
        break;
      case 'Skin':
        chartSelected = 'skin';
        break;
      default:
        chartSelected = 'default';
    }
    return chartSelected;
  }
}
