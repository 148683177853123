export class StringHelper {
  static format(...args: string[]): string {
    let s = args[0];
    for (let i = 0; i < args.length - 1; i++) {
      const reg = new RegExp('\\{' + i + '\\}', 'gm');
      s = s.replace(reg, StringHelper.replaceHtmlEntities(args[i + 1]));
    }
    return s;
  }

  static stripHtmlTags(input: string): string {
    if (input == null) return input;
    return input.replace(/<\/?[^>]+(>|$)/g, "");
  }

  static replaceHtmlEntities(input: string): string {
    if (input == null) return input;

    let decodedString = input.toLowerCase();

    const entityMap: { [key: string]: string } = {
      '&nbsp;': ' ',
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&apos;': "'",
      '&cent;': '¢',
      '&pound;': '£',
      '&yen;': '¥',
      '&euro;': '€',
      '&copy;': '©',
      '&reg;': '®',
      '&trade;': '™',
      '&sect;': '§',
      '&deg;': '°',
      '&plusmn;': '±',
      '&para;': '¶',
      '&micro;': 'µ',
      '&middot;': '·',
      '&ndash;': '–',
      '&mdash;': '—',
      '&hellip;': '…',
      '&laquo;': '«',
      '&raquo;': '»',
      '&lsquo;': '‘',
      '&rsquo;': '’',
      '&ldquo;': '“',
      '&rdquo;': '”',
      '&bull;': '•',
      '&prime;': '′',
      '&Prime;': '″',
      '&oline;': '‾',
      '&frasl;': '⁄',
      '&le;': '≤',
      '&ge;': '≥',
      '&ne;': '≠',
      '&equiv;': '≡',
      '&infin;': '∞',
      '&int;': '∫',
      '&sum;': '∑',
      '&forall;': '∀',
      '&part;': '∂',
      '&exist;': '∃',
      '&empty;': '∅',
      '&isin;': '∈',
      '&notin;': '∉',
      '&sub;': '⊂',
      '&sup;': '⊃',
      '&nsub;': '⊄',
      '&and;': '∧',
      '&or;': '∨',
      '&not;': '¬',
      '&there4;': '∴',
      '&sim;': '∼',
      '&cong;': '≅',
      '&asymp;': '≈',
      '&pi;': 'π',
      '&alpha;': 'α',
      '&beta;': 'β',
      '&gamma;': 'γ',
      '&delta;': 'δ',
      '&theta;': 'θ',
      '&lambda;': 'λ',
      '&omega;': 'ω',
      '&sigma;': 'σ',
      '&phi;': 'φ'
    };

    Object.keys(entityMap).forEach(entity => {
      decodedString = decodedString.replace(new RegExp(entity, 'g'), entityMap[entity]).replace(/\s+/g, ' ');
    });

    return StringHelper.capitalizeSentences(decodedString);
  }

  static capitalizeSentences(input: string) {
    if (input == null) return input;

    const processText = (text: string): string => {
      return text
        .replace(/^(\w)/, (m) => m.toUpperCase())
        .replace(/([.!?]\s*\w|,\s*\w)/g, (m) => m.toUpperCase())
    };
    if (/<[^>]+>/.test(input)) {
      return input.replace(/<li>(.*?)<\/li>|([^<]+)/g, (match, p1, p2) => {
        // Use p1 for list items and p2 for plain text
        const textToProcess = p1 || p2;
        const processedText = processText(textToProcess);
        // Return processed text wrapped in <li> if it was a list item, otherwise plain text
        return p1 ? `<li>${processedText}</li>` : processedText;
      });
    } else {
      // If it's plain text, process it directly
      return processText(input);
    }
  }


  static camelize(str: string): string {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
        return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
      })
      .replace(/\s+/g, '');
  }

  static leftTrim(str: string): string {
    if (str == null) return str;
    return str.replace(/^\s+/g, '');
  }

  static rightTrim(str: string): string {
    if (str == null) return str;
    return str.replace(/\s+$/g, '');
  }
}
