import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AlertService } from 'src/app/_services/alert.service';
import { UserService } from 'src/app/administration/services/user.service';
import { AuthenticationHelper } from 'src/app/share/authentication-helper';

@Component({
  selector: 'login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  @Input() email!: string;
  @Output() backToEmailForm: EventEmitter<void> = new EventEmitter();

  @ViewChild('loginForm', { static: false })
  loginForm!: DxFormComponent;

  loginModel: any = {};
  loginErrors: string[] = [];
  returnUrl: string = '/';

  companies: any[] = [];
  showPassword = false;
  password = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private userService: UserService
  ) { }

  ngOnInit() {
    localStorage.removeItem('Medico.CurrentUser');

    this.initUserCompanies();
    this.loginModel.email = this.email;
    this.returnUrl =  '/';
  }

  onBackToEmailForm(): void {
    this.backToEmailForm.next();
  }

  login(): void {
    const isLoginFormValid = this.loginForm.instance.validate().isValid;

    if (!isLoginFormValid) return;

    this.loginErrors = [];

    this.authenticationService
      .login(this.loginModel)
      .then(user => {
        if (user.errors.length) {
          this.loginErrors = user.errors;
        } else {
          AuthenticationHelper.setToken(user);
          this.router.navigate([this.returnUrl]);
        }
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  private initUserCompanies(): void {
    this.userService
      .getUserCompanies(this.email)
      .then(companies => {
        this.companies = companies;
        if (companies.length === 1) this.loginModel.companyId = companies[0].id;
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  getErrorHtml(error: string): string {
    return `<i class='bx bx-info-circle'></i> ${error}`;
  }

  onForgotPassword(): void {
    const email = this.loginModel.email;
    const companyId = this.loginModel.companyId;
    if (this.companies.length > 1 && !companyId) {
      this.alertService.error('Please select your company.');
      return;
    }
    this.router.navigate([`/forgot-password/${email}/${companyId}`]);
    this.router.navigate([`/forgot-password/`], {
      queryParams: { email, companyId },
    });
  }
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
  syncPassword(value: string): void {
    this.password = value;
    this.loginModel.password = this.password;
  }
}
