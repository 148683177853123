import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { Assessment } from 'src/app/patientChart/models/assessment';
import { AlertService } from 'src/app/_services/alert.service';
import { PatientChartTrackService } from 'src/app/_services/patient-chart-track.service';
import { IcdCodeService } from 'src/app/_services/icd-code.service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxDataUrlService } from 'src/app/_services/dxDataUrl.service';
import { DevextremeAuthService } from 'src/app/_services/devextreme-auth.service';
import { PatientChartNode } from 'src/app/_models/patientChartNode';
import { PatientChartNodeType } from 'src/app/_models/patientChartNodeType';
import { BaseHistoryComponent } from '../patient-history/base-history.component';
import { SelectedPatientChartNodeService } from 'src/app/_services/selected-patient-chart-node.service';
import { DefaultValueService } from 'src/app/_services/default-value.service';
import { RepositoryService } from 'src/app/_services/repository.service';
import { NotesEditorComponent } from 'src/app/share/components/notes-editor/notes-editor.component';
import { PhraseSuggestionHelperComponent } from '../phrase-suggestion-helper/phrase-suggestion-helper.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AppointmentService } from '../../../../_services/appointment.service';
import { AppointmentGridItem } from 'src/app/scheduler/models/appointmentGridItem';
import { ConfigService } from 'src/app/_services/config.service';
import { TextHelper } from '../../../../_helpers/text.helper';
import moment from 'moment';

@Component({
  templateUrl: 'assessment.component.html',
  selector: 'assessment',
  styleUrls: ['../careteam-combobox.scss'],
})
export class AssessmentComponent
  extends BaseHistoryComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('assessmentsGrid', { static: false })
  assessmentsGrid!: DxDataGridComponent;
  @ViewChild('assessmentForm', { static: false })
  assessmentForm!: DxFormComponent;
  @ViewChild('notesEditor', { static: false })
  notesEditor!: NotesEditorComponent;
  @ViewChild('phraseHelper', { static: false })
  phraseHelper!: PhraseSuggestionHelperComponent;

  @Input() patientChartNode?: PatientChartNode;
  @Input() patientChartDocumentNode?: PatientChartNode;

  @Input() appointmentId!: any;
  @Input() admissionId!: string;
  @Input() isSignedOff!: boolean;
  @Input() companyId!: string;
  public dateFormat = 'MM/dd/yyyy';
  @Input() templateId?: string;

  private _orderService?: OrderService;
  private _assessmentCurrentOrderNumber?: number;

  isNewAssessment = true;

  orderNumberMaxAvailableValue?: number;
  orderNumberMinAvailableValue?: number;

  availableOrderNumbers: Array<number> = [];
  selectedAssessments: Array<any> = [];

  assessments: Array<any> = [];
  problemList: Array<any> = [];
  assessment: any;

  icdCodesDataSource: any = {};
  providersDataSource: any = [];
  defaultProviders: any = [];
  useDefaultProvider: any = false;
  defaultProviderText = 'Default Provider';
  phrasesDataSource: any = {};

  toEmitIcdCodes: any = {};

  isAssessmentPopupOpened = false;
  data = [];
  searchModeOption = 'contains';
  searchExprOption: any = 'Name';
  searchTimeoutOption = 200;
  minSearchLengthOption = 0;
  showDataBeforeSearchOption = false;
  icdCode: any;
  includeNotesInReport: boolean = true;
  notes = '';
  isPhrasesHelperVisible = false;
  statusList: any = [
    { id: 'Current', name: 'Current' },
    { id: 'Resolved', name: 'Resolved' },
    { id: 'Discontinued', name: 'Discontinued' },
  ];
  filterList: any = [{ id: 'All', name: 'All' }, ...this.statusList];
  subjectList = [];
  pointsList = [
    {
      id: 'Self limited or minor (maximum of 2)',
      value: 'Self limited or minor (maximum of 2)',
    },
    {
      id: 'Established problem, stable or improving',
      value: 'Established problem, stable or improving',
    },
    {
      id: 'Established problem, worsening',
      value: 'Established problem, worsening',
    },
    {
      id: 'New problem, with no additional work-up planned (maximum of 1)',
      value: 'New problem, with no additional work-up planned (maximum of 1)',
    },
    {
      id: 'New problem, with additional work-up planned',
      value: 'New problem, with additional work-up planned',
    },
    {
      id: 'Disability Claimant',
      value: 'Disability Claimant',
    },
  ];
  endDateEnabled = false;
  original: any[] = [];
  appointment?: AppointmentGridItem;

  patientId: string = '';

  saveProvider: any;
  isDiagnosisSelected: boolean = false;
  public assessmentStatus = "All";
  constructor(
    public repositoryService: RepositoryService,
    private alertService: AlertService,
    private patientChartTrackService: PatientChartTrackService,
    private icdCodeService: IcdCodeService,
    private dxDataUrlService: DxDataUrlService,
    private devextremeAuthService: DevextremeAuthService,
    defaultValueService: DefaultValueService,
    selectedPatientChartNodeService: SelectedPatientChartNodeService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appointmentService: AppointmentService,
    private configService: ConfigService
  ) {
    super(defaultValueService, selectedPatientChartNodeService);
  }

  ngOnInit() {
    this.appointmentId = this.activatedRoute.snapshot.params['appointmentId'] || '';
    this.appointmentService.getAppointmentGridItemById(this.appointmentId).then(value => {
      this.appointment = value;
      this.patientId = this.appointment.patientId!;
      this.initProvidersDataSource();
      this.getAssessments(this.assessmentStatus);
    });

    this.appointmentService.getById(this.appointmentId).then(appointment => {
      if (appointment) this.defaultProviders = appointment?.providerIds;
    });

    this.init();

    this.assessment = new Assessment();
    this.assessment.startDate = new Date();
  }


  private getAssessments(status) {
    const param = {
      patientId: this.patientId,
      id: this.appointmentId,
      status: status
    }
    this.appointmentService.getAssessments(param).subscribe(response => {
      debugger;
      this.assessments = response;
      this._orderService = new OrderService(this.assessments);
    });
  }

  ngAfterViewInit(): void { }

  onDetailedContentChanged(content: string) {
    this.notes = content;
  }

  showPhrasesHelper($event: any) {
    $event.preventDefault();
    this.isPhrasesHelperVisible = true;

    if (this.phraseHelper) this.phraseHelper.areSuggestionsVisible = true;
  }

  onPhraseSuggestionApplied($event: any) {
    if (this.notesEditor) {
      const templateContent = this.notesEditor?.content;
      this.notesEditor.insertContent(`${templateContent}${$event}`);
    }
  }

  contentChanged(_$event: any) { }

  onKeyPressInOrderNumberBox($event: any) {
    const event = $event.event;
    const keyCode = event.keyCode;
    if (keyCode === 46 || keyCode === 44) event.preventDefault();
  }

  // onPhraseSuggestionApplied($event: any) {
  //     this.assessment.notes = $event;
  // }

  onAssessmentFieldChanged($event: any): void {
    const dataField = $event.dataField;
    const fieldValue = $event.value;

    if (dataField === 'icdCode' && fieldValue) {
      this.icdCodeService
        .getById(fieldValue)
        .then(icdCode => {
          this.assessment.diagnosis = icdCode?.name;
          this.assessment.code = icdCode?.code;
          this.assessment.icdCode = '';
          this.isDiagnosisSelected = true;
        })
        .catch(error => this.alertService.error(error.message ? error.message : error));
    }

    if (dataField === 'status' && fieldValue) {
      this.endDateEnabled = fieldValue === 'Resolved' || fieldValue === 'Discontinued';
    }
  }

  onValueChanged(_$event: any) { }

  onAssessmentPopupHidden() {
    this.assessment = new Assessment();
    this.selectedAssessments = [];
    this.isNewAssessment = true;
  }

  openAssessmentForm() {
    if (!this._orderService) return;
    this.assessment = new Assessment();
    this._assessmentCurrentOrderNumber = this._orderService.maxOrderNumber;
    this.assessment.order = this._orderService.maxOrderNumber;

    const lastAssessment = localStorage.getItem('lastAssessment');
    if (lastAssessment != null) {
      const lastAssessmentData = JSON.parse(lastAssessment);
      if (lastAssessment != null) {
      }
    }

    this.setMinMaxOrderNumberRange();
    this.isAssessmentPopupOpened = !this.isAssessmentPopupOpened;
  }

  closeAssessmentForm() {
    this.isAssessmentPopupOpened = false;
  }

  deleteAssessment(assessment: any, $event: any) {
    $event.stopPropagation();
    const assessmentId = assessment.id;
    const confirmationPopup = this.alertService.confirm(
      'Are you sure you want to delete the assessment?',
      'Confirm deletion'
    );
    confirmationPopup.then(dialogResult => {
      if (dialogResult) {
        this._orderService?.adjustOrder(assessmentId, assessment.order, true);
        this.appointmentService.deleteAssessmentById(assessmentId).subscribe(response => {
          this.getAssessments(this.assessmentStatus);
          this.alertService.notifyMsg("Record deleted successfully", "success");
          this._orderService?.adjustOrder(assessmentId, assessment.order, true);
        });
      }
    });
  }

  public createAssessment() {
    const validationResult = this.assessmentForm.instance.validate();

    if (!validationResult.isValid) {
      return;
    }

    if (
      this.endDateEnabled &&
      (this.assessment.endDate === undefined || this.assessment.endDate === null)
    ) {
      this.alertService.error('Please select EndDate.');
      return;
    }

    if (moment(this.assessment.endDate).isBefore(this.assessment.startDate)) {
      this.alertService.error('The end date must be greater than the start date.');
      return;
    }

    this.assessment.appointmentId = this.appointmentId;
    this.assessment.patientId = this.patientId;
    this.assessment.icd10Code = this.assessment.code;
    this.assessment.icd10Desc = this.assessment.diagnosis;
    this.assessment.problemNumber = this.assessment.order;
    this.assessment.mdm = this.assessment.points;
    this.assessment.lastEditEmpId = null;
    this.assessment.notes = this.notesEditor.content;
    this.assessment.includeNotesInReport = this.includeNotesInReport;
    this.appointmentService.saveAssessments(this.assessment).subscribe(response => {
      this.isAssessmentPopupOpened = !this.isAssessmentPopupOpened;
      this.alertService.notifyMsg("Record saved successfully", "success");
      this.getAssessments(this.assessmentStatus);
    });
  }

  onAssessmentSelected(data: any) {
    this.isNewAssessment = false;
    this.appointmentService.getAssessmentById(data.id).subscribe(response => {
      this.assessment = response;
      this.setMinMaxOrderNumberRange();
      this.notes = this.assessment.notes;
      this._assessmentCurrentOrderNumber = response.problemNumber;
      if (this.notesEditor) this.notesEditor.insertContent(this.assessment.notes);
      this.assessment.appointmentId = this.appointmentId;
      this.assessment.patientId = this.patientId;
      this.assessment.icdCode = response.icD10Code;
      this.assessment.code = response.icD10Code;
      this.assessment.diagnosis = response.icD10Desc;
      this.assessment.order = response.problemNumber;
      this.assessment.points = response.mdm;
      this.isAssessmentPopupOpened = true;
    });
  }

  onAssessmentCellPrepared($event: any) {
    if ($event.columnIndex == 6) {
      //MDM
      $event.cellElement.addEventListener('mousemove', function () {
        if (
          typeof $event.data != 'undefined' &&
          typeof $event.data.points != 'undefined'
        ) {
          $event.cellElement.setAttribute('title', $event.data.points);
        }
      });
    }
  }

  calculateCellMDM(data: any) {
    if (data.points != null && typeof data.points != 'undefined') {
      if (data.points.length > 20) {
        return data.points.substring(0, 20) + '...';
      }
      return data.points;
    }

    return '';
  }

  createUpdateAssessment() {
    if (!this.assessment.useDefaultProvider) {
      this.assessment.provider = this.saveProvider;
    }
    const validationResult = this.assessmentForm.instance.validate();

    if (!validationResult.isValid) {
      return;
    }

    if (
      this.endDateEnabled &&
      (this.assessment.endDate === undefined || this.assessment.endDate === null)
    ) {
      this.alertService.error('Please select EndDate.');
      return;
    }

    if (moment(this.assessment.endDate).isBefore(this.assessment.startDate)) {
      this.alertService.error('The end date must be greater than the start date.');
      return;
    }

    const user = this.getUserDetails();

    localStorage.setItem('lastAssessment', JSON.stringify(this.assessment));
    // updated code
    this.assessment.notes = this.notesEditor.content;
    this.assessment.notes = TextHelper.removeDoubleSpaces(this.assessment.notes);
    this.assessment.notes = TextHelper.applyFormatting(this.assessment.notes);

    this.assessment.employee = user.fullName;

    if (this.isNewAssessment) {
      this.original.push(this.assessment);
    } else {
      const assessmentToUpdate = this.original.filter(
        a => a.id === this.assessment.id
      )[0];

      assessmentToUpdate.diagnosis = this.assessment?.diagnosis;
      assessmentToUpdate.notes = this.assessment?.notes;
      assessmentToUpdate.order = this.assessment?.order;
      assessmentToUpdate.startDate = this.assessment?.startDate;
      assessmentToUpdate.endDate = this.assessment?.endDate;
      assessmentToUpdate.points = this.assessment?.points;
      assessmentToUpdate.status = this.assessment?.status;
      assessmentToUpdate.provider = this.assessment?.provider;
    }

    this._orderService?.adjustOrder(
      this.assessment.id,
      this._assessmentCurrentOrderNumber
    );
    this.patientChartTrackService.emitAssessmentDataChanges(
      {
        nodeID: PatientChartNodeType.AssessmentNode,
        data: this.patientChartDocumentNode,
      },
      [this.assessment, this.toEmitIcdCodes]
    );

    this.isAssessmentPopupOpened = false;
    this.selectedAssessments = [];
    this.updateAssessmentsAndProblemList();

    // this.assessmentsGrid.instance.refresh();
    this.assessment = new Assessment();
    this.assessment.provider = this.appointment?.physicianId;
  }

  private updateAssessmentsAndProblemList() {
    if (!this.patientChartNode) return;

    this.assessments = this.patientChartNode.value.filter(
      (assessment: any) => assessment.status === 'Current'
    );
    this.problemList = this.patientChartNode.value.filter(
      (assessment: any) => assessment.status !== 'Current'
    );
  }

  private setMinMaxOrderNumberRange() {
    this.orderNumberMinAvailableValue = 1;
    if (this._orderService) {
      this.orderNumberMaxAvailableValue = this.isNewAssessment
        ? this._orderService.maxOrderNumber
        : this._orderService.maxOrderNumber - 1;
    }
  }

  private init() {
    this.initIcdCodeDataSource();
  }

  onIcdCodeChanged($event: any) {
    const keyword = $event.value;
    const apiUrl = `icdcode/search?keyword=${keyword}`;

    this.repositoryService.getData(apiUrl).subscribe({
      next: data => {
        this.data = data;
      },
      error: _err => { },
    });
  }

  public searchIcdCodes() {
    const keyword = '';
    const apiUrl = `icdcode/search?keyword=${keyword}`;

    this.repositoryService.getData(apiUrl).subscribe({
      next: data => {
        this.data = data;
      },
      error: _err => { },
    });
  }

  public onPlGroupValueChanged($event: any) {
    const event = $event.event.key;
    const _keyCode = event.keyCode;
  }

  private initIcdCodeDataSource(): void {
    this.icdCodesDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('icdcode'),
      key: 'Id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, _jQueryAjaxSettings) => { },
        this
      ),
    });

    this.icdCodesDataSource.store.load().then((data: any) => {
      this.toEmitIcdCodes = data;
    });
  }

  private initProvidersDataSource(): void {
    const apiUrl = `user/careTeam-provider?companyId=${this.companyId}&patientId=${this.patientId}`;
    this.repositoryService.getData(apiUrl).subscribe({
      next: data => {
        this.providersDataSource = data;
        if (Array.isArray(this.providersDataSource)) {
          for (const provider of this.providersDataSource)
            provider.defaultProvider = 'Default Provider';
        }
      },
      error: _error => {
        if (typeof _error.error === 'object') {
          this.alertService.error(
            "Can't connect to the API Server.<br>Please confirm your net connection or contact admin."
          );
        } else {
          this.alertService.error(_error.error);
        }
      },
    });
  }

  onStatusChanged(event: any) {
    const status = event.value.id;
    this.problemList = this.original.filter(c => c.status == status);
  }

  onStatusChanged1(e: any) {
    this.assessmentStatus = e.value.name;
    this.getAssessments(e.value.name);

  }

  customItemTemplate(data: any) {
    if (data.type === 0) {
      return `<div class="medico-provider" title="${data.name}" >${data.name}</div>`;
    } else {
      return `<div class="careteam-provider" title="${data.name}" >${data.name}</div>`;
    }
  }

  getProviderName(value: string) {
    const filterData = this.providersDataSource?.filter((item: any) => item.id === value);
    if (filterData.length > 0) {
      return filterData[0].name;
    } else {
      return '';
    }
  }

  closeForm() {
    this.resetForm();
    this.isAssessmentPopupOpened = false;
  }

  private resetForm() {
    this.isNewAssessment = true;
    this.selectedAssessments = [];
    this.assessment = new Assessment();
  }
}



class OrderService {
  private _items;

  constructor(items: Array<any>) {
    this._items = items;
  }

  get maxOrderNumber(): number {
    return this._items.length + 1;
  }

  adjustOrder(
    changedItemId: string,
    previousOrderNumber: number | undefined,
    isDelete = false
  ) {
    const changedItem = this._items.filter(i => i.id === changedItemId)[0];
    if (!changedItem) {
      throw `Item with id: ${changedItemId} was not found`;
    }
    if (isDelete) {
      const changedItemIndex = this._items.map(i => i.order).indexOf(changedItem.order);

      this._items.splice(changedItemIndex, 1);
      for (let i = changedItemIndex; i < this._items.length; i++) {
        this._items[i].order = this._items[i].order - 1;
      }
    }
    const isOrderChanged = changedItem.order !== previousOrderNumber;
    if (isOrderChanged) {
      const itemToModify = this._items.filter(
        i => i.order === changedItem.order && i.id !== changedItemId
      )[0];
      if (!itemToModify) {
        throw 'Item was not found';
      } else {
        itemToModify.order = previousOrderNumber;
      }
    }

    this._items.sort((item1, item2) => item1.order - item2.order);
  }


}
