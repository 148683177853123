import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from 'src/app/_services/config.service';
import { Appointment } from '../_models/appointment';
import { DateHelper } from 'src/app/_helpers/date.helper';
import { AppointmentGridItem } from '../scheduler/models/appointmentGridItem';
import { AppointmentReportModel } from '../_models/appointmentReportModel';
import { firstValueFrom } from 'rxjs';
import { Filter } from '../patients/classes/filter';

@Injectable({ providedIn: 'root' })
export class AppointmentService {
  currentAppointmentData: any;
  constructor(private http: HttpClient, private config: ConfigService) { }
  getCurrentAppointmentData() {
    return this.currentAppointmentData;
  }
  setCurrentAppointmentData(data: Appointment | null) {
    this.currentAppointmentData = data;
  }

  getByAdmissionId(admissionId: string): Promise<Appointment> {
    return firstValueFrom(
      this.http.get<Appointment>(
        `${this.config.apiUrl}appointment/admission/${admissionId}`
      )
    ).then(appointment => {
      if (appointment) {
        appointment.startDate = DateHelper.sqlServerUtcDateToLocalJsDate(
          appointment.startDate
        );
        appointment.endDate = DateHelper.sqlServerUtcDateToLocalJsDate(
          appointment.endDate
        );
      }

      return appointment;
    });
  }

  getAppointmentGridItemById(appointmentId: string): Promise<AppointmentGridItem> {
    return firstValueFrom(
      this.http.get<AppointmentGridItem>(
        `${this.config.apiUrl}appointment/griditem/${appointmentId}`
      )
    );
  }

  getByUserId(userId: string) {
    const url = `${this.config.apiUrl}appointment/user/${userId}`;
    return firstValueFrom(this.http.get<Appointment>(url));
  }

  getDocumentsByAppointmentId(appointmentId: string): Promise<string[]> {
    const url = `${this.config.apiUrl}appointment/chartDocuments/${appointmentId}`;
    return firstValueFrom(this.http.get<string[]>(url));
  }
  getMRNByCompanyId(companyId: string, locationId: string): Promise<string> {
    const url = `${this.config.apiUrl}appointment/MRNByLocation/${companyId}/${locationId}`;
    return firstValueFrom(this.http.get(url, { responseType: 'text' }))
      .then(response => {
        return response as string;
      })
      .catch((error: HttpErrorResponse) => {
        console.error('Error fetching MRN:', error);
        throw error;
      });
  }
  getPatientPreviousVisits(
    patientId: string | undefined,
    startDate: any
  ): Promise<AppointmentGridItem[]> {
    if (!patientId) return Promise.reject();

    const utcDate = DateHelper.jsLocalDateToSqlServerUtc(startDate);

    const url = `${this.config.apiUrl}appointment/previous/${patientId}/date/${utcDate}`;
    return firstValueFrom(this.http.get<AppointmentGridItem[]>(url)).then(
      previousVisits => {
        if (!previousVisits.length) return previousVisits;

        previousVisits.forEach(previousVisit => {
          previousVisit.startDate = DateHelper.sqlServerUtcDateToLocalJsDate(
            previousVisit.startDate
          );
          previousVisit.endDate = DateHelper.sqlServerUtcDateToLocalJsDate(
            previousVisit.endDate
          );

          const startDate = previousVisit.startDate;
          /*previousVisit.date = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          );*/
          previousVisit.date = DateHelper.sqlServerUtcDateToLocalJsDate(previousVisit.date);
        });

        return previousVisits;
      }
    );
  }

  getPatientPreviousVisitsBetweenDates(
    patientId: string | undefined,
    startDate: any,
    endDate: any,
    quantity: any
  ): Promise<AppointmentGridItem[]> {
    if (!patientId) return Promise.reject();

    const utcStartDate = DateHelper.jsLocalDateToSqlServerUtc(startDate);
    const utcEndDate = DateHelper.jsLocalDateToSqlServerUtc(endDate);

    const url = `${this.config.apiUrl}appointment/previous/${patientId}/twodates/${utcStartDate}/${utcEndDate}/${quantity}`;
    return firstValueFrom(this.http.get<AppointmentGridItem[]>(url)).then(
      previousVisits => {
        if (previousVisits.length) return previousVisits;

        previousVisits.forEach(previousVisit => {
          previousVisit.startDate = DateHelper.sqlServerUtcDateToLocalJsDate(
            previousVisit.startDate
          );
          previousVisit.endDate = DateHelper.sqlServerUtcDateToLocalJsDate(
            previousVisit.endDate
          );

          const startDate = previousVisit.startDate;
          previousVisit.date = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          );
        });

        return previousVisits;
      }
    );
  }

  getPatientPreviousVisitsBetweenDatesOther(
    patientId: string | undefined,
    startDate: any,
    endDate: any,
    quantity: any
  ): Promise<any> {
    if (!patientId) return Promise.reject();

    const utcStartDate = DateHelper.jsLocalDateToSqlServerUtc(startDate);
    const utcEndDate = DateHelper.jsLocalDateToSqlServerUtc(endDate);

    const url = `${this.config.apiUrl}appointment/previous/${patientId}/twodates/${utcStartDate}/${utcEndDate}/${quantity}`;
    return firstValueFrom(this.http.get<any>(url)).then(
      previousVisits => {

        if (previousVisits.length) return previousVisits;



        /*previousVisits.forEach(previousVisit => {
          previousVisit.startDate = DateHelper.sqlServerUtcDateToLocalJsDate(
            previousVisit.startDate
          );
          previousVisit.endDate = DateHelper.sqlServerUtcDateToLocalJsDate(
            previousVisit.endDate
          );

          const startDate = previousVisit.startDate;
          previousVisit.date = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          );
        });*/

        //return previousVisits;
        let previousVistis: any = [];
        return previousVistis;
      }
    );
  }

  getPatientLastVisit(patientId: string, startDate: any): Promise<Appointment> {
    const utcDate = DateHelper.jsLocalDateToSqlServerUtc(startDate);

    const url = `${this.config.apiUrl}appointment/last/patient/${patientId}/date/${utcDate}`;
    return firstValueFrom(this.http.get<Appointment>(url));
  }

  getByLocationId(locationId: string): Promise<Appointment> {
    const url = `${this.config.apiUrl}appointment/location/${locationId}`;
    return firstValueFrom(this.http.get<Appointment>(url));
  }

  getByRoomId(roomId: string) {
    const url = `${this.config.apiUrl}appointment/room/${roomId}`;
    return firstValueFrom(this.http.get<Appointment>(url));
  }

  save(appointment: Appointment): Promise<Appointment> {
    return firstValueFrom(
      this.http.post<Appointment>(`${this.config.apiUrl}appointment/`, appointment)
    );
  }

  checkValidatingBussinessHours(appointment: Appointment): Promise<Appointment> {
    return firstValueFrom(
      this.http.post<Appointment>(
        `${this.config.apiUrl}appointment/check-business-hours`,
        appointment
      )
    );
  }

  checkBussinessDayHours(appointment: any): Promise<any> {
    return firstValueFrom(
      this.http.post<any>(
        `${this.config.apiUrl}appointment/checkBusinessDayHours`,
        appointment
      )
    );
  }

  saveSimple(appointment: any): Promise<Appointment> {
    return firstValueFrom(
      this.http.post<Appointment>(
        `${this.config.apiUrl}appointment/savesimple`,
        appointment
      )
    );
  }

  getAssessments(param: any) {
    return this.http.post<any>(
      `${this.config.apiUrl}appointment/getAssessments`,
      param
    );
  }

  saveAssessments(param: any) {
    return this.http.post<any>(
      `${this.config.apiUrl}appointment/saveAssessments`,
      param
    );
  }

  getAssessmentById(id: any) {
    return this.http.get<any>(
      `${this.config.apiUrl}appointment/getAssessmentById/${id}`
    );
  }

  deleteAssessmentById(id: any) {
    return this.http.delete<any>(
      `${this.config.apiUrl}appointment/deleteAssessmentById/${id}`
    );
  }

  getById(id: string): Promise<Appointment> {
    return firstValueFrom(
      this.http.get<Appointment>(`${this.config.apiUrl}appointment/${id}`)
    );
  }

  delete(id: string): Promise<void> {
    return firstValueFrom(
      this.http.delete<void>(`${this.config.apiUrl}appointment/${id}`)
    );
  }

  getAppointmentHtmlReport(appointmentId: string): Promise<string> {
    const utcOffset = DateHelper.getUtcOffset();
    return firstValueFrom(
      this.http.get<AppointmentReportModel>(
        `${this.config.apiUrl}report/appointment/${appointmentId}/offset/${utcOffset}/view`
      )
    ).then(appointmentReportModel => {
      return appointmentReportModel.reportContent;
    });
  }

  getAllAppointment(filterPatient: Filter): any {
    const url = `${this.config.apiUrl}appointment/providers/getAll`;
    return this.http.post(url, filterPatient);
  }
}
