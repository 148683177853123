export class SearchConfiguration {
  skipItemsCount = 0;
  takeItemsCount = 10;

  get pageSizeCount(): number {
    return this.takeItemsCount;
  }

  allowedPageSizes: Array<number> = [10, 25, 50];

  availableFilters: string[] = ['contains', '=', 'startswith'];

  availableDateFilters: string[] = ['equals'];

  deleteIcon = 'delete.svg';
  editIcon = 'edit.svg'
  viewIcon = 'view-file.svg'
  iconWidth = 25;
}