import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  DxDataGridComponent,
  DxFormComponent,
  DxPopupComponent,
} from 'devextreme-angular';
import { event } from 'jquery';
import { SearchConfiguration } from 'src/app/_classes/searchConfiguration';
import { AlertService } from 'src/app/_services/alert.service';
import { ClinicalTablesService } from 'src/app/_services/clinicalTables.service';
import { ErrorHandlerService } from 'src/app/_services/error-handler.service';
import { RepositoryService } from 'src/app/_services/repository.service';
import { PatientChartNodeType } from '../../../../../_models/patientChartNodeType';
import { SelectedPatientChartNodeService } from '../../../../../_services/selected-patient-chart-node.service';
import { DefaultValueService } from '../../../../../_services/default-value.service';
import { BaseHistoryComponent } from '../base-history.component';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxDataUrlService } from '../../../../../_services/dxDataUrl.service';
import { DevextremeAuthService } from '../../../../../_services/devextreme-auth.service';
import { DateHelper } from '../../../../../_helpers/date.helper';

@Component({
  selector: 'care-team-mamnagement',
  templateUrl: './careTeamManagement.component.html',
  styleUrls: ['./careTeamManagement.component.scss'],
})
export class CareTeamManagementComponent extends BaseHistoryComponent implements OnInit {
  @ViewChild('careTeamDataGrid', { static: false })
  careTeamDataGrid!: DxDataGridComponent;
  @ViewChild('careTeamAddPopup', { static: false })
  careTeamAddPopup!: DxPopupComponent;
  @ViewChild('careTeamForm', { static: false })
  careTeamForm!: DxFormComponent;

  @Input() patientId: string = '';

  @ViewChild('hcpcsDataGrid') dataGridSelected: any;
  @ViewChild('dataGridDeleted') dataGridDeleted: any;
  @ViewChild('button') button: any;

  @Input() companyId: string = '';
  @Input() isSignedOff: any;
  careTeamSource: any;
  loading = false;
  selectedCareTeamId: string = '';
  isCareTeamFormOpened: boolean = false;
  title: string = 'Add CareTeam Provider';
  isNewProvider: boolean = true;
  careTeam: any = {};
  gridDataSource: any[] = [];
  isPopupVisible: boolean = false;
  isValidationBorder: boolean = true;
  isButtonDisabled: boolean = true;

  constructor(
    private alertService: AlertService,
    private repository: RepositoryService,
    private repositoryService: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private clinicalTablesService: ClinicalTablesService,
    private dxDataUrlService: DxDataUrlService,
    private devextremeAuthService: DevextremeAuthService,
    selectedPatientChartNodeService: SelectedPatientChartNodeService,
    defaultValueService: DefaultValueService
  ) {
    super(defaultValueService, selectedPatientChartNodeService);

    this.init();
  }

  private bindCareTeam() {
    const apiUrlcareTeam = `CareTeam/Get-CareTeam/${this.patientId}`;
    this.repositoryService.getData(apiUrlcareTeam).subscribe({
      next: res => {
        if (res.success) {
          this.careTeamSource = res.data;
        } else {
          this.alertService.error(res.message);
        }
        this.loading = false;
      },
      error: error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      },
    });
  }

  ngOnInit() {
    this.bindCareTeam();
  }
  private init() {
    this.initDefaultHistoryValue(PatientChartNodeType.CareTeamNode);
  }

  createCareTeamProvider() {
    const validationResult = this.careTeamForm.instance.validate();

    if (!validationResult.isValid) {
      return;
    }

    const method = this.button?.nativeElement.getAttribute('data-method');
    if (method == 'save') delete this.careTeam.Id;

    const apiUrl = `careTeam`;
    this.repositoryService.create(apiUrl, this.careTeam).subscribe({
      next: res => {
        if (res.success) {
          this.alertService.info(res.message);
          this.isCareTeamFormOpened = false;
          this.repositoryService.emitCareTeamChange.next(true);
          this.bindCareTeam();
          //this.careTeamDataGrid.instance.refresh();
          if (this.dataGridSelected) this.dataGridSelected.instance.refresh();
        } else {
          this.alertService.error(res.message);
        }

        this.loading = false;
      },
      error: error => {
        this.errorHandler.handleError(error);
        this.loading = false;
      },
    });
  }

  onSearchTextChanged(e: any) {
    const code = e.event.keyCode;
    const value = e.event.currentTarget.value;
    this.isPopupVisible = true;
    this.isInputError(value);
    this.areFieldsEmpty(value);
    if (!this.isValidKeyCode(code) || value.length < 3) return;
    // if (code !== 13) return;
    this.gridDataSource = [];
    const apiUrl = `clinicalTables/npi/`;

    this.clinicalTablesService.getClinicalData(apiUrl, value).subscribe({
      next: res => {
        const data = res[3];
        const tmp: any[] = [];
        data.forEach((el: any) => {
          const tmpData: { [key: string]: string } = {
            npi: el[0],
            providerName: el[1],
            providerType: el[2],
            practiceLocationAddress: el[3],
            providerLastName: el[4],
            providerMiddleName: el[5],
            providerFirstName: el[6],
            phoneNumber: el[7],
            faxNumber: el[8],
            practiceLocationAddressCity: el[9],
            practiceLocationAddressState: el[10],
            practiceLocationAddressZip: el[11],
            enumerationDate: el[14],
          };

          const taxonomy = JSON.parse(`[${el[13]}]`);
          taxonomy.forEach((element: any, index: number) => {
            tmpData[`taxonomy${index + 1}`] = element.taxonomy.grouping;
            tmpData[`taxonomyCode${index + 1}`] = element.taxonomy.code;
            tmpData[`licenseNumber${index + 1}`] = element.lic_number;
          });

          tmp.push(tmpData);
        });
        this.gridDataSource = tmp;
      },
      error: _error => {
        if (typeof _error.error === 'object') {
          this.alertService.error(
            "Can't connect to the API Server.<br>Please confirm your net connection or contact admin."
          );
        } else {
          this.alertService.error(_error.error);
        }
        this.loading = false;
      },
    });
  }

  onNpiSelected($event: any) {
    this.isButtonDisabled = false;
    this.isValidationBorder = false;
    this.isPopupVisible = false;

    const data = $event.data;
    this.careTeam = data;
    this.careTeam.patientId = this.patientId;
    if (this.selectedCareTeamId !== '') this.careTeam.Id = this.selectedCareTeamId;
  }

  clear() {
    this.careTeam = {};
  }

  add() {
    this.isCareTeamFormOpened = true;
    this.isNewProvider = true;
    this.title = 'Add CareTeam Provider';
    this.clear();
    this.isButtonDisabled = true;
    this.isValidationBorder = true;
  }

  edit(providers: any) {
    this.isCareTeamFormOpened = true;
    this.isNewProvider = false;
    this.title = 'Edit CareTeam Provider';
    this.careTeam = providers;
    this.selectedCareTeamId = providers.id;
  }

  deleteProvider(id: string) {
    const apiUrl = `careTeam/${id}`;
    this.repository.delete(apiUrl).subscribe({
      next: () => {
        this.bindCareTeam();
        //this.careTeamDataGrid.instance.refresh();
        this.repositoryService.emitCareTeamChange.next(true);
        this.dataGridDeleted.instance.refresh();
      },
      error: _error => {
        if (typeof _error.error === 'object') {
          this.alertService.error(
            "Can't connect to the API Server.<br>Please confirm your net connection or contact admin."
          );
        } else {
          this.alertService.error(_error.error);
        }
      },
    });
  }

  areFieldsEmpty(value: any) {
    if (
      value == undefined ||
      value == '' ||
      this.careTeam.providerFirstName == undefined ||
      this.careTeam.providerFirstName == ''
    ) {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  isValidKeyCode(keyCode: number): boolean {
    return (
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      (keyCode >= 48 && keyCode <= 57) ||
      keyCode === 13 ||
      keyCode === 8 ||
      keyCode === 46
    );
  }

  isInputError(value: any) {
    if (value == undefined || value == '' || value.length < 3) {
      this.isValidationBorder = true;
      this.isButtonDisabled = true;
    } else this.isValidationBorder = false;
  }
}
