import { Component, OnInit } from '@angular/core';
import { AppointmentTypeService } from '../../services/appointment-type.service';
import { AlertService } from 'src/app/_services/alert.service';
import { BaseAdminComponent } from 'src/app/_classes/baseAdminComponent';
import { ValueComponent } from '../../../share/components/value-component/value.component';

@Component({
  selector: 'appointment-type-management',
  templateUrl: './appointment-type-management.component.html',
  styleUrls: ['./appointment-type-management.component.scss'],
})
export class AppointmentTypeManagementComponent
  extends BaseAdminComponent
  implements OnInit {
  allAppointmentType: any;
  selectableRow: any;
  appointmentTypeForm: any;
  addPopup: boolean = false;
  isNewAppointmentType: boolean = false;
  titlePopup: string = '';

  constructor(
    private appointmentTypeService: AppointmentTypeService,
    private alertService: AlertService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    const result: any = await this.appointmentTypeService.GetAllAppointmentType();
    this.allAppointmentType = result[0].selectableListValues;
    this.selectableRow = result[0];
  }

  openAddPopup() {
    this.appointmentTypeForm = {};
    this.titlePopup = 'Add Appointment Type';
    this.isNewAppointmentType = true;
    this.addPopup = true;
  }

  generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  createAppointmentType() {
    if (!this.appointmentTypeForm.value) {
      this.alertService.error('Name is required.');
      return;
    }

    if (!this.isNewAppointmentType) {
      const appointmentTypeToUpdate = this.selectableRow.selectableListValues.find(
        (value: any) => value.id === this.appointmentTypeForm.id
      );
      appointmentTypeToUpdate.value = this.appointmentTypeForm.value;
      appointmentTypeToUpdate.description = this.appointmentTypeForm.value;
      this.appointmentTypeService.createAppointmentType(this.selectableRow);
      this.alertService.notifyMsg('Changes saved successfully', 'success');
    } else {
      const guid = this.generateGuid();
      this.appointmentTypeForm.description = this.appointmentTypeForm.value;
      this.appointmentTypeForm.isDefault = false;
      this.appointmentTypeForm.id = guid;

      this.selectableRow.selectableListValues.push(this.appointmentTypeForm);
      this.appointmentTypeService.createAppointmentType(this.selectableRow);
      this.alertService.notifyMsg('Changes saved successfully', 'success');
    }
    this.isNewAppointmentType = true;
    this.addPopup = false;
  }

  deleteAppointmentType(appointmentType: any, $event: any) {
    $event.stopPropagation();

    const confirmationPopup = this.alertService.confirm(
      'Are you sure you want to delete the appointment type?',
      'Confirm deletion'
    );

    confirmationPopup.then(dialogResult => {
      if (dialogResult) {
        const appointmentTypeToDeleteIndex =
          this.selectableRow.selectableListValues.findIndex(
            (value: any) => value.id == appointmentType.id
          );
        if (appointmentTypeToDeleteIndex !== -1) {
          this.selectableRow.selectableListValues.splice(appointmentTypeToDeleteIndex, 1);
        }
        this.appointmentTypeService.createAppointmentType(this.selectableRow);
        this.alertService.notifyMsg("Record deleted successfully", "success");
      }
    });
  }

  updateOpenPopup(appointmentData: any) {
    this.titlePopup = 'Update Appointment Type';
    this.appointmentTypeForm.value = appointmentData.value;
    this.appointmentTypeForm.description = appointmentData.description;
    this.appointmentTypeForm.isDefault = appointmentData.isDefault;
    this.appointmentTypeForm.id = appointmentData.id;
    this.isNewAppointmentType = false;
    this.addPopup = true;
  }
}
