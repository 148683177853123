export class PrefixList {
    static values: any[] = [
        {
            "id": "aa8f97c9-1756-1581-33de-e006a82af324",
            "value": "Mr."
        },
        {
            "id": "274950e7-d01c-8b18-3179-4329cb9af810",
            "value": "Mrs."
        },
        {
            "id": "d0f6fe7d-d69d-ced3-b6fa-1a66aaa1d0a8",
            "value": "Ms."
        },
        {
            "id": "41395a61-bac0-9bea-7cd2-22c1e63544ff",
            "value": "Dr."
        }
    ]
}


export class SuffixList {
    static values: any[] = [
        {
            "id": "06f46038-04e8-7a84-4eba-35a2ec8e4b95",
            "value": "DO"
        },
        {
            "id": "cc5d6fa3-18b5-ccec-599b-557b71be8515",
            "value": "MD"
        },
        {
            "id": "fdd05535-6d04-4b75-57bc-b027a913c512",
            "value": "PhD"
        },
        {
            "id": "37a2acb4-b0ef-b8b4-2e20-e447b23bf424",
            "value": "OD"
        },
        {
            "id": "3d75b60e-83ef-d1b2-de4a-cc0c3f3d7b06",
            "value": "PA"
        },
        {
            "id": "c3d3d6c6-a997-a3d9-5a89-288bf7b766fd",
            "value": "FNP"
        },
        {
            "id": "e2ecd2dc-597e-e2b7-273c-6cd6d8914f13",
            "value": "RN"
        },
        {
            "id": "1dbbcb35-fd06-b000-6408-3190f041194a",
            "value": "MA"
        }
    ]

}
