import {
  Component,
  Input,
  OnDestroy,
  AfterViewInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { GuidHelper } from 'src/app/_helpers/guid.helper';
import { Constants } from 'src/app/_classes/constants';
import { EnvironmentUrlService } from 'src/app/_services/environment-url.service';
import { ErrorHandlerService } from 'src/app/_services/error-handler.service';
import { RepositoryService } from 'src/app/_services/repository.service';
import tinymceEditor from 'tinymce';
@Component({
  templateUrl: 'patient-rich-text-editor.component.html',
  selector: 'patient-rich-text-editor',
})
export class PatientRichTextEditorComponent
  implements AfterViewInit, OnInit, OnDestroy, OnChanges
{
  @Input() initialContent: string = '';
  @Input() companyId?: string;

  @Output() editorChange = new EventEmitter<any>();
  @Output() editorReady = new EventEmitter<boolean>();

  editor: any;
  editorId: string;
  configData: any = {};

  constructor(
    private repositoryService: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private envService: EnvironmentUrlService
  ) {
    this.editorId = GuidHelper.generateNewGuid();
  }

  ngOnChanges(_changes: SimpleChanges) {
    if (this.initialContent && this.editor) this.editor.setContent(this.initialContent);
  }

  insertContent(content: string) {
    const wrappedContent = `<span>&nbsp;</span>${content}<span>&nbsp;</span>`;

    this.editor.execCommand('mceInsertContent', false, wrappedContent);
  }

  get content(): string {
    return this.editor.getContent();
  }

  ngOnInit() {
    this.bindEditorConfig();
  }

  ngAfterViewInit(): void {

  }

  bindEditorConfig() {
    const apiUrl = 'settings/editor-config';
    this.repositoryService.getData(apiUrl).subscribe({
      next: res => {
        if (res.success) {
          this.configData = res.data;
          setTimeout(() => {
            tinymceEditor.init({
              extended_valid_elements: `${Constants.selectableItem.tagName.toLowerCase()}[${
                Constants.selectableItem.attributes.id
              }|${Constants.selectableItem.attributes.metadata}|style]`,
              content_style: Constants.tinymceStyles.detailedEditor,
              
              height: 680,
              body_class: 'admin-rich-text-editor',
              ui_container: '.popup',
              selector: `#patient-rich-editor`,
              plugins: ["export", "lists", "table", "image", "powerpaste"],
              fontsize_formats: '8pt 10pt 12pt 14pt 18pt 20pt 24pt',
              menubar: true,
              readonly: true,
              toolbar:
                'export insertfile undo redo | fontsize | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | image',
              powerpaste_allow_local_images: true,
              powerpaste_word_import: 'prompt',
              powerpaste_html_import: 'prompt',
              browser_spellcheck: true,
              images_upload_url: `${this.envService.urlAddress}Order/uploadFile`,
              setup: (editor: any) => {
                this.editor = editor;
                editor.on('focusout', () => {
                  this.emitContentChange();
                });
              },
              init_instance_callback: (editor: any) => {
                editor.setContent(this.initialContent);
              },
            });
          }, 0);
        }
      },
      error: error => {
        this.errorHandler.handleError(error);
      },
    });
  }

  ngOnDestroy(): void {
    
  }

  private emitContentChange() {
    const content = this.editor.getContent();
    this.editorChange.emit(content);
  }

}
