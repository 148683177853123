import { Component } from '@angular/core';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent {
  adminSectionNames: Array<string> = [
    'companyManagement',
    'templateManagement',
    'medicationManagement',
    'patientChartManagement',
    'expressionsManagement',
    'insCompManagement',
    'labTestsManagement',
    'profileManagement',
    'vendorManagement',
    'preAuthManagement',
    'careTeamManagement',
    'businessHoursManagement',
    'appointmentStatusColorManagement',
    'configurationSettings',
    'appointmentTypeManagement',
  ];

  currentlyOpenedAdminSection: string = this.adminSectionNames[0];

  adminModelTree: Array<any> = [
    {
      text: 'Company Management',
      name: this.adminSectionNames[0],
      isSelected: true,
    },
    {
      text: 'Template Management',
      name: this.adminSectionNames[1],
    },
    {
      text: 'Medication Management',
      name: this.adminSectionNames[2],
    },
    {
      text: 'Patient Chart Management',
      name: this.adminSectionNames[3],
    },
    {
      text: 'Expressions Management',
      name: this.adminSectionNames[4],
    },
    {
      text: 'Insurance Company Management',
      name: this.adminSectionNames[5],
    },
    {
      text: 'Order Management',
      name: this.adminSectionNames[6],
    },
    {
      text: 'Profile Management',
      name: this.adminSectionNames[7],
    },
    {
      text: 'Vendor Management',
      name: this.adminSectionNames[8],
    },
    {
      text: 'Business & Holiday Hours Management',
      name: this.adminSectionNames[9],
    },
    {
      text: 'Appointment Status Color Management',
      name: this.adminSectionNames[10],
    },
    {
      text: 'Configuration Settings',
      name: this.adminSectionNames[11],
    },
    {
      text: 'Appointment Type Management',
      name: this.adminSectionNames[12],
    },
  ];
  selectedItem: any = null;
  constructor() {
    this.selectedItem = this.adminModelTree[0];
    this.highlightSelectedItem();
  }

  isAdminSectionOpened(adminSectionName: string): boolean {
    return adminSectionName === this.currentlyOpenedAdminSection;
  }

  onItemRendered(event: any): void {
    this.highlightSelectedItem();
  }

  selectAdminSection($event: any) {
    if (!$event || !$event.itemData || !$event.itemData.name) {
      return;
    }
    const adminSectionName = $event.itemData.name;
    this.currentlyOpenedAdminSection = adminSectionName;
    this.selectedItem = $event.itemData;
    this.highlightSelectedItem();
  }

  highlightSelectedItem(): void {
    setTimeout(() => {
      const treeViewItems = document.querySelectorAll('.dx-treeview-node');
      treeViewItems.forEach((item: any) => {
        const itemText = item.textContent.trim();
        if (this.selectedItem && itemText === this.selectedItem.text) {
          item.classList.add('selected-item');
        } else {
          item.classList.remove('selected-item');
        }
      });
    }, 0);
  }
}
