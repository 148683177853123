import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/_services/config.service';
import { HttpClient } from '@angular/common/http';
import { SelectableList } from 'src/app/_models/selectableList';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class AppointmentTypeService {
  title = 'Appointment Type';
  constructor(private http: HttpClient, protected config: ConfigService) {}

  public GetAllAppointmentType() {
    return firstValueFrom(
      this.http.get<SelectableList>(
        `${
          this.config.apiUrl
        }${'library-selectable-lists'}/${'get-all-appointment-type'}/${this.title}`
      )
    );
  }
  public createAppointmentType(template: SelectableList) {
    return firstValueFrom(
      this.http.post<SelectableList>(
        `${this.config.apiUrl}${'library-selectable-lists'}`,
        template
      )
    );
  }
}
