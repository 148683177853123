import { Component, ViewChild } from '@angular/core';
import { BaseAdminComponent } from 'src/app/_classes/baseAdminComponent';
import { AlertService } from 'src/app/_services/alert.service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxDataUrlService } from 'src/app/_services/dxDataUrl.service';
import { DevextremeAuthService } from 'src/app/_services/devextreme-auth.service';
import { DxFormComponent, DxPopupComponent } from 'devextreme-angular';
import { RepositoryService } from 'src/app/_services/repository.service';

@Component({
  selector: 'role',
  templateUrl: './role.component.html',
})
export class RoleComponent extends BaseAdminComponent {
  @ViewChild('rolePopup', { static: false })
  rolePopup!: DxPopupComponent;
  @ViewChild('roleForm', { static: false })
  roleForm!: DxFormComponent;

  isrolePopupOpened = false;
  roleDataSource: any = {};
  isNewRole: boolean = false;
  role: any = {};
  selectedRoles: any[] = [];

  rolePermissions: Array<any> = [
    {
      name: 'Add',
      value: false,
    },
    {
      name: 'Edit',
      value: false,
    },
    {
      name: 'Delete',
      value: false,
    },
    {
      name: 'Print',
      value: false,
    },
    {
      name: 'Access Patient Data',
      value: false,
    },
  ];

  constructor(
    private alertService: AlertService,
    private dxDataUrlService: DxDataUrlService,
    private repositoryService: RepositoryService,
    private devextremeAuthService: DevextremeAuthService
  ) {
    super();

    this.init();
  }

  private init(): void {
    this.initRoleDataSource();
  }

  private initRoleDataSource(): void {
    this.roleDataSource = createStore({
      loadUrl: this.dxDataUrlService.getGridUrl('role'),
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, _jQueryAjaxSettings) => { },
        this
      ),
    });
  }

  openroleForm() {
    this.isrolePopupOpened = true;
  }

  onrolePopupHidden() {
    this.resetroleForm();
    this.resetPermissions();
  }

  private resetroleForm() {
    this.isNewRole = true;
    this.role = {};
    this.selectedRoles = [];
  }

  createUpdaterole() {
    //give the permissions to the specific role
    let selectedRolePermissions = '';
    for (const permission of this.rolePermissions) {
      if (permission.value)
        selectedRolePermissions +=
          selectedRolePermissions != '' ? ', ' + permission.name : permission.name;
    }
    this.role.permissions = selectedRolePermissions;
    const apiUrl = `role`;
    this.repositoryService.create(apiUrl, this.role).subscribe({
      next: _data => {
        this.isrolePopupOpened = false;
        this.resetroleForm();
        this.init();
        this.resetPermissions();
      },
      error: err => {

      },
    });
  }

  onRoleChanged(role: any) {
    const roleId = role.id;
    if (!roleId) return;

    const apiUrl = `role/${roleId}`;

    this.repositoryService.getData(apiUrl).subscribe({
      next: role => {
        this.role = role;

        //update the current permissions array
        if (!this.role.permissions) this.role.permissions = [];
        else {
          const permissionsArray = this.role.permissions
            .split(',')
            .map((permission: string) => permission.trim());
          permissionsArray.forEach((permission: any) => {
            const existingPermission = this.rolePermissions.find(
              p => p.name === permission
            );
            if (existingPermission) existingPermission.value = true;
          });
        }
        this.isNewRole = false;
        this.isrolePopupOpened = true;
      },
      error: err => {

      },
    });
  }

  resetPermissions() {
    this.rolePermissions = [
      {
        name: 'Add',
        value: false,
      },
      {
        name: 'Edit',
        value: false,
      },
      {
        name: 'Delete',
        value: false,
      },
      {
        name: 'Print',
        value: false,
      },
      {
        name: 'Access Patient Data',
        value: false,
      },
    ];
  }
}
