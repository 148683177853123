export class StateList {
  static values: any[] = [
    {
      name: 'Alabama',
      value: 1,
    },
    {
      name: 'Alaska',
      value: 2,
    },
    {
      name: 'Arizona',
      value: 3,
    },
    {
      name: 'Arkansas',
      value: 4,
    },
    {
      name: 'California',
      value: 5,
    },
    {
      name: 'Colorado',
      value: 6,
    },
    {
      name: 'Connecticut',
      value: 7,
    },
    {
      name: 'Delaware',
      value: 8,
    },
    {
      name: 'District of Columbia',
      value: 9,
    },
    {
      name: 'Florida',
      value: 10,
    },
    {
      name: 'Georgia',
      value: 11,
    },
    {
      name: 'Hawaii',
      value: 12,
    },
    {
      name: 'Idaho',
      value: 13,
    },
    {
      name: 'Illinois',
      value: 14,
    },
    {
      name: 'Indiana',
      value: 15,
    },
    {
      name: 'Iowa',
      value: 16,
    },
    {
      name: 'Kansas',
      value: 17,
    },
    {
      name: 'Louisiana',
      value: 18,
    },
    {
      name: 'Maine',
      value: 19,
    },
    {
      name: 'Maryland',
      value: 20,
    },
    {
      name: 'Massachusetts',
      value: 21,
    },
    {
      name: 'Michigan',
      value: 22,
    },
    {
      name: 'Minnesota',
      value: 23,
    },
    {
      name: 'Mississippi',
      value: 24,
    },
    {
      name: 'Missouri',
      value: 25,
    },
    {
      name: 'Montana',
      value: 26,
    },
    {
      name: 'Nebraska',
      value: 27,
    },
    {
      name: 'Nevada',
      value: 28,
    },
    {
      name: 'New Hampshire',
      value: 29,
    },
    {
      name: 'New Jersey',
      value: 30,
    },
    {
      name: 'New Mexico',
      value: 31,
    },
    {
      name: 'New York',
      value: 32,
    },
    {
      name: 'North Carolina',
      value: 33,
    },
    {
      name: 'North Dakota',
      value: 34,
    },
    {
      name: 'Ohio',
      value: 35,
    },
    {
      name: 'Oklahoma',
      value: 36,
    },
    {
      name: 'Oregon',
      value: 37,
    },
    {
      name: 'Pennsylvania',
      value: 38,
    },
    {
      name: 'Rhode Island',
      value: 39,
    },
    {
      name: 'South Carolina',
      value: 40,
    },
    {
      name: 'South Dakota',
      value: 41,
    },
    {
      name: 'Tennessee',
      value: 42,
    },
    {
      name: 'Texas',
      value: 43,
    },
    {
      name: 'Utah',
      value: 44,
    },
    {
      name: 'Vermont',
      value: 45,
    },
    {
      name: 'Virginia',
      value: 46,
    },
    {
      name: 'Washington',
      value: 47,
    },
    {
      name: 'West Virginia',
      value: 48,
    },
    {
      name: 'Wisconsin',
      value: 49,
    },
    {
      name: 'Wyoming',
      value: 50,
    },
  ];
}
