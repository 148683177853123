import { Component, OnInit } from '@angular/core';
import { PatientChartNode } from 'src/app/_models/patientChartNode';
import { AdmissionService } from 'src/app/patientChart/services/admission.service';
import { TrowserViewBase } from 'src/app/shared/base-classes';
import { ButtonOptions } from 'src/app/shared/utils';

@Component({
  selector: 'app-review-chart-admission',
  templateUrl: './review-chart-admission.component.html',
  styleUrls: ['./review-chart-admission.component.sass']
})

export class ReviewChartAdmissionComponent extends TrowserViewBase implements OnInit {
  public popupToolbarItems: any[] = [];
  public isSignedOffReview = false;
  public patientChartNodeReview!: PatientChartNode;
  public patientChartDocumentNodeReview!: PatientChartNode;
  public appointmentIdReview = '';
  public patientIdReview = '';
  public admissionIdReview = '';
  public companyIdReview = '';
  constructor(private admissionService: AdmissionService) {
    super();
    this.initPopupToolbarItems();
  }

  ngOnInit(): void {
  }

  public showReviewChart(entity) {
    this.reviewPatientChartFromAdmission(entity);
  }

  private reviewPatientChartFromAdmission(appointment: any) {
    this.admissionService.getById(appointment.admissionId).then(admission => {
      this.patientChartNodeReview = JSON.parse(admission.admissionData || 'null');
      this.patientChartDocumentNodeReview = this.patientChartNodeReview;
      this.appointmentIdReview = appointment?.id;
      this.patientIdReview = appointment?.patientId;
      this.admissionIdReview = appointment?.admissionId;
      this.companyIdReview = appointment?.companyId;
      this.popupVisible = true;
    });
  }

  private initPopupToolbarItems(): void {
    this.popupToolbarItems = super.getTrowserToolbarItems(
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('Cancel', true, this.onBtnCancelClick, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', null, null, this.isDataProcessing),
    );
  }

  private onBtnCancelClick = () => {
    this.popupVisible = false;
  }
}