export class Constants {
  static messages: any = {
    notSet: 'not set',
  };

  static selectableItem: any = {
    tagName: 'LABEL',
    attributes: {
      metadata: 'metadata',
      id: 'id',
      selectableType: 'selectable-type',
      initialValue: 'initial-value',
    },
  };

  static selectableItemTypes: any = {
    list: 'list',
    range: 'range',
    date: 'date',
    variable: 'variable',
  };

  static expressionItem: any = {
    attributes: {
      expressionId: 'expr-id',
    },
  };

  static mvItem: any = {
    attributes: {
      mvId: 'mv-id',
    },
  };

  static varItem: any = {
    attributes: {
      varId: 'var-id',
    },
  };

  static tinymceStyles: any = {
    detailedEditor: `
        .patient-rich-text-editor label[selectable-type] {
            cursor: pointer;
            border: 1px solid red;
            padding: 2px;
            line-height: 1.6em;
            color: black !important;
            font-weight: bolder;
        }

        body.mce-content-body label[selectable-type] {
            color: red;
            font-weight: lighter;
            font-style: italic
        }

        body.mce-content-body label[expr-id] {
            color: lightslategrey;
            font-weight: lighter;
            font-style: italic
        }

        body.mce-content-body label[mv-id] {
          color: #ff8f09;
          font-weight: lighter;
          font-style: italic
        }

        body.mce-content-body label[var-id] {
          color: #0966ff;
          font-weight: lighter;
          font-style: italic
        }`,
    manualEditor: `
        .patient-rich-text-editor label[selectable-type] {
            font-weight: bolder;
        }`,
    config: `{
            height: 400,
            menubar: false,
            plugins: [
              'export lists table powerpaste'
            ],
            powerpaste_allow_local_images: true,
            powerpaste_word_import: 'prompt',
            powerpaste_html_import: 'prompt',
            browser_spellcheck: true,
            fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt',
            content_style: 'body { font-family: Arial; }',
            toolbar:
              'export insertfile undo redo | fontsizeselect | styleselect  | \
              bold italic | alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | table '
          }`,
  };

  static tinyConfig(fontFamily: any) {
    return (
      `{
            height: 600,
            menubar: false,
            plugins: [
            'export lists table powerpaste image'
            ],
            powerpaste_allow_local_images: true,
            powerpaste_word_import: 'prompt',
            powerpaste_html_import: 'prompt',
            browser_spellcheck: true,
            fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt',
            content_style: 'body { font-family: ` +
      fontFamily +
      `; }',
            toolbar:
            'export insertfile undo redo | fontsizeselect | styleselect  | \
            bold italic | alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | table | image '
        }`
    );
  }

  static report: any = {
    emptyLine: '<br/>',
  };

  static vitalSigns: any = {
    visionVitalSigns: {
      visualAcuityRating: 20,
      visionValueTemplate: '{0} / {1}',
    },
  };

  static businessHoursTimeMap: any = {
    'Hours Before Lunch': '9:00-12:00',
    'Hours During Lunch': '12:00-13:00',
    'Hours After Lunch': '13:00-17:00',
    'After Hours': '18:00-21:00',
    'Office Closed': '0:00-23:30',
  };
}

export const preAuthData = {
  items: [
    {
      items: [
        {
          items: [],
          id: '077cb6f6-1705-4b5b-8d8b-d0813cd1a433',
          text: 'Chief Complaint',
          name: 'chiefComplaint',
          expanded: false,
          visible: true,
          selected: true,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '49069700-9799-4eba-b24e-8aefb0ff5b64',
          text: 'Statement of Examination',
          name: 'Statement of Examination',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: 'b7f55cee-f0b0-4f49-87bc-67c77836d51f',
              text: 'Patient Medical Records Reviewed',
              name: 'reviewedMedicalRecords',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '2a5b173c-5019-4aee-9de2-bd62bbee4691',
              text: 'Tobacco History',
              name: 'tobaccoHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '8e818fd1-8b42-4a10-8309-52e91bfb0f27',
              text: 'Drug History',
              name: 'drugHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '2986d6bd-5032-485a-881e-6553db0553f9',
              text: 'Alcohol History',
              name: 'alcoholHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'a593bc48-34ce-41c9-a6ca-58d81f7c25ea',
              text: 'Family History',
              name: 'familyHistory',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '29328b6b-2cb8-4d34-8e7e-3fe08d741d53',
              text: 'Previous Medical History',
              name: 'previousMedicalHistory',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '8c032154-319a-41fb-ac64-26ee988e167a',
              text: 'Previous Surgical History',
              name: 'previousSurgicalHistory',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'c9020f23-34d7-475d-92ee-71ed3e45f7af',
              text: 'Education',
              name: 'education',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '2538ca20-55e5-4231-809c-f63595b7148f',
              text: 'Occupational History',
              name: 'occupationalHistory',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'c4856662-b424-496a-bfc8-70b9ec57faac',
              text: 'Allergies',
              name: 'allergies',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'c64ff816-2502-40c8-bc0b-9adfda94d791',
              text: 'Medications',
              name: 'medications',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
          ],
          id: '4e101d8d-fe2d-465b-a39b-9562ac26fe1c',
          text: 'Claimant History',
          name: 'claimantHistory',
          expanded: true,
          visible: true,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: 'c0c0736b-5cb2-4e2a-931c-5f5fad45cd94',
              text: 'General History of Present Illness',
              name: 'General History of Present Illness',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
          ],
          id: '42516c54-9098-41a2-b0cf-8cf607a3c1a3',
          text: 'History of Present Illness',
          name: 'hpi',
          expanded: false,
          visible: true,
          selected: true,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '2874ceff-eb4d-4ff3-9f87-0fac600dd126',
              text: 'General Review Of Systems',
              name: 'General Review Of Systems',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
          ],
          id: 'b9c41c03-3c50-4ef9-ad23-2248dfe0827a',
          text: 'Review Of Systems',
          name: 'ros',
          expanded: false,
          visible: true,
          selected: true,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '315f412c-dca9-414c-b0f0-9ad54f4b90d0',
          text: 'Activities of Daily Living',
          name: 'Activities of Daily Living',
          expanded: false,
          visible: true,
          selected: true,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '0075b0d9-e2ce-42c8-be36-3dcb5c13b030',
              text: 'Vital Signs',
              name: 'vitalSigns',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
            {
              items: [
                {
                  items: [],
                  id: '6c38d2d7-e697-4c81-8c05-c002dd8f4b4b',
                  text: 'General',
                  name: 'General',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '0fc21156-9c66-495e-9bd7-ab5388a3f847',
                  text: 'Assistive Device',
                  name: 'Assistive Device',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'cef917e2-45ea-4a88-968c-d3c5b25b24fc',
                  text: 'Gait and Station',
                  name: 'Gait and Station',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'cb5ef3e1-fcba-4f65-9b52-03da9267db26',
                  text: 'Neurological',
                  name: 'Neurological',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'fe5bc197-078a-48f0-a03a-97e288f6ca1f',
                  text: 'Head, Ears, Eyes, Nose, and Throat Exam',
                  name: 'Head, Ears, Eyes, Nose, and Throat Exam',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '16dd9a06-5cd7-4c0a-bbb7-aba059ae848d',
                  text: 'Pulmonary',
                  name: 'Pulmonary',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '985414a2-83c3-4f88-8ef6-6e58f48e328f',
                  text: 'Cardiovascular',
                  name: 'Cardiovascular',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'c9765ca8-3d43-4235-a653-5a13fc922eed',
                  text: 'Gastrointestinal',
                  name: 'Gastrointestinal',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '98541acf-27a3-43cb-a4c6-a72e9624222c',
                  text: 'Pulses',
                  name: 'Pulses',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '7cda7b90-1805-4763-b74d-7e8165f67f1d',
                  text: 'Strength and Tone',
                  name: 'Strength and Tone',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'd384ae9b-72eb-4ae9-b48d-3b92f8adbf28',
                  text: 'Sensory',
                  name: 'Sensory',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '28e935ac-4268-4da4-beaa-781d87906eeb',
                  text: 'Deep Tendon Reflexs',
                  name: 'Deep Tendon Reflexs',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'a6900424-c61b-4007-864d-f32e2cbaa892',
                  text: 'Fine Motor Skills',
                  name: 'Fine Motor Skills',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'fce4c158-8395-41da-8d2e-a7993f42be9b',
                  text: 'Skin',
                  name: 'Skin',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '757b000f-a5bc-42ad-a469-970ed31e7ad3',
                  text: 'Range of Motion',
                  name: 'Range of Motion',
                  expanded: false,
                  visible: true,
                  selected: true,
                  isNotShownInReport: false,
                },
              ],
              id: 'c8ad2026-b548-4b3b-ba0b-647153b96934',
              text: 'Physical Exam',
              name: 'physicalExam',
              expanded: false,
              visible: true,
              selected: true,
              isNotShownInReport: false,
            },
          ],
          id: '3eb235c0-b6e4-4307-bafc-0a538f3278c7',
          text: 'Physical Exams',
          name: 'physicalExams',
          expanded: false,
          visible: true,
          selected: true,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: 'fabbd5a9-84df-4a35-89e0-61b5a599d711',
          text: 'Procedure',
          name: 'procedure',
          expanded: false,
          visible: true,
          selected: true,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: 'f5db7c28-d3ed-42d0-898a-1a99cb1f6469',
          text: 'Prescription',
          name: 'prescription',
          expanded: false,
          visible: true,
          selected: true,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '7b8238ef-23ee-4d87-b117-c9db94c965a2',
          text: 'Assessment',
          name: 'assessment',
          expanded: false,
          visible: true,
          selected: true,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '33659a4a-585d-4f35-bc65-4a640a434c6a',
          text: 'Plan',
          name: 'plan',
          expanded: false,
          visible: true,
          selected: true,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: 'bf28f7c2-48b5-262b-6fb7-b7f4db558c8a',
          text: 'Discharge Instructions',
          name: 'Discharge Instructions',
          expanded: false,
          visible: true,
          selected: true,
          isNotShownInReport: false,
        },
      ],
      id: '882e8831-cefa-41ee-b8fb-3035bfd5d53f',
      text: 'History And Physical',
      name: 'historyAndPhysical',
      expanded: true,
      visible: true,
      isNotShownInReport: false,
    },
    {
      items: [
        {
          items: [],
          id: 'e95dbc44-7024-45c4-87eb-0da105ee6447',
          text: 'Statement of Examination',
          name: 'statementOfExamination',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '0cf24650-569d-4148-8d30-8eb5f8e5bfd5',
              text: 'Patient Medical Records Reviewed',
              name: 'reviewedMedicalRecords',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '480194ad-18e8-4471-957f-541052cbd3a7',
              text: 'Tobacco History',
              name: 'tobaccoHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '8a7bff7d-a26f-4829-8e5b-bfc63ddcb64f',
              text: 'Drug History',
              name: 'drugHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '379feb47-9a1c-4170-8c90-889db3abadab',
              text: 'Alcohol History',
              name: 'alcoholHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'a22356f5-0f3c-4927-8fca-cebe54ce3118',
              text: 'Previous Medical History',
              name: 'previousMedicalHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '570459d5-f7a1-485a-8caa-e11567a51798',
              text: 'Previous Surgical History',
              name: 'previousSurgicalHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '13b23f79-d8ae-417c-9eda-5ab59ee09bb0',
              text: 'Family History',
              name: 'familyHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'abbabf3e-4cb6-465b-aac6-7cc5f10d6313',
              text: 'Education',
              name: 'education',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '1299e420-f802-4d0d-92f4-f6774509dfdf',
              text: 'Occupational History',
              name: 'occupationalHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '425c7f5b-b54f-4b8e-abe1-303c8a5073c4',
              text: 'Allergies',
              name: 'allergies',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'cf3952f4-d7c3-4afa-a165-d08b624433da',
              text: 'Medications',
              name: 'medications',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '1d603806-2fec-4adb-8a52-d11ad95cd7bc',
          text: 'Patient History',
          name: 'patientHistory',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '7fa2e300-0816-45d5-8e77-8e4619d39cbe',
          text: 'Chief Complaint',
          name: 'chiefComplaint',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '8013f159-b1fe-4f51-a2b0-6d70bc0dee8a',
              text: 'General History of Present Illness',
              name: 'General History of Present Illness',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '0fb17988-3d9a-4392-b5d2-4e9dd9c3f52c',
          text: 'HPI',
          name: 'hpi',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '54ca27d9-ad74-4649-a158-291172000212',
              text: 'General Review Of Systems',
              name: 'General Review Of Systems',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: 'ddcd14df-47c2-4b13-9384-07e6f85d750a',
          text: 'Review Of Systems',
          name: 'ros',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: 'e1437ed7-2c47-42bc-907a-9bde1f0f58d2',
          text: 'Activities of Daily Living',
          name: 'pE_Default_ActivitiesOfDailyLiving',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: 'e88d51c1-e9bb-463c-bb7c-170351624133',
              text: 'Vital Signs',
              name: 'vitalSigns',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [
                {
                  items: [],
                  id: 'fe1e01a6-0ddc-4559-97d7-f155ac4164e2',
                  text: 'General',
                  name: 'General',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'fd051ac8-37e9-4a72-b3fe-dedae8ff570b',
                  text: 'Assistive Device',
                  name: 'Assistive Device',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'ea1eddbb-947b-4e54-9122-e3e05a6e5503',
                  text: 'Gait and Station',
                  name: 'Gait and Station',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'a55e8d50-c198-4d37-af17-cf4227cdc948',
                  text: 'Neurological',
                  name: 'Neurological',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '0d4c320d-5222-4d76-9516-f5fdc39aed5d',
                  text: 'Head, Ears, Eyes, Nose, and Throat Exam',
                  name: 'Head, Ears, Eyes, Nose, and Throat Exam',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '33d230b9-8e48-4205-9bff-1355a1ea1ed2',
                  text: 'Pulmonary',
                  name: 'Pulmonary',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '8adc1057-8561-44c5-8a5a-ce8527904600',
                  text: 'Cardiovascular',
                  name: 'Cardiovascular',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '22415b4f-44ee-4b03-bc6e-fd6f701204d5',
                  text: 'Gastrointestinal',
                  name: 'Gastrointestinal',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'b00f587c-1046-4fbe-ae4c-cd3cfecae0df',
                  text: 'Pulses',
                  name: 'Pulses',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '9cec3d9b-3550-4f8c-a00b-c4c59dcb6f76',
                  text: 'Strength and Tone',
                  name: 'Strength and Tone',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '88996c66-4552-42dc-b995-cf849c4aa1a9',
                  text: 'Sensory',
                  name: 'Sensory',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '7834293d-0686-427e-8442-14cb07056014',
                  text: 'Deep Tendon Reflexs',
                  name: 'Deep Tendon Reflexs',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'a4e81030-9b7a-48f8-ad50-c8fd9cb128f6',
                  text: 'Fine Motor Skills',
                  name: 'Fine Motor Skills',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'cb7745aa-e042-4f72-8937-da6e49a10407',
                  text: 'Skin',
                  name: 'Skin',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '5af43b81-9c16-47ff-b13d-aa830499fbaa',
                  text: 'Range of Motion',
                  name: 'Range of Motion',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
              ],
              id: 'ae55906c-36c3-4ecc-89d3-9b4bb4951dd2',
              text: 'Physical Exam',
              name: 'physicalExam',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '78f1949e-ea65-46f3-9e1b-fb5b1c9edf53',
          text: 'Physical Exams',
          name: 'physicalExams',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '2204262a-5930-47f8-a617-8bf3718198a4',
          text: 'Procedure',
          name: 'procedure',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '60cd2436-4dbc-4b7c-b054-a9a4d4c763e7',
          text: 'Prescription',
          name: 'prescription',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '85371d63-ad52-43f0-b75b-c482c861c0fc',
          text: 'Assessment',
          name: 'assessment',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '45c7316e-cec2-419f-b282-78384883c259',
          text: 'Plan',
          name: 'plan',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
      ],
      id: 'db61f5f8-efa6-47fa-9713-ee53f6982300',
      text: 'Pediatric History and Physical',
      name: 'pediatricHistoryAndPhysical',
      expanded: false,
      visible: true,
      selected: false,
      isNotShownInReport: false,
    },
    {
      items: [
        {
          items: [
            {
              items: [],
              id: 'c470d790-aaa0-4176-8d09-136df030964c',
              text: 'Patient Medical Records Reviewed',
              name: 'reviewedMedicalRecords',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'b1469420-4190-4467-9ae7-f152d143500f',
              text: 'Tobacco History',
              name: 'tobaccoHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '6404155a-8733-42f6-a452-773a41f2bf13',
              text: 'Drug History',
              name: 'drugHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'ec71ea4b-713e-45f1-b80f-5bca29ebc825',
              text: 'Alcohol History',
              name: 'alcoholHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'd26e7640-9e15-4368-a82b-00d8b740cd19',
              text: 'Previous Medical History',
              name: 'previousMedicalHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '028862d8-2b66-49f1-82c5-d8b712f0ebfe',
              text: 'Previous Surgical History',
              name: 'previousSurgicalHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '412958d5-7cfc-4cd2-9e9a-b1ebaa4b2c31',
              text: 'Family History',
              name: 'familyHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '956cd824-6f12-4d27-b52f-c99fd4311e86',
              text: 'Education',
              name: 'education',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'b76061a1-4340-4673-a982-6a52becd366e',
              text: 'Occupational History',
              name: 'occupationalHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '9a76d529-55da-4277-bc86-57303dd392fb',
              text: 'Allergies',
              name: 'allergies',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '429f88a8-9328-4def-a54f-c4bd873c6587',
              text: 'Medications',
              name: 'medications',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '94f2e091-cf78-4d98-81bf-5fb78af27fab',
          text: 'Claimant History',
          name: 'claimantHistory',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '2499fece-441f-4feb-9b69-a46e6bccc264',
          text: 'Chief Complaint',
          name: 'chiefComplaint',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: 'de25873d-8d1c-4f17-836a-52b37070247d',
              text: 'General History of Present Illness',
              name: 'General History of Present Illness',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '73621e31-ed5f-481a-9015-cb366e0b3838',
          text: 'History of Present Illness',
          name: 'hpi',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '9c4b3a51-714f-4c69-8828-e021b0767c9a',
              text: 'General Review Of Systems',
              name: 'General Review Of Systems',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '03b7da27-03ff-4656-9118-73a40254deb8',
          text: 'Review Of Systems',
          name: 'ros',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '4b0a57f5-e426-4914-9502-f2bb284a4d50',
              text: 'Vital Signs',
              name: 'vitalSigns',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [
                {
                  items: [],
                  id: '1a76395f-26a6-4a13-96f9-86cbf3c042e0',
                  text: 'General',
                  name: 'General',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'cd598c3d-ef8f-41f4-9d6a-05f61ccc62ac',
                  text: 'Assistive Device',
                  name: 'Assistive Device',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '20023d3e-cfc9-411b-aee1-e42f87d9e67d',
                  text: 'Gait and Station',
                  name: 'Gait and Station',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'cee1fe35-d7ec-4cc3-a2a5-1bbbd8866de9',
                  text: 'Neurological',
                  name: 'Neurological',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '5e1598bc-6884-4a0c-a113-4d023ca16d84',
                  text: 'Head, Ears, Eyes, Nose, and Throat Exam',
                  name: 'Head, Ears, Eyes, Nose, and Throat Exam',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'ec042310-b2e7-4fc3-8f05-a2e4540fbed2',
                  text: 'Pulmonary',
                  name: 'Pulmonary',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'ee7bbc65-a756-46d6-9a34-0fa3a92f9f8b',
                  text: 'Cardiovascular',
                  name: 'Cardiovascular',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'fb3bbac1-1d88-4631-9216-84b68f0b45d6',
                  text: 'Gastrointestinal',
                  name: 'Gastrointestinal',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '6f39010d-0480-426f-88e0-f0cddf5defef',
                  text: 'Pulses',
                  name: 'Pulses',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'f6ae9f53-b90e-4ad7-8847-33f9ce2f8a1b',
                  text: 'Strength and Tone',
                  name: 'Strength and Tone',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'cd6424a4-37ba-4179-acaa-54dd77f75e40',
                  text: 'Sensory',
                  name: 'Sensory',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'd622b92d-c2d5-4bb3-9b5d-29261826affb',
                  text: 'Deep Tendon Reflexs',
                  name: 'Deep Tendon Reflexs',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '7c9be883-1157-481a-99b3-e3b9bf7f34da',
                  text: 'Fine Motor Skills',
                  name: 'Fine Motor Skills',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '8e99e116-054e-49b8-8cbc-ef3bf66b6524',
                  text: 'Skin',
                  name: 'Skin',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'c87a28f4-0175-4244-a6c2-0c98150a02e9',
                  text: 'Range of Motion',
                  name: 'Range of Motion',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
              ],
              id: 'd51efab7-b932-4d5c-b556-052e93f07d84',
              text: 'Physical Exam',
              name: 'physicalExam',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '36b1904a-956b-4156-beef-c6a00ca17c81',
          text: 'Physical Exams',
          name: 'physicalExams',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: 'ada38248-e304-4c5f-bfa0-398ab5934545',
          text: 'Prescription',
          name: 'prescription',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: 'e6db2ffe-7a33-4318-a564-ff5653b30b3b',
          text: 'Assessment',
          name: 'assessment',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '6edf89a0-a9af-4c22-8410-fa0a2df28084',
          text: 'Plan',
          name: 'plan',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '80511380-d753-4939-9f05-a213ddc6ec2a',
              text: 'Lab Library Template',
              name: 'Lab Library Template',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '10bdc17a-83c5-4cf9-aff4-5e55e039ec7d',
              text: 'Other Laboratory Values',
              name: 'Other Laboratory Values',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '078414ed-395b-4249-9b24-e923ac6a14e9',
              text: 'Lab Library Template 2',
              name: 'Lab Library Template 2',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: 'f8207c06-2839-2445-14ae-b482b316ca38',
          text: 'Laboratory Values',
          name: 'laboratory',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
      ],
      id: 'a2bb6786-2d98-4be0-b954-b9c4068d1d1a',
      text: 'TeleHealth Visit',
      name: 'teleHealthVisit',
      expanded: false,
      visible: true,
      selected: false,
      isNotShownInReport: false,
    },
    {
      items: [
        {
          items: [
            {
              items: [],
              id: 'ee36793e-8168-4f45-81e2-fb3a9c632ac9',
              text: 'Patient Medical Records Reviewed',
              name: 'reviewedMedicalRecords',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '1a70e6a8-131f-436b-8bef-af9bfed2e436',
              text: 'Tobacco History',
              name: 'tobaccoHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'a6a44472-eb7b-49ba-b627-2bc5519c04f2',
              text: 'Drug History',
              name: 'drugHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '0c439d36-5a01-4f90-a3f4-3412c049bc0f',
              text: 'Alcohol History',
              name: 'alcoholHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '48f860e8-8c5e-479b-bc2d-f9d27ce8fa94',
              text: 'Previous Medical History',
              name: 'previousMedicalHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '5f3692b4-ee30-45ee-a918-b8bb958f13d6',
              text: 'Previous Surgical History',
              name: 'previousSurgicalHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'ac744e84-871d-4c69-b232-12ae4f6def75',
              text: 'Family History',
              name: 'familyHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '38145109-052d-49df-9653-0ba5342f7797',
              text: 'Education',
              name: 'education',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '6e58f6e6-6bf6-476d-899a-e8901fd59df8',
              text: 'Occupational History',
              name: 'occupationalHistory',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '7eef94b8-ecfc-493b-98f4-b9ddbcec76a0',
              text: 'Allergies',
              name: 'allergies',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '099a9a21-58d3-4c1b-84b2-d1ae38539b91',
              text: 'Medications',
              name: 'medications',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '31a174ef-6941-451d-9259-02ac49ba5901',
          text: 'Patient History',
          name: 'patientHistory',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '8bef4070-c97a-4b8f-93d0-93a6c284ce9b',
          text: 'Chief Complaint',
          name: 'chiefComplaint',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '6bf64c4f-c928-4f13-9827-61f288c0fdc9',
              text: 'General History of Present Illness',
              name: 'General History of Present Illness',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '556703f5-0510-47aa-8017-6c5331b0eaa2',
          text: 'History of Present Illness',
          name: 'hpi',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '3b4c66cb-a501-4501-af73-74f8c00bddb1',
              text: 'General Review Of Systems',
              name: 'General Review Of Systems',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '0b3b7d92-2a15-4da8-ba22-6e67010bde3d',
          text: 'Review Of Systems',
          name: 'ros',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: '5000a763-6166-42f4-833e-8adb0b291434',
              text: 'Vital Signs',
              name: 'vitalSigns',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [
                {
                  items: [],
                  id: '60a68971-77f9-4d36-8ad7-e88cce17b244',
                  text: 'General',
                  name: 'General',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '21b45a48-09f8-401d-ad86-abf6a9111bec',
                  text: 'Assistive Device',
                  name: 'Assistive Device',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '44c23fb0-6191-4ee8-ac99-e2440773b55b',
                  text: 'Gait and Station',
                  name: 'Gait and Station',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'd579264c-f331-4d0a-b924-bda80e2c96d7',
                  text: 'Neurological',
                  name: 'Neurological',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '10b6d4f8-a7ea-4bef-98d6-cab47ff52bfa',
                  text: 'Head, Ears, Eyes, Nose, and Throat Exam',
                  name: 'Head, Ears, Eyes, Nose, and Throat Exam',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '51a35555-65da-4d6e-a7dd-8cbb347ef6ab',
                  text: 'Pulmonary',
                  name: 'Pulmonary',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'e3c7062c-0732-4927-a42b-8c2dd2d7a6b6',
                  text: 'Cardiovascular',
                  name: 'Cardiovascular',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '867d0e2c-8a07-4c69-a5e1-cb722823b331',
                  text: 'Gastrointestinal',
                  name: 'Gastrointestinal',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'c647b161-b8df-417c-84d9-96122560ab10',
                  text: 'Pulses',
                  name: 'Pulses',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'd41461c9-0b1e-4eb4-93f3-88b59e11b610',
                  text: 'Strength and Tone',
                  name: 'Strength and Tone',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: 'c1c79c85-4d65-42b5-9321-429f9c7a78e2',
                  text: 'Sensory',
                  name: 'Sensory',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '27cb0b40-ced5-4f18-9c7c-dd2f345c51c2',
                  text: 'Deep Tendon Reflexs',
                  name: 'Deep Tendon Reflexs',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '004b360e-4adf-4819-9c5c-42d096dd49eb',
                  text: 'Fine Motor Skills',
                  name: 'Fine Motor Skills',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '7750d353-5e4e-4871-bb80-08ecb75cc5ff',
                  text: 'Skin',
                  name: 'Skin',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
                {
                  items: [],
                  id: '13ed4e19-373a-43e8-9392-36528e78d437',
                  text: 'Range of Motion',
                  name: 'Range of Motion',
                  expanded: false,
                  visible: true,
                  selected: false,
                  isNotShownInReport: false,
                },
              ],
              id: '0837dd16-f51d-4eae-96c4-fc18fc928e74',
              text: 'Physical Exam',
              name: 'physicalExam',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: 'b45f65f6-1504-4f63-8566-ba2f8c5c0ec2',
          text: 'Physical Exams',
          name: 'physicalExams',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '29cf40b9-cf09-495e-9f82-39d810c6537f',
          text: 'Procedure',
          name: 'procedure',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '7563bba6-0e9b-41af-bffd-187a32a7c4d0',
          text: 'Prescription',
          name: 'prescription',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: 'fc18ec44-1804-4e52-a76d-887f7736519a',
          text: 'Assessment',
          name: 'assessment',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [],
          id: '7866f02e-54f9-45f5-bd09-3a27d12cf8ef',
          text: 'Plan',
          name: 'plan',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
        {
          items: [
            {
              items: [],
              id: 'f67c0ab4-0802-4b35-8b29-204e505d9a51',
              text: 'Lab Library Template',
              name: 'Lab Library Template',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: '41fbd819-933e-4bf5-836c-141c185da8f6',
              text: 'Other Laboratory Values',
              name: 'Other Laboratory Values',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
            {
              items: [],
              id: 'f65be45b-0f6a-4335-9743-e0b816e34c1a',
              text: 'Lab Library Template 2',
              name: 'Lab Library Template 2',
              expanded: false,
              visible: true,
              selected: false,
              isNotShownInReport: false,
            },
          ],
          id: '7204991a-a63c-44d6-8879-85ede231b6c6',
          text: 'Lab Library',
          name: 'laboratory',
          expanded: false,
          visible: true,
          selected: false,
          isNotShownInReport: false,
        },
      ],
      id: '4b6cd008-616a-46b8-995a-8426fbe01d5e',
      text: 'Telehealth Library',
      name: 'telehealthLibrary',
      expanded: false,
      visible: true,
      selected: false,
      isNotShownInReport: false,
    },
  ],
  id: '04f698d5-ec3b-4b75-99da-df654e008f61',
  text: 'Patient Chart',
  name: 'patientChart',
  expanded: true,
  visible: true,
  isNotShownInReport: false,
};
