import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentUrlService } from './environment-url.service';

@Injectable({ providedIn: 'root' })
export class ClinicalTablesService {

  constructor(private http: HttpClient, private envUrl: EnvironmentUrlService) {}

  public getClinicalData(route: string, searchTerms: string): Observable<any> {
    return this.http.get(
      this.createCompleteRoute(route, this.envUrl.urlAddress)+searchTerms,
      this.generateHeaders()
    );
  }

  private createCompleteRoute(route: string, envAddress: string) {
    return `${envAddress}${route}`;
  }

  private generateHeaders() {
    const _estmusr = JSON.parse(localStorage.getItem('_estmusr') ?? 'null');
    let token = '';
    if (_estmusr) {
      token = _estmusr.token;
    }
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS',
        Authorization: 'Bearer ' + token,
      }),
    };
  }
}
