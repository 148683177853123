import { Component, OnInit, ViewChild, OnDestroy, NgModule, ViewEncapsulation, ViewContainerRef, ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { AppConfiguration } from 'src/app/_classes/appConfiguration';
import { DxSchedulerComponent } from 'devextreme-angular/ui/scheduler';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { AppointmentsFilter } from '../../models/appointmentsFilter';
import { SearchConfiguration } from 'src/app/_classes/searchConfiguration';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxDataUrlService } from 'src/app/_services/dxDataUrl.service';
import { GuidHelper } from 'src/app/_helpers/guid.helper';
import { EmployeeTypeList } from 'src/app/administration/classes/employeeTypeList';
import { SelectableListService } from 'src/app/_services/selectable-list.service';
import { SelectableListConfig } from 'src/app/_models/selectableListConfig';
import { CompanyIdService } from 'src/app/_services/company-id.service';
import { DateHelper } from 'src/app/_helpers/date.helper';
import { AppointmentService } from '../../../_services/appointment.service';
import { Router } from '@angular/router';
import { forkJoin, from, Observable, Subject, Subscription } from 'rxjs';
import { DevextremeAuthService } from 'src/app/_services/devextreme-auth.service';
import { AlertService } from 'src/app/_services/alert.service';
import { LibrarySelectableListIds } from 'src/app/_classes/librarySelectableListIds';
import { SelectableListsNames } from 'src/app/_classes/selectableListsNames';
import { ApiBaseUrls } from 'src/app/_models/apiBaseUrls';
import { AdmissionService } from 'src/app/patientChart/services/admission.service';
import { AppointmentAlertAervice } from '../../services/appointment-alert.service';
import { AppointmentPatientUpdateUserActions } from 'src/app/_models/AppointmentPatientUpdateUserActions';
import { Appointment } from 'src/app/_models/appointment';
import { SchedulerView } from '../../models/schedulerView';
import { SchedulerViewNames, SchedulerViews } from '../../constants/schedulerViews';
import { AppointmentGridItem } from '../../models/appointmentGridItem';
import { AppointmentsFilterComponent } from '../appointments-filter/appointments-filter.component';
import { LookupModel } from 'src/app/_models/lookupModel';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { AppointmentStatusColorManagementService } from 'src/app/administration/components/appointment-status-color-management/appointment-status-color-management.service';
import { LibrarySelectableListService } from 'src/app/administration/services/library/library-selectable-list.service';
import { LibrarySelectableListTitles } from 'src/app/_classes/librarySelectableListTitles';
import moment from 'moment';
import { AppointmentInfoComponent } from '../../actions/appointment-info/appointment-info.component';
import { ComponentHelper } from 'src/app/shared/utils/helpers/component.helper';
import { RepositoryService } from 'src/app/_services/repository.service';
import { PatientAllegationsComponent } from '../../components/patient-allegations/patient-allegations.component';
import { PatientIdentificationCodeSearchFilter } from 'src/app/lookUpZipCode/models/PatientIdentificationCodeSearchFilter';
import { PatientIdentificationCodeType } from 'src/app/lookUpZipCode/models/enums/patientIdentificationCodeType';
import { PatientChartNode } from 'src/app/_models/patientChartNode';
import { PatientIdentificationCodeService } from 'src/app/patients/services/patient-identification-code.service';
import { PatientService } from 'src/app/_services/patient.service';
import { FormatHelper } from 'src/app/shared/utils/helpers/format.helper';

@Component({
  selector: 'appointment-scheduler',
  templateUrl: './appointment-scheduler.component.html',
  styleUrls: ['./appointment-scheduler.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentSchedulerComponent implements OnInit, OnDestroy {
  @ViewChild('componentAllegations', { static: false })
  patientAllegationsComponent: PatientAllegationsComponent;
  @ViewChild('appointmentScheduler', { static: false })
  appointmentScheduler!: DxSchedulerComponent;
  @ViewChild('appointmentsGrid', { static: false })
  appointmentsGrid!: DxDataGridComponent
  @ViewChild('appointmentsFilter', { static: false })
  appointmentsFilter!: AppointmentsFilterComponent;
  @ViewChild('componentContainer', { static: false, read: ViewContainerRef }) componentContainer: ViewContainerRef;
  public addAppointmentComponentRef: ComponentRef<AppointmentInfoComponent>;
  isPatientChartReview = false;
  patientChartNodeReview!: PatientChartNode;
  patientChartDocumentNodeReview!: PatientChartNode;
  appointmentIdReview = '';
  patientIdReview = '';
  admissionIdReview = '';
  companyIdReview = '';
  isAppointmentCreationFormOpened = false;
  areSelectableListsInitialized = false;
  public identificationLabel: any;
  currentOpenedAppointmentForm: any = null;
  isAdmissionPopupVisible = false;
  showAppointmentLegend = false;
  formatDate: any = "mm/dd/yyyy";
  companyId: string = '';
  companyIdSubscription: Nullable<Subscription>;
  isCalendarView: boolean = true;
  allegationsString: string = '';
  selectedLocationId: string = GuidHelper.emptyGuid;
  appConfiguration: AppConfiguration = new AppConfiguration();
  searchConfiguration: SearchConfiguration = new SearchConfiguration();
  isManageAllegationsPopupVisible = false;
  startDate: any;
  endDate: any;
  filter: AppointmentsFilter = new AppointmentsFilter();
  locationDataSource: any = {};
  patientDataSource: any = {};
  physianDataSource: any = {};
  providerDataSource: any = {};
  nurseDataSource: any = {};
  maDataSource: any = {};
  roomDataSource: any = {};
  patientChartDocumentDataSource: any = {};
  schedulerAvailableViews: SchedulerView[] = SchedulerViews;
  selectedAppointment: Array<any> = [];
  icdCodesDataSource: any = {}
  isDayView: boolean = true;
  appointmentStatusItems: any;
  dtsAppointmentStatusItems: any;
  tableRoomDataSource: any = {};
  tableSelectedLocationId: string = GuidHelper.emptyGuid;
  tableSelectedCompanyId: string = '';
  careTeamDataSource: any = {};
  currentDiagnosisDataSource: any = {};
  currentChiefComplaintsDataSource: any = {};
  patientPreviousAdmissions: Array<any> = [];
  allAppointmentStatuses: any;
  allAppointmentTypes: any;
  allAppointmentStatusColors: any;
  isUnsavedChangesPopupShow = false;
  allegationsViewer = false;
  isAllegationPopupOpened = false;
  public patientChartDocumentNodes: any;
  public nurseId: any;
  public providerId: any;
  public careTeamIds: any;
  public mrn: string;
  public isMRNManual: boolean = false;
  public patientId: any;
  public dateTimeFormat = 'MM/dd/yyyy hh:mm a';
  public prevTitle = '';
  public calanderView: any = "Day";
  public confirmSubject = new Subject<boolean>();
  confirm$: Observable<boolean> = this.confirmSubject.asObservable();
  dtsPatients: any[];
  public currentApptView = 'day';
  dtsAppointments: any[];
  private collapsed = false;
  selectedStartDate: any;
  currentAppointmentId: any;

  public businessTypes = {
    "Hours After Lunch": "Afternoon Hours",
    "Hours Before Lunch": "Morning Hours",
    "After Hours": "Evening Hours",
    "Hours During Lunch": "Lunch Hours"
  };
  startValInstance: any;
  endValInstance: any;

  constructor(
    private dxDataUrlService: DxDataUrlService,
    private selectableListService: SelectableListService,
    private companyIdService: CompanyIdService,
    private appointmentService: AppointmentService,
    private router: Router,
    private repository: RepositoryService,
    private devextremeAuthService: DevextremeAuthService,
    private alertService: AlertService,
    private patientService: PatientService,
    private formatHelper: FormatHelper,
    private admissionService: AdmissionService,
    private appointmentAlertAervice: AppointmentAlertAervice,
    private appointmentStatusColorManagementService: AppointmentStatusColorManagementService,
    private librarySelectableListService: LibrarySelectableListService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private patientIdentificationCodeService: PatientIdentificationCodeService,

  ) {

  }


  private checkBussinessDayHours(date: any) {
    const params = {
      startDateStr: moment(date).format('M/D/YYYY h:mm:ss A'),
      endDateStr: moment(date).format('M/D/YYYY h:mm:ss A'),
      isCheckBusiness: this.currentApptView.toLowerCase() === 'month' ? false : true
    };
    this.appointmentService.checkBussinessDayHours(params).then(response => {
      this.appConfiguration.startWorkingHour = response.startHour;
      this.appConfiguration.endWorkingHour = response.sendHour;

    });
  }

  openAllegationForm() {
    this.isAllegationPopupOpened = true;
    if (this.patientAllegationsComponent) {
      this.patientAllegationsComponent.bindPreviousAllegations(this.patientId);
    } else {
      console.error('PatientAllegationsComponent is not initialized');
    }
  }
  selectAllegation(): void {
    if (this.patientAllegationsComponent.target && this.patientAllegationsComponent.target.length > 0) {
      // Join the array elements with a comma
      this.allegationsString = this.patientAllegationsComponent.target.join(', ');
      // Update the formData directly
      this.currentOpenedAppointmentForm.option('formData.allegations', this.allegationsString);
    }
    this.patientAllegationsComponent.target = [];
    this.patientAllegationsComponent.source = [];
    this.isAllegationPopupOpened = false;
  }
  get canRenderComponents(): boolean {
    return !!this.companyId && !!this.areSelectableListsInitialized;
  }


  navigateToPatientChartFromScheduler($event: any, appointment: any) {
    $event.stopPropagation();
    this.router.navigate(['/patient-chart', appointment.appointmentData.id]);
  }
  private checkIsManualMrn(): boolean {
    const codeSearchFilter = new PatientIdentificationCodeSearchFilter();
    codeSearchFilter.companyId = this.companyId;

    codeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Mrn;

    this.patientIdentificationCodeService.isManualCode(codeSearchFilter).then(result => {
      this.isMRNManual = result;
    })
    return this.isMRNManual;
  }

  showBusinessHourError(response) {
    if (response.success === false) {
      let message = 'The selected time is outside of business hours. Please select a time between the following slots:';
      if (response.workingHours && response.workingHours.length > 0) {
        message += '<ul id="business-hours-list">';
        response.workingHours.forEach(time => {
          message += `
                <li>
                    <span class="appt-type">${this.businessTypes[time.type]}</span>
                    <span class="appt-start-time">${time.startAt}</span>
                    <span class="appt-end-time">${time.endAt}</span>
                </li>`;
        });
        message += '</ul>';
      }
      this.alertService.error(message);
    }
  }


  async onAppointmentFormOpening(data) {
    const formData = { ...data.appointmentData };
    const popup = data.component._appointmentPopup.popup;

    const params = {
      startDate: data.appointmentData.startDate,
      endDate: data.appointmentData.endDate,
      startDateStr: moment(data.appointmentData.startDate).format('M/D/YYYY h:mm:ss A'),
      endDateStr: moment(data.appointmentData.endDate).format('M/D/YYYY h:mm:ss A'),
      isCheckBusiness: this.currentApptView.toLowerCase() === 'month' ? false : true
    };
    const checkingResult: any =
      await this.appointmentService.checkValidatingBussinessHours(params);
    this.isMRNManual = this.checkIsManualMrn();
    if (!checkingResult.success) {
      this.appointmentScheduler.instance.hideAppointmentPopup();
      this.alertService.error(checkingResult.message);
      return;
    } else {
      const documents = await this.appointmentService
        .getDocumentsByAppointmentId(data.appointmentData.id);
      data.appointmentData.patientChartDocumentNodes = documents;
      this.patientChartDocumentNodes = documents;
      this.nurseId = data.appointmentData.nurseId;
      this.providerId = data.appointmentData.physicianId;
      this.currentAppointmentId = data.appointmentData.id;
      formData.utcOffset = moment(data.appointmentData.startDate).utcOffset() / 60;
      const form = data.form;
      if (this.currentApptView.toLowerCase() === 'month' && data.appointmentData.id === undefined) {
        form.updateData('startDate', new Date(data.appointmentData.startDate.setHours(9, 0, 0, 0)));
        form.updateData('endDate', new Date(data.appointmentData.startDate.setHours(9, 30, 0, 0)));
      }
      if (data.appointmentData.careTeamIds !== undefined) {
        this.careTeamIds = data.appointmentData.careTeamIds
        if (this.careTeamIds?.length === 1 && this.careTeamIds[0] === '') {
          form.updateData('careTeamIds', []);
        }
        else {
          form.updateData('careTeamIds', this.careTeamIds);
        }
      }

      if (
        data.appointmentData != null &&
        typeof data.appointmentData.patientId != 'undefined'
      ) {
        this.reloadCurrentDiagnosisItems(data.appointmentData.patientId, form);
        this.reloadCurrentChiefComplaintsItems(data.appointmentData.patientId, form);
      }

      this.currentOpenedAppointmentForm = form;

      this.setupAppointmentPopup(popup);
      form._options.showValidationSummary = false;

      const appointmentLocationId = data.appointmentData.locationId;
      if (appointmentLocationId) this.selectedLocationId = appointmentLocationId;
      const allegationGroupItems = [];
      const isAlreadyCreated = !!form.option('formData.admissionId');
      if (!isAlreadyCreated) {
        allegationGroupItems.push({
          itemType: 'group',
          colCount: 4,
          items: [
            {
              ...this.initAllegationsTextArea(form),
              colSpan: 3,
            },
            {
              itemType: 'button',
              colSpan: 1,
              cssClass: 'center-button-allegation',
              buttonOptions: {
                text: 'Select Allegations',
                onClick: () => {
                  this.openAllegationForm();
                },
              },
            },
          ],
        });
      }
      else {
        allegationGroupItems.push(this.initAllegationsTextArea(form));
      }
      const mrnCodeSearchFilter = new PatientIdentificationCodeSearchFilter();
      mrnCodeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Mrn;
      mrnCodeSearchFilter.companyId = this.companyId;
      const mrnManualCode$ = from(this.patientIdentificationCodeService.getByCompany(mrnCodeSearchFilter));
      const [mrnCode] = await forkJoin([mrnManualCode$]).toPromise();
      this.identificationLabel = mrnCode.identificationLabel;
      form.option('items', [
        {
          itemType: 'group',
          caption: 'Schedule Info',
          colCount: 4,
          colSpan: 2,
          items: [
            this.initAppointmentTypeSelectBox(form),
            this.initAppointmentPatientSelectBox(form),
            this.initAppointmentLocationSelectBox(form),
            this.initAppointmentStatusSelectBox(),
            this.initAppointmentProvidersTagBox(form),
            this.initAppointmentMasTagBox(form),
            this.initAppointmentRoomSelectBox(),
            this.initAppointmentStartDateBox(),
            this.initAppointmentEndDateBox(),
            this.initAppointmentMRNTextBox(),
          ],
        },
        {
          itemType: 'group',
          items: allegationGroupItems
        },
        {
          itemType: 'group',
          items: [
            this.initNotesTextArea(),
          ],
        },
        {
          itemType: 'group',
          items: [
            this.initPatientChartDocumentSelectBox(form),
            this.initAdmissionTextBox(),
          ],
        },

        {
          itemType: 'group',
          items: [this.initCareTeamSelectBox(form)]
        },
      ]);
      this.isAppointmentCreationFormOpened = true;
    }
  }

  onAppointmentDeleted() {
    this.refreshRelatedSchedulerComponents();
  }

  onAppointmentAdded() {
    this.alertService.notifyMsg("Appointment updated successfully", "success");
    this.refreshRelatedSchedulerComponents();
  }

  onAppointmentUpdating(event: any) {
    const isPatientChartSigned = !!event.newData.signingDate;
    if (isPatientChartSigned) {
      this.alertService.warning('Unable to change appointment for already signed chart.');
      event.cancel = true;
      return;
    }

    const previousPatientId = event.oldData.patientId;
    const newPatientId = event.newData.patientId;

    if (previousPatientId == newPatientId) return;

    const isPatientChartCreated = !!event.oldData.admissionId;

    if (!isPatientChartCreated) return;

    event.cancel = new Promise((resolve, _reject) => {
      alert();
      this.appointmentAlertAervice
        .confirmPatientChangesInAppointment(
          `${event.oldData.patientFirstName} ${event.oldData.patientLastName}`
        )
        .show()
        .then((userActionResult: any) => {
          if (!userActionResult) resolve(true);
          else {
            let newAppointment;
            switch (userActionResult) {
              case AppointmentPatientUpdateUserActions.DeletePreviousPatientChart:
                this.admissionService.deleteById(event.oldData.admissionId).then(() => {
                  event.newData.admissionId = null;
                  resolve(false);
                });
                break;
              case AppointmentPatientUpdateUserActions.CreateNewAppointment:
                newAppointment = this.duplicateAppointment(event.newData);
                event.newData.patientId = event.oldData.patientId;
                this.appointmentService.save(newAppointment).then(() => {
                  resolve(false);
                });
                break;
              default:
                return resolve(false);
            }
          }
        })
        .catch((error: any) =>
          this.alertService.error(error.message ? error.message : error)
        );
    });
  }

  onAppointmentUpdated() {
    this.refreshRelatedSchedulerComponents();
    this.alertService.notifyMsg("Appointment saved successfully", "success");
  }

  hideSchedulerTooltip($event: any): void {
    if ($event) $event.stopPropagation();
    this.appointmentScheduler.instance.hideAppointmentTooltip();
  }

  ngOnInit() {
    this.initLocationDataSource();
    this.initPatientDataSource();
    this.initRoomDataSource();
    this.initPhysicianDataSource();
    //this.initProviderDataSource();
    this.initNurseDataSource();
    // this.initMaDataSource();    
    this.initCareTeamDataSource();
    this.initPatientChartDocumentDataSource();

    this.subscribeToCompanyIdChanges();

    this.initTableRoomDataSource();

    this.initDiagnosisDataSource();


    this.initCurrentDiagnosisDataSource();
    this.initCurrentChiefComplaintsDataSource();

    this.appointmentStatusColorManagementService.load().then(colors => {
      this.allAppointmentStatusColors = {};
      colors.map((colorData: any) => {
        const status = colorData.status;
        const color = colorData.color;
        this.allAppointmentStatusColors[status] = color;
      });
    });

    this.librarySelectableListService
      .getByTitle(LibrarySelectableListTitles.appointmentStatus)
      .then(selectableList => {
        this.allAppointmentStatuses = selectableList.selectableListValues;
        this.appointmentStatusItems = selectableList.selectableListValues.map(
          appointmentStatus => {
            return appointmentStatus.value;
          }
        );
        this.dtsAppointmentStatusItems = selectableList.selectableListValues;
      });

    this.librarySelectableListService
      .getByTitle(LibrarySelectableListTitles.appointmentType)
      .then(selectableList => {
        this.allAppointmentTypes = selectableList.selectableListValues.map(
          appointmentType => {
            return appointmentType.value;
          }
        );
      });
  }

  ngOnDestroy(): void {
    this.companyIdSubscription?.unsubscribe();
  }

  onFilterChanged(filter?: AppointmentsFilter) {
    this.filter = filter || new AppointmentsFilter();
    this.refreshSchedulerAndAppointmentsGrid();
  }

  onSchedulerOptionChanged($event: any) {
    this.calanderView = $event.value;

    const eventName = $event.name;
    switch (eventName) {
      case 'currentDate':
        if (this.filter.schedulerDate !== $event.value) {
          this.filter.schedulerDate = $event.value;
          this.refreshSchedulerAndAppointmentsGrid();
        }
        // this.checkBussinessDayHours($event.value);
        break;
      case 'currentView':
        if (this.filter.schedulerView !== $event.value) {
          this.filter.schedulerView = $event.value;
          this.currentApptView = $event.value.toLowerCase();
          // if (this.currentApptView.toLowerCase() === 'day') {
          //   this.checkBussinessDayHours($event.value);
          // }
          this.refreshSchedulerAndAppointmentsGrid();
        }
        break;
    }
    if ($event.value === 'Day') {
      this.isDayView = true;
    }
    else {
      this.isDayView = false;
    }

  }

  private removeAppointment(appointmentId: string): Promise<void> {
    return this.appointmentService.delete(appointmentId).then(() => {
      if (this.isCalendarView === true) {
        this.hideSchedulerTooltip(null);
        this.appointmentScheduler.instance.getDataSource().reload();
      }
      else {
        this.appointmentsGrid.instance.refresh();
      }
      this.alertService.notifyMsg("Appointment deleted successfully", "success");
    });
  }

  private refreshRelatedSchedulerComponents() {
    if (this.appointmentsGrid) {
      this.appointmentsGrid.instance.refresh();
    }
  }

  private initAppointmentTypeSelectBox(_form: any): any {
    return {
      label: {
        text: 'Appointment Type',
      },
      editorType: 'dxSelectBox',
      dataField: 'appointmentType',
      isRequired: true,
      editorOptions: {
        items: this.allAppointmentTypes,
      },
    };
  }
  private initAppointmentPatientSelectBox(_form: any): any {
    const isAdmissionAlreadyCreated = !!_form.option('formData.admissionId');
    const instance = this;
    return {
      label: {
        text: 'Patient',
      },
      editorType: 'dxSelectBox',
      dataField: 'patientId',
      isRequired: true,
      colSpan: 2,
      editorOptions: {
        searchEnabled: true,
        dataSource: this.patientDataSource,
        displayExpr: (data) => {
          if (data)
            return data.displayName.split('Name Alert')[0].trim();
        },
        valueExpr: 'id',
        itemTemplate: (data) => {
          const container = document.createElement('div');
          const nameSpan = document.createElement('span');
          nameSpan.innerText = data.displayName.split('Name Alert')[0].trim();

          container.appendChild(nameSpan);

          if (data.displayName.includes('Name Alert')) {
            const alertSpan = document.createElement('span');
            alertSpan.className = 'name-alert';
            alertSpan.innerText = ' Name Alert';
            container.appendChild(alertSpan);
          }

          return container;
        },
        onValueChanged: ($event: any) => {
          if ($event.value !== null && $event.value !== undefined) {
            this.getPatientById($event.value, _form);
            instance.patientId = $event.value;
            if (!isAdmissionAlreadyCreated) {
              instance.patientAllegationsComponent.bindPreviousAllegations(instance.patientId);
            }
            //instance.reloadCurrentDiagnosisItems($event.value, null);
            //instance.reloadCurrentChiefComplaintsItems($event.value, null);
          }
        }
      },
    };
  }

  private async getPatientById(patientId, _form: any) {
    await this.patientService.getById(patientId).then(res => {
      _form.option('formData.mrn', res.rqid === null ? "NA" : res.rqid);
    });
  }


  private initAppointmentLocationSelectBox(form: any): any {
    return {
      label: {
        text: 'Location',
      },
      isRequired: true,
      editorType: 'dxSelectBox',
      dataField: 'locationId',
      editorOptions: {
        searchEnabled: true,
        dataSource: this.locationDataSource,
        displayExpr: 'name',
        valueExpr: 'id',
        onValueChanged: async (args: any) => {
          if (!this.isAppointmentCreationFormOpened) return;

          const locationId = args.value;
          if (locationId) {
            this.selectedLocationId = locationId;
            if (this.isMRNManual) {
              form.option('formData.mrn', '');
            }
            // else {
            //   this.mrn = await this.appointmentService
            //     .getMRNByCompanyId(this.companyId, this.selectedLocationId);
            //   form.option('formData.mrn', this.mrn);
            // }
            const selectedRoomId = form.option('formData.roomId');
            if (selectedRoomId) form.option('formData.roomId', '');

            form.getEditor('roomId').getDataSource().reload();
          }
        },
      },
    };
  }

  private initAppointmentRoomSelectBox(): any {
    return {
      label: {
        text: 'Room',
      },
      isRequired: true,
      dataField: 'roomId',
      editorType: 'dxSelectBox',
      editorOptions: {
        searchEnabled: true,
        displayExpr: 'name',
        valueExpr: 'id',
        dataSource: this.roomDataSource,
      },
    };
  }

  private initAppointmentProvidersTagBox(form: any): any {
    if (this.providerId !== undefined) {
      form.updateData('providerIds', [this.providerId]);
    }
    return {
      label: {
        text: 'Provider',
      },
      editorType: 'dxTagBox',
      dataField: 'providerIds',
      isRequired: true,
      editorOptions: {
        searchEnabled: true,
        displayExpr: 'name',
        valueExpr: 'id',
        dataSource: this.providerDataSource,
      },
    };
  }


  private initAppointmentMasTagBox(form): any {
    if (this.nurseId !== undefined) {
      form.updateData('maIds', [this.nurseId]);
    }
    return {
      label: {
        text: 'MA',
      },
      isRequired: true,
      editorType: 'dxTagBox',
      dataField: 'maIds',
      editorOptions: {
        searchEnabled: true,
        dataSource: this.maDataSource,
        displayExpr: 'name',
        valueExpr: 'id',
      },
    };
  }

  private initAppointmentStatusSelectBox(): any {
    return {
      label: {
        text: 'Status',
      },
      isRequired: true,
      dataField: 'appointmentStatus',
      editorType: 'dxSelectBox',
      editorOptions: {
        items: this.appointmentStatusItems,
      },
    };
  }
  private startDateValue: any = null;
  private endDateValue: any = null;

  private initAppointmentStartDateBox(): any {
    return {
      label: {
        text: 'Start Date',
      },
      isRequired: true,
      dataField: 'startDate',
      editorType: 'dxDateBox',
      editorOptions: {
        readOnly: this.currentApptView !== 'month' || this.currentAppointmentId !== undefined,
        type: 'datetime'
      }
    };
  }

  private initAppointmentEndDateBox(): any {
    return {
      label: {
        text: 'End Date',
      },
      isRequired: true,
      dataField: 'endDate',
      editorType: 'dxDateBox',
      editorOptions: {
        readOnly: this.currentApptView !== 'month' || this.currentAppointmentId !== undefined,
        type: 'datetime'
      }
    };
  }

  private validateAndCheckBusinessApi() {
    if (this.startDateValue && this.endDateValue && this.currentApptView.toLowerCase() === 'month') {
      const params = {
        startDate: this.startDateValue,
        endDate: this.endDateValue,
        startDateStr: moment(this.startDateValue).format('M/D/YYYY h:mm:ss A'),
        endDateStr: moment(this.endDateValue).format('M/D/YYYY h:mm:ss A'),
        isCheckBusiness: true
      };

      this.appointmentService.checkValidatingBussinessHours(params).then(response => {
        const result: any = response;
        if (!result.succuess) {
          this.startValInstance.option('isValid', false);
          this.endValInstance.option('isValid', false);
          this.alertService.error(result.message);
          this.startValInstance.option('value', null);
          this.endValInstance.option('value', null);
          this.startValInstance.validate();
          this.endValInstance.validate();
        } else {
          this.startValInstance.option('isValid', true);
          this.endValInstance.option('isValid', true);
          this.startValInstance.reset();
          this.endValInstance.reset();
        }
      });
    }
  }


  private initAppointmentMRNTextBox(): any {
    return {
      label: {
        text: this.identificationLabel,
      },
      disabled: true,
      dataField: 'mrn',
      editorType: 'dxTextBox',
      editorOptions: {
        value: this.mrn
      },
    };
  }

  private initPatientChartDocumentSelectBox(form: any): any {
    const isAdmissionAlreadyCreated = !!form.option('formData.admissionId');
    form.updateData('patientChartDocumentNodes', this.patientChartDocumentNodes);

    return {
      label: {
        text: 'Chart Document',
      },
      helpText: isAdmissionAlreadyCreated
        ? 'Unable to select patient chart document. The patient chart was already opened (created). All documents modifications should be directly made on the patient chart page.'
        : 'The selected documents will be used to build the patient chart tree. Please select at least one document',
      validationRules: [
        {
          type: 'required',
          message: 'A document must be selected.',
        },
      ],
      isRequired: true,
      dataField: 'patientChartDocumentNodes',
      editorType: 'dxTagBox',
      editorOptions: {
        disabled: isAdmissionAlreadyCreated,
        placeholder: 'Select patient chart document...',
        searchEnabled: true,
        displayExpr: 'name',
        valueExpr: 'id',
        dataSource: this.patientChartDocumentDataSource,
      },
    };
  }


  private initAdmissionTextBox() {
    return {
      label: {
        visible: false,
      },
      editorType: 'dxTextBox',
      dataField: 'admissionId',
      editorOptions: {
        visible: false,
      },
    };
  }

  private initAllegationsTextArea(form: any): any {
    const isAdmissionAlreadyCreated = !!form.option('formData.admissionId');

    return {
      label: {
        text: 'Chief Complaint',
      },
      editorType: 'dxTextArea',
      dataField: 'allegations',
      editorOptions: {
        disabled: isAdmissionAlreadyCreated,
        dataField: 'allegations',
        onValueChanged: (args: any) => {
          this.allegationsString = args.value;
        },
      },
    };
  }

  private initNotesTextArea(): any {
    return {
      label: {
        text: 'Notes',
      },
      editorType: 'dxTextArea',
      dataField: 'notes',
    };
  }

  private initCareTeamSelectBox(form: any): any {
    return {
      label: {
        text: 'Care Team',
      },
      editorType: 'dxTagBox',
      dataField: 'careTeamIds',
      editorOptions: {
        displayExpr: 'name',
        valueExpr: 'npi',
        searchEnabled: true,
        dataSource: this.careTeamDataSource
      },
    };
  }

  private createAppointmentGridDataSource(): any {
    const appointmentGridStore = createStore({
      loadUrl: this.dxDataUrlService.getGridUrl('appointment/griditem'),
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        this.onBeforeRequestingAppointmentGridItems,
        this
      ),
    });

    this.applyDecoratorForAppointmentGridDataSourceLoadFunc(appointmentGridStore);
    return appointmentGridStore;
  }

  private createSchedulerDataSource(): any {
    const schedulerStore = createStore({
      key: 'id',
      loadUrl: this.dxDataUrlService.getGridUrl('appointment'),
      insertUrl: this.dxDataUrlService.getEntityEndpointUrl('appointment'),
      updateUrl: this.dxDataUrlService.getEntityEndpointUrl('appointment'),
      deleteUrl: this.dxDataUrlService.getEntityEndpointUrl('appointment'),
      updateMethod: 'POST',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        this.onBeforeRequestingAppointments,
        this
      ),
    });
    this.applyDecoratorForDataSourceLoadFunc(schedulerStore);
    return schedulerStore;
  }

  private onBeforeRequestingAppointmentGridItems(method: string, ajaxOptions: any): void {
    if (method === 'load') {
      ajaxOptions.data.startDate = DateHelper.jsLocalDateToSqlServerUtc(this.startDate);

      ajaxOptions.data.endDate = DateHelper.jsLocalDateToSqlServerUtc(this.endDate);

      ajaxOptions.data.companyId = this.companyId;

      this.applyFilterIfNeeded(ajaxOptions.data);
    }
  }

  private onBeforeRequestingAppointments(method: string, ajaxOptions: any): void {
    if (method === 'delete') {
      ajaxOptions.url = `${ajaxOptions.url}/${ajaxOptions.data.key}`;
    }

    if (method === 'load') {
      ajaxOptions.data.startDate = DateHelper.jsLocalDateToSqlServerUtc(this.startDate);
      ajaxOptions.data.endDate = DateHelper.jsLocalDateToSqlServerUtc(this.endDate);
      ajaxOptions.data.companyId = this.companyId;
      this.applyFilterIfNeeded(ajaxOptions.data);
    }
    if (method !== 'load' && method !== 'delete') {
      const appointmentData = JSON.parse(ajaxOptions.data['values']);

      appointmentData.companyId = this.companyId;

      appointmentData.startDate = DateHelper.jsLocalDateToSqlServerUtc(
        appointmentData.startDate
      );
      appointmentData.endDate = DateHelper.jsLocalDateToSqlServerUtc(
        appointmentData.endDate
      );
      ajaxOptions.data = JSON.stringify(appointmentData);
      ajaxOptions.headers = {
        'Content-type': 'application/json',
      };
    }
  }

  private applyFilterIfNeeded(parameters: any) {
    const locationId = this.filter.locationId;
    if (locationId) parameters.locationId = locationId;

    const patientId = this.filter.patientId;
    if (patientId) parameters.patientId = patientId;

    const physicianId = this.filter.physicianId;
    if (physicianId) parameters.physicianId = physicianId;

    const appointmentStatusFilter = this.filter.appointmentStatusFilter;
    if (appointmentStatusFilter.filterType) {
      parameters.appointmentStatuses = appointmentStatusFilter.statuses.join(',');

      parameters.filterType = appointmentStatusFilter.filterType;
    }
  }

  private applyDecoratorForDataSourceLoadFunc(store: any) {
    const nativeLoadFunc = store.load;
    store.load = (loadOptions: any) => {
      let startDate =
        DateHelper.sqlServerUtcDateToLocalJsDate(this.filter.schedulerDate) || new Date();
      let endDate = startDate;
      if (this.filter.schedulerView === SchedulerViewNames.month) {
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1);
      } else if (this.filter.schedulerView === SchedulerViewNames.week) {
        startDate = new Date(startDate.setDate(startDate.getDate() - startDate.getDay()));
        endDate = new Date(endDate.setDate(endDate.getDate() - endDate.getDay() + 7));
      } else {
        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate() + 1
        );
      }
      endDate.setSeconds(endDate.getSeconds() - 1);

      this.startDate = startDate;
      this.endDate = endDate;

      return nativeLoadFunc.call(store, loadOptions).then((result: any[]) => {
        if (this.appointmentsGrid?.instance?.refresh)
          this.appointmentsGrid.instance.refresh();

        result.forEach((item: any) => {
          item.startDate = DateHelper.sqlServerUtcDateToLocalJsDate(item.startDate);
          item.endDate = DateHelper.sqlServerUtcDateToLocalJsDate(item.endDate);

          const startDate = item.startDate;
          item.date = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          );
        });

        this.appointmentsFilter.physicianDataSource = this.getDataSourceItemsForFilter(
          result,
          (appointment: AppointmentGridItem) => appointment.physicianId,
          (appointment: AppointmentGridItem) =>
            `${appointment.physicianFirstName} ${appointment.physicianLastName}`
        );

        this.appointmentsFilter.patientDataSource = this.getDataSourceItemsForFilter(
          result,
          (appointment: AppointmentGridItem) => appointment.patientId,
          (appointment: AppointmentGridItem) =>
            `${appointment.patientFirstName} ${appointment.patientLastName}`
        );

        this.appointmentsFilter.locationDataSource = this.getDataSourceItemsForFilter(
          result,
          (appointment: AppointmentGridItem) => appointment.locationId,
          (appointment: AppointmentGridItem) => appointment.locationName
        );
        return result;
      });
    };
  }


  private getDataSourceItemsForFilter(
    appointments: AppointmentGridItem[],
    itemIdFunc: (appointment: AppointmentGridItem) => string | undefined,
    itemNameFunc: (appointment: AppointmentGridItem) => string | undefined
  ): LookupModel[] {
    if (!appointments || !appointments.length) return [];
    return appointments.reduce(
      (items: LookupModel[], appointment: AppointmentGridItem) => {
        const itemId = itemIdFunc(appointment);
        const itemName = itemNameFunc(appointment);
        const existingItem = items.find(i => i.id === itemId);
        if (existingItem) return items;

        if (itemId && itemName) {
          const newItem = new LookupModel();
          newItem.id = itemId;
          newItem.name = itemName;
          items.push(newItem);
        }

        return items;
      },
      []
    );
  }

  private applyDecoratorForPatientDataSourceLoadFunc(store: any) {
    const nativeLoadFunc = store.load;
    store.load = (loadOptions: any) => {
      return nativeLoadFunc.call(store, loadOptions).then((result: any[]) => {
        this.dtsPatients = result;
        const lastNameToDobMap = new Map<string, Set<string>>();

        result.forEach((item) => {
          const dateOfBirth = DateHelper.getDate(
            DateHelper.sqlServerUtcDateToLocalJsDate(item.dateOfBirth)?.toString()
          );
          const lastName = item.name.split(' ').pop();

          if (lastNameToDobMap.has(lastName)) {
            const dobSet = lastNameToDobMap.get(lastName)!;
            dobSet.add(dateOfBirth);
          } else {
            lastNameToDobMap.set(lastName, new Set([dateOfBirth]));
          }
        });

        const markedResults = result.map((item) => {
          const dateOfBirth = DateHelper.getDate(
            DateHelper.sqlServerUtcDateToLocalJsDate(item.dateOfBirth)?.toString()
          );
          const lastName = item.name.split(' ').pop();

          if (lastNameToDobMap.get(lastName)!.size > 1) {
            item.nameAlert = true;
            item.displayName = `${item.name} - ${dateOfBirth} Name Alert`;
          } else {
            item.nameAlert = false;
            item.displayName = `${item.name} - ${dateOfBirth}`;
          }
          return item;
        });
        const uniqueResults = new Map<string, any>();
        markedResults.forEach((item) => {
          uniqueResults.set(item.displayName, item);
        });

        return Array.from(uniqueResults.values());
      });
    };
  }


  private applyDecoratorForAppointmentGridDataSourceLoadFunc(store: any) {
    const nativeLoadFunc = store.load;
    store.load = (loadOptions: any) => {
      return nativeLoadFunc.call(store, loadOptions).then((result: any[]) => {
        result.forEach(appointmentGridItem => {
          appointmentGridItem.startDate = DateHelper.sqlServerUtcDateToLocalJsDate(
            appointmentGridItem.startDate
          );

          appointmentGridItem.endDate = DateHelper.sqlServerUtcDateToLocalJsDate(
            appointmentGridItem.endDate
          );

          const startDate = appointmentGridItem.startDate;
          appointmentGridItem.date = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          );
        });
        return result;
      });
    };
  }

  private initLocationDataSource(): void {
    this.locationDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('location'),
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.companyId;
        },
        this
      ),
    });
  }

  private initRoomDataSource(): void {
    this.roomDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('room'),
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.locationId = this.selectedLocationId;
          jQueryAjaxSettings.data.companyId = this.companyId;
        },
        this
      ),
    });
  }

  private initTableRoomDataSource(): void {
    this.tableRoomDataSource = new DataSource({
      store: createStore({
        loadUrl: this.dxDataUrlService.getLookupUrl('room'),
        key: 'id',
        onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
          (_method, jQueryAjaxSettings) => {
            jQueryAjaxSettings.data.locationId = this.tableSelectedLocationId;
            jQueryAjaxSettings.data.companyId = this.tableSelectedCompanyId;
          },
          this
        ),
      }),
    });
  }

  private initPatientDataSource(): void {
    this.patientDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl(ApiBaseUrls.patient),
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.companyId;
        },
        this
      ),
    });

    // this.patientDataSource.store = patientDataSourceStore;
    // this.applyDecoratorForPatientDataSourceLoadFunc(patientDataSourceStore);
  }

  private initPhysicianDataSource(): void {
    this.physianDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('user'),
      loadParams: { employeeType: EmployeeTypeList.values[0].value },
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.companyId;
        },
        this
      ),
    });
  }

  // private initProviderDataSource(): void {
  //   this.providerDataSource.store = createStore({
  //     loadUrl: this.dxDataUrlService.getLookupUrl('user'),
  //     loadParams: { employeeType: EmployeeTypeList.values[0].value },
  //     key: 'id',
  //     onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
  //       (_method, jQueryAjaxSettings) => {
  //         jQueryAjaxSettings.data.companyId = this.companyId;
  //       },
  //       this
  //     ),
  //   });
  // }

  private initProviderDataSource(): void {
    const apiUrl = `user/getProviders?companyId=${this.companyId}`;
    this.repository.getData(apiUrl).subscribe({
      next: data => {
        this.providerDataSource = data;
      }
    });
  }

  private initNurseDataSource(): void {
    this.nurseDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('user'),
      loadParams: { employeeType: EmployeeTypeList.values[1].value },
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.companyId;
        },
        this
      ),
    });
  }

  private initMaDataSource(): void {
    const apiUrl = `user/getNurses?companyId=${this.companyId}`;
    this.repository.getData(apiUrl).subscribe({
      next: data => {
        this.maDataSource = data;
      }
    });
  }

  // private initMaDataSource(): void {
  //   this.maDataSource.store = createStore({
  //     loadUrl: this.dxDataUrlService.getLookupUrl('user'),
  //     loadParams: { employeeType: EmployeeTypeList.values[2].value },
  //     key: 'id',
  //     onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
  //       (_method, jQueryAjaxSettings) => {
  //         jQueryAjaxSettings.data.companyId = this.companyId;
  //       },
  //       this
  //     ),
  //   });
  // }

  private initDiagnosisDataSource(): void {
    this.icdCodesDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('icdcode'),
      key: 'Id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, _jQueryAjaxSettings) => { },
        this
      ),
    });
  }

  private initCareTeamDataSource(): void {
    this.careTeamDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('CareTeam'),
      loadParams: {},
      key: 'npi',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => { },
        this
      ),
    });
  }

  private initCurrentDiagnosisDataSource(): void {
    this.currentDiagnosisDataSource = new DataSource({
      store: new ArrayStore({
        key: 'id',
        data: [],
      }),
    });
  }

  private reloadCurrentDiagnosisItems(patientId: string, form: any): void {
    this.admissionService.getCurrentDiagnosisByPatientId(patientId).then(resp => {
      this.currentDiagnosisDataSource.store().clear();
      resp.forEach((diagnosis: any) => {
        this.currentDiagnosisDataSource.store().insert(diagnosis);
      });
      this.currentDiagnosisDataSource.reload();

      // if (form) {
      //   form.repaint();
      // }
    });
  }

  private initCurrentChiefComplaintsDataSource(): void {
    this.currentChiefComplaintsDataSource = new DataSource({
      store: new ArrayStore({
        key: 'id',
        data: [],
      }),
    });
  }

  private reloadCurrentChiefComplaintsItems(patientId: string, form: any): void {
    this.admissionService.getCurrentChiefComplaintsByPatientId(patientId).then(resp => {
      this.currentChiefComplaintsDataSource.store().clear();
      resp.forEach((diagnosis: any) => {
        this.currentChiefComplaintsDataSource.store().insert(diagnosis);
      });
      this.currentChiefComplaintsDataSource.reload();

      // if (form) {
      //   form.repaint();
      // }
    });
  }

  private initPatientChartDocumentDataSource(): void {
    this.patientChartDocumentDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl(ApiBaseUrls.patientChartDocuments),
      loadParams: {},
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.companyId;
        },
        this
      ),
    });
  }

  private refreshSchedulerAndAppointmentsGrid(): void {
    const appointmentScheduler = this.appointmentScheduler;
    if (appointmentScheduler && this.appointmentScheduler.instance) {
      appointmentScheduler.dataSource = this.createSchedulerDataSource();
    }

    const appointmentsGrid = this.appointmentsGrid;
    if (appointmentsGrid && appointmentsGrid.instance) {
      appointmentsGrid.dataSource = this.createAppointmentGridDataSource();
    }
  }

  private setupAppointmentPopup(popup: any) {

    popup.option('onHidden', () => {
      this.currentOpenedAppointmentForm = null;
    });
    popup.option('elementAttr', { class: 'd-con-appt-popup' });
    popup.option('showCloseButton', true);
    popup.option('fullScreen', true);
    popup.option('resizeEnabled', false);
    popup.option('dragEnabled', false);
    popup.option('showTitle', true);
    popup.option('animation', {
      show: {
        type: 'slideIn',
        direction: 'top'
      },
      hide: {
        type: 'slideOut',
        direction: 'top'
      }
    })
    popup.option('title', 'Appointment');

    popup.on('hidden', () => (this.isAppointmentCreationFormOpened = false));
  }

  private subscribeToCompanyIdChanges() {
    this.companyIdSubscription = this.companyIdService.companyId.subscribe(companyId => {
      if (companyId) {
        this.companyId = companyId;
        this.initSelectableLists(this.companyId);
        this.initProviderDataSource();
        this.initMaDataSource();
      }
    });
  }

  private initSelectableLists(companyId: string): void {
    if (this.areSelectableListsInitialized) this.areSelectableListsInitialized = false;

    const appointmentStatusListConfig = new SelectableListConfig(
      companyId,
      SelectableListsNames.application.appointmentStatus,
      LibrarySelectableListIds.application.appointmentStatus
    );

    this.selectableListService
      .setSelectableListsValuesToComponent([appointmentStatusListConfig], this)
      .then(() => {
        this.areSelectableListsInitialized = true;
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  private duplicateAppointment(appointment: any): Appointment {
    const newAppointment = new Appointment();

    newAppointment.patientId = appointment.patientId;
    newAppointment.companyId = appointment.companyId;
    newAppointment.locationId = appointment.locationId;
    newAppointment.physicianId = appointment.physicianId;
    newAppointment.nurseId = appointment.nurseId;
    newAppointment.roomId = appointment.roomId;
    newAppointment.startDate = appointment.startDate;
    newAppointment.endDate = appointment.endDate;
    newAppointment.allegations = appointment.allegations;
    newAppointment.allegationsNotes = appointment.allegationsNotes;
    newAppointment.appointmentStatus = appointment.appointmentStatus;
    newAppointment.patientChartDocumentNodes = appointment.patientChartDocumentNodes;

    return newAppointment;
  }

  saveSimpleAdmission(appointment: any) {
    this.appointmentService.saveSimple(appointment).then(resp => {
      this.alertService.notifyMsg("Admission saved successfully", "success");
      this.refreshRelatedSchedulerComponents();
    });
  }

  onTableLocationChanged($event: any, appointment: any): void {
    this.tableSelectedLocationId = $event.value;
    this.tableSelectedCompanyId = appointment.companyId;
    this.tableRoomDataSource.reload();
  }

  getTableRoomDataSource(appointment: any) {
    this.tableSelectedLocationId = appointment.locationId;
    this.tableSelectedCompanyId = appointment.companyId;
    return this.tableRoomDataSource;
  }

  getAppointmentStatusColor(status: string) {
    if (!this.allAppointmentStatusColors) return null;
    return Object.prototype.hasOwnProperty.call(this.allAppointmentStatusColors, status)
      ? this.allAppointmentStatusColors[status]
      : null;
  }

  changeAppointmentLeyendStatus() {
    this.showAppointmentLegend = !this.showAppointmentLegend;
  }

  public onViewTypeChanged = (e: any) => {
    if (e.value === true) {
      this.isCalendarView = false;
      setTimeout(() => {
        this.appointmentsGrid.dataSource = this.createAppointmentGridDataSource();
      });
    } else {
      this.isCalendarView = true;
      setTimeout(() => {
        this.appointmentScheduler.dataSource = this.createSchedulerDataSource();
      });
    }
  }

  public onBtnAppointmentInfo(entity: any) {
    this.showAppointmentDetail(entity);
  }


  public onBtnPatientChartClick(appointmentId: string) {
    this.router.navigate(['/patient-chart', appointmentId]);
  }

  public showAppointmentDetail(entity) {
    this.addAppointmentComponentRef =
      ComponentHelper.addComponent(this.componentFactoryResolver, this.componentContainer, AppointmentInfoComponent);
    const detailInstance = this.addAppointmentComponentRef.instance;
    detailInstance.popupTitle = "Appointment Info";
    detailInstance.showAppointmentInfo(entity);
    detailInstance.popupHiddenEvent.subscribe(response => {
      ComponentHelper.removeComponent(this.componentContainer, this.addAppointmentComponentRef);
    });
  }

  public onBtnDeleteAppointment(appointment: any) {
    this.appointmentScheduler.instance.hideAppointmentPopup();
    const isAdmissionCreated = !appointment.appointmentData.admissionId;
    const appointmentId = appointment.appointmentData.id;

    if (!isAdmissionCreated) {
      this.alertService
        .confirm('Are you sure you want to delete the appointment?', 'Confirm deletion')
        .then(isDeletionConfirmed => {
          if (!isDeletionConfirmed) return;

          this.removeAppointment(appointmentId);
        })
        .catch(error => this.alertService.error(error));
    } else {
      const isAdmissionSignedIn = !!appointment.appointmentData.signingDate;
      if (isAdmissionSignedIn) {
        this.alertService.warning('Unable to delete signed patient chart');
        return;
      }

      this.appointmentAlertAervice
        .confirmDeleteAppointmentWithPatientChart()
        .show()
        .then((isDeletionConfirmed: any) => {
          if (!isDeletionConfirmed) return;

          this.admissionService
            .deleteById(appointment.appointmentData.admissionId)
            .then(() => {
              this.removeAppointment(appointmentId);
            });
        })
        .catch((error: any) =>
          this.alertService.error(error.message ? error.message : error)
        );
    }
  }


  public onBtnDeleteGrdAppointment(appointment: any) {
    const isAdmissionCreated = !appointment.admissionId;
    const appointmentId = appointment.id;

    if (!isAdmissionCreated) {
      this.alertService
        .confirm('Are you sure you want to delete the appointment?', 'Confirm deletion')
        .then(isDeletionConfirmed => {
          if (!isDeletionConfirmed) return;

          this.removeAppointment(appointmentId);
        })
        .catch(error => this.alertService.error(error));
    } else {
      const isAdmissionSignedIn = !!appointment.signingDate;
      if (isAdmissionSignedIn) {
        this.alertService.warning('Unable to delete signed patient chart');
        return;
      }

      this.appointmentAlertAervice
        .confirmDeleteAppointmentWithPatientChart()
        .show()
        .then((isDeletionConfirmed: any) => {
          if (!isDeletionConfirmed) return;

          this.admissionService
            .deleteById(appointment.admissionId)
            .then(() => {
              this.removeAppointment(appointmentId);
            });
        })
        .catch((error: any) =>
          this.alertService.error(error.message ? error.message : error)
        );
    }
  }

  onBtnShowPreviousAdmissions(data: any) {
    this.appointmentService
      .getPatientPreviousVisits(data.patientId, data.startDate)
      .then(admissions => {
        this.patientPreviousAdmissions = admissions;
        this.prevTitle = "Previous Admissions - " + data.patientFirstName +
          ' ' +
          data.patientLastName;
        this.isAdmissionPopupVisible = true;

      });
  }

  onBtnReviewPatientChartFromAdmission(appointment: any) {
    this.admissionService.getById(appointment.admissionId).then(admission => {
      this.patientChartNodeReview = JSON.parse(admission.admissionData || 'null');
      this.isPatientChartReview = true;
      this.patientChartDocumentNodeReview = this.patientChartNodeReview;
      this.appointmentIdReview = appointment.id;
      this.patientIdReview = appointment.patientId;
      this.admissionIdReview = appointment.admissionId;
      this.companyIdReview = appointment.companyId;
    });
  }

  onBtnPatientChartFromAdmission(appointmentId: string) {
    if (!appointmentId) return;
    this.router.navigate(['/patient-chart', appointmentId]);

  }

  parseDate(dateString) {
    return this.formatHelper.formatTime(dateString);
  }

  onApptContentReady = (e) => {
    if (!this.collapsed) {
      const firstRowData = e.component.getKeyByRowIndex(0);
      if (firstRowData != undefined) {
        e.component.expandRow(firstRowData);
        this.collapsed = true;
      }
    }
  };

}
