export class ApiBaseUrls {
  static patientChartDocuments = 'patient-chart-documents';
  static libraryPatientChartDocuments = 'library-patient-chart-documents';
  static patientChart = 'patient-charts';
  static libraryPatientChart = 'library-patient-charts';
  static selectableList = 'selectable-lists';
  static selectableListCategory = 'selectable-list-categories';
  static librarySelectableListCategory = 'library-selectable-list-categories';
  static templateTypes = 'template-types';
  static templates = 'templates';
  static libraryTemplateTypes = 'library-template-types';
  static libraryTemplates = 'library-templates';
  static librarySelectableList = 'library-selectable-lists';
  static selectableItems = 'selectable-items';
  static company = 'companies';
  static lookUpZipCode = 'lookUpZipCode';
  static patient = 'patients';
  static validate = 'validate';
  static patientNotes = 'patients/patient-notes';
  static chiefComplaint = 'chiefcomplaint';
  static templateHistory = 'template-history';
  static libraryReferenceTables = 'library-reference-tables';
  static libraryExpressions = 'library-expressions';
  static expressions = 'expressions';
  static expressionItems = 'expression-items';
  static referenceTables = 'reference-tables';
  static expressionExecutionRequests = 'expression-execution-requests';
  static expressionExecutionContexts = 'expression-execution-contexts';
  static errorLog = 'error-log-records';
  static phrase = 'phrase';
  static phraseUsage = 'phrase-usage';
  static patientIdentificationCodes = 'patient-identification-codes';
  static patientIdentificationNmericCodes = 'patient-identification-numeric-codes';
  static docsToProcess = 'dataParser';
}
