export class ModelViewSelectionTypes {
  static values: any[] = [
    { value: 1, name: 'First' },
    { value: 2, name: 'Last' },
    { value: 3, name: 'Min' },
    { value: 4, name: 'Max' },
    { value: 5, name: 'Sum' },
    { value: 6, name: 'Date Range' },
    { value: 7, name: 'Text' },
  ];
}
