import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { Company } from 'src/app/_models/company';
import { AlertService } from 'src/app/_services/alert.service';
import { DxDataUrlService } from 'src/app/_services/dxDataUrl.service';
import { CompanyService } from 'src/app/_services/company.service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { CompanyCreateUpdateTrackService } from 'src/app/_services/company-create-update-track.service';
import { DevextremeAuthService } from 'src/app/_services/devextreme-auth.service';
import { ZipCodeType } from 'src/app/patients/models/zipCodeType';
import { BaseAdminComponent } from 'src/app/_classes/baseAdminComponent';
import { ApiBaseUrls } from 'src/app/_models/apiBaseUrls';
import { CompanyDetailInfoComponent } from '../../detail/company-detail-info.component';
import { ComponentHelper } from 'src/app/shared/utils/helpers/component.helper';
import { CompanyIdService } from 'src/app/_services/company-id.service';
import { UserService } from 'src/app/administration/services/user.service';
import { MedicoApplicationUser } from 'src/app/administration/models/medicoApplicationUser';

@Component({
  selector: 'companies-management',
  templateUrl: 'companies-management.component.html',
})
export class CompaniesManagementComponent
  extends BaseAdminComponent
  implements AfterViewInit {
  @ViewChild('companyDataGrid', { static: false })
  companyDataGrid!: DxDataGridComponent;
  @ViewChild('companyPopup', { static: false })
  companyPopup!: DxPopupComponent;
  @ViewChild('companyForm', { static: false })
  companyForm!: DxFormComponent;
  @ViewChild('componentContainer', { static: false, read: ViewContainerRef }) componentContainer: ViewContainerRef;
  public addCompanyComponentRef: ComponentRef<CompanyDetailInfoComponent>;
  companyDataSource: any = {};

  selectedCompanys: Array<any> = [];

  company: Company = new Company();
  isNewCompany = true;
  isCompanyPopupOpened = false;

  constructor(
    private alertService: AlertService,
    private dxDataUrlService: DxDataUrlService,
    private companyService: CompanyService,
    private userService: UserService,
    private companyCreateUpdateTrackService: CompanyCreateUpdateTrackService,
    private devextremeAuthService: DevextremeAuthService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    super();
    this.init();
  }

  get zipMask(): string {
    switch (this.company.zipCodeType) {
      case ZipCodeType.FiveDigit:
        return this.validationMasks.fiveDigitZip;
      case ZipCodeType.NineDigit:
        return this.validationMasks.nineDigitZip;
    }
  }

  isFiveDigitCode(zipCodeType: number): boolean {
    return zipCodeType === ZipCodeType.FiveDigit;
  }

  isNineDigitCode(zipCodeType: number): boolean {
    return zipCodeType === ZipCodeType.NineDigit;
  }

  openCompanyForm() {
    this.isCompanyPopupOpened = true;
  }

  onCompanyPopupHidden() {
    this.resetCreateUpdateCompanyForm();
  }

  createUpdateCompany() {
    const validationResult = this.companyForm.instance.validate();
    if (!validationResult.isValid) {
      return;
    }
    this.companyService
      .save(this.company)
      .then(company => {
        this.createDefaultUser(company);
        this.companyDataGrid.instance.refresh();
        this.resetCreateUpdateCompanyForm();
        this.isCompanyPopupOpened = false;
        this.companyCreateUpdateTrackService.emitCompanyChanges(company.id);
        this.alertService.notifyMsg("Changes saved successfully", "success");
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  private createDefaultUser(data) {
    const domain = data.name.split(" ")[0].toLowerCase();
    const user: MedicoApplicationUser = {
      role: "a8a30f96-8aa9-4919-b319-914621c9d627",
      firstName: "Admin",
      namePrefix: null,
      nameSuffix: null,
      middleName: null,
      lastName: "User",
      email: `admin.${domain}@medicophysicians.net`,
      address: data.address,
      secondaryAddress: null,
      city: data.city,
      state: data.state,
      zip: data.zipCode,
      zipCodeType: data.zipCodeType,
      primaryPhone: data.phone,
      secondaryPhone: null,
      employeeType: 5,
      ssn: "000000000",
      gender: 4,
      password: this.generateRandomPassword(),
      dateOfBirth: new Date(),
      employeeTypes: [5],
      stateName: "",
      employeeTypeName: "Administrative",
      companyId: data.id,
      isActive: true
    };

    this.userService
      .save(user)
      .then(() => {
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  generateRandomPassword() {
    const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
    const digits = '0123456789';
    const specialChars = '@#$%^&*';

    const getRandomChar = (chars) => chars[Math.floor(Math.random() * chars.length)];
    const passwordArray = [
      getRandomChar(upperChars),
      getRandomChar(lowerChars),
      getRandomChar(digits),
      getRandomChar(specialChars)
    ];

    for (let i = passwordArray.length; i < 8; i++) {
      const allChars = upperChars + lowerChars + digits + specialChars;
      passwordArray.push(getRandomChar(allChars));
    }
    return passwordArray.sort(() => Math.random() - 0.5).join('');
  }

  ngAfterViewInit(): void {
    this.registerEscapeBtnEventHandler(this.companyPopup);
  }

  deactivateCompany(company: any, $event: any) {
    $event.stopPropagation();
    const companyId = company.id;

    this.companyService
      .getById(companyId)
      .then(company => {
        const confirmationPopup = this.alertService.confirm(
          `Are you sure you want to deactivate the "${company.name}" company?`,
          'Confirm deactivation'
        );

        confirmationPopup.then(dialogResult => {
          if (dialogResult) {
            company.isActive = false;
            this.companyService
              .save(company)
              .then(() => {
                this.companyDataGrid.instance.refresh();
                this.initCompanyDataSource();
              })
              .catch(error =>
                this.alertService.error(error.message ? error.message : error)
              );
          }
        });
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  activateCompany(company: any, $event: any) {
    $event.stopPropagation();
    const companyId = company.id;

    this.companyService
      .getById(companyId)
      .then(company => {
        const confirmationPopup = this.alertService.confirm(
          `Are you sure you want to activate the "${company.name}" company ?`,
          'Confirm activation'
        );

        confirmationPopup.then(dialogResult => {
          if (dialogResult) {
            company.isActive = true;
            this.companyService
              .save(company)
              .then(() => {
                this.companyDataGrid.instance.refresh();
                this.initCompanyDataSource();
              })
              .catch(error =>
                this.alertService.error(error.message ? error.message : error)
              );
          }
        });
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  onCompanySelected($event: any) {

    const selectedCompanyId = $event.id;
    this.companyService
      .getById(selectedCompanyId)
      .then(company => {
        this.company = company;
        this.isNewCompany = false;
        this.isCompanyPopupOpened = true;
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  getState(data: any) {
    const stateNumber = data.state;
    return this.states.filter(s => s.value === stateNumber)[0].name;
  }

  private resetCreateUpdateCompanyForm() {
    this.company = new Company();
    this.isNewCompany = true;
    this.selectedCompanys = [];
  }

  private init(): any {
    this.initCompanyDataSource();
  }

  private initCompanyDataSource(): void {
    this.companyDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getGridUrl(ApiBaseUrls.company),
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, _ajaxOptions) => { },
        this
      ),
    });
  }


  public onCompanyInfoSelected(e) {
    this.showCompanyDetail(e);
  }

  public showCompanyDetail(entity) {
    this.addCompanyComponentRef =
      ComponentHelper.addComponent(this.componentFactoryResolver, this.componentContainer, CompanyDetailInfoComponent);
    const detailInstance = this.addCompanyComponentRef.instance;
    detailInstance.popupTitle = "Company Info";

    detailInstance.showCompanyDetail(entity);
    detailInstance.popupHiddenEvent.subscribe(response => {
      this.initCompanyDataSource();
      ComponentHelper.removeComponent(this.componentContainer, this.addCompanyComponentRef);
    });
  }
}
