import {
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { DxColorBoxComponent } from 'devextreme-angular';
import { ChartColor } from 'src/app/patientChart/models/chartColor';
import { DefaultChartColors } from 'src/app/administration/classes/defaultChartColors';
import { AuditManagementService } from 'src/app/administration/components/audit-management/audit-management.service';
import { PatientChartManagementComponent } from '../patient-chart-management.component';
import { ColorHelper } from '../../../../_helpers/color.helper';

@Component({
  selector: 'audit-item-color',
  templateUrl: './audit-item-color.component.html',
  styleUrls: ['./audit-item-color.component.scss'],
})
export class AuditItemColorComponent {
  @ViewChildren(DxColorBoxComponent) colorBox!: QueryList<DxColorBoxComponent>;

  @Input() chartColors!: ChartColor;
  @Output() chartColorsChange = new EventEmitter<ChartColor>();
  defaultColors: DefaultChartColors = new DefaultChartColors();
  resettingColor: boolean = false;
  categoryFrontEnd: Array<string> = [
    'No Content Changed',
    'Content Updated',
    'Default Or Incomplete',
    'Abnormal Values',
  ];
  categoryBackEnd: Array<string> = [
    'NoContentChanged',
    'Updated',
    'DefaultOrIncomplete',
    'Abnormal',
  ];
  classNames: Array<Array<string>> = [
    ['cat_1Color', 'cat_1Border'],
    ['cat_2Color', 'cat_2Border'],
    ['cat_3Color', 'cat_3Border'],
    ['cat_4Color', 'cat_4Border'],
  ];

  constructor(
    private auditManagementService: AuditManagementService,
    private patientChartManagementComponent: PatientChartManagementComponent,
    private colorHelper: ColorHelper
  ) {}

  onColorChange($event: any): void {
    if (!$event || !$event.value || !$event.previousValue || !$event.element.id) return;
    if (this.resettingColor == true) {
      this.resettingColor = false;
      return;
    }

    const newColor: string = $event.value;
    const oldColor: string = $event.previousValue;
    const name: string = $event.element.id;
    const verifyColor: RegExp = new RegExp('^#[a-zA-Z0-9]{1,6}$', 'g');
    let colorName: string = '';

    if (!newColor.match(verifyColor)) {
      return;
    }

    name.includes('cat_1')
      ? (colorName = this.categoryBackEnd[0])
      : name.includes('cat_2')
      ? (colorName = this.categoryBackEnd[1])
      : name.includes('cat_3')
      ? (colorName = this.categoryBackEnd[2])
      : (colorName = this.categoryBackEnd[3]);

    if (name.includes('Border')) colorName = 'Border' + colorName;

    this.change(colorName, newColor);
    this.chartColorsChange?.emit(this.chartColors);
    this.resettingColor = true;
  }

  setDefaultColors(): void {
    this.setDefault(this.defaultColors);
  }

  change(colorTitle: string, newColor: string): void {
    switch (colorTitle) {
      case 'updated':
      case 'Updated':
        this.chartColors.updated = newColor;
        break;
      case 'abnormal':
      case 'Abnormal':
        this.chartColors.abnormal = newColor;
        break;
      case 'noContentChanged':
      case 'NoContentChanged':
        this.chartColors.noContentChanged = newColor;
        break;
      case 'defaultOrIncomplete':
      case 'DefaultOrIncomplete':
        this.chartColors.defaultOrIncomplete = newColor;
        break;
      case 'borderUpdated':
      case 'BorderUpdated':
        this.chartColors.borderUpdated = newColor;
        break;
      case 'borderAbnormal':
      case 'BorderAbnormal':
        this.chartColors.borderAbnormal = newColor;
        break;
      case 'borderDefaultOrIncomplete':
      case 'BorderDefaultOrIncomplete':
        this.chartColors.borderDefaultOrIncomplete = newColor;
        break;
      case 'borderNoContentChanged':
      case 'BorderNoContentChanged':
        this.chartColors.borderNoContentChanged = newColor;
        break;
      default:
     
    }

  }

  setDefault(defaultColor: DefaultChartColors): void {
    this.chartColors.updated = defaultColor.updated;
    this.chartColors.abnormal = defaultColor.abnormal;
    this.chartColors.defaultOrIncomplete = defaultColor.defaultOrIncomplete;
    this.chartColors.noContentChanged = defaultColor.noContentChanged;
    this.chartColors.borderUpdated = defaultColor.borderUpdated;
    this.chartColors.borderAbnormal = defaultColor.borderAbnormal;
    this.chartColors.borderDefaultOrIncomplete = defaultColor.borderDefaultOrIncomplete;
    this.chartColors.borderNoContentChanged = defaultColor.borderNoContentChanged;
  }
  saveColors(): void {
    if (this.patientChartManagementComponent.selectedPatientChartNode?.text) {
      const chartSelected = this.colorHelper.ChartColor(
        this.patientChartManagementComponent.selectedPatientChartNode?.text
      );
      this.auditManagementService.setColors(this.chartColors, chartSelected);
      this.chartColorsChange?.emit(this.chartColors);
    }
  }
}
