import { PatientService } from 'src/app/_services/patient.service';
import { DateHelper } from 'src/app/_helpers/date.helper';
import { PatientInsuranceService } from 'src/app/_services/patient-insurance.service';
import { CompanyService } from 'src/app/_services/company.service';
import { AppointmentService } from 'src/app/_services/appointment.service';
import * as moment from 'moment';
import { StateList } from 'src/app/_classes/stateList';
import { Patient } from 'src/app/patients/models/patient';
import { Company } from 'src/app/_models/company';
import { AppointmentGridItem } from 'src/app/scheduler/models/appointmentGridItem';
import { Inject, Injectable } from '@angular/core';
import { Admission } from '../models/admission';
import { ConfigService } from 'src/app/_services/config.service';
import { PatientInsurance } from 'src/app/patients/models/patientInsurance';
import { DOCUMENT } from '@angular/common';
import { HtmlReportHelperService } from './html-report-helper.service';
import { PatientIdentificationCodeSearchFilter } from 'src/app/lookUpZipCode/models/PatientIdentificationCodeSearchFilter';
import { PatientIdentificationCodeService } from 'src/app/patients/services/patient-identification-code.service';
import { PatientIdentificationCodeApiModel } from 'src/app/patients/models/patientIdentificationCodeApiModel';
import { PatientIdentificationCodeType } from 'src/app/patients/models/enums/patientIdentificationCodeType';

@Injectable()
export class PatientChartReportHeaderService {
  treeName = 'Progress Note';
  constructor(
    private patientService: PatientService,
    private patientInsuranceService: PatientInsuranceService,
    private companyService: CompanyService,
    private appointmentService: AppointmentService,
    private configService: ConfigService,
    @Inject(DOCUMENT) private document: Document,
    private htmlReportHelperService: HtmlReportHelperService,
    private patientIdentificationCodeService: PatientIdentificationCodeService
  ) { }

  setTreeName(treename: any) {
    if (treename) this.treeName = treename;
  }
  //used to set a random number between 100000 and 999999
  getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getPatientChartNodeReportContent(
    admission: Admission,
    companyId: string,
    isOutsideRichTextEditor = false
  ): Promise<string> {
    const { patientId, appointmentId } = admission;

    const patientPromise = this.patientService.getById(patientId);
    const patientInsurancePromise =
      this.patientInsuranceService.getByPatientId(patientId);

    const companyPromise = this.companyService.getById(companyId);

    const codeSearchFilter1 = new PatientIdentificationCodeSearchFilter();
    codeSearchFilter1.companyId = companyId;
    codeSearchFilter1.identificationCodeType = PatientIdentificationCodeType.Fin;

    const codeSearchFilter2 = new PatientIdentificationCodeSearchFilter();
    codeSearchFilter2.companyId = companyId;
    codeSearchFilter2.identificationCodeType = PatientIdentificationCodeType.Mrn;
    const identificationCodeTypeFIN = this.patientIdentificationCodeService.get(codeSearchFilter1);
    const identificationCodeTypeMRN = this.patientIdentificationCodeService.get(codeSearchFilter2);
    const appointmentGridViewPromise =
      this.appointmentService.getAppointmentGridItemById(appointmentId);

    const promises: [
      Promise<Patient>,
      Promise<any>,
      Promise<Company>,
      Promise<AppointmentGridItem>,
      Promise<PatientIdentificationCodeApiModel>,
      Promise<PatientIdentificationCodeApiModel>
    ] = [
        patientPromise,
        patientInsurancePromise,
        companyPromise,
        appointmentGridViewPromise,
        identificationCodeTypeFIN,
        identificationCodeTypeMRN
      ];

    return Promise.all(promises).then(result => {
      const [
        patient,
        patientInsurance,
        company,
        appointmentGridView,
        typeFIN,
        typeMRN
      ] = result;


      const appointmentInfoTable = this.createAppointmentInfoTable(
        patient,
        patientInsurance,
        appointmentGridView,
        company,
        typeFIN,
        typeMRN
      );
      const logoUrl = this.getLogoUrl(isOutsideRichTextEditor);
      const companyState = this.getCompanyStateName(company.state);
      return `
          <div class="report-container">
            <div class="header-section">
              <div class="company-info">
              <div class="">
                <img src="${logoUrl}" alt="Company Logo">
              </div>
                <p>${company.address} - ${company.secondaryAddress}</p>
                <p>${company.city} - ${companyState} - 85012</p>
                <p>Office ${company.phone}</p>
                <p>Fax ${company.fax} - 866 264 4120</p>
              </div>
            </div>
            <div class="appointment-info">
              ${appointmentInfoTable}
            </div>
          </div>
        `;
    });
  }

  // Function to get company state name
  private getCompanyStateName(stateCode: any): string {
    const state = StateList.values.find(s => s.value === stateCode);
    return state ? state.name : '';
  }

  // Function to get the logo URL
  private getLogoUrl(isOutsideRichTextEditor: boolean): string {
    if (isOutsideRichTextEditor) {
      return `${this.configService.baseUrl}assets/logo-removebg-preview.png`
    }
    return this.configService.baseUrl
      ? `${this.configService.baseUrl}assets/logo-removebg-preview.png`
      : `${this.document.location.protocol}//${document.location.hostname}/assets/logo-removebg-preview.png`;
  }

  // Function to create the appointment info table
  private createAppointmentInfoTable(
    patient: Patient,
    patientInsurance: PatientInsurance,
    appointmentGridView: AppointmentGridItem,
    company: Company,
    typeFIN: any,
    typeMRN: any
  ): string {
    const emptyValue = '---';
    const patientName = `${patient.firstName} ${patient.lastName}`;
    const patientDoB = moment(patient.dateOfBirth).format('LL');
    let patientAge = this.getPatientAge(patient.dateOfBirth);
    const ssn = patient.ssn || emptyValue;

    // Store essential values in localStorage
    this.setLocalStorageValues(appointmentGridView, patient, typeFIN, typeMRN, patientName, patientAge);

    const appointmentDate = moment(appointmentGridView.startDate).format('LL');

    return this.htmlReportHelperService.createReportTable([
      ['<strong>Name</strong>', patientName, '<strong>Date of Exam</strong>', appointmentDate],
      ['<strong>Date Of Birth / Age</strong>', `${patientDoB} / ${patientAge}`, `<strong>${typeMRN.identificationLabel}</strong>`, patient.rqid || emptyValue],
      [`<strong>${typeFIN.identificationLabel}</strong>`, patient.fin || emptyValue, '<strong>Consultative Examiner</strong>', `${appointmentGridView.physicianFirstName} ${appointmentGridView.physicianLastName}`],
      ['<strong>Social Security</strong>', ssn, '<strong>Location of Exam</strong>', `${appointmentGridView.locationName}`],
    ]);
  }

  // Function to get patient age formatted properly
  private getPatientAge(dateOfBirth: Date): string {
    let age = DateHelper.getAge(dateOfBirth).toString();
    if (age === '0') {
      age = DateHelper.getMonthsFromBirth(dateOfBirth).toString() + 'M';
    }
    if (age === '0') {
      age = DateHelper.getDaysFromBirth(dateOfBirth).toString() + 'D';
    }
    return age;
  }

  // Function to set essential values in localStorage
  private setLocalStorageValues(
    appointmentGridView: AppointmentGridItem,
    patient: Patient,
    typeFIN: any,
    typeMRN: any,
    patientName: string,
    patientAge: string
  ): void {
    localStorage.setItem('patientReportInfo', JSON.stringify(appointmentGridView));
    localStorage.setItem('consultativeExaminer', `${appointmentGridView.patientNameSuffix || ''} ${appointmentGridView.physicianFirstName} ${appointmentGridView.physicianLastName}`);
    localStorage.setItem('assignedPhysicianName', `${appointmentGridView.physicianFirstName} ${appointmentGridView.physicianLastName}`);
    localStorage.setItem('assignedPatient', patientName);
    localStorage.setItem('examDate', moment(appointmentGridView.startDate).format('LL'));
    localStorage.setItem('finLabel', typeFIN.identificationLabel);
    localStorage.setItem('rqIdLabel', typeMRN.identificationLabel);
  }
}
