export class SelectableListsNames {
  static application = {
    appointmentStatus: 'appointmentStatus',
    duration: 'duration',
    frequency: 'frequency',
    nameSuffix: 'nameSuffix',
    namePrefix: 'namePrefix',
    patientSuffix: 'patientSuffix',
  };

  static vitalSigns = {
    bloodPressurePosition: 'bloodPressurePosition',
    bloodPressureLocation: 'bloodPressureLocation',
    oxygenSaturationTest: 'oxygenSaturationTest',
    oxygenUseType: 'oxygenUseType',
  };

  static scanDocuments = {
    associatedDocumentation: 'associatedDocumentation',
  };

  static tobaccoHistory = {
    tobaccoUseStatus: 'tobaccoUseStatus',
    tobaccoType: 'tobaccoType',
    tobaccoUse: 'tobaccoUse',
    duration: 'duration',
  };

  static drugHistory = {
    drugUseStatus: 'drugUseStatus',
    drugType: 'drugType',
    drugUse: 'drugUse',
    drugUseRoute: 'drugUseRoute',
  };

  static occupationalHistory = {
    occupation: 'occupation',
    employmentStatus: 'employmentStatus',
  };

  static educationHistory = {
    education: 'education',
  };

  static familyHistory = {
    familyMembers: 'familyMembers',
    familyStatus: 'familyStatus',
  };

  static medications = {
    medicationsAllergy: 'medicationsAllergy',
    medicationsUnits: 'medicationsUnits',
    medicationsRoute: 'medicationsRoute',
    medicationsDoseSchedule: 'medicationsDoseSchedule',
    medicationsStatus: 'medicationsStatus',
    medicationsForms: 'medicationsForms',
    medicationsDirections: 'medicationsDirections',
  };

  static alcoholHistory = {
    alcoholUseStatus: 'alcoholUseStatus',
    alcoholType: 'alcoholType',
    alcoholUse: 'alcoholUse',
  };

  static patienSearchtNotes = {
    patientNotesStatus: 'patientNotesStatus',
  };
}
