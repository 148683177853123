import { Output, EventEmitter, Component } from '@angular/core';
import * as $ from 'jquery';
import { ViewBase } from './view.base';
import { SystemUtility } from '../../utils/system-utility';
import { Messages } from '../../utils/messages';

@Component({
    template: ''
})

export abstract class PopupViewBase extends ViewBase {
    @Output() popupHiddenEvent = new EventEmitter<any>();

    public popupTitle: any;
    public isEditMode = false;
    public isViewMode = false;
    public isDataSaved = false;
    public popupVisible = false;
    public minWidth = 515;
    public minHeight = 253;
    protected isChangesDiscarded = false;

    constructor() {
        super();
    }

    public onPopupHidden(e: any) {
        this.setScrollDisplayStatus();
        this.popupHiddenEvent.emit();
    }

    protected showSavedSuccessMsg(closeTrowser: boolean): void {
        super.notifySuccessMsg(Messages.saveSucceededMsg);
        if (closeTrowser) {
            this.popupVisible = false;
        }
    }

    protected setScrollDisplayStatus() {
        const hasShownPopup = $('body div').hasClass('d-con-popup');
        hasShownPopup ? $('body').css({ 'overflow': 'hidden' }) : $('body').css({ 'overflow': 'auto' });
    }

    protected setFormFocus(formInstance: any, fieldName: string, filedInstance: any = null) {
        SystemUtility.setFormFocus(formInstance, fieldName, filedInstance);
    }
}
