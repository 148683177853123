import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PatientRichTextEditorComponent } from '../patient-rich-text-editor/patient-rich-text-editor.component';
import { PredefinedTemplateTypeNames } from 'src/app/_classes/predefinedTemplateTypeNames';
import { PatientChartTrackService } from '../../../../_services/patient-chart-track.service';
import { SelectableItemHtmlService } from 'src/app/_services/selectable-item-html.service';
import { PatientChartNode } from 'src/app/_models/patientChartNode';
import { PatientChartNodeType } from 'src/app/_models/patientChartNodeType';
import { TemplateHistoryService } from 'src/app/_services/template-history.service';
import { TemplateHistory } from 'src/app/_models/templateHistory';
import { TemplateHistorySearchFilter } from 'src/app/_models/templateHistorySearchFilter';
import { ExpressionExecutionService } from 'src/app/_services/expression-execution.service';
import { ExpressionExecutionRequest } from 'src/app/_models/expression-execution-request';
import { RegexRuleList } from 'src/app/_classes/regexRuleList';
import { DateHelper } from 'src/app/_helpers/date.helper';
import { AlertService } from 'src/app/_services/alert.service';
import { AllegationEditService } from 'src/app/_services/allegation-edit.service';
import { TextHelper } from 'src/app/_helpers/text.helper';
import { TemplateService } from '../../../../_services/template.service';
import { PatientChartComponent } from '../../../components/patient-chart/patient-chart.component';
import { SelectableListService } from '../../../../_services/selectable-list.service';
import { PatientChartNodePreviousValue } from 'src/app/_models/patientChartNodePreviousValue';

@Component({
  templateUrl: 'patient-chart-template.component.html',
  selector: 'patient-chart-template',
  styleUrls: ['acordionStyles.css'],
})
export class PatientChartTemplateComponent implements OnInit, OnDestroy {
  @Input() patientChartDocumentNode!: PatientChartNode;
  @Input() patientChartNode!: PatientChartNode;
  @Input() templateName!: string;
  @Input() templateType!: string;
  @Input() companyId!: string;
  @Input() isSignedOff!: boolean;
  @Input() admissionId!: string;
  consultativeExaminer: any;
  private regexRuleList: RegexRuleList = new RegexRuleList();
  rosTemplate = 'null';
  private _templateId: string = '';
  defaulttemplate: any;
  templateContentnew: any;
  templateHistoryData: any;
  startDate: any;
  endDate: any;
  flag: boolean = false;

  selectableListValues: any = [];
  selectableIteratorList: string[] = [];
  originalSelectableWords: string[] = [];
  templateSelectableWords: string[] = [];
  originalRosSelectableWords: string[] = [];
  rosSelectableWords: string[] = [];
  addedTemplates: any;
  @Input() set templateId(value: string) {
    this._templateId = value;
  }

  get templateId(): string {
    //we should check template Id correctness due to the invalid
    //template generation for patient chart template node
    let templateId = this._templateId;
    const isTemplateIdFixNeeded = this.regexRuleList.dollarSignPlusGuid.test(templateId);

    if (isTemplateIdFixNeeded) {
      const dollarSignIndex = this._templateId.indexOf('$');

      templateId = this._templateId.slice(dollarSignIndex + 1);
    }

    const isGuidValid = this.regexRuleList.guid.test(templateId);
    if (!isGuidValid)
      throw `Patient Chart Template --- templateId has wrong format --- ${templateId}`;

    return templateId;
  }

  selectedModeIndex = 0;

  private defaultTemplateModeIndex = 0;
  private detailedTemplateModeIndex = 1;

  templateModes = [
    {
      id: this.defaultTemplateModeIndex,
      text: 'Default',
    },
    {
      id: this.detailedTemplateModeIndex,
      text: 'Detailed',
    },
  ];

  onTemplateModeSelected(selectedMode: any) {
    this.isDetailedTemplate = selectedMode.id === this.detailedTemplateModeIndex;

    this.templateContent.isDetailedTemplateUsed = this.isDetailedTemplate;

    if (this.isDetailedTemplate) {
      this.calculateExpressions();
    }
  }

  // the property has to be used instead 'templateId' due to the issue of wrong setting of templateId
  get correctTemplateId(): string {
    //const patientChartNodeId = this.patientChartNode.id;
    //before fix, template id setting was wrong - templateId was equal to node id
    //if (patientChartNodeId !== this.templateId)
    //  return this.templateId;

    return this.patientChartNode.attributes.nodeSpecificAttributes.templateId;
  }

  @ViewChild('detailedRichTextEditor', { static: false })
  detailedRichTextEditor!: PatientRichTextEditorComponent;
  @ViewChild('defaultRichTextEditor', { static: false })
  defaultRichTextEditor!: PatientRichTextEditorComponent;
  datesTemplates: string[] = [];
  templateHistory: TemplateHistory[] = [];
  templateHistoryFilter: TemplateHistory[] = [];
  //value of new History Node flow (new flow by RAE)
  previousTemplateHistory: PatientChartNodePreviousValue[] = [];

  isDuplicateWordsWarningVisible = false;
  isPreviousTemplateContentVisible = false;

  isDetailedEditorReady = false;
  isDefaultEditorReady = false;

  previousDetailedContent = '';
  //this variable will take the current template value that will be inserted in the records (new flow by RAE)
  currentNodeHistoryData: PatientChartNodePreviousValue =
    new PatientChartNodePreviousValue();
  showButton = false;
  dataReady = false;
  color = false;
  doesTemplateHistoryExist() {
    this.setPreviousTemplateHistory();
    this.templateService.getById(this.templateId).then(response => {
      this.showButton = response.isHistorical.valueOf();
    });

  }

  templateContent: any = {
    defaultTemplateHtml: '',
    detailedTemplateHtml: '',
    isDetailedTemplateUsed: false,
    isDefault: false,
  };

  get templateResult(): string {
    if (!this.isDetailedTemplate) return this.templateContent.defaultTemplateHtml;

    return this.getFormattedDetailedTemplateContent(
      this.templateContent.detailedTemplateHtml
    );
  }

  get isDetailedTemplateShown(): boolean {
    return this.isDetailedTemplate;
  }

  get isDefaultTemplateShown(): boolean {
    return !this.isDetailedTemplate;
  }

  isDetailedTemplate: boolean = false;
  hasDefaultTemplate: boolean = true;

  constructor(
    private patientChartTrackService: PatientChartTrackService,
    private selectableItemHtmlService: SelectableItemHtmlService,
    private templateHistoryService: TemplateHistoryService,
    private alertService: AlertService,
    private expressionExecutionService: ExpressionExecutionService,
    private eventService: AllegationEditService,
    private templateService: TemplateService,
    private patientChart: PatientChartComponent,
    private selectableListService: SelectableListService
  ) { }
  ngOnDestroy(): void { }
  insertPreviousTemplateContent(element: any) {
    if (this.isDetailedTemplateShown) {
      this.templateContent.detailedTemplateHtml = `${'History of Present Illness: ' + element.content
        }${'--------------------------------------------------------------------'}
        ${this.templateContent.detailedTemplateHtml}`;
    } else
      this.templateContent.defaultTemplateHtml = `${element.content
        }${'--------------------------------------------------------------------'}${this.templateContent.defaultTemplateHtml
        }`;
    this.isPreviousTemplateContentVisible = false;
    //  } else {
    //If the previous template already exists, alert and avoid adding it into the chart
    //alert('The previous template has already beed added');
    //  }
  }
  replaceAllPreviousHistoryContent() {
    if (!this.addedTemplates) {
      this.addedTemplates = new Set();
    }

    const addContent = (currentContent, newContent) => {
      return newContent + '\n' + currentContent;
    };

    const getNewContent = () => {
      const checkedItems = this.previousTemplateHistory.filter(item => item.isRestore && !this.addedTemplates.has(item.template));
      if (checkedItems.length > 0) {
        return checkedItems.map(item => {
          this.addedTemplates.add(item.template);
          return item.template;
        }).join('\n');
      }
      return '';
    };

    if (this.isDetailedTemplateShown) {
      const newContent = getNewContent();
      if (newContent) {
        this.templateContent.detailedTemplateHtml = addContent(this.templateContent.detailedTemplateHtml, newContent);
      }
    } else {
      const newContent = getNewContent();
      if (newContent) {
        this.templateContent.defaultTemplateHtml = addContent(this.templateContent.defaultTemplateHtml, newContent);
      }
    }

    this.isPreviousTemplateContentVisible = false;
    this.templateContent.defaultTemplateHtml = this.callDuplicateWords(this.templateContent.defaultTemplateHtml);
  }


  replacePreviousHistoryContent(element: any) {
    if (this.isDetailedTemplateShown) {
      //if the detailed template is used in the editor, replace the content with it.
      this.templateContent.detailedTemplateHtml = element.template;
    }
    //if the detailed template is used in the editor, replace the content with it.
    else this.templateContent.defaultTemplateHtml = element.template;
    this.isPreviousTemplateContentVisible = false;
  }

  toggleDuplicateWordsWarning($event: any) {
    $event.preventDefault();

    this.isDuplicateWordsWarningVisible = !this.isDuplicateWordsWarningVisible;
  }

  togglePreviousDetailedContentPopover($event: any) {
    $event.preventDefault();
    this.isPreviousTemplateContentVisible = !this.isPreviousTemplateContentVisible;
    this.dataReady = true;
    this.consultativeExaminer = localStorage.getItem('consultativeExaminer');
    if (!this.consultativeExaminer) this.consultativeExaminer = '';
  }

  getRosTemplate() {
    const rosNode = this.patientChartDocumentNode.children?.find(
      item => item.name == 'ros'
    );
    if (rosNode && rosNode.children) {
      this.rosTemplate = rosNode.children[0].value?.isDetailedTemplateUsed
        ? rosNode.children[0].value.detailedTemplateHtml
        : rosNode.children[0].value.defaultTemplateHtml;

      this.originalRosSelectableWords = TextHelper.extractWordsFromHTML(this.rosTemplate);
      this.rosSelectableWords = this.originalRosSelectableWords;

    }
  }

  ngOnInit() {
    localStorage.setItem('templateChartName', this.patientChartNode.title);
    if (this.patientChartNode.title === 'General History of Present Illness') {
      this.getRosTemplate();
    }
    this.doesTemplateHistoryExist();

    this.obtainSelectableListValues();
    this.templateContent = this.patientChartNode.value;
    if (
      typeof this.templateContent?.detailedTemplateHtml === 'undefined' ||
      !this.templateContent?.detailedTemplateHtml
    ) {
      this.templateContent = this.templateContent || {};
      this.templateContent.detailedTemplateHtml = '';
    }

    if (
      typeof this.templateContent.defaultTemplateHtml == 'undefined' ||
      this.templateContent.defaultTemplateHtml == null
    ) {
      this.templateContent.defaultTemplateHtml = '';
    }

    this.defaulttemplate = this.templateContent.detailedTemplateHtml.replace(
      /<label /g,
      '&nbsp;<label /'
    );
    if (
      this.templateContent.defaultTemplateHtml &&
      !this.templateContent.isDetailedTemplateUsed
    ) {
      this.hasDefaultTemplate = true;
      this.isDetailedTemplate = false;
    } else {
      this.hasDefaultTemplate = !!this.templateContent.defaultTemplateHtml;
      this.calculateExpressions().then(() => (this.isDetailedTemplate = true));
    }
    //set the current template values and data to the template history new flow
    this.currentNodeHistoryData = {
      template: this.templateContent.detailedTemplateHtml.replace(
        /<label /g,
        '&nbsp;<label /'
      ),
      status: this.patientChartNode.attributes.nodeSpecificAttributes.editStatus
        ? this.patientChartNode.attributes.nodeSpecificAttributes.editStatus
        : 'UN',
      date: new Date()
    };
    this.templateService.setCurrentNodeData(this.currentNodeHistoryData);
    //give the history data to the array

    if (
      this.patientChartNode.nodeHistory &&
      this.patientChartNode.nodeHistory.length > 0
    ) {

      for (const previousNode of this.patientChartNode.nodeHistory) {
        //if the value is not null
        if (previousNode) {
          previousNode.template!.replace(/<label /g, '&nbsp;<label /');
          this.previousTemplateHistory.push(previousNode);
        }
      }
    } else this.previousTemplateHistory = [];
    this.previousTemplateHistory.forEach(item => {
      item.isRestore = false;
      // item.appointementStatus = 
    });
    // this.previousTemplateHistory = this.previousTemplateHistory.filter(tem => tem.status != 'DO')
  }

  toggleCheckbox(index: number) {
    if (index >= 0 && index < this.previousTemplateHistory.length) {
      this.previousTemplateHistory[index].isRestore = !this.previousTemplateHistory[index].isRestore;
    } else {
      console.error('Index out of range');
    }
  }

  savePatientChart() {
    const confirmationPopup = this.alertService.customConfirmation(
      "Multiple users of same chart may override data!",
      'Warning'
    );

    confirmationPopup.show().done(dialogResult => {
      if (dialogResult) {
        const isDefault =
          this.patientChartNode.value.isDefault == true ||
            this.patientChartNode.value.isDefault == undefined
            ? this.defaulttemplate === this.templateContent.detailedTemplateHtml
            : false;
        this.templateContent.isDefault = isDefault;
        this.setTemplateValue(this.templateContent);
        this.templateContent.detailedTemplateHtml = TextHelper.removeDoubleSpaces(
          this.templateContent.detailedTemplateHtml
        );
        this.templateContent.detailedTemplateHtml = TextHelper.applyFormatting(
          this.templateContent.detailedTemplateHtml
        );

        this.templateContent.defaultTemplateHtml = TextHelper.removeDoubleSpaces(
          this.templateContent.detailedTemplateHtml
        );
        this.templateContent.defaultTemplateHtml = TextHelper.applyFormatting(
          this.templateContent.detailedTemplateHtml
        );
        this.templateContent.defaultTemplateHtml =
          this.templateContent.defaultTemplateHtml.replace(/<label /g, '&nbsp;<label /');
        this.templateContent.detailedTemplateHtml =
          this.templateContent.detailedTemplateHtml.replace(/<label /g, '&nbsp;<label /');
        this.patientChartTrackService.emitPatientChartChanges(
          PatientChartNodeType.TemplateNode,
          this.patientChartNode
        );
        this.previousTemplateHistory.push(this.currentNodeHistoryData);
      }
    });
  }

  onDetailedContentChanged($event: any) {
    this.templateContent.detailedTemplateHtml = $event;
  }

  setTemplateValue(data: any) {
    this.eventService.setDataWithObj({
      method: 'setTemplateValue',
      data: {
        parentNode: this.patientChartDocumentNode.name,
        templateName: this.patientChartNode.title,
        templateId: this.patientChartNode.id,
        value: this.patientChartNode.value,
      },
    });
  }

  onDefaultContentChanged($event: any) {
    this.templateContent.defaultTemplateHtml = $event;
  }

  onDefaultContentReady($event: any) {
    this.isDefaultEditorReady = $event;
  }

  onDetailedContentReady($event: any) {
    this.isDetailedEditorReady = $event;
  }

  calculateExpressions() {
    const expressionExecutionRequest = new ExpressionExecutionRequest();

    expressionExecutionRequest.admissionId = this.admissionId;
    expressionExecutionRequest.detailedTemplateContent =
      this.templateContent.detailedTemplateHtml;

    return this.expressionExecutionService
      .calculateExpressionsInTemplate(expressionExecutionRequest)
      .then(detailedTemplateContent => {
        this.templateContent.detailedTemplateHtml = detailedTemplateContent.replace(
          /<label /g,
          '&nbsp;<label /'
        );
        // this.patientChartTrackService.emitPatientChartChanges(
        //   PatientChartNodeType.TemplateNode
        // );
      });
  }
  calculateExpressionsNew() {
    const expressionExecutionRequest = new ExpressionExecutionRequest();

    expressionExecutionRequest.admissionId = this.admissionId;
    expressionExecutionRequest.detailedTemplateContent =
      this.templateContent.detailedTemplateHtml;

    return this.expressionExecutionService
      .calculateExpressionsInTemplate(expressionExecutionRequest)
      .then(detailedTemplateContent => {
        this.templateContent.detailedTemplateHtml = detailedTemplateContent.replace(
          /<label /g,
          '&nbsp;<label /'
        );
        this.patientChartTrackService.emitPatientChartChanges(
          PatientChartNodeType.TemplateNode
        );
      });
  }

  get isRosTemplate(): boolean {
    return this.templateType === PredefinedTemplateTypeNames.ros;
  }

  private getFormattedDetailedTemplateContent(detailedTemplateHtml: any): string {
    return this.selectableItemHtmlService.wrapBoldTagAroundSelectableElementsValues(
      detailedTemplateHtml
    );
  }

  private setPreviousTemplateHistory() {
    const searchFilter = new TemplateHistorySearchFilter();
    searchFilter.admissionId = this.admissionId;
    searchFilter.templateId = this.correctTemplateId;
    searchFilter.documentId = this.patientChartDocumentNode.id;
    this.templateHistoryService.get(searchFilter).then(templateHistory => {
      this.templateHistoryData = templateHistory;
      this.templateHistoryData.forEach((element: any) => {
        if (element.content)
          element.content = this.formatPreviousTemplateContent(element);

        if (this.templateHistory.length > this.templateHistoryData.length - 1) {
        } else {
          this.templateHistory.push(element);
        }
      });
    });
    this.onSearchTemplates();
  }

  private formatPreviousTemplateContent(templateHistory: TemplateHistory) {
    const contentWithoutSelectableItemsAttributes =
      this.selectableItemHtmlService.removeSelectableItemsAttributes(
        templateHistory.content
      );

    const date = DateHelper.getDate(
      DateHelper.sqlServerUtcDateToLocalJsDate(
        templateHistory.createdDate.toString()
      )?.toString()
    );
    const dateHeader = ``;
    this.datesTemplates.push(date);
    return `${dateHeader}${contentWithoutSelectableItemsAttributes}`;
  }

  copyDefaultContent() {
    const cleanHtml = this.templateContent.defaultTemplateHtml
      .replace(/&[a-zA-Z]+;/g, ' ');
    this.templateContent.detailedTemplateHtml = cleanHtml;
  }

  obtainSelectableListValues() {
    const selectableListId = '97b3715a-be7c-e811-80c2-0003ff433cda';
    this.selectableListService.getById(selectableListId).then(selectableList => {
      this.selectableListValues = selectableList.selectableListValues;
      this.selectableIteratorList = this.selectableListValues.map(item => item.value);
    });
  }
  callDuplicateWords(content: string) {
    this.rosSelectableWords.push('Of');
    this.flag = false;
    const { duplicateWords, highlightedContent } = TextHelper.findDuplicateWords(
      content,
      this.rosSelectableWords
    );


    for (let i = 0; i < this.rosSelectableWords.length; i++) {
      for (let j = 0; j < duplicateWords.length; j++) {
        if (this.rosSelectableWords[i] === duplicateWords[j]) {
          this.rosSelectableWords[i] = '';
        }
      }
    }

    if (duplicateWords.length > 0) this.flag = true;

    return highlightedContent;
  }
  //restart the selectable list words
  restartSelectableList() {
    this.rosSelectableWords = [];
    this.rosSelectableWords = TextHelper.extractWordsFromHTML(this.rosTemplate);
  }

  onSearchTemplates() {
    return new Promise<void>((resolve, reject) => {
      try {
        this.searchTemplates();
        if (this.templateHistoryFilter?.length > 0) this.color = true;
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
  searchTemplates() {
    this.startDate = this.patientChart.from;
    this.endDate = this.patientChart.now;
    if (this.startDate && this.endDate) {
      this.templateHistoryFilter = this.templateHistoryData?.filter((item: any) => {
        const date = DateHelper.getDate(
          DateHelper.sqlServerUtcDateToLocalJsDate(
            item.createdDate.toString()
          )?.toString()
        );
        const newDate = new Date(date);
        return newDate >= this.startDate && newDate <= this.endDate;
      });
    } else this.templateHistoryFilter = this.templateHistory;
  }

  @HostListener('window:beforeunload', ['$event'])
  showUnsavedChangesWarningNotificationIfNeeded($event: any) { }
}
