import { NgModule } from '@angular/core';
import { AppointmentSchedulerComponent } from './components/appointment-scheduler/appointment-scheduler.component';
import { AppointmentsFilterComponent } from './components/appointments-filter/appointments-filter.component';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxListModule } from 'devextreme-angular/ui/list';
import { PatientLastVisitComponent } from './components/patient-last-visit/patient-last-visit.component';
import { ShareModule } from '../share/share.module';
import { CommonModule } from '@angular/common';
import { AppointmentAllegationsComponent } from './components/appointment-allegations/appointment-allegations.component';
import { DxPopoverModule } from 'devextreme-angular/ui/popover';
import { AppointmentAlertAervice } from './services/appointment-alert.service';
import { AppointmentsFilterService } from './services/appointments-filter.service';
import { DxHtmlEditorModule, DxSwitchModule } from 'devextreme-angular';
import { AppointmentInfoComponent } from './actions/appointment-info/appointment-info.component';
import { PreviousAppointmentsComponent } from './actions/previous-appointments/previous-appointments.component';
import { ReviewChartAdmissionComponent } from './actions/review-chart-admission/review-chart-admission.component';
import { PatientPreviousAdmissionsComponent } from './actions/patient-previous-admissions/patient-previous-admissions.component';
import { PatientAllegationsComponent } from './components/patient-allegations/patient-allegations.component';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    DxFormModule,
    DxSelectBoxModule,
    DxSchedulerModule,
    DxDataGridModule,
    ShareModule,
    DxPopupModule,
    DxPopoverModule,
    DxScrollViewModule,
    DxHtmlEditorModule,
    DxListModule,
    DxSwitchModule,
    AngularDualListBoxModule,
    FormsModule
  ],
  declarations: [
    AppointmentSchedulerComponent,
    AppointmentsFilterComponent,
    PatientLastVisitComponent,
    AppointmentAllegationsComponent,
    PatientAllegationsComponent,
    AppointmentInfoComponent,
    PreviousAppointmentsComponent,
    ReviewChartAdmissionComponent,
    PatientPreviousAdmissionsComponent
  ],
  providers: [AppointmentAlertAervice, AppointmentsFilterService],
})
export class SchedulerModule {}
