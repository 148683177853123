import { Component, OnInit, OnDestroy, ViewChild, HostListener, ComponentRef, ViewContainerRef, ComponentFactoryResolver, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { Appointment } from 'src/app/_models/appointment';
import { AlertService } from 'src/app/_services/alert.service';
import { Admission } from '../../models/admission';
import { PatientChartHeaderData } from '../../models/patientChartHeaderData';
import { PatientChartService } from '../../services/patient-chart.service';
import { SignatureInfoService } from '../../services/signature-info.service';
import { PatientChartTrackService } from '../../../_services/patient-chart-track.service';
import { AdmissionService } from '../../services/admission.service';
import { SignatureInfo } from '../../models/signatureInfo';
import { DateHelper } from 'src/app/_helpers/date.helper';
import { GuidHelper } from 'src/app/_helpers/guid.helper';
import { CompanyIdService } from 'src/app/_services/company-id.service';
import { DocumentService } from '../../patient-chart-tree/services/document.service';
import { PatientChartInfo } from '../../models/patientChartInfo';
import { PatientChartNode } from 'src/app/_models/patientChartNode';
import { PatientChartNodeManagementService } from '../../services/patient-chart-node-management.service';
import { WindowService } from 'src/app/_services/window.service';
import { PatientChartNodeType } from 'src/app/_models/patientChartNodeType';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { ProceedUnsavedChangesActionTypes } from 'src/app/_classes/proceed-unsaved-changes-action-types.enum';
import { DxDataUrlService } from 'src/app/_services/dxDataUrl.service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { ApiBaseUrls } from 'src/app/_models/apiBaseUrls';
import { DevextremeAuthService } from 'src/app/_services/devextreme-auth.service';
import { LookupModel } from 'src/app/_models/lookupModel';
import { DocumentListComponent } from '../document-list/document-list.component';
import { UpdatePatientChartDocumentNodesModel } from '../../models/updatePatientChartDocumentNodesModel';
import { SelectedPatientChartNodeService } from 'src/app/_services/selected-patient-chart-node.service';
import { PatientChartEqualityComparer } from '../../services/patient-chart-equality-comparer.service';
import { SelectableListsNames } from 'src/app/_classes/selectableListsNames';
import { SelectableListService } from 'src/app/_services/selectable-list.service';
import { SelectableListConfig } from 'src/app/_models/selectableListConfig';
import { LibrarySelectableListIds } from 'src/app/_classes/librarySelectableListIds';
import { RepositoryService } from 'src/app/_services/repository.service';
import { ErrorHandlerService } from 'src/app/_services/error-handler.service';
import { AppointmentStatus } from 'src/app/_classes/apptStatus';
import { patientChartTree } from 'src/app/administration/components/patientChartManagement/patient-chart-management.component';
import { EditStatusService } from 'src/app/patientChart/services/edit-status.service';
import {
  AllegationEditService,
  eventData,
} from 'src/app/_services/allegation-edit.service';
import { LookupStateModel } from 'src/app/_models/lookupStateModel';
import { PatientDataModelNode } from '../../classes/patientDataModelNode';
import { AuditManagementService } from 'src/app/administration/components/audit-management/audit-management.service';
import { ChartColor } from '../../models/chartColor';
//import { PatientChartAuditReportComponent } from '../patient-chart-audit-report/patient-chart-audit-report.component';
import { TemplateService } from 'src/app/_services/template.service';
import { PatientChartNodePreviousValue } from 'src/app/_models/patientChartNodePreviousValue';
import { Patient } from 'src/app/patients/models/patient';
import { PatientInsurance } from 'src/app/patients/models/patientInsurance';
import { DxFormComponent } from 'devextreme-angular';
import { Gender } from 'src/app/_classes/gender';
import { MaritalStatus } from 'src/app/patients/classes/maritalStatus';
import { ZipCodeTypeList } from 'src/app/_classes/zipCodeTypeList';
import { MaskList } from 'src/app/_classes/maskList';
import { PatientCommunicationMethodList } from 'src/app/_classes/patientCommunicationMethodList';
import { RegexRuleList } from 'src/app/_classes/regexRuleList';
import { StateList } from 'src/app/_classes/stateList';
import { ZipCodeType } from 'src/app/patients/models/zipCodeType';
import { PatientService } from 'src/app/_services/patient.service';
import { PatientInsuranceService } from 'src/app/_services/patient-insurance.service';
import { EmployeeTypeList } from 'src/app/administration/classes/employeeTypeList';
import { PatientChartReportHeaderService } from '../../services/patient-chart-report-header.service';
import { ColorHelper } from 'src/app/_helpers/color.helper';
import { TreeService } from '../../services/tree.service';
import { DemographicsComponent } from './components/demographics/demographics.component';
import { ComponentHelper } from 'src/app/shared/utils/helpers/component.helper';
import { AppointmentGridItem } from 'src/app/scheduler/models/appointmentGridItem';
import { AppointmentStatusColorManagementService } from 'src/app/administration/components/appointment-status-color-management/appointment-status-color-management.service';
type PatientDataTab = {
  id: number;
  title: string;
  template: string;
};

@Component({
  selector: 'patient-chart',
  templateUrl: './patient-chart.component.html',
  styleUrls: ['patient-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PatientChartComponent implements OnInit, OnDestroy {
  @ViewChild('treeView', { static: false })
  treeView!: DxTreeViewComponent;
  @ViewChild('documentList', { static: false })
  documentList!: DocumentListComponent;
  @ViewChild('componentContainer', { static: false, read: ViewContainerRef }) componentContainer: ViewContainerRef;
  public addDemographicsComponentRef: ComponentRef<DemographicsComponent>;
  companyId: string = GuidHelper.emptyGuid;
  companyIdSubscription?: Subscription;
  isAdmissionSignedOff: boolean = true;
  appointment?: Appointment;
  admission?: Admission;
  routeParamsSubscription?: Subscription;
  patientChartChangesSubscription?: Subscription;
  patientAssessmentChangesSubscription?: Subscription;
  emitNeedSavingSubscription?: Subscription;
  emitDocumentSave: Subject<LookupStateModel> = new Subject<LookupStateModel>();
  patientChartProjectionTree: any[] = [];
  patientChartRootNode?: PatientChartNode;
  selectedPatientChartNode?: PatientChartNode;
  public isPatientChartReview: boolean = false;
  patientChartNodeReview!: PatientChartNode;
  patientChartDocumentNodeReview!: PatientChartNode;
  appointmentIdReview = '';
  patientIdReview = '';
  admissionIdReview = '';
  companyIdReview = '';
  previousVisitsNode?: PatientChartNode;
  patientChartInfo?: PatientChartInfo;
  patientChartHeaderData?: PatientChartHeaderData;
  isPatientChartVisible = true;
  public reportTreeNodes: any;
  isPatientChartPreAuth = true;
  isPatientAuditChart = true;
  selectedNodeId = '';
  selectedPatientChartName: string = '';
  savedVersionOfAdmissionData?: string;
  isDocumentNodesManagementPopupVisible = false;
  isPreviousPatientChartsPopupVisible = false;
  patientChartDocumentDataSource: any = {};
  patientChartDocumentNodeIds: string[] = [];
  documentNodes: LookupModel[] = [];
  physicianViewer = false;
  labOrderViewer = false;
  timelineViewer = false;
  messageViewer = false;
  careTeamViewer = false;
  now: Date = new Date();
  from: Date = new Date();
  currentDate: Date = new Date();
  quantity = 10;
  sortDirection = 'Ascending';
  dateDirection = '90d';
  sortDates = ['180d', '150d', '120d', '90d', '60d', '30d'];
  sortItems = ['Ascending', 'Descending'];
  from2?: Date = undefined;
  now1?: Date = undefined;
  previousVisitslength = 0;
  isNewChart = true;
  isStatusPopupVisible = false;
  appointmentsDataSource: any = {};
  statusForm: any = { sendEmail: true };
  statusList: any[] = [];
  dischargeInstructionsList: string[] = [];
  areSelectableListsInitialized = false;
  public fromNav = true;
  parsedData: any;
  patientEmail = '';
  alligationset: any;
  isHpiNameisAlligationName = false;
  UpdateAlligationName: any;
  patientNotesViewer?: boolean;
  originalStatementOfExamination = '';
  patientChartNodeType?: PatientChartNodeType;
  templateValue: any;
  appointmentId: any;
  showPatientAdmissionInfo = false;
  admissionSaved: boolean = false;
  companyIdSaved: boolean = false;
  patientChartTreeViewSaved: boolean = false;
  showPatientChartLeftColumn: boolean = true;
  isPatientChartView: boolean = false;
  isEditStatusSaved: boolean = true;
  doesUserWishToSave: boolean = true;
  cancelRouteChange: boolean = false;
  chartColors: ChartColor = new ChartColor();
  allColors: any = {};
  isChartColorsSaved = false;
  treeViewNodeStyles: { [key: string]: { [klass: string]: any } } = {};
  statusColors: any = {
    noContentChanged: '#CCCCCC',
    updated: '#00FF00',
    defaultOrIncomplete: '#FFA500',
    abnormal: '#FF0000',
  };
  colorHelper: ColorHelper = new ColorHelper();
  categories = [
    { color: 'noContentChanged', label: 'No Content Change' },
    { color: 'updated', label: 'Content Updated' },
    { color: 'defaultOrIncomplete', label: 'Default Or Incomplete' },
    { color: 'abnormal', label: 'Abnormal Values' },
  ];
  newChart: boolean = true;
  switchView: boolean = true;
  //take the count of the updated nodes
  updatedSections: Array<string | undefined> = [];

  //demographics-insurance-forms
  isPatientPopupOpened = false;
  @ViewChild('patientForm', { static: false })
  patientForm!: DxFormComponent;
  @ViewChild('insuranceForm', { static: false })
  insuranceForm!: DxFormComponent;
  patientTab: PatientDataTab = {
    id: 1,
    title: 'Patient',
    template: 'patient',
  };
  patientInsuranceTab: PatientDataTab = {
    id: 2,
    title: 'Insurance',
    template: 'insurance',
  };
  patientDataTabs: Array<PatientDataTab> = [];
  patient: Patient;
  insurance: PatientInsurance;
  maxDate: any = new Date();
  gender: any[] = Gender.values;
  maritalStatus: any[] = MaritalStatus.values;
  zipCodeTypes: any[] = ZipCodeTypeList.values;
  validationMasks: MaskList = new MaskList();
  isCopyActionExecuting = false;
  patientCommunicationMethod: any[] = PatientCommunicationMethodList.values;
  regexRuleList: RegexRuleList = new RegexRuleList();
  states: any[] = StateList.values;
  public isNewInsurance = true;
  providersDataSource: any = {};
  masDataSource: any = {};
  serviceType?: number;
  insuranceCompanies: Array<any> = [];
  currentTabNumber = 0;
  get patientSuffixListValues(): string[] {
    return ['None', 'II', 'III', 'Sr', 'Jr'];
  }
  get zipMask(): string {
    switch (this.patient.zipCodeType) {
      case ZipCodeType.FiveDigit:
        return this.validationMasks.fiveDigitZip;
      default:
        return this.validationMasks.nineDigitZip;
    }
  }
  get zipMaskInsurance(): string {
    switch (this.insurance.zipCodeType) {
      case ZipCodeType.FiveDigit:
        return this.validationMasks.fiveDigitZip;
      default:
        return this.validationMasks.nineDigitZip;
    }
  }
  get genderString(): string {
    if (!this.patientInfo.gender) return '';

    return this.gender.find(g => g.value === this.patientInfo.gender).name;
  }
  allAppointmentStatusColors: any;
  isHovered = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private repositoryService: RepositoryService,
    private errorHandler: ErrorHandlerService,
    private route: ActivatedRoute,
    private appointmentService: AppointmentService,
    private alertService: AlertService,
    private patienChartService: PatientChartService,
    private signatureInfoService: SignatureInfoService,
    private patientChartTrackService: PatientChartTrackService,
    private admissionService: AdmissionService,
    private documentService: DocumentService,
    private router: Router,
    private allegationEditService: AllegationEditService,
    private companyIdService: CompanyIdService,
    private patientChartNodeManagementService: PatientChartNodeManagementService,
    private windowService: WindowService,
    private dxDataUrlService: DxDataUrlService,
    private devextremeAuthService: DevextremeAuthService,
    private patientChartEqualityComparer: PatientChartEqualityComparer,
    private selectableListService: SelectableListService,
    private selectedPatientChartNodeService: SelectedPatientChartNodeService,
    private editStatusService: EditStatusService,
    private auditManagementService: AuditManagementService,
    private templateService: TemplateService,
    private patientService: PatientService,
    private appointmentStatusColorManagementService: AppointmentStatusColorManagementService,
    private headerService: PatientChartReportHeaderService,
    private treeService: TreeService,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.insurance = new PatientInsurance();
    this.patient = new Patient();
  }

  openDocumentNodesManagementPopup() {
    this.isDocumentNodesManagementPopupVisible = true;
  }

  openStatusPopup() {
    this.isStatusPopupVisible = true;
  }

  chartViewer = false;
  auditReportViewer = false;
  private resetViewers() {
    this.isPatientChartView = false;
    this.physicianViewer = false;
    this.labOrderViewer = false;
    this.timelineViewer = false;
    this.chartViewer = false;
    this.patientNotesViewer = false;
    this.messageViewer = false;
    this.auditReportViewer = false;
    this.careTeamViewer = false;
  }

  showViewer(viewer: string) {
    this.resetViewers();

    switch (viewer) {
      case 'physician':
        this.physicianViewer = true;
        break;
      case 'labOrder':
        this.labOrderViewer = true;
        break;
      case 'patientNotes':
        this.patientNotesViewer = true;
        break;
      case 'message':
        this.messageViewer = true;
        break;
      case 'timeline':
        this.timelineViewer = true;
        break;
      case 'auditReport':
        this.auditReportViewer = true;
        break;
      case 'careTeam':
        this.careTeamViewer = true;
        break;
    }
  }

  showPhysicianViewer() {
    this.showViewer('physician');
  }

  showLabOrderViewer() {
    this.showViewer('labOrder');
  }

  showPatientNotesViewer() {
    this.showViewer('patientNotes');
  }

  showPatientMessageViewer() {
    this.showViewer('message');
  }

  showTimeline() {
    this.showViewer('timeline');
  }

  showAuditReportViewer() {
    this.showViewer('auditReport');
  }
  showCareTeamViewer() {
    this.showViewer('careTeam');
  }

  signOff(isUnsigned: boolean): void {
    const message = !isUnsigned
      ? 'Are you sure you want to Sign Off patient chart?'
      : 'Are you sure you want to Unsign patient chart ?';
    this.alertService.confirm(message, 'Signed Off Confirmation').then(result => {
      if (result) {
        const signatureInfo = new SignatureInfo();
        const currentDate: any = new Date();

        if (!this.admission) return;
        const admissionId = this.admission.id;

        const signDate = DateHelper.jsLocalDateToSqlServerUtc(currentDate);

        signatureInfo.signDate = signDate;
        signatureInfo.admissionId = admissionId;
        signatureInfo.isUnsigned = isUnsigned;

        this.appointmentService
          .getById(this.admission.appointmentId)
          .then(appointment => {
            if (appointment.physicianId)
              signatureInfo.physicianId = appointment.physicianId;

            // check existing
            this.signatureInfoService
              .getSignature(admissionId)
              .then((existingInfo: SignatureInfo) => {
                if (existingInfo != null) {
                  signatureInfo.id = existingInfo.id;
                }
                this.signatureInfoService
                  .save(signatureInfo)
                  .then(() => {
                    this.isAdmissionSignedOff = !isUnsigned;

                    this.patientChartProjectionTree = [];
                    if (this.previousVisitsNode) {
                      this.patientChartProjectionTree.push(
                        this.patienChartService.getPatientChartTreeProjection(
                          this.previousVisitsNode,
                          this.isAdmissionSignedOff,
                          true
                        )
                      );
                    }
                    this.patientChartRootNode = this.updateHpiName(
                      this.patientChartRootNode
                    );
                    if (this.patientChartRootNode) {
                      this.patientChartProjectionTree.push(
                        this.patienChartService.getPatientChartTreeProjection(
                          this.patientChartRootNode,
                          true,
                          false
                        )
                      );
                      if (this.selectedPatientChartNode) {
                        const patientChartDocumentNode =
                          this.patientChartNodeManagementService.getDocumentNodeRelatedToInnerNode(
                            this.patientChartRootNode,
                            this.selectedPatientChartNode.id
                          );
                        this.onPatientChartNodeSelected(
                          this.selectedPatientChartNode,
                          patientChartDocumentNode
                        );
                      }
                      this.savePatientAdmission();
                    }

                    const successMsg = !isUnsigned
                      ? 'Document was signed off successfully'
                      : 'Document was unsigned successfully';
                    this.alertService.info(successMsg);
                  })
                  .catch(error =>
                    this.alertService.error(error.message ? error.message : error)
                  );
              });
          })
          .catch(error => this.alertService.error(error.message ? error.message : error));
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  showUnsavedChangesWarningNotificationIfNeeded($event: any) {
    if (
      this.patientChartEqualityComparer.doesPatientChartHaveUnsavedChanges(
        this.patientChartRootNode,
        this.savedVersionOfAdmissionData
      )
    )
      $event.returnValue = 'Patient Chart has unsaved changes';
  }

  showUnsavedChangesWarningNotification(): Promise<ProceedUnsavedChangesActionTypes> {
    const myDialog = custom({
      title: 'Warning',
      messageHtml: `Unsaved patient chart changes will be lost${this.updatedSections.length > 0 ? `: ${this.updatedSections.join(', ')}` : ''
        }`,
      buttons: [
        {
          text: 'Save and Proceed',
          onClick: () => {
            return ProceedUnsavedChangesActionTypes.SaveAndProceed;
          },
        },
        {
          text: 'Cancel',
          onClick: () => {
            return ProceedUnsavedChangesActionTypes.Cancel;
          },
        },
        {
          text: "Don't save",
          onClick: () => {
            return ProceedUnsavedChangesActionTypes.DoNotSave;
          },
        },
      ],
    });

    return myDialog.show();
  }

  async ngOnInit(): Promise<void> {
    this.from = new Date();
    if (this.dateDirection === '180d') {
      this.from.setMonth(this.now.getMonth() - 6);
    }
    if (this.dateDirection === '150d') {
      this.from.setMonth(this.now.getMonth() - 5);
    }
    if (this.dateDirection === '120d') {
      this.from.setMonth(this.now.getMonth() - 4);
    }
    if (this.dateDirection === '90d') {
      this.from.setMonth(this.now.getMonth() - 3);
    }
    if (this.dateDirection === '60d') {
      this.from.setMonth(this.now.getMonth() - 2);
    }
    if (this.dateDirection === '30d') {
      this.from.setMonth(this.now.getMonth() - 1);
    }
    this.initProvidersDataSource();
    this.initMasDataSource();

    this.subscribeToCompanyIdChanges();
    this.subscribeToRouteParamsChanges();
    this.subscribeToPatientChartChanges();
    this.initPatientChartDocumentDataSource();
    this.getAppointmentStatusColor();
    this.allegationEditService.getEventWithObj.subscribe((event: eventData) => {
      if (event.method == 'setTemplateValue') {
        this.templateValue = event.data;
      }
      if (event.method == 'setHpiUpdateFlag') {
        this.isHpiNameisAlligationName = event.data;
      }

      if (event.method == 'updatePatientAllegationSet') {
        this.UpdateAlligationName = event.data;
      }
    });

    this.repositoryService.emitCareTeamChange.subscribe((data: any) => {
      this.updateCareTeamStatus();
    });
    const user = JSON.parse(localStorage.getItem('Medico.CurrentUser') || 'null');
    if (user.email == 'superadmin@mail.com') {
      this.allColors = await this.auditManagementService.getAllColors();
      this.auditManagementService.setDBColors(this.allColors);
    }

    this.emitNeedSavingSubscription = this.editStatusService.emitNeedSaving.subscribe(
      save => {
        if (save) {
          this.patientChartRootNode = save;
          this.savePatientAdmission().then(() => {
            this.savedVersionOfAdmissionData = this.admission?.admissionData;
            this.isEditStatusSaved = true;
            if (
              this.selectedPatientChartNode &&
              this.editStatusService.getIsEditStatusSet()
            ) {
              const [fillColor, borderColor] = this.chartColors.getColorsForSpecificNode(
                this.selectedPatientChartNode.attributes.nodeSpecificAttributes?.editStatus ?? 'DO',
                this.selectedPatientChartNode.attributes.auditRequired ?? '',
                this.selectedPatientChartNode.name,
                this.allColors
              );

              this.treeViewNodeStyles[this.selectedPatientChartNode.id][
                'background-color'
              ] = fillColor;
              this.treeViewNodeStyles[this.selectedPatientChartNode.id]['border-color'] =
                borderColor;
            }
            this.tryInitAudit();
          });
        }
      }
    );

    this.patientAssessmentChangesSubscription =
      this.patientChartTrackService.assessmentDataChanged.subscribe(data => {
        if (data.nodeID > 0) {
          const patientChartNodeType = data.nodeID;
          this.patientChartNodeType = patientChartNodeType;
          if (!this.fromNav) {
            if (data.data) {
              this.setAssessmentData(data.data);
            }
            this.savePatientAdmission()
              .then(() => {
                this.savedVersionOfAdmissionData = this.admission?.admissionData;
                const patientChartTreeSnapshot = this.patientChartProjectionTree;
                if (patientChartNodeType === PatientChartNodeType.ScanDocumentNode) {
                  if (this.appointment?.patientId) {
                    this.documentService
                      .getByPatientId(this.appointment?.patientId)
                      .then(patientScanDocumentsInfo => {
                        if (this.patientChartRootNode) {
                          this.patienChartService.addScanDocumentsToPatientChartNodes(
                            this.patientChartRootNode,
                            patientScanDocumentsInfo
                          );
                        }
                        this.updatePatientChartTreeView(patientChartTreeSnapshot);
                      });
                  }
                }
                this.updatePatientChartTreeView(patientChartTreeSnapshot);

                const patientChartHeaderUpdateNeeded =
                  patientChartNodeType === PatientChartNodeType.AllergiesNode ||
                  patientChartNodeType === PatientChartNodeType.VitalSignsNode;

                if (patientChartHeaderUpdateNeeded) {
                  this.setPatientChartHeaderData();
                }
                this.alertService.notifyMsg("Patient chart was saved successfully", "success");
              })
              .catch(error =>
                this.alertService.error(error.message ? error.message : error)
              );
          } else {
            this.fromNav = false;
          }
        }
      });
    this.auditManagementService.getColors('default').then(colors => {
      this.chartColors.setAll(colors);
      this.isChartColorsSaved = true;
      this.tryInitAudit();
    });

    this.editStatusService.onPatientChartInit();
  }

  private getAppointmentStatusColor() {
    this.appointmentStatusColorManagementService.load().then(colors => {
      this.allAppointmentStatusColors = {};
      colors.map((colorData: any) => {
        const status = colorData.status;
        const color = colorData.color;
        this.allAppointmentStatusColors[status] = color;
      });
    });
  }

  getAppointmentStatusBgColor(status: string) {
    if (!this.allAppointmentStatusColors) return null;
    return Object.prototype.hasOwnProperty.call(this.allAppointmentStatusColors, status)
      ? this.allAppointmentStatusColors[status]
      : null;
  }

  getButtonStyles(status: string) {
    const statusColor = this.getAppointmentStatusBgColor(status);
    return {
      'background-color': 'transparent',
      'border-color': statusColor === null ? '#82ad38' : statusColor,
      'color': statusColor === null ? '#82ad38' : statusColor
    };
  }

  getButtonHoverStyles(status: string) {
    const statusColor = this.getAppointmentStatusBgColor(status);
    return {
      'background-color': statusColor === null ? '#82ad38' : statusColor,
      'color': 'white',
      'border-color': statusColor === null ? '#82ad38' : statusColor
    };
  }


  private tryInitAudit(): void {
    if (
      this.admissionSaved &&
      this.companyIdSaved &&
      this.patientChartTreeViewSaved &&
      this.isEditStatusSaved &&
      this.isChartColorsSaved
    ) {
      this.chartViewer = false;
      this.hideOthers();
      this.isPatientChartView = true;
    }
  }

  verifyIsNewChart(patientChartRootNode: PatientChartNode): void {
    if (!this.newChart) return;
    const node = patientChartRootNode;
    if (node.attributes.nodeSpecificAttributes?.editStatus != null) {
      if (
        node.attributes.nodeSpecificAttributes?.editStatus !== 'DO' &&
        node.attributes.nodeSpecificAttributes?.editStatus !== 'NC'
      ) {
        this.newChart = false;
        return;
      }
    }
    if (node.children) {
      for (const aux of node.children) {
        this.verifyIsNewChart(aux);
      }
    }
  }

  showReason(): string {
    if (this.selectedPatientChartNode)
      if (
        this.selectedPatientChartNode.attributes.nodeSpecificAttributes[
        'editStatusReason'
        ]
      )
        return this.selectedPatientChartNode.attributes.nodeSpecificAttributes[
          'editStatusReason'
        ];
    return '';
  }

  private initSelectableLists(companyId: string): void {
    if (this.areSelectableListsInitialized) this.areSelectableListsInitialized = false;

    const appointmentStatusListConfig = new SelectableListConfig(
      companyId,
      SelectableListsNames.application.appointmentStatus,
      LibrarySelectableListIds.application.appointmentStatus
    );

    this.selectableListService
      .setSelectableListsValuesToComponent([appointmentStatusListConfig], this)
      .then(() => {
        this.areSelectableListsInitialized = true;
        this.statusList = this.selectableListService.getSelectableListValuesFromComponent(
          this,
          SelectableListsNames.application.appointmentStatus
        );
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  ngOnDestroy(): void {
    this.editStatusService.onPatientChartDestroyed();

    this.routeParamsSubscription?.unsubscribe();
    this.patientChartChangesSubscription?.unsubscribe();
    this.companyIdSubscription?.unsubscribe();
    this.patientAssessmentChangesSubscription?.unsubscribe();
    this.emitNeedSavingSubscription?.unsubscribe();
    if (!this.appointment?.isVisited) {
      this.updateNodesStatusWhenVisited();
      this.sendChartOpenedNotification();
    }
    this.callEvaluateAuditStatus();
    this.appointmentService.setCurrentAppointmentData(null);
  }
  async callEvaluateAuditStatus() {
    debugger;
    const incomplete = this.evaluateAuditStatus(this.patientChartRootNode);
    this.patientChartRootNode!.chartAuditStatus = incomplete ? 'Incomplete' : 'Complete';
    this.admission!.admissionData = JSON.stringify(this.patientChartRootNode);
    await this.admissionService.save(this.admission!);
    //add the audit status property to the appointment too
    this.appointment!.chartAuditStatus = incomplete ? 'Incomplete' : 'Complete';
    await this.appointmentService.save(this.appointment!);

  }
  //if the chart is visited for first time, update the status values from DO(Default) to UC(Unchanged)
  async updateNodesStatusWhenVisited(): Promise<any> {
    if (this.patientChartRootNode) {
      for (let j = 0; j < this.patientChartRootNode.children!.length; j++) {
        const mainNode = this.patientChartRootNode.children![j];
        if (mainNode) {
          if (mainNode.children && mainNode.children.length > 0) {
            for (let i = 0; i < mainNode.children.length; i++) {
              const childNodeAttributes = mainNode.children[i].attributes?.nodeSpecificAttributes;

              if (
                childNodeAttributes?.auditStatus === 'DO' ||
                childNodeAttributes?.auditStatus == null
              ) {
                mainNode.children[i].attributes.nodeSpecificAttributes = {
                  ...childNodeAttributes,
                  auditStatus: 'UC'
                };
              }
            }
          } else {
            const mainNodeAttributes = mainNode.attributes?.nodeSpecificAttributes;

            if (
              mainNodeAttributes?.auditStatus === 'DO' ||
              mainNodeAttributes?.auditStatus == null
            ) {
              mainNode.attributes.nodeSpecificAttributes = {
                ...mainNodeAttributes,
                auditStatus: 'UC'
              };
            }
          }

          this.patientChartRootNode.children![j] = mainNode;
        }
      }
    }
    this.admission!.admissionData = JSON.stringify(this.patientChartRootNode);
    //replace if some node of the method fails
    this.admission!.admissionData = this.admission!.admissionData.replace(
      /"auditStatus":"DO"/g,
      '"auditStatus":"UC"'
    );

    await this.admissionService.save(this.admission!);
    //mark the appointment as visited
    this.appointment!.isVisited = true;
    await this.appointmentService.save(this.appointment!);

  }
  onReportHidden() {
    this.showPatientChartLeftColumn = true;
    this.isPatientChartVisible = true;
    this.isPatientChartPreAuth = true;
    this.isPatientAuditChart = true;
    this.isPatientChartView = true;
  }

  makeReport() {
    this.reportTreeNodes = this.patientChartProjectionTree.slice(1);
    this.isPatientChartVisible = false;
  }

  preAuth() {
    this.isPatientChartPreAuth = false;
  }

  auditChart() {
    this.isPatientChartView = false;
    this.isPatientAuditChart = false;
  }

  hideOthers() {
    this.physicianViewer = false;
    this.labOrderViewer = false;
    this.timelineViewer = false;
    this.patientNotesViewer = false;
    this.messageViewer = false;
    this.isPatientChartView = false;
    this.auditReportViewer = false;
    this.careTeamViewer = false;
  }


  selectPatientChartNode($event: any): Promise<void> {
    localStorage.setItem('chartAdmissionId', this.admission?.id);
    this.selectedPatientChartName = $event.itemData.text;
    if (this.selectedPatientChartName === 'Patient Chart') {
      this.showAuditReportViewer();
      return;
    }
    this.chartViewer = true;
    this.hideOthers();
    this.fromNav = true;
    setTimeout(() => {
      this.fromNav = false;
    }, 2000);
    $event.itemData.selected = true;

    const nodeId = $event.itemData.id;
    if (nodeId.indexOf('patient_main') !== -1) {
      switch (true) {
        case nodeId.includes('physicianViewer'):
          this.showPhysicianViewer();
          break;
        case nodeId.includes('orders'):
          this.showLabOrderViewer();
          break;
        case nodeId.includes('notes'):
          this.showPatientNotesViewer();
          break;
        case nodeId.includes('messages'):
          this.showPatientMessageViewer();
          break;
        case nodeId.includes('auditReport'):
          this.showAuditReportViewer();
          break;
      }
    }
    this.selectedNodeId = nodeId;
    const selectedPatientChartNode = this.patientChartNodeManagementService.getById(
      nodeId,
      this.patientChartRootNode
    );

    if (!selectedPatientChartNode) {
      this.admissionSaved = false;
      this.patientChartTreeViewSaved = false;
      const selectedPreviousVisitsChartNode =
        this.patientChartNodeManagementService.getById(nodeId, this.previousVisitsNode);

      if (!selectedPreviousVisitsChartNode) {
        throw `Patient chart node with id: ${nodeId} was not found`;
      }

      this.checkForAndSaveChanges();
      if (!this.cancelRouteChange) {
        this.onPatientChartNodeSelected(selectedPreviousVisitsChartNode, undefined);
        this.moveToTopIfScrollExists();
      }
      this.cancelRouteChange = false;

      return;
    }

    const patientChartDocumentNode =
      this.patientChartNodeManagementService.getDocumentNodeRelatedToInnerNode(
        this.patientChartRootNode,
        selectedPatientChartNode.id
      );
    if (!patientChartDocumentNode)
      throw `Unable to find root document node for child node with id: ${nodeId}`;

    if (!selectedPatientChartNode)
      throw `Patient chart node with id: ${nodeId} was not found`;
    this.onPatientChartNodeSelected(selectedPatientChartNode, patientChartDocumentNode);
    this.moveToTopIfScrollExists();
  }

  savePatientAdmission(): Promise<any> {
    this.appointmentId = this.activatedRoute.snapshot.params['appointmentId'] || '';
    if (this.admission && this.admission?.appointmentId == this.appointmentId) {
      if (this.templateValue) {
        this.patientChartRootNode = this.updateTemplateValue(this.templateValue);
        this.sendTemplateChangedNotification(this.appointment?.mrn);
      }
      this.updateNodeHistory(this.patientChartRootNode!);
      this.admission.admissionData = JSON.stringify(this.patientChartRootNode);
      if (this.patienChartService.previousVisitChart) this.savePreviousVisitAdmission();
      if (this.selectedPatientChartNode)
        //add the template name to the list of updated templates

        this.updatedSections.push(this.selectedPatientChartNode?.title);
      const incomplete = this.evaluateAuditStatus(this.patientChartRootNode);
      this.patientChartRootNode!.chartAuditStatus = incomplete
        ? 'Incomplete'
        : 'Complete';
      return this.admissionService.save(this.admission);
    }
  }

  savePreviousVisitAdmission() {
    const updatedPreviousChartNode = this.updatePreviousVisitTemplateValue(
      this.templateValue
    );
    this.patienChartService.previousVisitAdmission.admissionData = JSON.stringify(
      updatedPreviousChartNode
    );
    return this.admissionService.save(this.patienChartService.previousVisitAdmission);
  }

  async checkForAndSaveChanges(): Promise<void> {
    this.cancelRouteChange = false;
    this.doesUserWishToSave = true;
    if (
      this.patientChartEqualityComparer.doesPatientChartHaveUnsavedChanges(
        this.patientChartRootNode,
        this.savedVersionOfAdmissionData
      )
    ) {
      await this.showUnsavedChangesWarningNotification().then(
        async proceedUnsavedChangesActionType => {
          if (
            proceedUnsavedChangesActionType === ProceedUnsavedChangesActionTypes.Cancel
          ) {
            this.doesUserWishToSave = true;
            this.cancelRouteChange = true;
          } else if (
            proceedUnsavedChangesActionType === ProceedUnsavedChangesActionTypes.DoNotSave
          ) {
            this.doesUserWishToSave = false;
            this.cancelRouteChange = false;
          } else {
            this.doesUserWishToSave = true;
            this.cancelRouteChange = false;
          }
          if (this.doesUserWishToSave && !this.cancelRouteChange) {
            await this.savePatientAdmission().then(() => {
              this.savedVersionOfAdmissionData = this.admission?.admissionData;
            });
          }
        }
      );
    }
  }

  //update the template history with the last value of the template
  updateNodeHistory(root: PatientChartNode): boolean {
    if (root.name === this.selectedPatientChartNode?.name) {
      if (!root.nodeHistory) root.nodeHistory = [] as PatientChartNodePreviousValue[];
      const previousNodeForHistory = this.templateService.getCurrentNodeData();
      if (previousNodeForHistory) previousNodeForHistory.patientId = this.patientInfo.id;
      root.nodeHistory.push(previousNodeForHistory);
      this.templateService.setCurrentNodeData(null);
      return true;
    }

    if (root.children && root.children?.length > 0) {
      for (const child of root.children) {
        if (this.updateNodeHistory(child)) {
          return true;
        }
      }
    }
    return false;
  }

  updateTemplateValue(templateNode: any) {
    this.selectedPatientChartNode;
    if (!this.patientChartRootNode?.children) return {};

    let template = [];
    const res: any = { ...this.patientChartRootNode };
    if (this.patientChartNodeType === PatientChartNodeType.TemplateNode) {
      for (let j = 0; j < this.patientChartRootNode.children.length; j++) {
        const mainNode = this.patientChartRootNode.children[j];
        if (mainNode) {
          if (mainNode.name == templateNode.parentNode && mainNode.children) {
            template = mainNode.children.filter(x => x.id === templateNode.templateId);
            if (template.length > 0) {
              template[0].value = templateNode.value;
              for (let i = 0; i < mainNode.children.length; i++) {
                if (mainNode.children[i].id === templateNode.templateId) {
                  mainNode.children[i] = template[0];
                  //update the template history with the last value of the template
                  mainNode.nodeHistory = [] as PatientChartNodePreviousValue[];
                  const previousNodeForHistory =
                    this.templateService.getCurrentNodeData();
                  if (previousNodeForHistory)
                    previousNodeForHistory.patientId = this.patientInfo.id;
                  mainNode.nodeHistory.push(previousNodeForHistory);
                  this.templateService.setCurrentNodeData(null);
                  break;
                }
              }
            } else {
              for (let i = 0; i < mainNode.children.length; i++) {
                const data = mainNode.children[i].children;
                if (data) {
                  template = data.filter(x => x.id === templateNode.templateId);
                  if (template.length > 0) {
                    template[0].value = templateNode.value;
                    for (let i = 0; i < mainNode.children.length; i++) {
                      if (mainNode.children[i].id === templateNode.templateId) {
                        mainNode.children[i] = template[0];
                        //update the template history with the last value of the template
                        mainNode.nodeHistory = [] as PatientChartNodePreviousValue[];
                        const previousNodeForHistory =
                          this.templateService.getCurrentNodeData();
                        if (previousNodeForHistory)
                          previousNodeForHistory.patientId = this.patientInfo.id;
                        mainNode.nodeHistory.push(previousNodeForHistory);
                        this.templateService.setCurrentNodeData(null);
                        break;
                      }
                    }
                    break;
                  }
                }
              }
            }
            res.children[j] = mainNode;
          }
        }
      }
    }

    for (let j = 0; j < this.patientChartRootNode.children.length; j++) {
      const mainNode = this.patientChartRootNode.children[j];
      if (mainNode) {
        if (mainNode.name != this.selectedPatientChartNode?.name && mainNode.children) {
          for (let i = 0; i < mainNode.children.length; i++) {
            if (mainNode.children[i].name === this.selectedPatientChartNode?.name) {
              mainNode.children[i].attributes.nodeSpecificAttributes.auditStatus = 'CH';
              break;
            }
          }
        } else if (mainNode.name === this.selectedPatientChartNode?.name) {
          mainNode.attributes.nodeSpecificAttributes.auditStatus = 'CH';
        }
        res.children[j] = mainNode;
        this.patientChartRootNode.children[j] = mainNode;
      }
    }

    return res;
  }
  //look if a chart can be referenced as a complete or incomplete taking into account the audit status
  evaluateAuditStatus(node: any) {
    if (
      node.attributes.auditRequired === 'Is Required (Cannot be default)' &&
      node.attributes.nodeSpecificAttributes?.editStatus === 'DO'
    ) {
      return true;
    }

    if (node.children && node.children.length > 0) {
      for (let i = 0; i < node.children.length; i++) {
        if (this.evaluateAuditStatus(node.children[i])) {
          return true;
        }
      }
    }

    return false;
  }

  //send a notification to a superadmin regarding that a template of a chart is updated
  sendTemplateChangedNotification(patientChartRootNodeId: any) {
    const user = JSON.parse(localStorage.getItem('Medico.CurrentUser') || 'null');
    const repositoryPayload = {
      createDate: new Date(),
      createdBy: '',
      description:
        '<p>The Chart' +
        ' ' +
        patientChartRootNodeId +
        ' ' +
        'in the template' +
        ' ' +
        this.selectedPatientChartNode?.name +
        ' ' +
        'has been updated by' +
        ' ' +
        user.email +
        ' ' +
        ', the node pass from the status:' +
        ' ' +
        this.selectedPatientChartNode.attributes.nodeSpecificAttributes?.editStatus ?? 'DO' +
        ' ' +
        'to the status:' +
        ' ' +
        'SE' +
        '</p>',
      id: 0,
      link: ' ',
      messagetypeId: 'Message',
      parentId: 0,
      patientId: this.patientInfo.id,
      priority: 'Medium',
      title: 'Patient chart updated',
      userIds: ['8f6379e5-d925-499b-b0bd-81c796009afa'],
    };

    this.repositoryService.create(`notification`, repositoryPayload).subscribe(() => {
      return;
    });
  }

  //send a notification to a superadmin regarding that a chart is opened in first time
  sendChartOpenedNotification() {
    const user = JSON.parse(localStorage.getItem('Medico.CurrentUser') || 'null');
    const repositoryPayload = {
      createDate: new Date(),
      createdBy: '',
      description:
        '<p>The Chart' +
        ' ' +
        this.appointment?.mrn +
        ' ' +
        'with the tree' +
        ' ' +
        this.patientChartRootNode?.name +
        ' ' +
        'has been opened by first time by' +
        ' ' +
        user.email +
        '</p>',
      id: 0,
      link: ' ',
      messagetypeId: 'Message',
      parentId: 0,
      patientId: this.patientInfo.id,
      priority: 'Medium',
      title: 'Patient chart opened',
      userIds: ['8f6379e5-d925-499b-b0bd-81c796009afa'],
    };

    this.repositoryService.create(`notification`, repositoryPayload).subscribe(() => {
      return;
    });
  }

  isNodeOpened(nodeId: string): boolean {
    return nodeId === this.selectedNodeId;
  }

  closeDocumentNodesManagementPopup() {
    this.isDocumentNodesManagementPopupVisible = false;
  }

  createUpdatePatientChartDocumentNodes() {
    const isUpdatePatientChartDocumentNodesNeeded =
      this.isUpdatePatientChartDocumentNodesNeeded();

    if (!isUpdatePatientChartDocumentNodesNeeded) {
      this.isDocumentNodesManagementPopupVisible = false;
      return;
    }

    const updatePatientChartDocumentNodesModel =
      new UpdatePatientChartDocumentNodesModel();

    updatePatientChartDocumentNodesModel.admissionId = this.admission?.id;
    updatePatientChartDocumentNodesModel.documentNodes =
      this.documentList.actualDocumentNodes;

    this.admissionService
      .updatePatientChartDocumentNodes(updatePatientChartDocumentNodesModel)
      .then(() => {
        location.reload();
        this.isDocumentNodesManagementPopupVisible = false;
      });
  }

  private isUpdatePatientChartDocumentNodesNeeded() {
    const actualDocumentNodes = this.documentList.actualDocumentNodes;

    if (actualDocumentNodes.length !== this.documentNodes.length) return true;

    for (let i = 0; i < actualDocumentNodes.length; i++) {
      const actualDocumentNode = actualDocumentNodes[i];
      const initialDocumentNode = this.documentNodes[i];

      if (actualDocumentNode.id !== initialDocumentNode.id) {
        this.emitDocumentSave.next(actualDocumentNode);
        return true;
      }
    }

    return false;
  }

  private initPatientChartDocumentDataSource(): void {
    this.patientChartDocumentDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl(ApiBaseUrls.patientChartDocuments),
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.companyId;
        },
        this
      ),
    });
  }

  private moveToTopIfScrollExists() {
    const window = this.windowService.windowRef;
    const isVerticalExists = !!window.pageYOffset;
    if (isVerticalExists) window.scrollTo(0, 0);
  }

  private onPatientChartNodeSelected(
    selectedChartNode: PatientChartNode,
    patientChartDocumentNode?: PatientChartNode,
  ): void {
    if (this.admission) {
      const admissionId = this.admission.id;
      this.patientChartInfo = new PatientChartInfo(
        patientChartDocumentNode,
        selectedChartNode,
        this.admission.patientId,
        admissionId,
        this.isAdmissionSignedOff,
        this.admission.appointmentId,
        this.companyId
      );
    }

    const appointmentId = selectedChartNode.value?.chartId;
    if (appointmentId) {
      const appointmentGridViewPromise =
        this.appointmentService.getAppointmentGridItemById(appointmentId);

      const promises: [
        Promise<AppointmentGridItem>
      ] = [
          appointmentGridViewPromise,
        ];

      Promise.all(promises).then(result => {
        const [appointmentGridView] = result;
        this.setPatientChartHeaderData(appointmentGridView.startDate);
        // if (appointmentId) {
        // }else{
        //   this.selectedPatientChartNode.nodeHistory.forEach(item => {
        //     item.isRestore = false;
        //     item.appointmentStatus = appointmentGridView.appointmentStatus
        // }); 
        // }
      })
    }
    //use the helper method in order to know the node color in DB
    const nameInDB = this.colorHelper.ChartColor(selectedChartNode.name);

    //get the pack of colors for the specific node name
    const nodeColors: any = this.allColors.find((x: any) => x.Preset == nameInDB);
    if (nodeColors !== undefined) {
      this.statusColors.noContentChanged = nodeColors.NoContentChanged;
      this.statusColors.updated = nodeColors.Updated;
      this.statusColors.defaultOrIncomplete = nodeColors.DefaultOrIncomplete;
      this.statusColors.abnormal = nodeColors.Abnormal;
    }
    this.selectedPatientChartNode = selectedChartNode;
    this.selectedPatientChartNodeService.setSelectedPatientChartNodeId(
      selectedChartNode.id
    );

    this.selectedPatientChartNodeService.toEmitPatientChartNodeSelected(
      selectedChartNode
    );

  }

  private subscribeToCompanyIdChanges() {
    this.companyIdSubscription = this.companyIdService.companyId.subscribe(companyId => {
      if (companyId) {
        if (this.companyId === GuidHelper.emptyGuid) {
          this.companyId = companyId;
          this.companyIdSaved = true;
          this.tryInitAudit();
        } else this.router.navigate(['/appointments']);

        this.initSelectableLists(this.companyId);
      }
    });
  }

  setAssessmentData(data: PatientChartNode) {
    this.patientChartRootNode;

    if (this.patientChartRootNode?.children) {
      for (let index = 0; index < this.patientChartRootNode.children.length; index++) {
        if (this.patientChartRootNode.children[index].name == data.name) {
          this.patientChartRootNode.children[index] = data;
        }
      }
    }
  }

  private subscribeToPatientChartChanges(): void {
    this.patientChartChangesSubscription =
      this.patientChartTrackService.patientChartChanged.subscribe(
        patientChartNodeType => {
          this.patientChartNodeType = patientChartNodeType;
          if (!this.fromNav) {
            this.savePatientAdmission()
              .then(() => {
                this.savedVersionOfAdmissionData = this.admission?.admissionData;
                const patientChartTreeSnapshot = this.patientChartProjectionTree;
                if (patientChartNodeType === PatientChartNodeType.ScanDocumentNode) {
                  if (this.appointment?.patientId) {
                    this.documentService
                      .getByPatientId(this.appointment.patientId)
                      .then(patientScanDocumentsInfo => {
                        if (this.patientChartRootNode) {
                          this.patienChartService.addScanDocumentsToPatientChartNodes(
                            this.patientChartRootNode,
                            patientScanDocumentsInfo
                          );
                        }

                        this.updatePatientChartTreeView(patientChartTreeSnapshot);
                      });
                  }
                }
                this.updatePatientChartTreeView(patientChartTreeSnapshot);

                const patientChartHeaderUpdateNeeded =
                  patientChartNodeType === PatientChartNodeType.AllergiesNode ||
                  patientChartNodeType === PatientChartNodeType.VitalSignsNode;

                if (patientChartHeaderUpdateNeeded) {
                  this.setPatientChartHeaderData();
                }
                this.alertService.notifyMsg("Patient chart was saved successfully", "success");
              })
              .catch(error =>
                this.alertService.error(error.message ? error.message : error)
              );
          } else {
            this.fromNav = false;
          }
        }
      );
  }

  updateHpiName(treeData: any) {
    const admissionData = this.admission?.admissionData;
    if (admissionData) {
      let historyAndPhysicalData = JSON.parse(admissionData).children.filter(
        (x: any) => x.name == 'historyAndPhysical'
      );
      if (historyAndPhysicalData.length == 0)
        historyAndPhysicalData = JSON.parse(admissionData).children.filter(
          (x: any) => x.name == 'progressNote'
        );
      if (historyAndPhysicalData.length == 0)
        historyAndPhysicalData = JSON.parse(admissionData).children.filter(
          (x: any) => x.name == 'telehealthProgressNote'
        );
      if (historyAndPhysicalData) {
        if (historyAndPhysicalData.length > 0) {
          const chiefComplaint = historyAndPhysicalData[0].children.filter(
            (y: any) => y.name === 'chiefComplaint'
          );
          if (chiefComplaint[0].value.patientAllegationsSets) {
            this.alligationset = chiefComplaint[0].value.patientAllegationsSets;
            const alligationHPI = this.alligationset.filter(
              (x: any) =>
                x.hpiTemplates.length > 0 && x.hpiTemplates[0].templateTypeName == 'hpi'
            );
            const HPIItems = treeData.children[0].children.filter(
              (y: any) => y.name == 'hpi'
            )[0].children;
            if (alligationHPI && HPIItems) {
              for (let i = 0; i < alligationHPI.length; i++) {
                for (let j = 0; j < HPIItems.length; j++) {
                  if (
                    alligationHPI[i].allegations == HPIItems[j].name ||
                    alligationHPI[i].hpiTemplates[0].reportTitle == HPIItems[j].title
                  ) {
                    if (!alligationHPI[i].changeAlligationName) {
                      HPIItems[j].name = alligationHPI[i].hpiTemplates[0].reportTitle;
                      HPIItems[j].title = alligationHPI[i].hpiTemplates[0].reportTitle;
                    } else {
                      HPIItems[j].name = alligationHPI[i].allegations;
                      HPIItems[j].title = alligationHPI[i].allegations;
                    }
                    treeData.children[0].children.filter(
                      (y: any) => y.name == 'hpi'
                    )[0].children = HPIItems;
                  }
                }
              }
            }
          }
        }
      }
    }
    return treeData;
  }
  private subscribeToRouteParamsChanges(): void {
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      const appointmentId = params['appointmentId'];
      this.appointmentService
        .getById(appointmentId)
        .then(appointment => {
          if (!appointment) return;
          this.appointment = appointment;
          this.appointmentService.setCurrentAppointmentData(this.appointment);

          const isAdmissionCreated = !!appointment.admissionId;

          if (!isAdmissionCreated) {
            this.createNewAdmission(appointment);
          } else {
            this.admissionService.getById(appointment.admissionId).then(admission => {
              this.admission = admission;
              this.admissionSaved = true;
              this.patientChartRootNode = JSON.parse(admission.admissionData || 'null');
              if (this.patientChartRootNode && this.patientChartRootNode.children)
                this.headerService.setTreeName(
                  this.patientChartRootNode?.children[0]?.title
                    ? this.patientChartRootNode?.children[0].title
                    : 'Progress Note'
                );
              this.savedVersionOfAdmissionData = admission.admissionData;
              this.signatureInfoService
                .isAdmissionSignedOff(admission.id)
                .then(isAdmissionSignedOff => {
                  this.verifyIsNewChart(this.patientChartRootNode!);
                  this.isAdmissionSignedOff = isAdmissionSignedOff;
                  this.refreshPatientChart(this.isAdmissionSignedOff);
                });
              this.tryInitAudit();
            });
          }
        })
        .catch(error => {
          this.alertService.error(error.message ? error.message : error);
        });
    });
  }

  private createNewAdmission(appointment: Appointment) {
    if (!this.appointment?.patientId || !appointment.id) return;
    this.isEditStatusSaved = false;
    const newAdmission = new Admission();
    newAdmission.createdDate = new Date();
    newAdmission.patientId = this.appointment.patientId;
    newAdmission.appointmentId = appointment.id;
    this.admissionService.save(newAdmission).then(admission => {
      this.admission = admission;
      this.admissionSaved = true;
      this.patientChartRootNode = JSON.parse(admission.admissionData || 'null');
      this.savedVersionOfAdmissionData = admission.admissionData;
      this.refreshPatientChart(false, true);
      this.tryInitAudit();
      this.subscribeToRouteParamsChanges();
    });
  }

  onNowDateChanged(args: any) {
    this.now = args.value;
    this.refreshPatientChart(this.isAdmissionSignedOff);
  }

  onFromDateChanged(args: any) {
    this.from = args.value;
    this.refreshPatientChart(this.isAdmissionSignedOff);
  }

  onLimitChanged(e: any) {
    this.quantity = e.value;
    this.onContentChanged();
  }

  onSortChanged(e: any) {
    this.sortDirection = e.value;
    this.onContentChanged();
  }

  onSortDateChanged(e: any) {
    this.dateDirection = e.value;
    this.from = new Date();
    if (this.dateDirection === '180d') {
      this.from.setMonth(this.now.getMonth() - 6);
    }
    if (this.dateDirection === '150d') {
      this.from.setMonth(this.now.getMonth() - 5);
    }
    if (this.dateDirection === '120d') {
      this.from.setMonth(this.now.getMonth() - 4);
    }
    if (this.dateDirection === '90d') {
      this.from.setMonth(this.now.getMonth() - 3);
    }
    if (this.dateDirection === '60d') {
      this.from.setMonth(this.now.getMonth() - 2);
    }
    if (this.dateDirection === '30d') {
      this.from.setMonth(this.now.getMonth() - 1);
    }
    this.onContentChanged();
  }

  async onContentChanged() {
    await this.refreshPatientChart(this.isAdmissionSignedOff);
  }

  getHeight() {
    return window.innerHeight - 420 + 'px';
  }

  private async refreshPatientChart(isAdmissionSignedOff: boolean, isNewChart?: boolean) {
    this.isNewChart = isNewChart ? isNewChart : false;
    const previousPatientVisits =
      this.appointmentService.getPatientPreviousVisitsBetweenDates(
        this.appointment?.patientId,
        this.from,
        this.now,
        this.quantity
      );
    const patientDocuments = await this.documentService.getByPatientId(
      this.appointment?.patientId
    );

    const aux = Promise.all([previousPatientVisits, patientDocuments]).then(result => {
      const previousVisits = result[0];

      let _filter1: any[];
      if (result[0].length < this.quantity) {
        _filter1 = previousVisits.slice(0, result[0].length + 1);
      } else {
        _filter1 = previousVisits.slice(0, this.quantity + 1);
      }

      const createdDateString = this.appointment?.startDate;

      if (createdDateString) {
        _filter1 = _filter1.filter(item => {
          // Extract the date string from the 'key' property
          const itemDate = new Date(item.key).toISOString().split('T')[0];
          // Extract the date string from the createdDate property
          const createdDate = new Date(createdDateString).toISOString().split('T')[0];
          // Return true if the item's key date is not equal to the created date
          return itemDate !== createdDate;
        });
      }
      if (this.sortDirection === 'Ascending') {
        _filter1.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      } else {
        _filter1.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      }

      if (this.patientChartRootNode?.children && this.selectedPatientChartName != '')
        this.patientChartRootNode.children[0].title = this.selectedPatientChartName;

      const previousVisitsNode =
        this.patienChartService.createPreviousPatientVisitsNodeOther(_filter1);
      this.previousVisitsNode = previousVisitsNode;

      const patientScanDocumentsInfo = result[1];

      this.patienChartService.addScanDocumentsToPatientChartNodes(
        this.patientChartRootNode,
        patientScanDocumentsInfo
      );
      this.patientChartRootNode = this.updateHpiName(this.patientChartRootNode);
      this.patientChartProjectionTree = [];
      if (previousVisitsNode) {
        this.patientChartProjectionTree.push(
          this.patienChartService.getPatientChartTreeProjection(
            previousVisitsNode,
            isAdmissionSignedOff,
            true
          )
        );
      }
      if (this.patientChartRootNode) {
        this.patientChartProjectionTree.push(
          this.patienChartService.getPatientChartTreeProjection(
            this.patientChartRootNode,
            isAdmissionSignedOff,
            false
          )
        );
      }
      this.patientChartTreeViewSaved = true;
      this.setPatientChartHeaderData();
      this.setPatientChartDocumentNodesAndIds();
      this.tryInitAudit();
    });

    if (!this.editStatusService.isPatientChartRootNode() && this.patientChartRootNode)
      this.editStatusService.setPatientChartRootNode(this.patientChartRootNode);

    if (this.admission && isNewChart) {
      this.isEditStatusSaved = false;
      if (this.patientChartRootNode) {
        this.editStatusService.setPatientChartRootNode(this.patientChartRootNode);
        this.editStatusService.initNewChart();
      }
    } else {
      this.editStatusService.initChart();
    }
  }

  colorTitles(data: PatientDataModelNode): { [klass: string]: any } {
    const leafNodes = this.editStatusService.getLeafNodes();
    const style: { [klass: string]: any } = {};
    style['text'] = '#337ab7';

    if (data.id && this.editStatusService.getIsEditStatusSet()) {
      if (!Object.keys(this.treeViewNodeStyles).includes(data.id)) {
        const matchingNodes = leafNodes.filter(node => node.id === data.id);
        if (matchingNodes.length === 1) {
          const node = matchingNodes[0];
          const [fillColor, borderColor] = this.chartColors.getColorsForSpecificNode(
            node.attributes.nodeSpecificAttributes?.editStatus,
            node.attributes.auditRequired ?? '',
            node.name,
            this.auditManagementService.getDBColors()
          );
          style['background-color'] = fillColor;
          style['padding'] = '2px';
          style['border'] = 'none !important';
        }
        this.treeViewNodeStyles[data.id] = style;
      }
      return this.treeViewNodeStyles[data.id];
    }
    return style;
  }

  private updatePatientChartTreeView(patientChartTreeSnapshot: any) {
    this.patientChartProjectionTree = [];
    if (this.previousVisitsNode) {
      this.patientChartProjectionTree.push(
        this.patienChartService.getPatientChartTreeProjection(
          this.previousVisitsNode,
          this.isAdmissionSignedOff,
          true
        )
      );
    }
    this.patientChartRootNode = this.updateHpiName(this.patientChartRootNode);
    if (this.patientChartRootNode) {
      this.patientChartProjectionTree.push(
        this.patienChartService.getPatientChartTreeProjection(
          this.patientChartRootNode,
          this.isAdmissionSignedOff,
          false
        )
      );
    }
    this.restoreTreeState(this.patientChartProjectionTree, patientChartTreeSnapshot);
    this.patientChartTreeViewSaved = true;
    this.tryInitAudit();
  }

  private restoreTreeState(tree: any, treeSnapshot: any[]) {
    for (let i = 0; i < tree.length; i++) {
      const treeSnapshotItem = treeSnapshot[i];
      tree[i].expanded = treeSnapshotItem ? treeSnapshotItem.expanded : false;

      const treeItems = tree[i].items;
      if (treeItems && treeItems.length && treeSnapshotItem && treeSnapshotItem.items) {
        this.restoreTreeState(tree[i].items, treeSnapshot[i].items);
      }
    }
  }

  private setPatientChartHeaderData(appointementDate = null): void {
    const admissionId = this.admission?.id;
    const dateOfService = appointementDate != null ? appointementDate : this.appointment?.startDate;
    if (admissionId && this.admission?.patientId) {
      this.patientChartHeaderData = new PatientChartHeaderData(
        this.admission.patientId,
        admissionId,
        dateOfService
      );
    }
  }

  private setPatientChartDocumentNodesAndIds() {
    if (!this.patientChartRootNode) return;
    const patientChartDocumentNodes = this.patientChartNodeManagementService.getNodes(
      this.patientChartRootNode,
      (patientChartNode: PatientChartNode) =>
        patientChartNode.type === PatientChartNodeType.DocumentNode
    );

    if (!patientChartDocumentNodes.length) return;

    this.patientChartDocumentNodeIds = patientChartDocumentNodes.map(
      patientChartNode => patientChartNode.id
    );

    for (let i = 0; i < patientChartDocumentNodes.length; i++) {
      const patientChartDocumentNode = patientChartDocumentNodes[i];

      const patientChartDocumentNodeLookup = new LookupModel();
      patientChartDocumentNodeLookup.id = patientChartDocumentNode.id;
      patientChartDocumentNodeLookup.name = patientChartDocumentNode.title;
      this.documentNodes.push(patientChartDocumentNodeLookup);
    }

    if (this.patientChartRootNode?.children) {
      const getDischargeInstructions =
        this.patientChartRootNode.children[0].children?.find(
          x => x.name === 'dischargeInstructions'
        );
      if (getDischargeInstructions?.children) {
        let i = 0;
        while (i < getDischargeInstructions.children.length) {
          const getDischargeInstructionsName = getDischargeInstructions.children[i].name;
          const result = this.dischargeInstructionsList.find(obj => {
            return obj === getDischargeInstructionsName;
          });
          if (result === undefined) {
            this.dischargeInstructionsList.push(
              getDischargeInstructions.children[i].name
            );
            i++;
          } else {
            i++;
          }
        }
      }
    }
  }

  patientInfo: any = {};

  setPatientInfo($event: any) {
    this.patientInfo = $event;
  }

  updateAppointmentStatus() {
    if (this.admission)
      this.admission.admissionData = JSON.stringify(this.patientChartRootNode);

    let dischargeContent = '';
    if (this.statusForm.appointmentStatus === 'Discharged') {
      this.parsedData = JSON.parse(this.savedVersionOfAdmissionData || 'null');

      this.parsedData.children.forEach((element: any) => {
        if (element.name === 'historyAndPhysical') {
          element.children.forEach((innerElement: any) => {
            if (innerElement.name.match('discharge')) {
              dischargeContent = innerElement.children[0].value.detailedTemplateHtml;
            }
          });
        }
      });

      if (dischargeContent === '' && this.statusForm.sendEmail) {
        this.alertService.warning(
          'Content for Discharge Instructions has not been provided. Please update that in order to send Email'
        );
        return;
      }
    } else {
      this.statusForm.sendEmail = false;
    }
    if (this.statusForm.sendEmail) {
      if (this.patientInfo.email === null) {
        this.alertService.warning(
          'Email will not be sent as patient email is unavailable!'
        );
        return;
      }
    }

    const appointmentId = this.appointment?.id;
    if (!appointmentId) return;

    const apiUrl = `appointment/status?email=${this.patientInfo.email}`;
    const data: AppointmentStatus = {
      id: appointmentId,
      status: this.statusForm.appointmentStatus,
      notes: this.statusForm.notes,
      emailContent: dischargeContent,
      sendEmail: this.statusForm.sendEmail,
      createdBy: '',
    };
    this.repositoryService.update(apiUrl, data).subscribe({
      next: res => {
        if (res) {
          this.isStatusPopupVisible = false;
          notify('Appointment status updated successfully');
          this.subscribeToRouteParamsChanges();
        }
      },
      error: error => {
        this.errorHandler.handleError(error);
      },
    });
  }

  editNode(e: any, data: any) {
    e.preventDefault();
    e.stopPropagation();
    const selectedPreviousVisitsChartNode =
      this.patientChartNodeManagementService.getById(data.id, this.previousVisitsNode);
    const appointmentId = selectedPreviousVisitsChartNode?.value.chartId;
    if (appointmentId) {
      this.appointmentService
        .getById(appointmentId)
        .then(appointment => {
          this.admissionService.getById(appointment.admissionId).then(admission => {
            if (!admission?.admissionData || !this.patientChartRootNode) return;

            const patientNwChartRootNode = JSON.parse(admission.admissionData);
            if (!this.patientChartRootNode.children) {
              this.patientChartRootNode.children = [...patientNwChartRootNode.children];
            } else {
              this.patientChartRootNode.children.push(...patientNwChartRootNode.children);
            }
            this.refreshPatientChart(this.isAdmissionSignedOff);
          });
        })
        .catch(error => this.alertService.error(error.message ? error.message : error));
    }
  }

  togglePatientAdmissionInfoView() {
    this.showPatientAdmissionInfo = !this.showPatientAdmissionInfo;
  }

  //code not used in the current flow
  private getPatientChartisActive(params: string) {
    if (patientChartTree.length != undefined) {
      const found = patientChartTree[0].map((obj: { items: any }) => obj.items);
      switch (params) {
        case 'historyAndPhysical':
          if (patientChartTree[0][0].isActive == false) {
            if (this.patientChartRootNode?.children) {
              this.patientChartRootNode.children[0].attributes.isActive = false;
            }
          } else {
            for (let i = 0; i < found[0].length; i++) {
              if (found[0][i].isActive == false) {
                if (this.patientChartRootNode?.children) {
                  const templateChart =
                    this.patientChartRootNode.children[0].children?.find(
                      x => x.title === found[0][i].text
                    );
                  if (templateChart) templateChart.attributes.isActive = false;
                }
              } else {
                if (found[0][i].items.length != 0) {
                  for (let j = 0; j < found[0][i].items.length; j++) {
                    if (found[0][i].items[j].isActive == false) {
                      if (this.patientChartRootNode?.children) {
                        const templateChart =
                          this.patientChartRootNode.children[0].children
                            ?.find(x => x.title === found[0][i].text)
                            ?.children?.find(x => x.title === found[0][i].items[j].text);
                        if (templateChart) templateChart.attributes.isActive = false;
                      }
                    }
                  }
                }
              }
            }
          }
          break;
        case 'progressNote':
          if (patientChartTree[0][1].isActive == false) {
            if (this.patientChartRootNode?.children) {
              this.patientChartRootNode.children[0].attributes.isActive = false;
            }
          } else {
            for (let i = 0; i < found[1].length; i++) {
              if (found[1][i].isActive == false) {
                if (this.patientChartRootNode?.children) {
                  const templateChart =
                    this.patientChartRootNode.children[0].children?.find(
                      x => x.title === found[0][i].text
                    );
                  if (templateChart) templateChart.attributes.isActive = false;
                }
              } else {
                if (found[1][i].items.length != 0) {
                  for (let j = 0; j < found[1][i].items.length; j++) {
                    if (found[1][i].items[j].isActive == false) {
                      if (this.patientChartRootNode?.children) {
                        const templateChart =
                          this.patientChartRootNode.children[0].children
                            ?.find(x => x.title === found[0][i].text)
                            ?.children?.find(x => x.title === found[0][i].items[j].text);
                        if (templateChart) templateChart.attributes.isActive = false;
                      }
                    }
                  }
                }
              }
            }
          }
          break;
        case 'telehealthProgressNote':
          if (patientChartTree[0][2].isActive == false) {
            if (this.patientChartRootNode?.children) {
              this.patientChartRootNode.children[0].attributes.isActive = false;
            }
          } else {
            for (let i = 0; i < found[2].length; i++) {
              if (found[2][i].isActive == false) {
                if (this.patientChartRootNode?.children) {
                  const templateChart =
                    this.patientChartRootNode.children[0].children?.find(
                      x => x.title === found[0][i].text
                    );
                  if (templateChart) templateChart.attributes.isActive = false;
                }
              } else {
                if (found[2][i].items.length != 0) {
                  for (let j = 0; j < found[2][i].items.length; j++) {
                    if (found[2][i].items[j].isActive == false) {
                      if (this.patientChartRootNode?.children) {
                        const templateChart =
                          this.patientChartRootNode.children[0].children
                            ?.find(x => x.title === found[0][i].text)
                            ?.children?.find(x => x.title === found[0][i].items[j].text);
                        if (templateChart) templateChart.attributes.isActive = false;
                      }
                    }
                  }
                }
              }
            }
          }
          break;
        case 'patientCallNote':
          if (patientChartTree[0][3].isActive == false) {
            if (this.patientChartRootNode?.children) {
              this.patientChartRootNode.children[0].attributes.isActive = false;
            }
          } else {
            for (let i = 0; i < found[3].length; i++) {
              if (found[3][i].isActive == false) {
                if (this.patientChartRootNode?.children) {
                  const templateChart =
                    this.patientChartRootNode.children[0].children?.find(
                      x => x.title === found[0][i].text
                    );
                  if (templateChart) templateChart.attributes.isActive = false;
                }
              } else {
                if (found[3][i].items.length != 0) {
                  for (let j = 0; j < found[3][i].items.length; j++) {
                    if (found[3][i].items[j].isActive == false) {
                      if (this.patientChartRootNode?.children) {
                        const templateChart =
                          this.patientChartRootNode.children[0].children
                            ?.find(x => x.title === found[0][i].text)
                            ?.children?.find(x => x.title === found[0][i].items[j].text);
                        if (templateChart) templateChart.attributes.isActive = false;
                      }
                    }
                  }
                }
              }
            }
          }
          break;
      }
    }
  }

  onPatientPopupOpened(tabNumber: number) {
    this.showDemographicsDetail(tabNumber);
  }

  onPatientFieldChanged($event: any) {
    if (this.patientInfo) {
      const dataField = $event.dataField;
      if (dataField === 'zipCodeType' && $event.value) this.patientInfo.zip = '';
    }
  }

  onPatientInsuranceFieldChanged($event: any) {
    const dataField = $event.dataField;
    if (dataField === 'zipCodeType' && $event.value) {
      if (this.isCopyActionExecuting) {
        this.isCopyActionExecuting = false;
        return;
      }

      this.insurance.zip = '';
    }
  }
  async createUpdatePatient() {
    const validationResult = this.patientForm.instance.validate();

    if (!validationResult.isValid) {
      return;
    }
    // if (this.isNewPatient) {
    //   this.checkPatientExistance(this.patient)
    //     .then(existedPatients => {
    //       if (existedPatients) {
    //         this.alertService.warning(
    //           `The patient <b>${this.patient.firstName} ${this.patient.lastName}</b> already exists. Try to find in the data grid`
    //         );
    //         return;
    //       }
    //     })
    //     .catch(error => this.alertService.error(error.message ? error.message : error));
    //   const patients = await this.patientService.getAllPatients();
    //   this.patient.companyId = this.companyId;

    //   for (const element of patients) {
    //     if (element.name === this.patient.firstName + ' ' + this.patient.lastName) {
    //       const dialogResult = await this.alertService.confirm(
    //         'Patient is Similar to others, do you want to continue?',
    //         'Warning'
    //       );

    //       if (dialogResult) {
    //         await this.save();
    //       }
    //       return;
    //     }
    //   }
    //   this.save();
    // } else {
    this.save();
    //}
  }

  private initProvidersDataSource(): void {
    this.providersDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('user'),
      loadParams: { employeeType: EmployeeTypeList.values[0].value },
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.patient.companyId;
        },
        this
      ),
    });
  }


  private save() {
    this.patientService.save(this.patientInfo).then(patient => {
      this.patientInfo.id = patient.id;
      this.patientInfo.password = patient.password;
      this.patientInfo.fin = patient.fin;
      this.alertService.info('Patient data was updated successfully');
    });
  }


  private initMasDataSource(): void {
    this.masDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('user'),
      loadParams: { employeeType: EmployeeTypeList.values[2].value },
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.patient.companyId;
        },
        this
      ),
    });
  }
  closePatientForm() {
    this.isPatientPopupOpened = false;
  }

  async updateCareTeamStatus() {
    const updatedRootNode = this.treeService.updateNodeByName(
      this.patientChartRootNode!,
      'careTeam'
    );
    if (updatedRootNode) {
      this.patientChartRootNode = updatedRootNode;
      this.admission!.admissionData = JSON.stringify(this.patientChartRootNode);
      await this.admissionService.save(this.admission!);
    }
  }

  updatePreviousVisitTemplateValue(templateNode: any) {
    this.patienChartService.previousVisitChart;
    if (!this.patienChartService.previousVisitChart?.children) return {};

    let template = [];
    const res: any = { ...this.patienChartService.previousVisitChart };
    if (this.patientChartNodeType === PatientChartNodeType.TemplateNode) {
      for (
        let j = 0;
        j < this.patienChartService.previousVisitChart.children.length;
        j++
      ) {
        const mainNode = this.patienChartService.previousVisitChart.children[j];
        if (mainNode) {
          if (mainNode.name == templateNode.parentNode && mainNode.children) {
            template = mainNode.children.filter(
              (x: any) => x.id === templateNode.templateId
            );
            if (template.length > 0) {
              template[0].value = templateNode.value;
              for (let i = 0; i < mainNode.children.length; i++) {
                if (mainNode.children[i].id === templateNode.templateId) {
                  mainNode.children[i] = template[0];
                  //update the template history with the last value of the template
                  mainNode.nodeHistory = [] as PatientChartNodePreviousValue[];
                  const previousNodeForHistory =
                    this.templateService.getCurrentNodeData();
                  if (previousNodeForHistory)
                    previousNodeForHistory.patientId = this.patientInfo.id;
                  mainNode.nodeHistory.push(previousNodeForHistory);
                  this.templateService.setCurrentNodeData(null);
                  break;
                }
              }
            } else {
              for (let i = 0; i < mainNode.children.length; i++) {
                const data = mainNode.children[i].children;
                if (data) {
                  template = data.filter((x: any) => x.id === templateNode.templateId);
                  if (template.length > 0) {
                    template[0].value = templateNode.value;
                    for (let i = 0; i < mainNode.children.length; i++) {
                      if (mainNode.children[i].id === templateNode.templateId) {
                        mainNode.children[i] = template[0];
                        //update the template history with the last value of the template
                        mainNode.nodeHistory = [] as PatientChartNodePreviousValue[];
                        const previousNodeForHistory =
                          this.templateService.getCurrentNodeData();
                        if (previousNodeForHistory)
                          previousNodeForHistory.patientId = this.patientInfo.id;
                        mainNode.nodeHistory.push(previousNodeForHistory);
                        this.templateService.setCurrentNodeData(null);
                        break;
                      }
                    }
                    break;
                  }
                }
              }
            }
            res.children[j] = mainNode;
          }
        }
      }
    }

    for (let j = 0; j < this.patienChartService.previousVisitChart.children.length; j++) {
      const mainNode = this.patienChartService.previousVisitChart.children[j];
      if (mainNode) {
        if (mainNode.name != this.selectedPatientChartNode?.name && mainNode.children) {
          for (let i = 0; i < mainNode.children.length; i++) {
            if (mainNode.children[i].name === this.selectedPatientChartNode?.name) {
              mainNode.children[i].attributes.nodeSpecificAttributes.auditStatus = 'CH';
              break;
            }
          }
        } else if (mainNode.name === this.selectedPatientChartNode?.name) {
          mainNode.attributes.nodeSpecificAttributes.auditStatus = 'CH';
        }
        res.children[j] = mainNode;
        this.patienChartService.previousVisitChart.children[j] = mainNode;
      }
    }

    return res;
  }

  copyFromPatient() {
    this.isCopyActionExecuting = true;

    const patientFieldsToExclude = [
      'maritalStatus',
      'companyId',
      'id',
      'patientInsuranceId',
    ];
    this.insurance.FIN = this.patientInfo.FIN;
    const patient = this.patientInfo;
    const insurance = this.insurance;

    if (!patient || !insurance) {
      return;
    }

    for (const fieldName in patient) {
      if (
        patientFieldsToExclude.indexOf(fieldName) !== -1 ||
        !Object.prototype.hasOwnProperty.call(patient, fieldName)
      ) {
        continue;
      }

      (<any>insurance)[fieldName] = (<any>patient)[fieldName];
    }

    this.insurance.firstName = patient.firstName;

    insurance.patientId = patient.id;
  }

  public showDemographicsDetail(tabNum) {
    this.addDemographicsComponentRef =
      ComponentHelper.addComponent(this.componentFactoryResolver, this.componentContainer, DemographicsComponent);
    const detailInstance = this.addDemographicsComponentRef.instance;
    detailInstance.popupTitle = "Demographics - " + this.patientInfo.firstName + " " + this.patientInfo.lastName;
    detailInstance.dtsPatient = this.patientInfo;
    detailInstance.dtsInsurance = this.insurance;
    detailInstance.showDemographics(tabNum, this.patientInfo.id);
    detailInstance.popupHiddenEvent.subscribe(response => {
      if (detailInstance.isDataSaved) {
        this.alertService.notifyMsg("Patient data was updated successfully", "success");
        location.reload();
      }
      ComponentHelper.removeComponent(this.componentContainer, this.addDemographicsComponentRef);
    });
  }

  onBtnReviewPatientChartFromAdmission(e: any, data: any) {
    e.preventDefault();
    e.stopPropagation();
    const selectedPreviousVisitsChartNode =
      this.patientChartNodeManagementService.getById(data.id, this.previousVisitsNode);
    const appointmentId = selectedPreviousVisitsChartNode?.value.chartId;
    if (appointmentId) {
      this.appointmentService
        .getById(appointmentId)
        .then(appointment => {
          this.admissionService.getById(appointment.admissionId).then(admission => {
            this.patientChartNodeReview = JSON.parse(admission.admissionData || 'null');
            this.patientChartDocumentNodeReview = this.patientChartNodeReview;
            this.appointmentIdReview = appointment.id;
            this.patientIdReview = appointment.patientId;
            this.admissionIdReview = appointment.admissionId;
            this.companyIdReview = appointment.companyId;
            this.isPatientChartReview = true;
          });
        });
    }
  }

  setSwitchView(value: boolean) {
    this.switchView = value;
  }
}
