import {
  Component,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  AfterViewInit,
} from '@angular/core';
import { IAdminSelectableComponent } from 'src/app/administration/interfaces/iAdminSelectableComponent';
import { AdminSelectableListComponent } from '../admin-selectable-list/admin-selectable-list.component';
import { AdminSelectableRangeComponent } from '../admin-selectable-range/admin-selectable-range.component';
import { AdminSelectableDateComponent } from '../admin-selectable-date/admin-selectable-date.component';
import { AdminSelectableNameComponent } from '../admin-selectable-name/admin-selectable-name.component';
import { AdminSelectableVariableComponent } from '../admin-selectable-variable/admin-selectable-variable.component';

@Component({
  selector: 'admin-selectable-view-model',
  templateUrl: './admin-selectable-view-model.component.html',
})
export class AdminSelectableViewModelComponent implements AfterViewInit {
  _adminSelectableComponents: Array<IAdminSelectableComponent> = [];
  selectableListDataSource: Array<any> = [
    { name: 'Appoinment' },
    { name: 'Patient' },
    { name: 'Chart' },
    { name: 'Template' },
    { name: 'Order' },
    { name: 'Admission' },
    { name: 'Expression' },
  ];

  selectedItem = '';

  @ViewChild('selectableList', { static: false })
  selectableList!: AdminSelectableListComponent;
  @ViewChild('selectableRange', { static: false })
  selectableRange!: AdminSelectableRangeComponent;
  @ViewChild('selectableDate', { static: false })
  selectableDate!: AdminSelectableDateComponent;
  @ViewChild('selectableName', { static: false })
  selectableName!: AdminSelectableNameComponent;
  @ViewChild('selectableVariable', { static: false })
  selectableVariable!: AdminSelectableVariableComponent;

  @Input() companyId?: string;

  @Output()
  selectableItemValueGenerated: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngAfterViewInit(): void {
    this._adminSelectableComponents = [
      this.selectableList,
      this.selectableDate,
      this.selectableName,
      this.selectableRange,
      this.selectableVariable,
    ];
  }

  onSelectableHtmlElementGenerated(htmlString: any) {
    this.selectableItemValueGenerated.next('@' + htmlString.value);
    this.selectedItem = '';
  }
}
