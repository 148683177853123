import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { PatientChartNode } from 'src/app/_models/patientChartNode';
import { PatientChartNodeType } from 'src/app/_models/patientChartNodeType';
import { PatientChartNodeReportInfo } from 'src/app/patientChart/components/report-sections/baseHistoryReportSection';
import { ReportSectionService } from 'src/app/patientChart/services/report-section.service';
import { ReportDataTreeNode } from 'src/app/patientChart/classes/reportDataTreeNode';
import { GuidHelper } from 'src/app/_helpers/guid.helper';
import { PatientChartReportHeaderService } from 'src/app/patientChart/services/patient-chart-report-header.service';
import { PatientChartReportFooterService } from 'src/app/patientChart/services/patient-chart-report-footer.service';
import { Admission } from 'src/app/patientChart/models/admission';
import { SelectedPatientChartNodeService } from 'src/app/_services/selected-patient-chart-node.service';
import { WindowService } from 'src/app/_services/window.service';
import { PatientChartNodeManagementService } from 'src/app/patientChart/services/patient-chart-node-management.service';
import { PatientChartInfo } from 'src/app/patientChart/models/patientChartInfo';
import { EditStatusService } from 'src/app/patientChart/services/edit-status.service';
@Component({
  templateUrl: 'report-node-view.component.html',
  selector: 'report-node-view',
  styles: ['.report-node-container {  }'],
})
export class ReportNodeViewComponent implements OnInit, OnDestroy {
  @Input() patientChartNode!: PatientChartNode;
  @Input() patientChartDocumentNode!: PatientChartNode;
  @Input() admissionId!: string;
  @Input() appointmentId!: string;
  @Input() patientId!: string;
  @Input() isSignedOff!: boolean;
  @Input() companyId!: string;

  reportNodeContent = '';
  isPreviewEditNode = false;
  patientChartInfo: PatientChartInfo | undefined;
  chartViewer = false;

  constructor(
    private reportSectionService: ReportSectionService,
    private patientChartReportHeaderService: PatientChartReportHeaderService,
    private patientChartReportFooterService: PatientChartReportFooterService,
    private elementRef: ElementRef,
    private selectedPatientChartNodeService: SelectedPatientChartNodeService,
    private windowService: WindowService,
    private patientChartNodeManagementService: PatientChartNodeManagementService,
    private editStatusService: EditStatusService
  ) { }
  ngOnDestroy(): void {
    this.editStatusService.onPatientChartDestroyed();
  }

  ngOnInit(): void {
    const isRootNode = this.patientChartNode.type === PatientChartNodeType.RootNode;

    const reportDataTreeNodePromise = isRootNode
      ? this.generateReportDataTreeWithHeaderAndFooter()
      : this.generateReportDataTreeNode(this.patientChartNode);

    reportDataTreeNodePromise.then((reportDataTree: ReportDataTreeNode) => {
      this.updateReportContent(reportDataTree);
    });
    this.editStatusService.onPatientChartInit();
  }

  private generateReportDataTreeNode(
    patientChartNode: PatientChartNode
  ): Promise<ReportDataTreeNode> {
    const reportDataTreeNode = new ReportDataTreeNode();
    reportDataTreeNode.patientChartNodeId = patientChartNode.id;

    const patientChartNodeReportInfo = new PatientChartNodeReportInfo(
      this.patientId,
      patientChartNode,
      this.admissionId,
      this.appointmentId
    );

    return this.reportSectionService
      .getPatientChartNodeContent(patientChartNodeReportInfo)
      .then(htmlContent => {
        if (htmlContent) reportDataTreeNode.html = htmlContent;

        const childrenNodes = patientChartNode.children;

        if (!childrenNodes) return reportDataTreeNode;

        const activeChildrenNodes = childrenNodes.filter(
          childNode => childNode.attributes.isActive
        );

        if (!activeChildrenNodes || !activeChildrenNodes.length) {
          return reportDataTreeNode;
        }

        const childrenNodesPromises: Promise<any>[] = [];

        for (let i = 0; i < activeChildrenNodes.length; i++) {
          const childNode = activeChildrenNodes[i];
          const childNodePromise = this.generateReportDataTreeNode(childNode);

          childrenNodesPromises.push(childNodePromise);
        }

        return Promise.all(childrenNodesPromises).then(childrenDataTreeNodes => {
          for (let i = 0; i < childrenDataTreeNodes.length; i++) {
            const childrenDataTreeNode = childrenDataTreeNodes[i];
            reportDataTreeNode.childrenNodes.push(childrenDataTreeNode);
          }

          return reportDataTreeNode;
        });
      });
  }

  private generateReportDataTreeWithHeaderAndFooter(): Promise<ReportDataTreeNode> {
    const reportDataTreeRootNode = new ReportDataTreeNode();

    const headerHtmlPromise = this.getReportHeader();
    const footerHtmlPromise = this.getReportFooter(this.isSignedOff);
    const reportDataTreeBodyPromise = this.generateReportDataTreeNode(
      this.patientChartNode
    );

    return Promise.all([
      headerHtmlPromise,
      footerHtmlPromise,
      reportDataTreeBodyPromise,
    ]).then(result => {
      const reportHeaderHtml = result[0];
      const reportFooterHtml = result[1];
      const reportDataTreeBody = result[2];

      const reportDataTreeHeader = new ReportDataTreeNode(
        GuidHelper.generateNewGuid(),
        reportHeaderHtml
      );

      reportDataTreeRootNode.childrenNodes.push(reportDataTreeHeader);
      reportDataTreeRootNode.childrenNodes.push(reportDataTreeBody);

      if (reportFooterHtml) {
        const reportDataTreeFooter = new ReportDataTreeNode('', reportFooterHtml);

        reportDataTreeRootNode.childrenNodes.push(reportDataTreeFooter);
      }

      return reportDataTreeRootNode;
    });
  }

  private getReportHeader(): Promise<string> {
    const admission = new Admission();
    admission.patientId = this.patientId;
    admission.appointmentId = this.appointmentId;

    return this.patientChartReportHeaderService.getPatientChartNodeReportContent(
      admission,
      this.companyId,
      true
    );
  }

  private getReportFooter(isPatientAdmissionSignedOff: boolean): Promise<string> {
    if (!isPatientAdmissionSignedOff) return Promise.resolve('');

    return this.patientChartReportFooterService.getPatientChartNodeReportContent(
      this.admissionId
    );
  }

  private updateReportContent(reportDataTreeNode: ReportDataTreeNode) {
    const reportNodeContent = this.generateReportContent('', reportDataTreeNode);
    this.reportNodeContent = `<div class="block">${reportNodeContent}</div>`;

    let count = 0;
    const data = this.reportNodeContent.split('_template').map(val => {
      val = val + '_template' + count;
      count++;
      return val;
    });
    this.reportNodeContent = data.join('');

    this.reportNodeContent = this.reportNodeContent.replace(
      '_template' + (count - 1),
      ''
    );
    setTimeout(() => this.setevents(), 1);
  }

  private generateReportContent(
    content: string,
    reportDataTreeNode: ReportDataTreeNode
  ): string {
    if (reportDataTreeNode.html) {
      content += reportDataTreeNode.html.replace("<h5>", "<h6>").replace("</h5>", "</h6>");
    }

    const childrenNodes = reportDataTreeNode.childrenNodes;

    if (childrenNodes.length) {
      for (let i = 0; i < childrenNodes.length; i++) {
        const childNode = childrenNodes[i];
        content = this.generateReportContent(content, childNode);
      }
    }

    return content;
  }

  setevents() {
    const templateCount = (this.reportNodeContent.match(/_template/g) || []).length;
    for (let i = 0; i < templateCount; i++) {
      if (this.elementRef.nativeElement.querySelector('._template' + i)) {
        this.elementRef.nativeElement.querySelector('._template' + i).style.color =
          '#337ab7';
        this.elementRef.nativeElement.querySelector('._template' + i).style.cursor =
          'pointer';
        this.elementRef.nativeElement
          .querySelector('._template' + i)
          .addEventListener('dblclick', this.editTemplate.bind(this));
      }
    }
  }
  editTemplate(item: any) {
    this.isPreviewEditNode = true;
    this.chartViewer = true;

    const className: string = '.' + item.target.className;
    const nodeId = item.target.attributes.id.value;
    //this.selectedNodeId = nodeId;

    const selectedPatientChartNode = this.patientChartNodeManagementService.getById(
      nodeId,
      this.patientChartNode
    );

    if (className)
      this.elementRef.nativeElement.querySelector(className).style.color = 'purple';

    if (!selectedPatientChartNode)
      throw `Patient chart node with id: ${nodeId} was not found`;

    const patientChartDocumentNode =
      this.patientChartNodeManagementService.getDocumentNodeRelatedToInnerNode(
        this.patientChartNode,
        selectedPatientChartNode.id
      );

    if (!patientChartDocumentNode)
      throw `Unable to find root document node for child node with id: ${nodeId}`;
    this.onPatientChartNodeSelected(selectedPatientChartNode, patientChartDocumentNode);
    this.moveToTopIfScrollExists();
  }
  private onPatientChartNodeSelected(
    selectedChartNode: PatientChartNode,
    patientChartDocumentNode: PatientChartNode
  ): void {
    const admissionId = this.admissionId;
    this.patientChartInfo = new PatientChartInfo(
      patientChartDocumentNode,
      selectedChartNode,
      this.patientId,
      admissionId,
      false,
      this.appointmentId,
      this.companyId
    );

    //this.selectedPatientChartNode = selectedChartNode;
    this.selectedPatientChartNodeService.setSelectedPatientChartNodeId(
      selectedChartNode.id
    );
    this.selectedPatientChartNodeService.toEmitPatientChartNodeSelected(
      selectedChartNode
    );
  }
  private moveToTopIfScrollExists() {
    const window = this.windowService.windowRef;
    const isVerticalExists = !!window.pageYOffset;

    if (isVerticalExists) window.scrollTo(0, 0);
  }
  popupHiding(e: any) {
    //should insert some logic here
  }
}
