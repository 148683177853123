import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TrowserViewBase } from 'src/app/shared/base-classes';
import { ButtonOptions } from 'src/app/shared/utils';
import { PreviousAppointmentsComponent } from '../previous-appointments/previous-appointments.component';
import { ComponentHelper } from 'src/app/shared/utils/helpers/component.helper';

@Component({
  selector: 'app-appointment-info',
  templateUrl: './appointment-info.component.html',
  styleUrls: ['./appointment-info.component.sass']
})
export class AppointmentInfoComponent extends TrowserViewBase implements OnInit {
  @ViewChild('componentDetailContainer', { static: false, read: ViewContainerRef }) componentContainer: ViewContainerRef;
  public showPreviousAppointmentComponentRef: ComponentRef<PreviousAppointmentsComponent>;
  public popupToolbarItems: any[] = [];
  public dtsAppointmentInfo: any = {};
  constructor(private componentFactoryResolver: ComponentFactoryResolver,) {
    super();
  }

  ngOnInit(): void {
  }

  public onPopupHiding(e: any) {
    this.popupVisible = false;
  }

  public showAppointmentInfo(entity: any) {
    this.dtsAppointmentInfo = entity;
    this.dtsAppointmentInfo.nurse = entity.nurseFirstName + ' ' + entity.nurseLastName;
    this.dtsAppointmentInfo.patient = entity.patientFirstName + ' ' + entity.patientLastName;
    this.dtsAppointmentInfo.physician = entity.physicianFirstName + ' ' + entity.physicianLastName;
    this.popupVisible = true;
    this.initPopupToolbarItems();
  }


  private initPopupToolbarItems(): void {
    this.popupToolbarItems = super.getTrowserToolbarItems(
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('Cancel', true, this.onBtnCancelClick, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('Show Previous Appointments', this.dtsAppointmentInfo.totalNumberOfPatientAppointments > 1, this.onBtnPreviousAppointmentClick, this.isDataProcessing),
    );
  }

  private onBtnCancelClick = () => {
    this.popupVisible = false;
  }

  private onBtnPreviousAppointmentClick = () => {
    this.showPreviousAppointmentDetail(this.dtsAppointmentInfo);
  }

  public showPreviousAppointmentDetail(entity) {
    this.showPreviousAppointmentComponentRef =
      ComponentHelper.addComponent(this.componentFactoryResolver, this.componentContainer, PreviousAppointmentsComponent);
    const detailInstance = this.showPreviousAppointmentComponentRef.instance;
    detailInstance.popupTitle = "Previous Appointments - " + entity.patientFirstName + ' ' + entity.patientLastName;
    detailInstance.showPreviousAppointment(entity);
    detailInstance.popupHiddenEvent.subscribe(response => {
      ComponentHelper.removeComponent(this.componentContainer, this.showPreviousAppointmentComponentRef);
    });
  }
}
