import { Component, ViewChild, AfterViewInit, OnInit, OnDestroy, Input } from '@angular/core';
import { BaseAdminComponent } from 'src/app/_classes/baseAdminComponent';
import { AlertService } from 'src/app/_services/alert.service';
import { MedicoApplicationUser } from 'src/app/administration/models/medicoApplicationUser';
import { Gender } from 'src/app/_classes/gender';
import { EmployeeTypeList } from 'src/app/administration/classes/employeeTypeList';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { UserService } from 'src/app/administration/services/user.service';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxDataUrlService } from 'src/app/_services/dxDataUrl.service';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { AccountService } from 'src/app/_services/account.service';
import { GuidHelper } from 'src/app/_helpers/guid.helper';
import { Subscription } from 'rxjs';
import { CompanyIdService } from 'src/app/_services/company-id.service';
import { ZipCodeType } from 'src/app/patients/models/zipCodeType';
import { DevextremeAuthService } from 'src/app/_services/devextreme-auth.service';
import { CompanyService } from 'src/app/_services/company.service';
import { CompanySearchFilter } from 'src/app/administration/models/companySearchFilter';
import { LookupModel } from 'src/app/_models/lookupModel';
import { RepositoryService } from 'src/app/_services/repository.service';
import { PrefixList, SuffixList } from 'src/app/administration/classes/suffixAndPrefixList';

@Component({
  selector: 'medico-application-user',
  templateUrl: './medico-application-user.component.html',
  styleUrls: ['./medico-application-user.component.css'],
})
export class MedicoApplicationUserComponent
  extends BaseAdminComponent
  implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('userGrid', { static: false })
  userGrid!: DxDataGridComponent;
  @ViewChild('userForm', { static: false })
  userForm!: DxFormComponent;
  @ViewChild('userPopup', { static: false })
  userPopup!: DxPopupComponent;
  @ViewChild('resetPasswordForm', { static: false })
  resetPasswordForm!: DxFormComponent;
  //@ViewChild("extraFieldsTab") extraFieldsTab: ExtraFieldsTabComponent;
  public passwordMode = 'password';
  public comparePasswordMode = 'password';
  public passwordButton: any = {};
  public comparepasswordButton: any = {};
  isEscapeBtnEventHandlerSet = false;
  showNewPasswordUser = false;
  showNewPasswordCopyUser = false;
  newPasswordUser = '';
  newPasswordCopyUser = '';
  canRenderComponent = false;
  @Input() companyInfoId: string;
  companyId: string = GuidHelper.emptyGuid;
  companyIdSubscription?: Subscription;

  user: MedicoApplicationUser = new MedicoApplicationUser();

  genderValues: any[] = Gender.values;
  /* employeeTypes: any[] = EmployeeTypeList.values; */

  employeeTypes: any[] = EmployeeTypeList.values.filter(type => type.name !== 'Nurse');

  userDataSource: any = {};
  roleDataSource: any = {};

  selectedUsers: Array<any> = [];

  isUserPopupOpened = false;

  isNewUser = true;
  isUserSet = false;

  isResetPasswordPopupOpened = false;
  resetPasswordModel: any = { newPasswordUser: null, newPasswordCopyUser: null };
  companies: LookupModel[] = [];

  public nameSuffixes: any[] = SuffixList.values;
  public namePrefixes: any[] = PrefixList.values;

  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private companyService: CompanyService,
    private dxDataUrlService: DxDataUrlService,
    private appointmentService: AppointmentService,
    private accountService: AccountService,
    private companyIdService: CompanyIdService,
    private devextremeAuthService: DevextremeAuthService,
    private repositoryService: RepositoryService
  ) {
    super();

    this.toogleButtonOptions();
    this.toogleCompareButtonOptions();
  }

  get zipMask(): string {
    switch (this.user.zipCodeType) {
      case ZipCodeType.FiveDigit:
        return this.validationMasks.fiveDigitZip;
      case ZipCodeType.NineDigit:
        return this.validationMasks.nineDigitZip;
    }
  }

  ngOnDestroy(): void {
    this.companyIdSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToCompanyIdChanges();
    this.init();
  }

  private toogleButtonOptions = () => {
    this.passwordButton = {
      icon: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/icons/eye.png',
      type: 'default',
      onClick: () => {
        this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
      },
    };
  }

  private toogleCompareButtonOptions = () => {
    this.comparepasswordButton = {
      icon: 'https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/icons/eye.png',
      type: 'default',
      onClick: () => {
        this.comparePasswordMode = this.comparePasswordMode === 'text' ? 'password' : 'text';
      },
    };
  }

  resetUserPassword = () => {
    const validationResult = this.resetPasswordForm.instance.validate();
    if (!validationResult.isValid) {
      return;
    }
    const email = this.resetPasswordModel.email;
    const companyId = this.resetPasswordModel.companyId;
    const password = this.resetPasswordModel.newPasswordUser;

    this.accountService
      .resetPassword(email, companyId, password)
      .then(result => {
        if (result) {
          this.resetPasswordModel = {};
          this.isResetPasswordPopupOpened = false;
          this.alertService.notifyMsg('Password was reset successfully', "success");
        } else {
          this.alertService.error('Unable to reset the password. Please try again later.');
        }

      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  getState(data: any) {
    const stateValue = data.state;
    return this.states.filter(t => t.value === stateValue)[0].name;
  }

  getCompany(data: any) {
    const companyValue = data.companyId;
    var companyInfo = this.companies.filter(t => t.id === companyValue);
    if (companyInfo)
      return companyInfo[0].name;
    return '';
  }

  getEmployeeType(data: any) {
    if (data.employeeTypes !== null) {
      if (data.employeeTypes.length > 1) {
        const employeeTypesValues: number[] = data.employeeTypes;
        return employeeTypesValues
          .map(employeeTypeValue =>
            this.employeeTypes.find(t => t.value === employeeTypeValue)?.name || ''
          )
          .filter(name => name)
          .join(', ');
      } else {
        const employeeTypeValue = data['employeeType'];
        return this.employeeTypes.find(t => t.value === employeeTypeValue)?.name || '';
      }
    }
    else {
      return ''
    }
  }


  passwordComparison() {
    return this.user.password;
  }

  validatePassword(params: any) {
    const email = this.resetPasswordModel.email;
    const password = params.value;
    this.accountService.checkPassword(email, password).then(validationResult => {
      params.rule.isValid = validationResult;
      params.validator.validate();
    });

    return false;
  }

  onResetPassworPopupHidden() {
    this.resetPasswordModel = {};
  }

  openUserForm() {
    this.isUserSet = true;
    this.isUserPopupOpened = true;
    this.user.companyId = this.companyId;
    if (!this.isEscapeBtnEventHandlerSet)
      this.registerEscapeBtnEventHandler(this.userPopup);
  }

  onUserPopupHidden() {
    this.resetUserForm();
  }

  openResetPasswordPopup(user: any, $event: any): void {
    $event.stopPropagation();
    this.newPasswordUser = '';
    this.newPasswordCopyUser = '';
    this.resetPasswordModel.email = user.email;
    this.resetPasswordModel.companyId = user.companyId;
    this.isResetPasswordPopupOpened = true;
  }

  deleteUser(user: any, $event: any): void {
    $event.stopPropagation();

    const userId = user.id;
    const employeeType = user.employeeType;

    this.canDeactivateDeleteUser(userId, employeeType).then(canDelete => {
      if (!canDelete) {
        this.alertService.warning('User already is used. You cannot delete it.');
        return;
      }

      this.continueDeletingUser(userId);
    });
  }

  ngAfterViewInit(): void { }

  onUserSelected(data: any) {

    const userId = data.id;

    this.userService
      .getById(userId)
      .then(user => {
        this.user = user;

        this.isNewUser = false;
        this.isUserSet = true;
        this.isUserPopupOpened = true;
      })
      .catch(error => {
        this.selectedUsers = [];
        this.alertService.error(error.message ? error.message : error);
      });
  }

  async createUpdateUser() {
    const validationResult = this.userForm.instance.validate();

    if (!validationResult.isValid) {
      return;
    }

    if (this.isNewUser) {
      const emailValidationResult: any = await this.accountService.checkEmailExistance(
        this.user.email ?? '',
        this.companyId
      );

      const isEmailValid = emailValidationResult.isValid;
      if (!isEmailValid) {
        this.alertService.info('Email is already existed in company.');
        return;
      }
    }

    for (let index = 0; index < this.states.length; index++) {
      const state = this.states[index];
      if (state.value == this.user.state) {
        this.user.stateName = state.name;
      }
    }

    for (let index = 0; index < this.employeeTypes.length; index++) {
      const type = this.employeeTypes[index];
      if (type.value == this.user.employeeTypes[0]) {
        this.user.employeeTypeName = type.name;
      }
    }

    this.userService
      .save(this.user)
      .then(() => {
        this.resetUserForm();
        this.isUserPopupOpened = false;
        this.alertService.notifyMsg("Record saved successfully", "success");
        this.userGrid.instance.refresh();
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  private continueDeletingUser(userId: string): void {
    const confirmationPopup = this.alertService.confirm(
      'Are you sure you want to delete the user?',
      'Confirm deletion'
    );
    confirmationPopup.then(dialogResult => {
      if (dialogResult) {
        this.userService.delete(userId).then(() => {
          this.userGrid.instance.refresh();
        });
      }
    });
  }

  private init(): void {
    this.initRoleDataSource();
    this.initCompanies();
    this.initUserDataSource();

  }

  private initRoleDataSource(): void {
    this.roleDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('role'),
      key: 'Id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, _jQueryAjaxSettings) => { },
        this
      ),
    });
  }

  private initUserDataSource(): void {
    this.userDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getGridUrl('user'),
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.companyId;
        },
        this
      ),
    });
  }

  private canDeactivateDeleteUser(userId: string, employeeType: number) {
    //1 - Physician
    //2 - Nurse
    if (employeeType === 1 || employeeType === 2) {
      return this.appointmentService
        .getByUserId(userId)
        .then(appointment => !appointment);
    }

    return Promise.resolve(true);
  }

  public deleteInactiveUsers() {
    const confirmationPopup = this.alertService.confirm(
      'Are you sure you want to delete the inactive users? Users that are related with a patient chart will not be deleted',
      'Confirm deletion'
    );
    confirmationPopup.then(dialogResult => {
      if (dialogResult) {
        this.userDataSource.load().done(async (result: any) => {
          if (result && result.length > 0) {
            for (const user of result) {
              if (!user.isActive) {
                const userAppointment = await this.appointmentService.getByUserId(
                  user.id
                );
                if (!userAppointment) {
                  this.userService.delete(user.id).then(() => {
                    this.sendDeleteUserNotification(user);

                  });
                }
              }
            }
            this.init();
          }
        });
      }
    });
  }

  private resetUserForm() {
    this.isUserSet = false;
    this.isNewUser = true;
    this.selectedUsers = [];
    this.user = new MedicoApplicationUser();
  }

  private subscribeToCompanyIdChanges() {
    this.companyIdSubscription = this.companyIdService.companyId.subscribe(companyId => {
      if (companyId) {
        this.companyId = companyId;
        if (this.userGrid && this.userGrid.instance) this.userGrid.instance.refresh();
      }
    });
  }

  private initCompanies(): void {
    const companyFilter = new CompanySearchFilter();
    companyFilter.isActive = true;
    companyFilter.take = 100;

    this.companyService.getByFilter(companyFilter).then((c: any[]) => {
      this.companies = c;
    });
  }
  toggleShowNewPasswordUser(): void {
    this.showNewPasswordUser = !this.showNewPasswordUser;
  }
  toggleShowNewPasswordCopyUser(): void {
    this.showNewPasswordCopyUser = !this.showNewPasswordCopyUser;
  }
  syncNewPasswordUser(value: string): void {
    this.newPasswordUser = value;
    this.resetPasswordModel.newPasswordUser = this.newPasswordUser;
  }
  syncNewPasswordCopyUser(value: string): void {
    this.newPasswordCopyUser = value;
    this.resetPasswordModel.newPasswordCopyUser = this.newPasswordCopyUser;
  }

  //send a notification to a superadmin regarding that an user has been deleted
  sendDeleteUserNotification(user: any) {
    const repositoryPayload = {
      createDate: new Date(),
      createdBy: '',
      description: '<p>The user' + ' ' + user.firstName + ' ' + 'has been deleted</p>',
      id: 0,
      link: ' ',
      messagetypeId: 'Message',
      parentId: 0,
      patientId: '',
      priority: 'Medium',
      title: 'User Deleted',
      userIds: ['8f6379e5-d925-499b-b0bd-81c796009afa'],
    };
    this.repositoryService.create(`notification`, repositoryPayload).subscribe(() => {
      return;
    });
  }

  public comparePassword = () => {
    return this.resetPasswordForm.instance.option("formData").newPasswordUser;
  };

  public compareConfirmPassword = () => {
    return this.userForm.instance.option("formData").password;
  };
}
