import { Component } from '@angular/core';
import dxDataGrid from 'devextreme/ui/data_grid';
import dxForm from 'devextreme/ui/form';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  title = 'medico';
  constructor() {
    dxDataGrid.defaultOptions({
      options: {
        loadPanel: null,
        showRowLines: true,
        rowAlternationEnabled: false,
        hoverStateEnabled: true,
        allowColumnResizing: true,
        showColumnLines: false,
        showBorders: false,
        columnAutoWidth: true,
        width: '100%',
        columnChooser: {
          enabled: false
        },
        filterRow: {
          visible: true
        },
        headerFilter: {
          visible: false
        },
        paging: {
          pageSize: 25
        },
        pager: {
          visible: true,
          showPageSizeSelector: true,
          allowedPageSizes: [10, 25, 50, 75, 100]
        },
        onCellPrepared: function (e) {
          if (e.rowType === 'header' || e.rowType === 'data') {
            e.cellElement.css({ 'vertical-align': 'middle', 'text-align': 'left' });
          }
        }
      }
    });


    dxForm.defaultOptions({
      options: {
        showColonAfterLabel: false,
        labelLocation: "top"
      }
    });
  }
}


