import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { schema } from 'prosemirror-schema-basic';
import {Schema, DOMParser} from "prosemirror-model";
import {addListNodes} from "prosemirror-schema-list";
import { exampleSetup } from 'prosemirror-example-setup';
import { Constants } from 'src/app/_classes/constants';

@Component({
  selector: 'prose-mirror-editor',
  templateUrl: './prose-mirror-editor.component.html',
  styleUrls: ['./prose-mirror-editor.component.scss']
})

export class ProsemirrorEditorComponent implements OnInit {

  constructor(private elementRef: ElementRef) {}

  @Input() initialContent?: string;
  @Input() height?: number = 400;
  editorConfig: any;
  editor: any;
  configData: any = {};  
  // @Input() height: number = 320;

  get content(): string {
    return this.editor.getContent();
  }

  ngOnInit(): void {
    this.editorConfiguration();

    const mySchema = new Schema({
      nodes: addListNodes(schema.spec.nodes, "paragraph block*", "block"),
      marks: schema.spec.marks
    });

    const editorContainer = this.elementRef.nativeElement.querySelector('div');
    editorContainer.style.height = `${this.height}px`;

    let parser = (content) => {
      let domNode = document.createElement("div");
      domNode.innerHTML = content;
      return DOMParser.fromSchema(schema).parse(domNode);
    }
    const editorState = EditorState.create({      
      doc: parser(this.initialContent),
      plugins: exampleSetup({schema: mySchema}),
    });

    const view = new EditorView(editorContainer, {
      state: editorState,
    });
  }

  insertContent(content: string) {
    const wrappedContent = `<span>&nbsp;</span>${content}<span>&nbsp;</span>`;
    var initialParagraph = this.editor.schema.node("paragraph");
    this.editor.tr.replaceWith(wrappedContent, wrappedContent , this.editor.schema.node("discourseContainer", { title:'title' }, initialParagraph)).apply();
    // this.editor.execCommand('mceInsertContent', false, wrappedContent);
  }

  private editorConfiguration() {
    this.editorConfig = {
      extended_valid_elements: `${Constants.selectableItem.tagName.toLowerCase()}[${Constants.selectableItem.attributes.id}|${Constants.selectableItem.attributes.metadata}|style]`,
      content_style: Constants.tinymceStyles.detailedEditor,
      height: 400,
      body_class: 'prose-mirror-editor',
      ui_container: '.popup',
      plugins: ["export", "lists", "table", "image", "powerpaste"],
      fontsize_formats: '8pt 10pt 12pt 14pt 18pt 20pt 24pt',
      menubar: true,
      toolbar: 'export insertfile undo redo | fontsize | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | image',
      powerpaste_allow_local_images: true,
      powerpaste_word_import: 'prompt',
      powerpaste_html_import: 'prompt',
      browser_spellcheck: true,
      setup: (editor: any) => {
        this.editor = editor;
      },
      init_instance_callback: (editor: any) => {
        editor && this.initialContent && editor.setContent(this.initialContent);
      },
    };
  }

}