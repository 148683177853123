import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FormatHelper {
    private readonly datePipe = new DatePipe('en-us');
    private readonly defaultFormat = 'dd MMM yyyy';


    public formatDate(value: any, format?: string) {
        if (!format) {
            format = this.defaultFormat;
        }
        return this.datePipe.transform(value, format);
    }

    public formatDateTime(value: any) {
        return this.formatDate(value, 'MM/dd/yyyy hh:mm a');
    }
    public formatTime(value: any) {
        return this.formatDate(value, 'hh:mm a');
    }

    public formatShortDateTime(value: any) {
        return this.formatDate(value, 'MM/dd/yyyy');
    }
}
