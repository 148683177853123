import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { MaskList } from 'src/app/_classes/maskList';
import { Company } from 'src/app/_models/company';
import { AlertService } from 'src/app/_services/alert.service';
import { AppointmentService } from 'src/app/_services/appointment.service';
import { CompanyIdService } from 'src/app/_services/company-id.service';
import { CompanyService } from 'src/app/_services/company.service';
import { DevextremeAuthService } from 'src/app/_services/devextreme-auth.service';
import { DxDataUrlService } from 'src/app/_services/dxDataUrl.service';
import { EmployeeTypeList } from 'src/app/administration/classes/employeeTypeList';
import { AppointmentTypeService } from 'src/app/administration/services/appointment-type.service';
import { Filter } from '../../classes/filter';
import { LibrarySelectableListService } from 'src/app/administration/services/library/library-selectable-list.service';
import { LibrarySelectableListTitles } from 'src/app/_classes/librarySelectableListTitles';

@Component({
  selector: 'patient-filter',
  templateUrl: './patient-filter.component.html',
  styles: ['::ng-deep .alert { margin-bottom: 0px !important; }'],
})
export class PatientFilterComponent implements OnInit, OnDestroy {
  open: boolean = false;

  @Input() infoDataPatients: any;
  infoUpdateAllPatient: any;
  infoUpdateAllPatientCopy: any;
  infoUpdateAllPatientApply: any;
  infoPatient: any;
  filter: any;
  validationMasks: MaskList = new MaskList();

  @Output() filterEmit = new EventEmitter<any>();
  @Output() resetEmit = new EventEmitter<any>();
  customDisplayExpr: any;

  //New
  providerDataSource: any = [];
  companyId: any;
  companyIdSubscription?: Subscription;
  company: Company = new Company();
  locationDataSource: any = {};
  allAppointmentType: any;
  auditStatus: Array<any> = [
    {
      value: 1,
      name: 'Complete',
    },
    {
      value: 2,
      name: 'Incomplete',
    },
  ];

  icdCodesDataSource: any = [];
  icdCodesDataSourceAux: any = [];
  filterPatient!: Filter;

  allAppointmentStatuses: any;
  appointmentStatusItems: any;
  getAllAppointmentFilter: any;
  endDateDisable: boolean = false;

  constructor(
    private dxDataUrlService: DxDataUrlService,
    private devextremeAuthService: DevextremeAuthService,
    private companyIdService: CompanyIdService,
    private companyService: CompanyService,
    private alertService: AlertService,
    private appointmentTypeService: AppointmentTypeService,
    private appointmentService: AppointmentService,
    private librarySelectableListService: LibrarySelectableListService
  ) { }
  async ngOnInit(): Promise<void> {
    // this.infoDataPatients.store.load().done((result: any) => {
    //   const resultWithFormattedDate = this.formatDates(result);
    //   this.infoUpdateAllPatient = resultWithFormattedDate;
    //   this.infoUpdateAllPatientApply = resultWithFormattedDate;
    // });

    this.infoPatient = {};

    this.subscribeToCompanyIdChanges();

    this.initProviderDataSource();
    this.initLocationDataSource();
    this.initIcdCodeDataSource();
    this.getItems();

    this.providerDataSource = this.providerDataSource.store.load().done((result: any) => {
      this.providerDataSource = result;
    });

    this.locationDataSource = this.locationDataSource.store.load().done((result: any) => {
      this.locationDataSource = result;
    });

    const result: any = await this.appointmentTypeService.GetAllAppointmentType();
    this.allAppointmentType = result[0].selectableListValues;

    this.icdCodesDataSource.store.load().done((result: any) => {
      this.icdCodesDataSource = result;
    });
  }

  ngOnDestroy(): void {
    this.companyIdSubscription?.unsubscribe();
  }

  cancelAppointmentFilter() {
    this.open = false;
  }

  resetAppointmentFilter() {
    this.resetEmit.emit(this.infoDataPatients);
    this.filterPatient.physicianId = undefined;
    this.filterPatient.locationId = undefined;
    this.filterPatient.patientId = undefined;
    this.filterPatient.statusValue = undefined;
    this.filterPatient.startDate = undefined;
    this.filterPatient.endDate = undefined;
    this.open = false;
  }

  applyAppointmentFilter() {
    this.filterPatient.companyId = this.companyId;

    if (
      this.filterPatient.startDate != undefined ||
      this.filterPatient.startDate != null
    ) {
      if (
        this.filterPatient &&
        (this.filterPatient.endDate === undefined || this.filterPatient.endDate === null)
      ) {
        this.alertService.error('Please select EndDate.');
        return;
      }

      if (moment(this.filterPatient.endDate).isBefore(this.filterPatient.startDate)) {
        this.alertService.error('The end date must be greater than the start date.');
        return;
      }
    }

    this.appointmentService
      .getAllAppointment(this.filterPatient)
      .subscribe((data: any) => {
        this.getAllAppointmentFilter = data;

        const patientIdsWithAppointments = this.getAllAppointmentFilter.map(
          (appointment: any) => appointment.patientId
        );

        this.infoUpdateAllPatientApply = this.infoUpdateAllPatient.filter(
          (patient: any) => patientIdsWithAppointments.includes(patient.id)
        );

        this.filterEmit.emit(this.infoUpdateAllPatientApply);
      });

    this.open = false;
  }

  openPopover() {
    this.open = !this.open;
  }

  // These methods are for capturing when selectors change their statuses

  providerChanged($event: any) {
    return;
  }

  locationChanged($event: any) { }

  patientChanged(data: any) {
    return;
  }

  formatDates(data: any[]): any[] {
    return data.map(item => {
      return {
        ...item,
        dateOfBirth: moment(item.dateOfBirth).format('MM/DD/YYYY'),
      };
    });
  }

  phoneNumberChanged($event: any) {
    return;
  }

  onFilterFieldChanged($event: any) {
    if (
      this.filterPatient.startDate === undefined ||
      this.filterPatient.startDate === null
    ) {
      this.endDateDisable = false;

      this.filterPatient.endDate = undefined;
    }

    if (
      this.filterPatient.startDate != undefined ||
      this.filterPatient.startDate != null
    ) {
      this.endDateDisable = true;
    }
  }

  //Get company
  private subscribeToCompanyIdChanges() {
    this.companyIdSubscription = this.companyIdService.companyId.subscribe(companyId => {
      if (companyId) {
        this.companyId = companyId;
        this.open = false;
        this.companyService
          .getById(this.companyId)
          .then(company => {
            this.company = company;
          })
          .catch(error => this.alertService.error(error.message ? error.message : error));
      }
    });
  }

  //Get all Provider
  private initProviderDataSource(): void {
    this.providerDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('user'),
      loadParams: { employeeType: EmployeeTypeList.values[0].value },
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.companyId;
        },
        this
      ),
    });
  }

  //Get all location
  private initLocationDataSource(): void {
    this.locationDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('location'),
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.companyId;
        },
        this
      ),
    });
  }

  //Get all icd10 code
  private initIcdCodeDataSource(): void {
    this.icdCodesDataSource.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('icdcode'),
      key: 'Id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, _jQueryAjaxSettings) => { },
        this
      ),
    });
  }

  //Get all appointments
  private getAllAppointment() {
    this.filterPatient.companyId = this.companyId;
    this.appointmentService
      .getAllAppointment(this.filterPatient)
      .subscribe((data: any) => {
        this.getAllAppointmentFilter = data;
      });
  }

  //Get all appointment status
  getItems() {
    this.librarySelectableListService
      .getByTitle(LibrarySelectableListTitles.appointmentStatus)
      .then(selectableList => {
        this.allAppointmentStatuses = selectableList.selectableListValues;
        this.appointmentStatusItems = selectableList.selectableListValues.map(
          appointmentStatus => {
            return appointmentStatus.value;
          }
        );
      });
  }
}
