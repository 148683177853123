import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { forkJoin, from } from 'rxjs';
import { Gender } from 'src/app/_classes/gender';
import { LibrarySelectableListIds } from 'src/app/_classes/librarySelectableListIds';
import { MaskList } from 'src/app/_classes/maskList';
import { PatientCommunicationMethodList } from 'src/app/_classes/patientCommunicationMethodList';
import { RegexRuleList } from 'src/app/_classes/regexRuleList';
import { SelectableListsNames } from 'src/app/_classes/selectableListsNames';
import { StateList } from 'src/app/_classes/stateList';
import { ZipCodeTypeList } from 'src/app/_classes/zipCodeTypeList';
import { SelectableListConfig } from 'src/app/_models/selectableListConfig';
import { AlertService } from 'src/app/_services/alert.service';
import { CompanyService } from 'src/app/_services/company.service';
import { DevextremeAuthService } from 'src/app/_services/devextreme-auth.service';
import { DxDataUrlService } from 'src/app/_services/dxDataUrl.service';
import { PatientInsuranceService } from 'src/app/_services/patient-insurance.service';
import { PatientService } from 'src/app/_services/patient.service';
import { SelectableListService } from 'src/app/_services/selectable-list.service';
import { EmployeeTypeList } from 'src/app/administration/classes/employeeTypeList';
import { MaritalStatus } from 'src/app/patients/classes/maritalStatus';
import { PatientIdentificationCodeType } from 'src/app/patients/models/enums/patientIdentificationCodeType';
import { Patient } from 'src/app/patients/models/patient';
import { PatientIdentificationCodeSearchFilter } from 'src/app/patients/models/PatientIdentificationCodeSearchFilter';
import { PatientInsurance } from 'src/app/patients/models/patientInsurance';
import { ZipCodeType } from 'src/app/patients/models/zipCodeType';
import { PatientIdentificationCodeService } from 'src/app/patients/services/patient-identification-code.service';
import { TrowserViewBase } from 'src/app/shared/base-classes';
import { ButtonOptions } from 'src/app/shared/utils';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.sass']
})

export class DemographicsComponent extends TrowserViewBase implements OnInit {
  @ViewChild('patientForm', { static: false })
  patientForm!: DxFormComponent;
  @ViewChild('insuranceForm', { static: false })
  insuranceForm!: DxFormComponent;
  public popupToolbarItems: any[] = [];
  public serviceType?: number;
  public validationMasks: MaskList = new MaskList();
  public dtsPatient: any;
  public dtsInsurance: any;
  public regexRuleList: RegexRuleList = new RegexRuleList();
  public maxDate: any = new Date();
  public dtsGenders: any[] = Gender.values;
  public dtsMaritalStatus: any[] = MaritalStatus.values;
  public dtsZipCodeTypes: any[] = ZipCodeTypeList.values;
  public dtsPatientCommunications: any[] = PatientCommunicationMethodList.values;
  public dtStates: any[] = StateList.values;
  public dtsPatientSuffix: any = ['None', 'II', 'III', 'Sr', 'Jr'];
  public insuranceCompanies: any = {};
  public currentTabNumber: number = 0;
  public dtsProviders: any = {};
  public dtsMA: any = {};
  public regexPattern = '^\d{5}(-\d{4})?$';
  public isCopyPatientAllowed: boolean = false;
  identificationID1: any;
  identificationID2: any;
  isMrnManual? = false;
  isFinManual? = false;
  mrnManualCode?: string = '';
  finManualCode?: string = '';
  isFinCodeInUse = false;
  selectedPatientCommunicationMethods: any;
  get familyListValues(): string[] {
    return this.selectableListService.getSelectableListValuesFromComponent(
      this,
      SelectableListsNames.familyHistory.familyMembers
    );
  }

  constructor(private devextremeAuthService: DevextremeAuthService, private dxDataUrlService: DxDataUrlService,
    private patientInsuranceService: PatientInsuranceService, private alertService: AlertService,
    private companyService: CompanyService,
    private patientService: PatientService,
    private patientIdentificationCodeService: PatientIdentificationCodeService,
    private selectableListService: SelectableListService) {
    super();

    this.dtsInsurance = new PatientInsurance();
    this.dtsPatient = new Patient();
    this.initPopupToolbarItems();
  }

  ngOnInit(): void {
    this.initProvidersDataSource();
    this.initMasDataSource();

  }



  private initSelectableLists() {
    const familyListConfig = new SelectableListConfig(
      this.dtsPatient.companyId,
      SelectableListsNames.familyHistory.familyMembers,
      LibrarySelectableListIds.familyHistory.familyMembers
    );
    const selectableLists = [familyListConfig];

    this.selectableListService
      .setSelectableListsValuesToComponent(selectableLists, this)
      .then(() => {
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }


  private setCompanyType(companyId: string) {
    this.companyService
      .getById(companyId)
      .then(company => {
        this.serviceType = company.serviceType;
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }


  async fetchAllCodes() {
    const finCodeSearchFilter = new PatientIdentificationCodeSearchFilter();
    finCodeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Fin;
    finCodeSearchFilter.companyId = this.dtsPatient.companyId;

    const mrnCodeSearchFilter = new PatientIdentificationCodeSearchFilter();
    mrnCodeSearchFilter.identificationCodeType = PatientIdentificationCodeType.Mrn;
    mrnCodeSearchFilter.companyId = this.dtsPatient.companyId;

    const finManualCode$ = from(this.patientIdentificationCodeService.getByCompany(finCodeSearchFilter));
    const mrnManualCode$ = from(this.patientIdentificationCodeService.getByCompany(mrnCodeSearchFilter));
    const isFinManual$ = from(this.patientIdentificationCodeService.isManualCode(finCodeSearchFilter));
    const isMrnManual$ = from(this.patientIdentificationCodeService.isManualCode(mrnCodeSearchFilter));

    const [finCode, mrnCode, isFinManual, isMrnManual] = await forkJoin([finManualCode$, mrnManualCode$, isFinManual$, isMrnManual$]).toPromise();

    this.identificationID1 = finCode.identificationLabel;
    this.identificationID2 = mrnCode.identificationLabel;
    this.finManualCode = finCode == null ? " " : finCode.manualCode;
    this.mrnManualCode = mrnCode == null ? " " : mrnCode.manualCode;
    this.isFinManual = isFinManual;
    this.isMrnManual = isMrnManual;

    if (this.isFinManual && this.finManualCode != '') {
      this.patientService.isFINAsigned(this.finManualCode!).then(result => {
        if (result) {
          this.isFinCodeInUse = true;
          this.alertService.error('The manual identification code is in used by another patient. You must generate a new code in admin FIN/MRN view.');
          return;
        }
      })
    }
  }
  public showDemographics(tabNum: any, id: any) {
    this.currentTabNumber = tabNum;
    this.onPatientSelected(id);
  }


  onPatientSelected(patientId) {
    const patientPromise = this.patientService.getById(patientId);
    const patientInsurancePromise =
      this.patientInsuranceService.getByPatientId(patientId);

    Promise.all([patientPromise, patientInsurancePromise])
      .then(result => {
        const patient = result[0];
        const insurance = result[1];
        this.dtsPatient = patient;
        if (insurance === null) {
          this.dtsInsurance = new PatientInsurance();
        }
        else {
          this.dtsInsurance = insurance;
        }
        this.dtsPatient.selectedPatientCommunicationMethods = patient.patientCommunicationMethod
          ? patient.patientCommunicationMethod.split(',').map(value => parseInt(value.trim(), 10))
          : [];
        this.dtsInsurance.selectedPatientCommunicationMethods = patient.patientCommunicationMethod
          ? patient.patientCommunicationMethod.split(',').map(value => parseInt(value.trim(), 10))
          : [];
        this.dtsInsurance.rqid = this.dtsPatient.rqid;
        this.dtsInsurance.fin = this.dtsPatient.fin;
        this.dtsPatient.providerId = this.dtsInsurance.providerId;
        this.dtsPatient.maId = this.dtsInsurance.maId;
        this.setCompanyType(this.dtsPatient.companyId);
        this.fetchAllCodes();
        this.initInsuranceCompanyDataSource();
        this.initSelectableLists();
        this.popupVisible = true;
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }





  get zipMask(): string {
    return this.validationMasks.nineDigitZip;
  }
  get zipMaskInsurance(): string {
    switch (this.dtsInsurance.zipCodeType) {
      case ZipCodeType.FiveDigit:
        return this.validationMasks.fiveDigitZip;
      default:
        return this.validationMasks.nineDigitZip;
    }
  }

  public onPopupHiding(e: any) {
    this.popupVisible = false;
  }
  private initProvidersDataSource(): void {
    this.dtsProviders.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('user'),
      loadParams: { employeeType: EmployeeTypeList.values[0].value },
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.dtsPatient.companyId;
        },
        this
      ),
    });
  }

  private initInsuranceCompanyDataSource(): void {
    this.patientService.getAllInsuranceCompaniess().then(response => {
      this.insuranceCompanies = response;
    });
  }

  private initMasDataSource(): void {
    this.dtsMA.store = createStore({
      loadUrl: this.dxDataUrlService.getLookupUrl('user'),
      loadParams: { employeeType: EmployeeTypeList.values[2].value },
      key: 'id',
      onBeforeSend: this.devextremeAuthService.decorateOnBeforeSendMethod(
        (_method, jQueryAjaxSettings) => {
          jQueryAjaxSettings.data.companyId = this.dtsPatient.companyId;
        },
        this
      ),
    });
  }



  private initPopupToolbarItems = () => {
    this.popupToolbarItems = super.getTrowserToolbarItems(
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('Cancel', true, this.onBtnCancelClick, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('Copy from Patient', this.isCopyPatientAllowed, this.onBtnCopyPatientClick, this.isDataProcessing),
      new ButtonOptions('', false, null, this.isDataProcessing),
      new ButtonOptions('Update', true, this.onBtnDoneClick, this.isDataProcessing),
    );
  }

  private onBtnCopyPatientClick = () => {
    const patientFieldsToExclude = [
      'maritalStatus',
      'companyId',
      'id',
      'patientInsuranceId',
    ];
    this.dtsInsurance.FIN = this.dtsPatient.fin;
    const patient = this.dtsPatient;
    const insurance = this.dtsInsurance;
    if (!patient || !insurance) {
      return;
    }

    for (const fieldName in patient) {
      if (
        patientFieldsToExclude.indexOf(fieldName) !== -1 ||
        !Object.prototype.hasOwnProperty.call(patient, fieldName)
      ) {
        continue;
      }
      (<any>insurance)[fieldName] = (<any>patient)[fieldName];
    }
    this.dtsInsurance.firstName = patient.firstName;
    insurance.patientId = patient.id;
  }


  private onBtnDoneClick = () => {
    if (this.currentTabNumber === 0) {
      const validationResult = this.patientForm.instance.validate();
      if (!validationResult.isValid) {
        return;
      }
      else {
        this.updatePatient();
        this.savePatientInsurance();
      }
    }
  }


  onPatientCommunicationMethodChanged = (e: any) => {
    this.selectedPatientCommunicationMethods = e.value;
  }

  private updatePatient() {
    this.dtsPatient.patientCommunicationMethod = this.dtsPatient.selectedPatientCommunicationMethods.join(', ');
    this.patientService.save(this.dtsPatient).then(patient => {
      this.popupVisible = false;
      this.isDataSaved = true;
    });
  }

  private savePatientInsurance() {
    this.dtsInsurance.FIN = this.dtsPatient.fin;
    this.dtsInsurance.providerId = this.dtsPatient.providerId;
    this.dtsInsurance.maId = this.dtsPatient.maId;
    const validationResult = this.insuranceForm.instance.validate();
    if (!validationResult.isValid) {
      return;
    }
    this.patientInsuranceService
      .save(this.dtsInsurance)
      .then(insurance => {
        this.popupVisible = false;
        this.isDataSaved = true;
      })
      .catch(error => this.alertService.error(error.message ? error.message : error));
  }

  private onBtnCancelClick = () => {
    this.popupVisible = false;
  }



  public onTabSelectionChanged = (e) => {
    if (e.addedItems[0].title === "Demographics") {
      this.isCopyPatientAllowed = false;
    }
    else {
      this.isCopyPatientAllowed = true;
    }
    this.initPopupToolbarItems();
  }

  public getCaseVisible = () => {
    return (!this.isFinManual && this.dtsPatient.fin !== null)
  }

  public getRQVisible = () => {
    return (!this.isMrnManual && this.dtsPatient.rqid !== null)
  }
}
