import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AlertService } from 'src/app/_services/alert.service';
import { ErrorHandlerService } from 'src/app/_services/error-handler.service';
import { BusinessHoursService } from '../../services/business-hours.service';
import { CompanyIdService } from 'src/app/_services/company-id.service';
import { RepositoryService } from 'src/app/_services/repository.service';
import { HolidayHoursService } from '../../services/holiday-hours.service';
import { Day } from 'src/app/_classes/day';
import { Constants } from 'src/app/_classes/constants';
import { LibrarySelectableListService } from 'src/app/administration/services/library/library-selectable-list.service';

const BUSINESS_HOURS_TYPE_SELECT_LIST_TITLE = 'Business Hours Type';
const HOLIDAY_HOURS_TYPE_SELECT_LIST_TITLE = 'Holiday Hours Type';

const TYPES = ['Regular', 'After Hours', 'Lunch', 'Office Close'];

const STATUSES = ['Open', 'Close'];

@Component({
  selector: 'business-hours-management',
  templateUrl: './business-hours-management.component.html',
  styleUrls: ['./business-hours-management.component.sass'],
})
export class BusinessHoursManagementComponent implements OnInit {
  // private businessHours;
  allDays = Day.values;
  allTypes = TYPES;
  allStatuses = STATUSES;
  allBusinessHoursTypes: any;
  allHolidayHoursType: any;

  newDay: string = '';
  newStatus: string = 'Open';
  newType: any;
  newStartAt: any;
  newEndAt: any;
  iFlag: boolean = false;
  loading = false;

  copySDay: string = '';
  copyDDays: string[] = [];

  newHolidayDate: any;
  newHolidayStatus: string = '';
  newHolidayType: string = '';
  newHolidayStartAt: any;
  newHolidayEndAt: any;

  copyHolidaySDate: any;
  copyHolidayDDate: any;

  businessHoursList: any;
  businessHoursEnableStatus: any;

  holidayHoursList: any;
  staffListSource: any;

  isEnable: boolean = false;
  notifyList: string = '';
  staffList: any;
  disableField: boolean = false;

  constructor(
    private repository: RepositoryService,
    private alertService: AlertService,
    private selectableListService: LibrarySelectableListService,
    private businessHoursService: BusinessHoursService,
    private holidayHoursService: HolidayHoursService,
    private companyIdService: CompanyIdService,
    private errorHandler: ErrorHandlerService
  ) { }

  @ViewChild('startTimeBox') startTimeBox: any;
  @ViewChild('endTimeBox') endTimeBox: any;
  @ViewChild('businessHourTypeBox') businessHourTypeBox: any;

  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const instance = this;
    this.businessHoursService.load().then(resp => {
      this.businessHoursService.setBusinessHours(resp);
      this.businessHoursList = this.businessHoursService.getBusinessHoursToRender();
    });

    this.holidayHoursService.load().then(resp => {
      this.holidayHoursList = resp;
      this.holidayHoursService.setHolidayHours(resp);
    });

    this.selectableListService
      .getByTitle(BUSINESS_HOURS_TYPE_SELECT_LIST_TITLE)
      .then(selectableList => {
        instance.allBusinessHoursTypes = selectableList.selectableListValues;
      });

    this.selectableListService
      .getByTitle(HOLIDAY_HOURS_TYPE_SELECT_LIST_TITLE)
      .then(selectableList => {
        instance.allHolidayHoursType = selectableList.selectableListValues;
      });

    this.companyIdService.companyId.subscribe(companyId => {
      if (companyId) {
        this.bindEmployee(companyId);
      }
    });
  }

  removeBusinessHours(index: number) {
    this.businessHoursService.removeBusinessHours(index);
    this.businessHoursList = this.businessHoursService.getBusinessHoursToRender();
  }

  addBusinessHour() {
    this.iFlag = false;

    if (this.newStartAt === undefined || this.newEndAt === undefined) {
      return;
    }
    const newDate1 = moment(this.newStartAt).set({
      year: 1970,
      month: 0,
      date: 1,
      hour: moment(this.newStartAt).hours(),
      minute: moment(this.newStartAt).minutes(),
      second: 0,
    });
    const newDate2 = moment(this.newEndAt).set({
      year: 1970,
      month: 0,
      date: 1,
      hour: moment(this.newEndAt).hours(),
      minute: moment(this.newEndAt).minutes(),
      second: 0,
    });

    if (newDate1.isAfter(newDate2) || newDate1.isSame(newDate2)) {
      alert('The end time must be greater than the start time.');
      return;
    }

    this.businessHoursList.find((item: any) => {
      const [startAt, endAt] = [
        moment(item.startAt, 'hh:mm A').set({ year: 1970, month: 0, date: 1 }).toDate(),
        moment(item.endAt, 'hh:mm A').set({ year: 1970, month: 0, date: 1 }).toDate(),
      ];

      if (
        item.dayName === this.newDay &&
        moment(newDate2).isAfter(startAt) &&
        moment(newDate1).isBefore(endAt)
      ) {
        this.iFlag = true;
      }
    });

    if (this.iFlag) {
      alert('You already have this time space.');
      return;
    }

    this.businessHoursService.addBusinessHours(
      this.newDay,
      this.newStatus,
      this.newType,
      moment(this.newStartAt, 'HH:mm').format('hh:mm A'),
      moment(this.newEndAt, 'HH:mm').format('hh:mm A')
    );

    this.businessHoursList = this.businessHoursService.getBusinessHoursToRender();
  }

  saveBusinessHour() {
    this.businessHoursService.save(this.isEnable, this.notifyList).then(() => {
      this.alertService.alert('Changes saved successfully', 'Success');
    });
  }

  setEnableStatus(val: boolean) {
    this.isEnable = val;
  }

  setDay(val: any) {
    if (val === 'Saturday' || val === 'Sunday') {
      this.disableField = true;
      this.newStatus = 'Close';
    } else {
      this.disableField = false;
      this.newStatus = 'Open';
    }
  }

  setOpenCloseStatus(val: string) {
    if (val === 'Close') {
      this.disableField = true;
      this.businessHourTypeBox.instance.option('value', 'Office Closed');
      this.startTimeBox.instance.option('value', moment('0:00', 'HH:mm'));
      this.endTimeBox.instance.option('value', moment('23:30', 'HH:mm'));
    } else {
      this.disableField = false;
    }
  }

  setDefaultTimes(val: string) {
    const timeRange = Constants.businessHoursTimeMap[val];
    const arr = timeRange.split('-');

    this.startTimeBox.instance.option('value', moment(arr[0], 'HH:mm'));
    this.endTimeBox.instance.option('value', moment(arr[1], 'HH:mm'));
  }

  setNotifyStaffList(val: any) {
    this.notifyList = val.join(',');
  }

  copyBusinessHours() {
    if (this.copySDay == '' || this.copyDDays.length == 0) {
      return;
    }

    this.businessHoursService.copyBusinessHours(this.copySDay, this.copyDDays);
    this.businessHoursList = this.businessHoursService.getBusinessHoursToRender();
  }

  async removeHolidayHours(id: string) {
    await this.holidayHoursService.removeHolidayHours(id);
    this.holidayHoursService.load().then(resp => {
      this.holidayHoursList = resp;
      this.holidayHoursService.setHolidayHours(resp);
    });
  }

  addHolidayHours() {
    this.holidayHoursService.addHolidayHours(
      moment(this.newHolidayDate).format('M/D/YYYY'),
      this.newHolidayStatus,
      this.newHolidayType,
      moment(this.newHolidayStartAt).format('hh:mm A'),
      moment(this.newHolidayEndAt).format('hh:mm A')
    );
    this.holidayHoursList = this.holidayHoursService.getHolidayHours();
  }

  saveHolidayHours() {
    this.holidayHoursService.save().then(() => {
      this.alertService.alert('Saved Holiday hours successfully', 'Success');
    });
  }

  getInitialNotifyList() {
    this.businessHoursService.getBusinessHoursEnableStatus().then(resp => {
      this.isEnable = resp[0]?.isEnable ?? false;
      this.notifyList = resp[0]?.notifyList ?? '';

      this.staffList =
        resp.length > 0
          ? resp[0].notifyList
            .split(',')
            .filter((element: string) => element.length > 0)
            .map((element: string) =>
              this.staffListSource.find((item: any) => item.medicoUserId === element)
            )
            .filter((item: any) => item !== undefined)
            .map((item: any) => item.medicoUserId)
          : [];
    });
  }

  bindEmployee(args: any) {
    this.loading = true;
    const apiUrl = `user/medico-staff?companyId=${args}`;
    this.repository.getData(apiUrl).subscribe({
      next: data => {
        this.staffListSource = data;
        this.getInitialNotifyList();
      },
      error: _error => {
        if (typeof _error.error === 'object') {
          this.alertService.error(
            "Can't connect to the API Server.<br>Please confirm your net connection or contact admin."
          );
        } else {
          this.alertService.error(_error.error);
        }
        this.loading = false;
      },
    });
  }
}
